import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import { AddInsurerAttachmentDTO } from "../../../../../Services/DTO/QuotesDTO";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { TPA_TYPE } from "../../../../../Services/Enum/tpaType";
import { isIntegratedTpa } from "../../../../../Supporting files/HelpingFunction";

interface Props {
  cdBalanceState: Function;
  overviewData: any;
  refetchData: Function;
}

const organisationFields = [
  {
    label: "Organisation Name",
    key: "organisationName",
  },
  {
    label: "Ownership",
    key: "ownership",
  },
  {
    label: "Industry",
    key: "industry",
  },
  {
    label: "Sub-Industry",
    key: "sub_industry",
  },
  {
    label: "E-mail",
    key: "email",
  },
  {
    label: "Contact No.",
    key: "mobile",
  },
  {
    label: "Location",
    key: "location",
  },
];

const PolicyDetailOverViewTab: React.FC<Props> = ({
  overviewData,
  cdBalanceState,
  refetchData,
}) => {
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [attachment, setAttachment] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<string>("policyDetails");
  const [tpaName, settpaName] = useState<string>("");
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
  }, []);
  const Quotes_permission = useAppSelector((state) => state.permissionSlice.BROKER?.Quotes);

  // for scrolling
  const policyDetails = useRef(null);
  const organizationDetails = useRef(null);
  const servicingDetails = useRef(null);
  const attatchmentsdetails = useRef(null);
  const [editQuotesPermission, setEditQuotesPermission] = useState(false);

  useEffect(() => {
    setEditQuotesPermission(
      Quotes_permission?.edit === undefined ? false : Quotes_permission?.edit
    );
  }, []);
  useEffect(() => {
    setShowLoader(true);
  }, []);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const add_attachment = (value: any) => {
    let dto: AddInsurerAttachmentDTO = {
      attachments: {
        doc: value.doc,
        description: value.description,
        doc_name: value.doc_name,
      },
      path: 0,
      quoteId: id ? id : "",
    };

    const onSuccess = (res: any) => {
      refetchData(id);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    QUOTES_SERVICES.add_quote_attachment(onSuccess, onError, dto);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "disabled") {
    } else if (attrName === "attachment_updated") {
      add_attachment(value);
    } else if (attrName === "attachment_deleted") {
      refetchData(id);
    } else {
      attrName(value);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "policyDetails"
                  ? "active sectionLink policyDetails"
                  : "sectionLink policyDetails"
              }
              onClick={() => {
                scrollToSection(policyDetails);
                SetSectionStatus("policyDetails");
              }}
            >
              <span> Policy Details</span>
            </Link>

            <Link
              className={
                sectionStatus === "organizationDetails"
                  ? "active sectionLink organizationDetails"
                  : "sectionLink organizationDetails"
              }
              onClick={() => {
                scrollToSection(organizationDetails);
                SetSectionStatus("organizationDetails");
              }}
            >
              <span>Organization Details</span>
            </Link>
            <Link
              className={
                sectionStatus === "servicingDetails"
                  ? "active sectionLink servicingDetails"
                  : "sectionLink servicingDetails"
              }
              onClick={() => {
                scrollToSection(servicingDetails);
                SetSectionStatus("servicingDetails");
              }}
            >
              <span>Servicing Details</span>
            </Link>

            <Link
              className={
                sectionStatus === "attatchmentsdetails"
                  ? "active sectionLink attatchmentsdetails"
                  : "sectionLink attatchmentsdetails"
              }
              onClick={() => {
                handleClicks(sectionStatus);
                scrollToSection(attatchmentsdetails);
                SetSectionStatus("attatchmentsdetails");
              }}
            >
              <span>Attachments</span>
            </Link>
          </div>
        </Grid>
        <Grid xs>
          <div>
            <Grid container spacing={3}>
              <Grid xs={9} className="pl-0 mb-3">
                <div className="client_info_section">
                  <p className="client_info_p freshPolicy md_width">
                    Fresh Quote
                  </p>
                  <p className="client_info_p empID md_width">
                    Employees :{" "}
                    <span>
                      {overviewData.employeeCount
                        ? overviewData.employeeCount
                        : 0}
                    </span>
                  </p>
                  <p className="client_info_p dependents md_width">
                    Dependents:{" "}
                    <span>
                      {overviewData.dependendsCount
                        ? overviewData.dependendsCount
                        : 0}
                    </span>
                  </p>
                  <p className="client_info_p totalEmployees md_width mr-0">
                    Lives:{" "}
                    <span>
                      {overviewData.totalLives ? overviewData.totalLives : 0}
                    </span>
                  </p>
                </div>
              </Grid>
              <Grid xs={3} className="align-self-center text-right">
                <Link
                  className="networkhosplist"
                  onClick={() => {
                    if (isIntegratedTpa(tpaName)) {
                      window.open(`${ALL_ROUTES.NETWORK_HOSPITAL}?tpaName=${tpaName}&policyNumber=`, "_blank");
                    } else {
                      window.open(`${ALL_ROUTES.NETWORK_HOSPITAL}?tpaName=static&policyNumber=`, "_blank");
                    }
                  }}
                >
                  Network Hospital List
                </Link>
              </Grid>
            </Grid>
            <Box className="detailSection" ref={policyDetails}>
              <div className="sectionTitle">
                <h4>Product Details</h4>
              </div>
              <Grid container spacing={3}>
                <Grid md={5} lg={4} xl={3}>
                  <div className="planname">
                    <p>
                      <span className="group_health"></span>
                      {overviewData.productType?.name}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box className="detailSection" ref={organizationDetails}>
              <div className="sectionTitle" ref={organizationDetails}>
                <h4>Organization Details</h4>
              </div>
              <Grid container spacing={3}>
                <Grid sm={12} style={{ display: "flex", flexWrap: "wrap" }}>
                  {organisationFields.map((item) => {
                    if (overviewData.organisationDetails) {
                      return item.label != "Location" ? (
                        <div className="client_organization">
                          <h6>
                            {item.label}
                            <span>
                              {overviewData.organisationDetails[item.key]}
                            </span>
                          </h6>
                        </div>
                      ) : (
                        <div className="client_organization">
                          <h6>
                            {item.label}
                            <span>
                              {overviewData.organisationDetails[item.key].map(
                                (location: any, idx: number) =>
                                  idx === 0
                                    ? location.name
                                    : ", " + location.name
                              )}
                            </span>
                          </h6>
                        </div>
                      );
                    }
                  })}
                </Grid>
              </Grid>
              {/* <MultipleSearchDropdown
                class_name="inputField"
                title="Locations"
                value={{ key: "yo", label: "yo" }}
                attrName={["yo"]}
                value_update={() => {}}
                // disabled
                warn_status={false}
                multiple_data={[{ key: "yo", label: "yo" }]}
              /> */}
            </Box>

            <Box className="detailSection" ref={attatchmentsdetails}>
              <div className="sectionTitle">
                <h4>Attachments</h4>
                {editQuotesPermission &&
                  <div className="actionBtns">
                    <Link
                      className="blueBtn addfile"
                      sx={{ textDecoration: "none", cursor: "pointer" }}
                      onClick={() => setAttachment(true)}
                    >
                      Add New
                    </Link>
                  </div>
                }
              </div>
              <Attachment
                open_status={attachment}
                attrName={setAttachment}
                value_update={updateMasterState}
                dataArray={overviewData?.attachments}
                deleteurl={QUOTES_SERVICES.delete_attachment}
              />
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PolicyDetailOverViewTab;
