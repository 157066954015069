import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import DocumentChecklistView from "../../../../../Components/Broker/ProductConfigurator/DocumentChecklist/DocumentChecklist";
import SpocTable from "../../../../../Components/Broker/SPOCTable/SpocTable";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  date_time_format,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  isIntegratedTpa
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";

interface Props {
  cdBalanceState: Function;
  checklist?: any;
}

const PolicyDetailOverViewTab: React.FC<Props> = ({
  cdBalanceState,
  checklist,
}) => {
  const { timeZone, dateFormat } = useAppSelector((state: RootState) => state.userDetailsSlice.localInformation);
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [attachment, setAttachment] = React.useState(false);
  const [overviewData, setOverviewData] = useState<any>({});
  const [sectionStatus, SetSectionStatus] = React.useState<string>("policyDetails");
  const policyDetails = useRef(null);
  const insurerDetails = useRef(null);
  const organizationDetails = useRef(null);
  const servicingDetails = useRef(null);
  const docChecklist = useRef(null);
  const attatchmentsdetails = useRef(null);
  const userState: TUserData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [showLoader, setShowLoader] = useState(true);
  const [tpaName, settpaName] = useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [editPermission, setEditPermission] = useState<boolean>(true);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  let timeoutId: any;

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setShowLoader(true);
    setEditPermission(BROKER?.Policies.edit === undefined ? false : BROKER.Policies.edit)

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_policy_overview_tab(id ?? "");

    }

    timeoutId = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

  }, []);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const get_policy_overview_tab = (id: any) => {
    const onSuccess = (res: any) => {
      setTimeout(() => {
        setShowLoader(false);
      }, 1500);
      let data = res.data.data[0];
      setOverviewData(data);
      cdBalanceState(data.policyDetails?.cdBalance);
      settpaName(data.tpa?.name);
      setPolicyNumber(data.policyCase?.policyNumber)
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    POLICIES_SERVICES.get_policy_detail_view(
      `${URL_CONSTANTS.get_policy_detail_view}${id}&tab=OVERVIEW`,
      onSuccess,
      onError
    );
  };

  const policy_attachment = (value: any) => {
    let dto = {
      policyId: id,
      attachments: {
        doc: value.doc,
        description: value.description,
        doc_name: value.doc_name,
      },
    };
    const onSuccess = (res: any) => {
      get_policy_overview_tab(id);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.policy_attachment(dto, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "attachment_deleted") {
      get_policy_overview_tab(id);
    }
    if (attrName === "disabled") {
    } else if (attrName === "attachment_updated") {
      policy_attachment(value);
    } else {
      attrName(value);
    }
  };

  return (
    <>
      {showLoader && (<NewLoader />)}
      <Grid container spacing={3}>
        <Grid
          xs={2}
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "policyDetails"
                  ? "active sectionLink policyDetails"
                  : "sectionLink policyDetails"
              }
              onClick={() => {
                scrollToSection(policyDetails);
                SetSectionStatus("policyDetails");
              }}
            >
              <span> Policy Details</span>
            </Link>

            <Link
              className={
                sectionStatus === "insurerDetails"
                  ? "active sectionLink insurerDetails"
                  : "sectionLink insurerDetails"
              }
              onClick={() => {
                scrollToSection(insurerDetails);
                SetSectionStatus("insurerDetails");
              }}
            >
              <span>Insurer Details</span>
            </Link>
            <Link
              className={
                sectionStatus === "organizationDetails"
                  ? "active sectionLink organizationDetails"
                  : "sectionLink organizationDetails"
              }
              onClick={() => {
                scrollToSection(organizationDetails);
                SetSectionStatus("organizationDetails");
              }}
            >
              <span>Organization Details</span>
            </Link>
            {/* <Link
                  className={
                    sectionStatus === "servicingDetails"
                      ? "active sectionLink servicingDetails"
                      : "sectionLink servicingDetails"
                  }
                  onClick={() => {
                    scrollToSection(servicingDetails);
                    SetSectionStatus("servicingDetails");
                  }}
                >
                  <span>Servicing Details</span>
                </Link> */}

            <Link
              className={
                sectionStatus === "docChecklist"
                  ? "active sectionLink docChecklist"
                  : "sectionLink docChecklist"
              }
              onClick={() => {
                handleClicks(sectionStatus);
                scrollToSection(docChecklist);
                SetSectionStatus("docChecklist");
              }}
            >
              <span>TPA Document Checklist</span>
            </Link>

            <Link
              className={
                sectionStatus === "attatchmentsdetails"
                  ? "active sectionLink attatchmentsdetails"
                  : "sectionLink attatchmentsdetails"
              }
              onClick={() => {
                handleClicks(sectionStatus);
                scrollToSection(attatchmentsdetails);
                SetSectionStatus("attatchmentsdetails");
              }}
            >
              <span>Attachments</span>
            </Link>
          </div>
        </Grid>
        <Grid xs>
          <div>
            <Grid container spacing={3} className="mb-2">
              <Grid xs={12} lg={9} className="pl-0">
                <div className="client_info_section">
                  <p className="client_info_p freshPolicy md_width">
                    Fresh Policy
                  </p>
                  <p className="client_info_p empID md_width">
                    Employees :{" "}
                    <span>
                      {overviewData.employeeCount
                        ? overviewData.employeeCount
                        : 0}
                    </span>
                  </p>
                  <p className="client_info_p dependents md_width">
                    Dependents:{" "}
                    <span>
                      {overviewData.dependendsCount
                        ? overviewData.dependendsCount
                        : 0}
                    </span>
                  </p>
                  {overviewData?.productType?.name === "Group Term" ? ("") : (
                    <p className="client_info_p totalEmployees md_width mr-0">
                      Lives:{" "}
                      <span>
                        {overviewData.totalLives
                          ? overviewData.totalLives : 0}
                      </span>
                    </p>
                  )}

                </div>
              </Grid>
              <Grid xs={12} lg={3} className="align-self-center text-right">
                <Link
                  className="networkhosplist"
                  onClick={() => {
                    if (isIntegratedTpa(tpaName)) {
                      window.open(`${ALL_ROUTES.NETWORK_HOSPITAL}?tpaName=${tpaName}&policyNumber=${policyNumber}`, "_blank");
                    } else {
                      window.open(`${ALL_ROUTES.NETWORK_HOSPITAL}?tpaName=static&policyNumber=${policyNumber}`, "_blank");
                    }
                  }}
                >
                  Network Hospital List
                </Link>
              </Grid>
              {/* <Grid xs={9} lg={10} xl={4} className="align-self-center pl-0" display={'flex'}>
                <Box width={'60%'}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Document Checklist"
                    value={document.label}
                    attrName={["document", "doc"]}
                    value_update={(e: any, val: any) => {
                      setdocument({ label: val.label, id: val.label, warning: false })
                    }}
                    data={[
                      { label: "sample.pdf", value: "1" },
                      { label: "sample2.pdf", value: "2" },
                    ]}
                    warn_status={document.warning}
                    error_message={""}
                  />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  <Link
                    className="blueBtn view ml-4"
                    href={"#"}
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >View</Link>
                  <Link
                    className="blueBtn download ml-4"
                    href={"#"}
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >Download</Link>
                </Box>
              </Grid> */}
            </Grid>
            <Box
              className="detailSection"
              onMouseEnter={() => {
                SetSectionStatus("policyDetails");
              }}
              ref={policyDetails}
            >
              <div className="sectionTitle">
                <h4>Policy Details</h4>
              </div>
              <Grid container spacing={3}>
                <Grid md={5} lg={4} xl={3}>
                  <div className="planname">
                    <p>
                      <span className="group_health"></span>
                      {overviewData.productType
                        ? overviewData.productType.name
                        : ""}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Premium"
                    value={formatCurrencyAccToUser(
                      overviewData?.premiumDetails?.grossPremium,
                      getCurrencyCodeRegex(
                        userState.localInformation.currency
                      ),
                      userState.localInformation.countryCurrencyCode,
                      0
                    )}
                    attrName={"disabled"}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title="CD Balance"
                    value={formatCurrencyAccToUser(
                      overviewData?.policyDetails?.cdBalance,
                      getCurrencyCodeRegex(
                        userState.localInformation.currency
                      ),
                      userState.localInformation.countryCurrencyCode,
                      0
                    )}
                    attrName={"disabled"}
                    value_update={updateMasterState}
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={12}>
                  <p className="net_premium">
                    Net Premium -{" "}
                    {formatCurrencyAccToUser(
                      overviewData?.premiumDetails?.netPremium,
                      getCurrencyCodeRegex(
                        userState.localInformation.currency
                      ),
                      userState.localInformation.countryCurrencyCode,
                      0
                    )}{" "}
                    | Tax-{" "}
                    {formatCurrencyAccToUser(
                      overviewData?.premiumDetails?.taxAmount,
                      getCurrencyCodeRegex(
                        userState.localInformation.currency
                      ),
                      userState.localInformation.countryCurrencyCode,
                      0
                    )}{" "}
                    {""}(
                    {overviewData.premiumDetails &&
                      overviewData?.premiumDetails?.taxPercentage?.map(
                        (data: any, index: number) => (
                          <span>
                            {data.label}
                            {overviewData?.premiumDetails?.taxPercentage
                              .length -
                              1 ===
                              index
                              ? ""
                              : ";" + " "}
                          </span>
                        )
                      )}
                    )
                  </p>
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Policy Start Date"
                    value={date_time_format(
                      overviewData?.policyDetails?.startDate,
                      dateFormat, timeZone
                    )}
                    attrName={"disabled"}
                    value_update={updateMasterState}
                    error_messg="Please enter Start Date"
                    warn_status={false}
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Policy End Date"
                    value={date_time_format(
                      overviewData?.policyDetails?.endDate,
                      dateFormat, timeZone
                    )}
                    attrName={"disabled"}
                    value_update={updateMasterState}
                    error_messg="Please enter End Date"
                    warn_status={false}
                  />
                </Grid>
              </Grid>
            </Box>
            {overviewData?.insurer && (
              <Box
                className="detailSection"
                onMouseEnter={() => {
                  SetSectionStatus("insurerDetails");
                }}
                ref={insurerDetails}
              >
                <div className="sectionTitle">
                  <h4>Insurer Details</h4>
                </div>
                <Grid container spacing={3} alignItems="center">
                  <Grid
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    className="text-center p-0"
                  >
                    <img
                      src={
                        overviewData?.insurer
                          ? overviewData?.insurer[0]?.insurerBasicInfo.logo
                          : ""
                      }
                      alt=""
                      className="insurer_logo"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      title="Insurer Company Name"
                      value={
                        overviewData?.insurer[0]?.insurerBasicInfo.name
                      }
                      attrName={"disabled"}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      title="Insurer Company Office"
                      value={overviewData?.insurerDetails.office}
                      attrName={"disabled"}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>

                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      title="TPA Name"
                      value={overviewData?.tpa?.name}
                      attrName={"disabled"}
                      value_update={updateMasterState}
                      warn_status={false}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5 className="subHeading">Specific Person of Contact</h5>
                    <div className="sectionTitle spoc_section_title">
                      {/* <div className="actionBtns">
                <Link
                className="blueBtn addfile"
                // onClick={() => {
                  //   setOpenContactForm(true);
                  // }}
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Add Contact
                </Link>
                
                <Link
                  className="redBtn delete"
                  // onClick={() => {
                  //   setRemoveCategory(true);
                  // }}
                  sx={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  Delete
                </Link>
              </div> */}
                    </div>
                  </Grid>
                </Grid>
                <Box>
                  <SpocTable
                    data={
                      overviewData.insurer
                        ? overviewData?.insurer[0]?.location
                        : []
                    }
                  />
                </Box>
              </Box>
            )}

            <Box className="detailSection"
              onMouseEnter={() => {
                SetSectionStatus("organizationDetails");
              }} ref={organizationDetails}
            >
              <div className="sectionTitle" ref={organizationDetails}>
                <h4>Organization Details</h4>
              </div>
              <Grid container spacing={3}>
                <Grid xs={6} xl={4}>
                  <div className="client_organization">
                    <h6>
                      Organization Name
                      <span>
                        {
                          overviewData?.organisationDetails
                            ?.organisationName
                        }
                      </span>
                    </h6>
                  </div>
                </Grid>
                <Grid xs={6} xl={4}>
                  <div className="client_organization">
                    <h6>
                      Client ID{" "}
                      <span>
                        {overviewData?.organisationDetails?.clientId}
                      </span>
                    </h6>
                  </div>
                </Grid>
                <Grid xs={6} xl={4}>
                  <div className="client_organization">
                    <h6>
                      Client Location
                      <span>
                        {overviewData?.organisationDetails?.location?.map((data: any) => {
                          let loc: string = data.name;
                          return loc;
                        }).join(", ")}
                      </span>
                    </h6>
                  </div>
                </Grid>
                <Grid xs={6} xl={4}>
                  <div className="client_organization">
                    <h6>
                      Email
                      <span>
                        {
                          overviewData?.organisationDetails
                            ?.organisationEmail
                        }
                      </span>
                    </h6>
                  </div>
                </Grid>
                <Grid xs={6} xl={4}>
                  <div className="client_organization">
                    <h6>
                      Contact No.
                      <span>
                        {overviewData?.organisationDetails?.mobile}
                      </span>
                    </h6>
                  </div>
                </Grid>
              </Grid>
            </Box>
            {/* <Box
                  className="detailSection"
                  onMouseEnter={() => {
                    SetSectionStatus("servicingDetails");
                  }}
                  ref={servicingDetails}
                >
                  <div className="sectionTitle">
                    <h4>Servicing Details</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={3}>
                      <RKTextField
                        class_name="inputField"
                        title="Relationship Manager"
                        value={
                          overviewData?.servicesDetail?.relationshipManager
                        }
                        attrName={"disabled"}
                        value_update={updateMasterState}
                        warn_status={false}
                        error_messg="Please select client"
                      />
                    </Grid>
                    <Grid xs={3}>
                      <RKTextField
                        class_name="inputField"
                        title="Sales Person"
                        value={overviewData?.servicesDetail?.salesPerson}
                        attrName={"disabled"}
                        value_update={updateMasterState}
                        warn_status={false}
                        error_messg=" select client"
                      />
                    </Grid>
                    <Grid xs={3}>
                      <RKTextField
                        class_name="inputField"
                        title="Servicing Partner"
                        value={overviewData?.servicesDetail?.servicingPerson}
                        attrName={"disabled"}
                        value_update={updateMasterState}
                        warn_status={false}
                        error_messg="Please select client"
                      />
                    </Grid>
                    <Grid xs={3}>
                      <RKTextField
                        class_name="inputField"
                        title="TPA"
                        value={overviewData?.tpa?.name}
                        attrName={"disabled"}
                        value_update={updateMasterState}
                        warn_status={false}
                      />
                    </Grid>
                  </Grid>
                </Box> */}

            <Box
              onMouseEnter={() => {
                SetSectionStatus("docChecklist");
              }}
              ref={docChecklist}
            >
              <DocumentChecklistView
                type="tpa"
                role="viewOnly"
                title="TPA Document Checklist"
                checklist={checklist}
              />
            </Box>

            <Box
              className="detailSection"
              onMouseEnter={() => {
                SetSectionStatus("attatchmentsdetails");
              }}
              ref={attatchmentsdetails}
            >
              <div className="sectionTitle">
                <h4>Attachment </h4>
                <div className="actionBtns">
                  <Link
                    className="blueBtn addfile"
                    sx={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={() => {
                      if (!editPermission) return toast.error("Permission not allowed")
                      setAttachment(true)
                    }}
                  >
                    Add New
                  </Link>
                </div>
              </div>
              <Attachment
                open_status={attachment}
                attrName={setAttachment}
                value_update={updateMasterState}
                dataArray={overviewData?.attachments}
                deleteurl={POLICIES_SERVICES.delete_attachment_policy}
              />
            </Box>
          </div>
        </Grid>
      </Grid >
    </>
  );
};

export default PolicyDetailOverViewTab;
