import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Attachment from "../../../../../Components/Broker/Attachment/Attachment";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { AddInsurerAttachmentDTO } from "../../../../../Services/DTO/QuotesDTO";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";
import { useAppSelector } from "../../../../../Store/hooks";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import { extractNumbersFromString } from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import AddQuoteInsurerForm from "./AddInsurerForm";
import { toast } from "react-toastify";

const QuoteInsurer = ({ overviewData }: { overviewData: any }) => {
  const Quotes_permission = useAppSelector(
    (state) => state.permissionSlice.BROKER?.Quotes
  );
  const { formatCurrency } = useFormatCurrency();
  const currencySymbol = formatCurrency("0")[0];
  const [insurers, setInsurers] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string | false>("panel");
  const [insurer, setInsurer] = useState("");
  const [insurerData, setInsurerData] = useState([]);
  const [location, setLocation] = useState("");
  const [officeAddressData, setOfficeAddressData] = useState([]);
  const [netPremium, setNetPremium] = useState("");
  const [grossPremium, setGrossPremium] = useState("");
  const [attachment, setAttachment] = useState<boolean>(false);
  const [workingObj, setWorkingObj] = useState<any>({});
  const [quoteId, setQuoteId] = useState<string>("");
  const [viewAddInsurerForm, setViewAddInsurerForm] = useState<boolean>(false);
  const [addDocumentData, setAddDocumentData] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(true);
  const [addQuotesPermission, setAddQuotesPermission] = useState(true);
  const [editQuotesPermission, setEditQuotesPermission] = useState(false);

  useEffect(() => {
    setAddQuotesPermission(
      Quotes_permission?.add === undefined ? false : Quotes_permission?.add
    );
    setEditQuotesPermission(
      Quotes_permission?.edit === undefined ? false : Quotes_permission?.edit
    );
  }, []);

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get("id");
    setQuoteId(id ? id : "");
    get_insurer_tab_details(id);
    setShowLoader(true);
  }, []);

  const get_insurer_tab_details = (id: string | null) => {
    if (!id) {
      return;
    }

    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      setInsurers(
        res.data.data[0].insurerDetails.map((item: any) => {
          return {
            ...item,
            editable: false,
            viewAddAttachment: false,
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    QUOTES_SERVICES.get_quote_tab_data(onSuccess, onError, id, "INSURER");
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const updateMasterState = (attrName: any, value: any) => {
    console.log("attrName", attrName, value);
    if (attrName[1] === setNetPremium) {
      setInsurers([
        ...insurers.map((item) => {
          if (item.id === attrName[3]) {
            return {
              ...item,
              [attrName[0]]: value,
            };
          } else {
            return item;
          }
        }),
      ]);
    } else if (attrName[1] === setGrossPremium) {
      console.log("yahi hai");
      setInsurers([
        ...insurers.map((item, index) => {
          if (index === attrName[2]) {
            return {
              ...item,
              [attrName[0]]: value,
            };
          } else {
            return item;
          }
        }),
      ]);
    } else {
      attrName(value);
    }
  };

  const quote_individual_edit = (attrName: any) => {
    const dto: { key: string; value: string } = {
      key: `insurerDetails.${attrName[2]}.${attrName[0]}`,
      value: extractNumbersFromString(insurers[attrName[2]][attrName[0]]),
    };

    const onSuccess = (res: any) => {
      get_insurer_tab_details(quoteId);
    };

    const onError = (err: any) => {};

    QUOTES_SERVICES.quote_individual_edit(dto, onSuccess, onError, quoteId);
  };

  const add_attachment = (value: any, insurerIndex: number) => {
    let dto: AddInsurerAttachmentDTO = {
      attachments: {
        doc: value.doc,
        description: value.description,
        doc_name: value.doc_name,
      },
      path: insurerIndex,
      quoteId: quoteId,
    };
    const onSuccess = (res: any) => {
      get_insurer_tab_details(quoteId);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    QUOTES_SERVICES.add_insurer_attachment(onSuccess, onError, dto);
  };

  const handleAttachmentOpen = (insurerId: string, openStatus: boolean) => {
    setInsurers(
      insurers.map((item: any) => {
        if (item.id === insurerId) {
          return {
            ...item,
            viewAddAttachment: openStatus,
          };
        } else {
          return {
            ...item,
            viewAddAttachment: false,
          };
        }
      })
    );
  };

  const removeAttachmentHandler = (insurerId: string, attachmentId: string) => {
    setInsurers((pv) =>
      pv.map((ins: any) => {
        if (ins._id?.toString() === insurerId) {
          const updatedAttach = {
            ...ins,
            attachments: ins.attachments.filter(
              (attachment: any) => attachment._id?.toString() != attachmentId
            ),
          };
          return updatedAttach;
        }
        return ins;
      })
    );
  };

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid
              xs
              style={{
                maxWidth: "260px",
                position: "relative",
                top: "-75px",
                paddingLeft: "0",
              }}
            >
              <div
                className="leftSection h-100"
                style={{ position: "fixed", paddingLeft: "8px" }}
              >
                <Link className={"active sectionLink FeaturesAndExclusionss"}>
                  <span> Insurers</span>
                </Link>
              </div>
            </Grid>

            <Grid xs>
              <Box className="detailSection mb-0">
                {addQuotesPermission && (
                  <div className="sectionTitle">
                    <h4>Insurer Details</h4>
                    <div className="actionBtns">
                      <Link
                        className="blueBtn addfile"
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!editQuotesPermission)
                            return toast.error("Edit permissions not allowed");

                          setViewAddInsurerForm(true);
                        }}
                      >
                        Add Insurer
                      </Link>
                    </div>
                  </div>
                )}
                <Box className="employeeDetail w-100">
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      {insurers.map((data: any, index: number) => (
                        <Accordion
                          key={data.id}
                          className="Accordion_section gradedSection gradepadding"
                          onChange={handleChange("panel1")}
                        >
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <>
                              <Grid
                                container
                                spacing={3}
                                width={"100%"}
                                alignItems={"center"}
                              >
                                <Grid xs={4}>
                                  <h5 className="pt-0">Insurer Name</h5>
                                </Grid>
                                <Grid
                                  xs={8}
                                  style={{
                                    alignSelf: "Center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  {!data.deleteable ? null : (
                                    <div
                                      className="text-right"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <Link
                                        href="#"
                                        className="redBtn delete ml-3"
                                        underline="none"
                                        onClick={() => {
                                          setInsurers((addFeature) =>
                                            insurers.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        Remove
                                      </Link>
                                    </div>
                                  )}
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography></Typography>
                                  </AccordionSummary>
                                </Grid>
                              </Grid>
                            </>
                          </AccordionSummary>

                          <AccordionDetails>
                            {/* Inner content */}
                            <Grid container spacing={3} width={"100%"}>
                              <Grid sm={12} md={6} lg={3} xl={3}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Select Insurer"
                                  value={data.name}
                                  attrName={setInsurer}
                                  value_update={updateMasterState}
                                  data={insurerData}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid>
                              <Grid sm={12} md={6} lg={3} xl={3}>
                                <SearchDropdown
                                  class_name="inputField"
                                  title="Location"
                                  value={data.office}
                                  attrName={setLocation}
                                  value_update={updateMasterState}
                                  data={officeAddressData}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid>
                              <Grid sm={12} md={6} lg={3} xl={3}>
                                <RKTextField
                                  class_name="inputField"
                                  title="Email"
                                  value={data.email ? data.email : ""}
                                  attrName={setLocation}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  disabled={true}
                                />
                              </Grid>
                              <Grid sm={12} md={6} lg={3} xl={3}>
                                <RKTextFieldDisable
                                  validation_type="numeric"
                                  class_name="inputField"
                                  title="Net Premium"
                                  value={formatCurrency(data.netPremium)}
                                  attrName={[
                                    "netPremium",
                                    setNetPremium,
                                    index,
                                    data.id,
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  params={quote_individual_edit}
                                  disabled={!editQuotesPermission}
                                />
                              </Grid>
                              <Grid sm={12} md={6} lg={3} xl={3}>
                                <RKTextFieldDisable
                                  validation_type="numeric"
                                  class_name="inputField"
                                  title="Gross Premium"
                                  value={formatCurrency(data.grossPremium)}
                                  attrName={[
                                    "grossPremium",
                                    setGrossPremium,
                                    index,
                                    data.id,
                                  ]}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                  params={quote_individual_edit}
                                  disabled={!editQuotesPermission}
                                  info_message="dfgfdgdf"
                                  info_status
                                />
                                <p
                                  className="small-text text-right"
                                  style={{
                                    fontSize: "10px",
                                    color: "#ff000099",
                                  }}
                                >
                                  {+data.grossPremium - +data.netPremium > 0
                                    ? "Tax ="
                                    : ""}{" "}
                                  {+data.grossPremium - +data.netPremium < 0
                                    ? "Net Premium cannot be More than Gross Premium"
                                    : `${currencySymbol}${
                                        +data.grossPremium - +data.netPremium
                                      }`}
                                </p>
                              </Grid>
                              {addQuotesPermission && (
                                <Grid xs={12} style={{ textAlign: "right" }}>
                                  <div className="actionBtns">
                                    <Link
                                      className="blueBtn addfile"
                                      sx={{
                                        textDecoration: "none",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleAttachmentOpen(data.id, true);
                                        setAttachment(true);
                                        setAddDocumentData({ ...data, index });
                                        setWorkingObj(data);
                                      }}
                                    >
                                      Add Documents
                                    </Link>
                                  </div>
                                </Grid>
                              )}
                              <Grid xs={12}>
                                <Attachment
                                  childOf={"QUOTEINSURER"}
                                  open_status={data.viewAddAttachment}
                                  attrName={setAttachment}
                                  value_update={(attrName: any, value: any) => {
                                    if (attrName === "attachment_updated") {
                                      add_attachment(value, index);
                                    } else if (
                                      attrName === "attachment_deleted" &&
                                      value
                                    ) {
                                      removeAttachmentHandler(
                                        data._id?.toString(),
                                        value
                                      );
                                    } else {
                                      handleAttachmentOpen(data.id, value);
                                    }
                                  }}
                                  dataArray={data.attachments}
                                  deleteurl={
                                    QUOTES_SERVICES.delete_quote_attachment
                                  }
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                          {/* </div> */}
                        </Accordion>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {/* <Attachment
          open_status={data.viewAddAttachment}
          attrName={setAttachment}
          value_update={(attrName: any, value: any) => {
            if (attrName === "attachment_updated") {
              add_attachment(value, index);
            } else {
              handleAttachmentOpen(data.id, value);
            }
          }}
          dataArray={data.attachments}
        /> */}
            <BulkUploadForm
              open_status={attachment}
              attrName={setAttachment}
              value_update={(attrName: any, value: any) => {
                if (attrName === "attachment_new_data") {
                  add_attachment(value, addDocumentData?.index);
                } else if (attrName === setAttachment) {
                  setAttachment(false);
                  setAddDocumentData(null);
                } else {
                  handleAttachmentOpen(addDocumentData?.id, value);
                }
              }}
            />

            <AddQuoteInsurerForm
              quoteId={quoteId ? quoteId : ""}
              overviewData={overviewData}
              attrName={setViewAddInsurerForm}
              open_status={viewAddInsurerForm}
              value_update={updateMasterState}
              refetchData={get_insurer_tab_details}
              insurers={insurers}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export default QuoteInsurer;
