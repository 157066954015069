import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { differenceInDays, differenceInYears, format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../../APIVar/EmployeeUrlConstant";
import { EMPLOYEE_ENROLMENT_SERVICES } from "../../../../../Services/Employee/EmployeeEnrolmentService";
import { Post } from "../../../../../Supporting files/CallAPI";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import {
  containsLeapYearFebruary,
  countOccurrencesInCoverageConfigString,
  extractNumbersFromString,
  formatCurrencyAccToUser,
  getCurrencyCodeRegex,
  getPaytoEmployeeforFlat,
  updateTimeFormat,
} from "../../../../../Supporting files/HelpingFunction";
import AddFamily from "../AddFamily";
import "../ChoosePolicy/ChoosePolicy.scss";
import PolicyExclusion from "./PolicyExclusion/PolicyExclusion";
import PolicyFeatures from "./PolicyFeatures/PolicyFeatures";
import useIsMobile from "../../../../../Supporting files/MobileProvider";
import MPolicyExclusion from "./MPolicyExclusion/MPolicyExclusion";
import MPolicyFeatures from "./MPolicyFeatures/MPolicyFeatures";

interface Props {
  policiesAmount: any[];
  setPoliciesAmount: Function;
  amountRiders: number;
  setamountRiders: Function;
  handleCheckboxChange: Function;
  value_update: Function;
  policies: any;
  userLocalInfo: any;
  calculatePaymentInfo: any;
  totalPayable: number;
  refetchPolicies: any;
  newlyAddedMembers: any;
  setNewlyAddedMembers: any;
  employeeId: string;
  employeeDob: any;
  userObj: any;
}

const ChoosePolicy: React.FC<Props> = ({
  policiesAmount,
  setPoliciesAmount,
  amountRiders,
  setamountRiders,
  handleCheckboxChange,
  value_update,
  policies,
  userLocalInfo,
  calculatePaymentInfo,
  totalPayable,
  refetchPolicies,
  newlyAddedMembers,
  setNewlyAddedMembers,
  employeeId,
  employeeDob,
  userObj,
}) => {
  console.log("policiespolicies",policies)
  const isMobile = useIsMobile();
  const [addMember, setAddMember] = useState(false);
  const [selectedIndexPolicy, setselectedIndexPolicy] = useState(0);
  const [policyFeature, setPolicyFeature] = useState(false);
  const [policyExclusion, setPolicyExclusion] = useState(false);
  const [MExclusions, setMPolicyExclusion] = useState(false);
  const [MFeatures, setMPolicyFeature] = useState(false);
  const [included, setIncluded] = useState<string>("");
  const [policiesToShow, setPoliciesToShow] = useState<any[]>([]);
  const [ridersAmountArray, setRidersAmountArray] = useState<any[]>([]);
  const [addedRecords, setAddedRecords] = useState<any[]>([]);
  const [policySelected, setPolicySelected] = useState<any>({});
  const [expandedPanelId, setExpandedPanelId] = useState<string>(
    policies[0].policyData._id
  );
  const [expandedSidePanelId, setExpandedSidePanelId] = useState<string>("");
  const [riderIndArr, setriderIndArr] = useState([]);

  let test = 0;
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const authToken = params.get("token");

  useEffect(() => {
    if (newlyAddedMembers) {
      const filteredMembers = newlyAddedMembers.filter(
        (item1: any) => item1.policyId === expandedPanelId
      );
      const newPolicies = policies.map((item: any) => {
        return {
          ...item,
          // memberData: [...item.memberData, ...filteredMembers],
          memberData: [...item.memberData],
        };
      });
      // console.log("neww2",newPolicies[0].memberData)

      let arr: any = [];
      newPolicies.forEach((policyData: any) => {
        if (policyData.policyData?.productType?.name === "Group Term") {
          let payment = getPaytoEmployeeforFlat(policyData, employeeDob);
          arr.push(payment);
        }
      });
      setRidersAmountArray(arr);

      newPolicies.forEach((policy: any) => {
        if (policy.policyData?.productType?.name === "Group Term") {
          policy.policyData.benefits_detail_flat.forEach((benefit: any) => {
            arr.forEach((arrPolicy: any) => {
              arrPolicy.raiders_payout_data.forEach((arrPolicyRider: any) => {
                if (
                  arrPolicyRider.name === benefit.label &&
                  policy.policyData._id === arrPolicy.policyId
                ) {
                  benefit.riderAmount = arrPolicyRider.amount;
                  return;
                }
              });
            });
          });
        }
      });
      setPoliciesToShow(newPolicies);
      setPolicySelected(
        newPolicies.filter(
          (item: any) => item.policyData._id === expandedPanelId
        )[0]
      );
      if (newPolicies[0]?.memberData) {
        setAddedRecords(newPolicies[0]?.memberData);
      }
    }
  }, [policies, newlyAddedMembers, expandedPanelId]);

  console.log("newlyAddedMembers", newlyAddedMembers);

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  function findCoverageType(userAge: number, livesInfoFlat: any[]) {
    if (!Array.isArray(livesInfoFlat) || livesInfoFlat.length === 0) {
      return null;
    }
    for (let i = 0; i < livesInfoFlat.length; i++) {
      const coverageType = livesInfoFlat[i];
      const minAge = parseInt(extractNumbersFromString(coverageType.min));
      const maxAge = parseInt(extractNumbersFromString(coverageType.max));
      if (coverageType.min === "Up to 1 Year") {
        if (userAge < 1) {
          return coverageType;
        } else {
          if (userAge >= minAge && userAge <= maxAge) {
            return coverageType;
          }
        }
      } else {
        if (userAge >= minAge && userAge <= maxAge) {
          return coverageType;
        }
      }
    }
    return null;
  }

  function findIndexInBenefitsDetailsGrade(
    benefitsDetailsGrade: any,
    searchString: any
  ) {
    if (!Array.isArray(benefitsDetailsGrade)) {
      return -1;
    }
    for (let i = 0; i < benefitsDetailsGrade.length; i++) {
      const grade = benefitsDetailsGrade[i];
      if (grade.gradeDivisions && Array.isArray(grade.gradeDivisions)) {
        for (let j = 0; j < grade.gradeDivisions.length; j++) {
          const division = grade.gradeDivisions[j];
          if (
            division.label &&
            typeof division.label === "string" &&
            division.label.includes(searchString)
          ) {
            return i;
          }
        }
      }

      // if (grade.gradeDivisions && Array.isArray(grade.gradeDivisions)) {
      //   for (let j = 0; j < grade.gradeDivisions.length; j++) {
      //     const division = grade.gradeDivisions[j];
      //     if (division.label.includes(searchString)) {
      //       return i;
      //     }
      //   }
      // }
    }

    return -1;
  }

  const calculateAmount = (policy: any) => {
    console.log("policy", policy);
    let amountSinglePolicy: number = 0;
    let totalAmount: number = 0;
    let perdayValue = 0;
    let policyRemainingDays = 365;
    let proRataAmount;
    let proRataUnit: string;
    let memberArrWithPrice: any = [];

    const empAge = differenceInYears(
      new Date(),
      new Date(userObj["Date Of Birth"].toString())
    );
    proRataUnit = policy.policyData.policy.proreta_by;
    if (policy.policyData.payType.type === "STANDARD") {
      return (amountSinglePolicy = 0);
    } else if (policy.policyData.payType.type === "SPLIT") {
      if (policy.policyData.planType.name === "FLAT") {
        console.log("policy---", policy);
        let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
        const coverageTypeEmployee = findCoverageType(
          empAge,
          policy.policyData.livesInfoFlat
        );

        let amountdata = coverageTypeEmployee.coverageTypeData.find(
          (data: any) => data.name === "amount"
        );

        let start = moment(
          new Date(policy.policyData.policyDetails.startDate),
          "DD-MM-YYYY"
        );
        let yearDays = 365;
        if (
          containsLeapYearFebruary(
            new Date(policy.policyData.policyDetails.startDate),
            365
          )
        ) {
          yearDays = 366;
        }

        proRataAmount = +amountdata.value;
        let unit =
          proRataUnit === "YEARLY"
            ? yearDays
            : proRataUnit === "MONTHLY"
            ? 30
            : proRataUnit === "WEEKLY"
            ? 7
            : 1;
        perdayValue = proRataAmount / unit;
        let getSplitPercentageObject: any = policy.policyData.payType.data.find(
          (data: any) => data.name === "EMPLOYEE"
        );
        let startt = new Date(
          new Date(policy.policyData.policyDetails.startDate).setHours(
            0,
            0,
            0,
            0
          )
        );
        let enroll = new Date(
          new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
        );
        let endd = new Date(
          new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
        );
        let policyStart = startt > enroll ? startt : enroll;
        policyRemainingDays = differenceInDays(endd, policyStart) + 1;

        let splitedpercentage = getSplitPercentageObject.value;
        policyRemainingDays =
          policyRemainingDays >= 366 ? 366 : policyRemainingDays;
        let memberAmount =
          perdayValue * policyRemainingDays * (splitedpercentage / 100);
        amountSinglePolicy += memberAmount;
        memberArrWithPrice.push({
          name: "Self",
          amount: memberAmount,
        });
        policy.memberData.length > 0 &&
          policy?.memberData?.map((member: any) => {
            if (member?.checked || member?.checked === undefined) {
              const coverageTypeMember = findCoverageType(
                differenceInYears(new Date(), new Date(member?.dob)),
                policy.policyData.livesInfoFlat
              );
              let amountdata = coverageTypeMember?.coverageTypeData.find(
                (data: any) => data.name === "amount"
              );
              proRataAmount = +amountdata.value;
              perdayValue = proRataAmount / unit;
              let relationname =
                member?.relationShip === "Husband" ||
                member?.relationWithEmployee === "Husband" ||
                member?.relationShip === "Wife" ||
                member?.relationWithEmployee === "Wife"
                  ? "SPOUSE"
                  : member?.relationShip === "Daughter" ||
                    member?.relationWithEmployee === "Daughter" ||
                    member?.relationShip === "Son" ||
                    member?.relationWithEmployee === "Son"
                  ? "KIDS"
                  : member?.relationShip === "Father" ||
                    member?.relationWithEmployee === "Father" ||
                    member?.relationShip === "Mother" ||
                    member?.relationWithEmployee === "Mother"
                  ? "PARENTS"
                  : member?.relationShip === "Father-In-Law" ||
                    member?.relationWithEmployee === "Father-In-Law" ||
                    member?.relationShip === "Mother-In-Law" ||
                    member?.relationWithEmployee === "Mother-In-Law"
                  ? "PARENTSINLAW"
                  : "";

              let getSplitPercentageObject =
                policy?.policyData?.payType?.data?.find(
                  (data: any) => data?.name === relationname
                );
              let startt = new Date(
                new Date(policy.policyData.policyDetails.startDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let enroll = new Date(
                new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
              );
              let endd = new Date(
                new Date(policy.policyData.policyDetails.endDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let policyStart = startt > enroll ? startt : enroll;
              policyRemainingDays = differenceInDays(endd, policyStart) + 1;
              policyRemainingDays =
                policyRemainingDays >= 366 ? 366 : policyRemainingDays;
              let splitedpercentage = getSplitPercentageObject?.value ?? 0;
              let memberAmount =
                perdayValue * policyRemainingDays * (splitedpercentage / 100);
              amountSinglePolicy += memberAmount;
              memberArrWithPrice.push({
                name: `${member.fullName}/${
                  member?.relationShip || member?.relationWithEmployee
                }`,
                amount: memberAmount,
              });
            }
          });
        totalAmount += amountSinglePolicy;
        test += amountSinglePolicy;
        console.log("memberArrWithPrice", memberArrWithPrice);
        return { amountData: amountSinglePolicy, memberArrWithPrice };
      } else if (policy.policyData.planType.name === "GRADED") {
        let index, gradeName: any;
        if (policy.policyData.planType.gradeCriteria !== "Custom") {
          let gradeDivision = userObj[policy.policyData.planType.gradeCriteria];
          index = findIndexInBenefitsDetailsGrade(
            policy.policyData.benefitsDetailsGrade,
            gradeDivision
          );
          gradeName = policy.policyData.benefitsDetailsGrade[index]?.name;
          index = policy?.policyData?.livesInfoGrade?.findIndex(
            (li: any) => li.gradeName === gradeName
          );
        }
        if (policy.policyData.planType.gradeCriteria === "Custom") {
          // debugger;
          if (policy.customValue || policy?.policyData?.customValue) {
            let customIndex = +extractNumbersFromString(
              policy.customValue || policy?.policyData?.customValue
            );
            // let customIndex = +extractNumbersFromString(policy.customValue);
            index = customIndex - 1;
          } else {
            index = 0;
          }
        }
        console.log("index", index);
        let gradeIndex = index ?? 0;
        let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
        const coverageTypeEmployee = findCoverageType(
          empAge,
          policy?.policyData?.livesInfoGrade[gradeIndex ?? 0]?.data
        );
        let amountdata = coverageTypeEmployee?.coverageTypeData.find(
          (data: any) => data.name === "amount"
        );
        let start = moment(
          new Date(policy.policyData.policyDetails.startDate),
          "DD-MM-YYYY"
        );

        let yearDays = 365;
        if (
          containsLeapYearFebruary(
            new Date(policy.policyData.policyDetails.startDate),
            365
          )
        ) {
          yearDays = 366;
        }
        proRataAmount = +amountdata?.value;
        let unit =
          proRataUnit === "YEARLY"
            ? yearDays
            : proRataUnit === "MONTHLY"
            ? 30
            : proRataUnit === "WEEKLY"
            ? 7
            : 1;
        perdayValue = proRataAmount / unit;
        let getSplitPercentageObject = policy.policyData.payType.data.find(
          (data: any) => data.name === "EMPLOYEE"
        );
        let startt = new Date(
          new Date(policy.policyData.policyDetails.startDate).setHours(
            0,
            0,
            0,
            0
          )
        );
        let enroll = new Date(
          new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
        );
        let endd = new Date(
          new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
        );
        let policyStart = startt > enroll ? startt : enroll;
        policyRemainingDays = differenceInDays(endd, policyStart) + 1;

        let splitedpercentage = getSplitPercentageObject.value;
        policyRemainingDays =
          policyRemainingDays >= 366 ? 366 : policyRemainingDays;
        let memberAmount =
          perdayValue * policyRemainingDays * (splitedpercentage / 100);
        amountSinglePolicy += memberAmount;
        memberArrWithPrice.push({
          name: "Self",
          amount: memberAmount,
        });
        policy.memberData.length > 0 &&
          policy.memberData.map((member: any) => {
            if (
              member.checked ||
              member.checked === undefined ||
              member.checked === null
            ) {
              const coverageTypeMember = findCoverageType(
                differenceInYears(new Date(), new Date(member.dob)),
                policy.policyData.livesInfoGrade[gradeIndex ?? 0].data
              );
              let amountdata = coverageTypeMember.coverageTypeData.find(
                (data: any) => data.name === "amount"
              );
              proRataAmount = +amountdata.value;
              perdayValue = proRataAmount / unit;
              let relationname =
                member.relationShip === "Husband" ||
                member.relationWithEmployee === "Husband" ||
                member.relationShip === "Wife" ||
                member.relationWithEmployee === "Wife"
                  ? "SPOUSE"
                  : member.relationShip === "Daughter" ||
                    member.relationWithEmployee === "Daughter" ||
                    member.relationShip === "Son" ||
                    member.relationWithEmployee === "Son"
                  ? "KIDS"
                  : member.relationShip === "Father" ||
                    member.relationWithEmployee === "Father" ||
                    member.relationShip === "Mother" ||
                    member.relationWithEmployee === "Mother"
                  ? "PARENTS"
                  : member.relationShip === "Father-In-Law" ||
                    member.relationWithEmployee === "Father-In-Law" ||
                    member.relationShip === "Mother-In-Law" ||
                    member.relationWithEmployee === "Mother-In-Law"
                  ? "PARENTSINLAW"
                  : "";

              let getSplitPercentageObject =
                policy.policyData.payType.data.find(
                  (data: any) => data.name === relationname
                );

              let startt = new Date(
                new Date(policy.policyData.policyDetails.startDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let enroll = new Date(
                new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
              );
              let endd = new Date(
                new Date(policy.policyData.policyDetails.endDate).setHours(
                  0,
                  0,
                  0,
                  0
                )
              );
              let policyStart2 = startt > enroll ? startt : enroll;
              policyRemainingDays = differenceInDays(endd, policyStart2) + 1;

              policyRemainingDays =
                policyRemainingDays >= 366 ? 366 : policyRemainingDays;
              let splitedpercentage = getSplitPercentageObject?.value ?? 0;
              let memberAmount =
                perdayValue * policyRemainingDays * (splitedpercentage / 100);
              amountSinglePolicy += memberAmount;
              memberArrWithPrice.push({
                name: `${member.fullName}/${
                  member?.relationShip || member?.relationWithEmployee
                }`,
                amount: memberAmount,
              });
            }
          });
        totalAmount += amountSinglePolicy;
        test += amountSinglePolicy;
        return { amountData: amountSinglePolicy, memberArrWithPrice };
      }
    }
  };

  const calculateRiderAmount = (policy: any, riderIndex: any) => {
    let amountSinglePolicy: number = 0;
    let totalAmount: number = 100;
    let perdayValue = 0;
    let policyRemainingDays = 365;
    let proRataAmount;
    let proRataUnit: string;
    const empAge = differenceInYears(
      new Date(),
      new Date(userObj["Date Of Birth"].toString())
    );
    if (policy.policyData.planType.name === "FLAT") {
      proRataUnit =
        policy.policyData?.rider_premium_flat[riderIndex]?.proreta_by;
      let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
      const coverageTypeEmployee = findCoverageType(
        empAge,
        policy.policyData.rider_premium_flat[riderIndex].livesInfoFlat
      );
      let amountdata = coverageTypeEmployee.coverageTypeData.find(
        (data: any) => data.name === "amount"
      );
      let start = moment(
        new Date(policy.policyData.policyDetails.startDate),
        "DD-MM-YYYY"
      );
      let yearDays = 365;
      if (
        containsLeapYearFebruary(
          new Date(policy.policyData.policyDetails.startDate),
          365
        )
      ) {
        yearDays = 366;
      }
      proRataAmount = +amountdata?.value;
      let unit =
        proRataUnit === "YEARLY"
          ? yearDays
          : proRataUnit === "MONTHLY"
          ? 30
          : proRataUnit === "WEEKLY"
          ? 7
          : 1;
      perdayValue = proRataAmount / unit;
      // let getSplitPercentageObject = policy.policyData.payType.data.find(
      //   (data: any) => data.name === "EMPLOYEE"
      // );
      let startt = new Date(
        new Date(policy.policyData.policyDetails.startDate).setHours(0, 0, 0, 0)
      );
      let enroll = new Date(
        new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
      );
      let endd = new Date(
        new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
      );
      let policyStart = startt > enroll ? startt : enroll;
      policyRemainingDays = differenceInDays(endd, policyStart) + 1;

      let splitedpercentage =
        +policy.policyData?.rider_premium_flat[riderIndex]?.premium_split;
      policyRemainingDays =
        policyRemainingDays >= 366 ? 366 : policyRemainingDays;
      let memberAmount =
        perdayValue * policyRemainingDays * (splitedpercentage / 100);
      amountSinglePolicy += memberAmount;
      totalAmount += amountSinglePolicy;
      test += amountSinglePolicy;
      return amountSinglePolicy;
    } else if (policy.policyData.planType.name === "GRADED") {
      let index = 0;
      let gradeName: any;
      proRataUnit =
        policy.policyData?.rider_premium_graded[riderIndex]?.proreta_by;
      if (policy.policyData.planType.gradeCriteria !== "Custom") {
        let gradeDivision = userObj[policy.policyData.planType.gradeCriteria];
        index = findIndexInBenefitsDetailsGrade(
          policy.policyData.benefitsDetailsGrade,
          gradeDivision
        );
        gradeName = policy.policyData.benefitsDetailsGrade[index]?.name;
        index = policy?.policyData?.livesInfoGrade?.findIndex(
          (li: any) => li.gradeName === gradeName
        );
      }

      if (policy.policyData.planType.gradeCriteria === "Custom") {
        if (policy.policyData.customValue) {
          console.log("customValue", policy.policyData.customValue);
          let customIndex = +extractNumbersFromString(
            policy.policyData.customValue
          );
          index = customIndex - 1;
        } else {
          index = 0;
        }
      }
      let gradeIndex = index ?? 0;
      let membrEnrollmentDatePolicy = policy.enrollment_date.toString();
      const coverageTypeEmployee = findCoverageType(
        empAge,
        policy.policyData.rider_premium_graded[riderIndex].livesInfoGrade[
          gradeIndex
        ].data
      );
      let amountdata = coverageTypeEmployee.coverageTypeData.find(
        (data: any) => data.name === "amount"
      );
      let start = moment(
        new Date(policy.policyData.policyDetails.startDate),
        "DD-MM-YYYY"
      );
      let yearDays = 365;
      if (
        containsLeapYearFebruary(
          new Date(policy.policyData.policyDetails.startDate),
          365
        )
      ) {
        yearDays = 366;
      }

      proRataAmount = +amountdata.value;
      let unit =
        proRataUnit === "YEARLY"
          ? yearDays
          : proRataUnit === "MONTHLY"
          ? 30
          : proRataUnit === "WEEKLY"
          ? 7
          : 1;
      perdayValue = proRataAmount / unit;
      // let getSplitPercentageObject = policy.policyData.payType.data.find(
      //   (data: any) => data.name === "EMPLOYEE"
      // );
      let startt = new Date(
        new Date(policy.policyData.policyDetails.startDate).setHours(0, 0, 0, 0)
      );
      let enroll = new Date(
        new Date(membrEnrollmentDatePolicy).setHours(0, 0, 0, 0)
      );
      let endd = new Date(
        new Date(policy.policyData.policyDetails.endDate).setHours(0, 0, 0, 0)
      );
      let policyStart = startt > enroll ? startt : enroll;
      policyRemainingDays = differenceInDays(endd, policyStart) + 1;

      let splitedpercentage =
        +policy.policyData?.rider_premium_graded[riderIndex]?.premium_split;

      policyRemainingDays =
        policyRemainingDays >= 366 ? 366 : policyRemainingDays;
      let memberAmount =
        perdayValue * policyRemainingDays * (splitedpercentage / 100);
      amountSinglePolicy += memberAmount;
      totalAmount += amountSinglePolicy;
      test += amountSinglePolicy;
      return amountSinglePolicy;
    }
  };

  const parseCoverageString = (basicConfig: any, parentConfig: any) => {
    let EmployeeStr = "";
    let ParentStr = "";
    if (!basicConfig) {
      EmployeeStr = "Employee";
    } else {
      if (parentConfig === "Nill") {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "S"
          );
          const childrenOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "C"
          );
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse & ${childrenOccurences} ${
              childrenOccurences === 1 ? "Child" : "Children"
            }`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee & ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee & ${childrenOccurences} ${
              childrenOccurences === 1 ? "Child" : "Children"
            }`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        return { EmployeeStr };
      } else {
        if (basicConfig?.includes("+")) {
          const spouseOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "S"
          );
          const childrenOccurences = countOccurrencesInCoverageConfigString(
            basicConfig,
            "C"
          );
          if (spouseOccurences && childrenOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse, ${childrenOccurences} ${
              childrenOccurences === 1 ? "Child" : "Children"
            }`;
          } else if (spouseOccurences) {
            EmployeeStr = `1 Employee, ${spouseOccurences} Spouse`;
          } else if (childrenOccurences) {
            EmployeeStr = `1 Employee, ${childrenOccurences} ${
              childrenOccurences === 1 ? "Child" : "Children"
            }`;
          }
        } else {
          EmployeeStr = "Employee";
        }
        if (parentConfig) {
          if (parentConfig?.includes("+")) {
            const inLawsOccurences = countOccurrencesInCoverageConfigString(
              parentConfig,
              "PIL"
            );
            const parentsOccurences = countOccurrencesInCoverageConfigString(
              parentConfig,
              "P"
            );
            if (parentsOccurences && inLawsOccurences) {
              ParentStr = `${parentsOccurences} Parents & ${inLawsOccurences} Parents-In-Law`;
            } else if (parentsOccurences) {
              ParentStr = `${parentsOccurences} Parents`;
            } else if (inLawsOccurences) {
              ParentStr = `${inLawsOccurences} Parents-In-Law`;
            }
          }
        }
      }
    }
    return { EmployeeStr, ParentStr };
  };

  const getYearsDiff = (dob: Date) => {
    const today = new Date();
    const diffMilliseconds = today.getTime() - dob.getTime();
    const ageInYears = diffMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Consider leap years
    const roundedAge = Math.ceil(ageInYears);
    return roundedAge;
  };
  // Check for allowed status in policy
  const checkAllowedStatus = () => {
    let existStatusCount = 0;

    policySelected?.memberData?.forEach((employee: any) => {
      if (
        employee.enrolment_status === 40 ||
        employee.relationWithEmployee === 41
      ) {
        existStatusCount++;
      }
    });

    if (
      policySelected?.policyData?.policy?.maxMemberPerFamily > existStatusCount
    ) {
      return true;
    } else {
      toast.warning(
        "Member's Status is not allowing you to add in this Policydfsf"
      );
      return false;
    }
  };

  const checkLocation = (selectedPolicy: any) => {
    let isLoactionMatched =
      selectedPolicy?.policyData?.organisationDetails?.location?.some(
        (data: any) => {
          return data?.id === userObj?.location[0]?.value_id;
        }
      );
    if (isLoactionMatched) {
      return true;
    } else {
      toast.warning("This policy is not for Employee's Location");
      return false;
    }
  };

  // Check for age bands
  const checkAllowedAgeBand = (data: any) => {
    let policy: any = policySelected?.policyData;
    const dobDate = new Date(data?.dob);
    let ageInYears = getYearsDiff(dobDate);

    for (const band of policy?.ageBand) {
      let minAge = band.min?.includes("Up to 1 Year") ? 0 : parseInt(band.min);
      let maxAge = band.max?.includes("Years")
        ? parseInt(band.max)
        : Number.MAX_SAFE_INTEGER;

      if (ageInYears >= minAge && ageInYears <= maxAge) {
        return true;
      }
    }

    toast.warning("This age band not allowed in this policy");
    return false;
  };

  //old code dont delete this untill testing is done

  // const checkAllowedRelation = (addUser: string) => {
  //   let validationFlag = false;

  //   let AddNewPolicy = {
  //     ...policySelected,
  //     memberData: policySelected?.memberData?.map((data: any) => ({
  //       ...data,
  //       relationWithEmployee: data.relationShip || data.relationWithEmployee, // Assuming relationShip is a property of data
  //     })),
  //   };
  //   const allowedMember = AddNewPolicy.policyData.planType.basicConfig;
  //   const isSpouseAllowed = allowedMember?.includes("1S");

  //   // Check if spouse is allowed and if the user is Husband or Wife
  //   if (isSpouseAllowed && (addUser === "Husband" || addUser === "Wife")) {
  //     let isSpousePresentInMember = AddNewPolicy.memberData?.some(
  //       (data: any) => {
  //         return (
  //           data.relationWithEmployee === "Husband" ||
  //           data.relationWithEmployee === "Wife"
  //         );
  //       }
  //     );

  //     if (isSpousePresentInMember) {
  //       toast.warning("Spouse is already present in Policy");
  //     } else {
  //       return true;
  //     }
  //   } else if (
  //     !isSpouseAllowed &&
  //     (addUser === "Husband" || addUser === "Wife")
  //   ) {
  //     toast.warning("Spouse is Not allowed in this policy");
  //   }

  //   //Here check the child is present or not
  //   const isChildAllowed = allowedMember?.includes("1C");
  //   if (isChildAllowed && (addUser === "Son" || addUser === "Daughter")) {
  //     // Count the occurrences of '1C'
  //     let totalAllowedChildInPolicy = allowedMember
  //       ?.split("+")
  //       ?.filter((item: any) => item === "1C")?.length;
  //     let childCountInMember = 0;
  //     AddNewPolicy?.memberData?.forEach((employee: any) => {
  //       if (
  //         employee.relationWithEmployee === "Son" ||
  //         employee.relationWithEmployee === "Daughter"
  //       ) {
  //         childCountInMember++;
  //       }
  //     });
  //     if (totalAllowedChildInPolicy > childCountInMember) {
  //       return true;
  //     } else {
  //       toast.warning("Child Count is exceeded in this Policy");
  //     }
  //   } else if (
  //     !isChildAllowed &&
  //     (addUser === "Son" || addUser === "Daughter")
  //   ) {
  //     toast.warning("Child is Not allowed in this policy");
  //   }

  //   // Check For Parent

  //   const allowedParent = AddNewPolicy.policyData.planType.parentConfig;
  //   const isParentAllowed = allowedParent?.includes("1P");

  //   // Check if Mother is allowed and if the user is Mother
  //   if (isParentAllowed && addUser === "Mother") {
  //     let isMotherPresentInMember = AddNewPolicy?.memberData?.some(
  //       (data: any) => {
  //         return data.relationWithEmployee === "Mother";
  //       }
  //     );

  //     if (isMotherPresentInMember) {
  //       toast.warning(`${addUser} is already present in Policy`);
  //     } else {
  //       return true;
  //     }
  //   } else if (!isParentAllowed && addUser === "Mother") {
  //     toast.warning(`${addUser} is Not allowed in this policy`);
  //   }

  //   // Check if Father is allowed and if the user is Father
  //   if (isParentAllowed && addUser === "Father") {
  //     let isFatherPresentInMember = AddNewPolicy?.memberData?.some(
  //       (data: any) => {
  //         return data.relationWithEmployee === "Father";
  //       }
  //     );

  //     if (isFatherPresentInMember) {
  //       toast.warning(`${addUser} is already present in Policy`);
  //     } else {
  //       return true;
  //     }
  //   } else if (!isParentAllowed && addUser === "Father") {
  //     toast.warning(`${addUser} is Not allowed in this policy`);
  //   }

  //   // Check for parent in law

  //   const isParentInLawAllowed = allowedParent?.includes("1PIL");

  //   // Check if Mother-In-Law is allowed and if the user is Mother-In-Law
  //   if (isParentInLawAllowed && addUser === "Mother-In-Law") {
  //     let isMotherInLawPresentInMember = AddNewPolicy?.memberData?.some(
  //       (data: any) => {
  //         return data.relationWithEmployee === "Mother-In-Law";
  //       }
  //     );

  //     if (isMotherInLawPresentInMember) {
  //       toast.warning(`${addUser} is already present in Policy`);
  //     } else {
  //       return true;
  //     }
  //   } else if (!isParentInLawAllowed && addUser === "Mother-In-Law") {
  //     toast.warning(`${addUser} is Not allowed in this policy`);
  //   }

  //   // Check if Father-In-Law is allowed and if the user is Father-In-Law
  //   if (isParentInLawAllowed && addUser === "Father-In-Law") {
  //     let isFatherInLawPresentInMember = AddNewPolicy?.memberData?.some(
  //       (data: any) => {
  //         return data.relationWithEmployee === "Father-In-Law";
  //       }
  //     );

  //     if (isFatherInLawPresentInMember) {
  //       toast.warning(`${addUser} is already present in Policy`);
  //     } else {
  //       return true;
  //     }
  //   } else if (!isParentInLawAllowed && addUser === "Father-In-Law") {
  //     toast.warning(`${addUser} is Not allowed in this policy`);
  //   }
  //   if (addUser === "Sister" || addUser === "Brother") {
  //     toast.warning("This Relation is not allowed in this Policy");
  //   }
  //   return validationFlag;
  // };

  const checkAllowedRelation = (addUser: string) => {
    let validationFlag = false;

    // Check if spouse is already present in any of the policies
    const isHusbandPresent = policiesAmount.some((policy: any) =>
      policy.memberData?.some(
        (member: any) => member.relationShip === "Husband"
      )
    );
    const isWifePresent = policiesAmount.some((policy) =>
      policy.memberData?.some((member: any) => member.relationShip === "Wife")
    );

    let AddNewPolicy = {
      ...policySelected,
      memberData: policySelected?.memberData?.map((data: any) => ({
        ...data,
        relationWithEmployee: data.relationShip || data.relationWithEmployee,
      })),
    };

    const allowedMember = AddNewPolicy.policyData.planType.basicConfig;
    const isSpouseAllowed = allowedMember?.includes("1S");

    // Logic for Spouse
    if (isSpouseAllowed && (addUser === "Husband" || addUser === "Wife")) {
      if (addUser === "Husband" && isWifePresent) {
        toast.warning(
          "Wife is already present in another policy. You cannot add Husband."
        );
      } else if (addUser === "Wife" && isHusbandPresent) {
        toast.warning(
          "Husband is already present in another policy. You cannot add Wife."
        );
      } else {
        // No spouse present, allow adding spouse
        validationFlag = true;
      }
    } else if (
      !isSpouseAllowed &&
      (addUser === "Husband" || addUser === "Wife")
    ) {
      toast.warning("Spouse is Not allowed in this policy");
    }

    // Check if children are allowed
    const isChildAllowed = allowedMember?.includes("1C");
    if (isChildAllowed && (addUser === "Son" || addUser === "Daughter")) {
      let totalAllowedChildInPolicy = allowedMember
        ?.split("+")
        ?.filter((item: any) => item === "1C")?.length;
      let childCountInMember = 0;
      AddNewPolicy?.memberData?.forEach((employee: any) => {
        if (
          employee.relationWithEmployee === "Son" ||
          employee.relationWithEmployee === "Daughter"
        ) {
          childCountInMember++;
        }
      });
      if (totalAllowedChildInPolicy > childCountInMember) {
        validationFlag = true;
      } else {
        toast.warning("Child Count is exceeded in this Policy");
      }
    } else if (
      !isChildAllowed &&
      (addUser === "Son" || addUser === "Daughter")
    ) {
      toast.warning("Child is Not allowed in this policy");
    }

    // Check for Parent
    const allowedParent = AddNewPolicy.policyData.planType.parentConfig;
    const isParentAllowed = allowedParent?.includes("1P");

    // Check if Mother is allowed and if the user is Mother
    if (isParentAllowed && addUser === "Mother") {
      let isMotherPresentInMember = AddNewPolicy?.memberData?.some(
        (data: any) => data.relationWithEmployee === "Mother"
      );

      if (isMotherPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        validationFlag = true;
      }
    } else if (!isParentAllowed && addUser === "Mother") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check if Father is allowed and if the user is Father
    if (isParentAllowed && addUser === "Father") {
      let isFatherPresentInMember = AddNewPolicy?.memberData?.some(
        (data: any) => data.relationWithEmployee === "Father"
      );

      if (isFatherPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        validationFlag = true;
      }
    } else if (!isParentAllowed && addUser === "Father") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check for Parent-In-Law
    const isParentInLawAllowed = allowedParent?.includes("1PIL");

    // Check if Mother-In-Law is allowed and if the user is Mother-In-Law
    if (isParentInLawAllowed && addUser === "Mother-In-Law") {
      let isMotherInLawPresentInMember = AddNewPolicy?.memberData?.some(
        (data: any) => data.relationWithEmployee === "Mother-In-Law"
      );

      if (isMotherInLawPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        validationFlag = true;
      }
    } else if (!isParentInLawAllowed && addUser === "Mother-In-Law") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Check if Father-In-Law is allowed and if the user is Father-In-Law
    if (isParentInLawAllowed && addUser === "Father-In-Law") {
      let isFatherInLawPresentInMember = AddNewPolicy?.memberData?.some(
        (data: any) => data.relationWithEmployee === "Father-In-Law"
      );

      if (isFatherInLawPresentInMember) {
        toast.warning(`${addUser} is already present in Policy`);
      } else {
        validationFlag = true;
      }
    } else if (!isParentInLawAllowed && addUser === "Father-In-Law") {
      toast.warning(`${addUser} is Not allowed in this policy`);
    }

    // Siblings are not allowed in this policy
    if (addUser === "Sister" || addUser === "Brother") {
      toast.warning("This Relation is not allowed in this Policy");
    }

    return validationFlag;
  };

  const checkAllowedParentOrParentsInLaw = (addUser: string) => {
    let allowed = true;
    console.log("policySelected.memberData", policySelected);
    const { setOfParents } = policySelected?.policyData?.planType;
    if (setOfParents === "both") return true;
    if (addUser === "Mother-In-Law" || addUser === "Father-In-Law") {
      let isParentPresentInMember = policySelected.memberData.some(
        (data: any) => {
          return (
            data.relationWithEmployee === "Mother" ||
            data.relationWithEmployee === "Father" ||
            data.relationShip === "Mother" ||
            data.relationShip === "Father"
          );
        }
      );

      if (isParentPresentInMember) {
        toast.warn(
          "This policy allows you to add  either Parent or Parents-In-Law"
        );
        allowed = false;
      } else {
        allowed = true;
      }
    } else if (addUser === "Mother" || addUser === "Father") {
      let isParentPresentInMember = policySelected.memberData.some(
        (data: any) => {
          return (
            data.relationWithEmployee === "Mother-In-Law" ||
            data.relationWithEmployee === "Father-In-Law" ||
            data.relationShip === "Mother-In-Law" ||
            data.relationShip === "Father-In-Law"
          );
        }
      );
      if (isParentPresentInMember) {
        toast.warn(
          "This policy allows you to add  either Parent or Parents-In-Law"
        );
        allowed = false;
      } else {
        allowed = true;
      }
    }

    return allowed;
  };

  const checkMaxAgeForChildren = (
    maxChildAge: string,
    selectedMemberDOB: any,
    selectedRelation: string
  ) => {
    if (selectedRelation === "Son" || selectedRelation === "Daughter") {
      const dobDate = new Date(selectedMemberDOB ? selectedMemberDOB : "");
      const ageInYears = getYearsDiff(dobDate);
      let maxage: number = +extractNumbersFromString(maxChildAge);
      if (ageInYears > maxage) {
        toast.warning(`Permissible maximum age for children is ${maxChildAge}`);
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const add_family_member = (detailObj: any, toggle?: boolean) => {
    if (toggle === false) {
      return addFamilyMemberFromJourney(detailObj);
    }
    if (
      checkAllowedRelation(detailObj.relationShip) &&
      checkAllowedAgeBand(detailObj) &&
      checkAllowedStatus() &&
      checkAllowedParentOrParentsInLaw(detailObj.relationShip) &&
      checkMaxAgeForChildren(
        policySelected.policyData.maxChildAge,
        detailObj.dob,
        detailObj.relationShip
      )
    ) {
      function generateRandomCode() {
        var min = 100000;
        var max = 999999;
        var randomCode = Math.floor(Math.random() * (max - min + 1)) + min;
        return randomCode.toString();
      }

      const newMembers = [...newlyAddedMembers];
      newMembers.push({
        ...detailObj,
        newlyAdded: true,
        policyId: expandedPanelId,
        checked: true,
        id: detailObj._id,
        _id: generateRandomCode(),
      });
      const updatedPolicies = policiesAmount?.map((policy) => {
        if (policy?.policyData?._id === expandedPanelId) {
          const clonedMemberData = [
            ...policy?.memberData,
            {
              ...detailObj,
              newlyAdded: true,
              policyId: expandedPanelId,
              checked: true,
              id: detailObj._id,
              _id: generateRandomCode(),
            },
          ];

          return {
            ...policy,
            memberData: clonedMemberData,
          };
        } else {
          return policy;
        }
      });
      setPoliciesAmount(updatedPolicies);
      setNewlyAddedMembers(newMembers);
      setAddMember(false);
      // if (toggle === false) {
      //   addFamilyMemberFromJourney(detailObj)
      // } else {
      // }
    }
  };

  console.log("policySelected", policySelected);
  const addFamilyMemberFromJourney = (detailObj: any) => {
    if (
      checkAllowedRelation(detailObj.relationShip) &&
      checkAllowedAgeBand(detailObj) &&
      checkAllowedStatus() &&
      checkAllowedParentOrParentsInLaw(detailObj.relationShip) &&
      checkMaxAgeForChildren(
        policySelected.policyData.maxChildAge,
        detailObj.dob,
        detailObj.relationShip
      )
    ) {
      let payload = {
        member: {
          fullName: detailObj.fullName,
          relationWithEmployee: detailObj.relationShip,
          dob: detailObj.dob,
        },
      };
      Post(
        EMPLOYEE_URL_CONSTANTS.add_family_member_from_journey,
        payload,
        authToken
      )
        ?.then((data: any) => {
          if (data[0] === "success") {
            const newDep = data[1];
            const newMembers = [...newlyAddedMembers];
            newMembers.push({
              ...detailObj,
              newlyAdded: true,
              policyId: expandedPanelId,
              checked: true,
              id: detailObj._id,
              _id: newDep?._id,
            });
            const updatedPolicies = policiesAmount?.map((policy) => {
              if (policy?.policyData?._id === expandedPanelId) {
                const clonedMemberData = [
                  ...policy?.memberData,
                  {
                    ...detailObj,
                    newlyAdded: true,
                    policyId: expandedPanelId,
                    checked: true,
                    id: detailObj._id,
                    _id: newDep?._id,
                  },
                ];

                return {
                  ...policy,
                  memberData: clonedMemberData,
                };
              } else {
                return policy;
              }
            });
            setPoliciesAmount(updatedPolicies);
            setNewlyAddedMembers(newMembers);
            setAddMember(false);
          }
        })
        ?.catch((err: any) => console.log("err", err));
    }
  };
  console.log("policiesAmount", policiesAmount);

  useEffect(() => {
    let spouseCount = 0;
    let childrenCount = 0;
    let parentsCount = 0;
    let parentInLawsCount = 0;
    let str = "";
    addedRecords?.map((item: any) => {
      let relation = item.relationShip || item.relationWithEmployee;
      if (["Husband", "Wife"]?.includes(relation)) {
        spouseCount++;
      } else if (["Son", "Daughter"]?.includes(relation)) {
        childrenCount++;
      } else if (["Father", "Mother"]?.includes(relation)) {
        parentsCount++;
      } else if (["Father-In-Law", "Mother-In-Law"]?.includes(relation)) {
        parentInLawsCount++;
      }
    });
    const count = [
      { title: spouseCount === 1 ? "Spouse" : "Spouses", count: spouseCount },
      {
        title: childrenCount === 1 ? "child" : "children",
        count: childrenCount,
      },
      { title: parentsCount === 1 ? "Parent" : "Parents", count: parentsCount },
      {
        title: parentInLawsCount === 1 ? "Parent in Law" : "Parent in Laws",
        count: parentInLawsCount,
      },
    ];

    count.map((c) => {
      if (c.count > 0) {
        str += `${c.count} ${c.title}, `;
      }
    });
    setIncluded(str.slice(0, str.length - 2));
  }, [addedRecords]);

  // const updateToggle = (
  //   e: any,
  //   mainIndex: number,
  //   Subindex: number,
  //   detail: any
  // ) => {
  //   let data = [...policiesToShow];
  //   let obj = { ...data[mainIndex] };

  //   let updatedBenefits = obj.policyData.benefits_detail_flat.map(
  //     (benefit: any, index: number) => {
  //       if (index === Subindex) {
  //         return {
  //           ...benefit,
  //           enable: e.target.checked,
  //         };
  //       }
  //       return benefit;
  //     }
  //   );
  //   obj.policyData.benefits_detail_flat = updatedBenefits;
  //   data[mainIndex] = obj;

  //   setPoliciesToShow(data);

  //   addRiders(data);
  // };

  // const addRiders = (data: any) => {
  //   let amount = totalPayable;

  //   data.forEach((policyData: any) => {
  //     let dataNew =
  //       policyData.policyData?.productType?.name === "Group Term" &&
  //       policyData.policyData.planType.name === "GRADED"
  //         ? policyData.policyData.benefits_detail_graded[0].riders
  //         : policyData.policyData?.benefits_detail_flat;

  //     dataNew &&
  //       dataNew.forEach((rider: any) => {
  //         if (rider.enable) {
  //           amount += Number(rider.riderAmount || rider.amount);
  //         }
  //       });
  //   });
  //   setamountRiders(amount);
  // };

  const submitRider = () => {
    let data: any = policiesToShow;
    let dto: any[] = [];
    data.forEach((policy: any) => {
      if (policy.policyData?.productType?.name === "Group Term") {
        if (policy.policyData?.planType?.name === "GRADED") {
          dto.push({
            employeeId,
            policyId: policy.policyData?._id,
            rider: policy.policyData.benefits_detail_graded[0]?.riders
              ?.filter((element: any) => element.enable)
              .map((element: any) => {
                return {
                  id: element._id,
                  description: element.description,
                  label: element.label,
                  amount: element.amount,
                  enable: element.enable,
                };
              }),
          });
        } else {
          dto.push({
            employeeId,
            policyId: policy.policyData?._id,
            rider: policy.policyData.benefits_detail_flat
              .filter((element: any) => element.enable)
              .map((element: any) => {
                return {
                  id: element._id,
                  description: element.description,
                  label: element.label,
                  amount: element.amount,
                  enable: element.enable,
                };
              }),
          });
        }
      }
    });

    let final = {
      data: dto,
    };
    const onSuccess = (res: any) => {};
    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_ENROLMENT_SERVICES.submitRider(onSuccess, onError, final);
  };

  const updateToggle = (e: any, mainIndex: number, Subindex: number) => {
    let data = [...policiesToShow];
    let obj = { ...data[mainIndex] };
    let dataNew =
      obj.policyData?.productType?.name === "Group Term" &&
      obj.policyData.planType.name === "GRADED"
        ? obj.policyData.benefits_detail_graded[0].riders
        : obj.policyData?.benefits_detail_flat;
    let updatedBenefits = dataNew.map((benefit: any, index: number) => {
      if (index === Subindex) {
        return {
          ...benefit,
          enable: e.target.checked,
        };
      }
      return benefit;
    });
    if (
      obj.policyData?.productType?.name === "Group Term" &&
      obj.policyData.planType.name === "GRADED"
    ) {
      obj.policyData.benefits_detail_graded[0].riders = updatedBenefits;
    } else {
      obj.policyData.benefits_detail_flat = updatedBenefits;
    }
    data[mainIndex] = obj;
    setPoliciesToShow(data);
  };

  const includedFunc = (item: any) => {
    let includes = "";
    let voluntary = "";
    let ExistingRider: any[] = [];
    // if Riders exists in Group term
    if (
      item?.policyData?.productType?.name === "Group Term" &&
      item?.policyData?.is_rider
    ) {
      if (item?.policyData?.planType?.name === "FLAT") {
        ExistingRider = item?.policyData?.rider_premium_flat?.filter(
          (val: any) => Number(val?.premium_split) > 0
        );
      } else {
        ExistingRider = item?.policyData?.rider_premium_graded?.filter(
          (val: any) => Number(val?.premium_split) > 0
        );
      }
    }
    if (item?.policyData?.payType?.type === "STANDARD") {
      let data = parseCoverageString(
        item?.policyData?.planType?.basicConfig,
        item?.policyData?.planType?.parentConfig
      );
      includes = `${data.EmployeeStr} ${data.ParentStr || ""}`;
      voluntary = "";
    } else if (item?.policyData?.payType?.type === "SPLIT") {
      const incExist = item?.policyData?.payType?.data?.filter(
        (val: any) => val.value <= 0
      );
      const volExist = item?.policyData?.payType?.data?.filter(
        (val: any) => val.value > 0
      );
      incExist?.map((va: any) => {
        includes += `${va.name}, `;
      });
      volExist?.map((va: any) => {
        voluntary += `${va.name}, `;
      });
      includes = includes?.slice(0, -2);
      voluntary = voluntary?.slice(0, -2);
    }
    return { includes, voluntary, ExistingRider };
  };

  const handleCheckedMember = (
    member: any,
    memberIndex: number,
    isChecked: boolean,
    newlyAdded: boolean,
    memId: string
  ) => {
    if (newlyAdded) {
      const UpdateMember = newlyAddedMembers;
      if (!isChecked) {
        const index = UpdateMember.findIndex((m: any) => m.memId === memId);
        if (index !== -1) {
          UpdateMember.splice(index, 1);
          setNewlyAddedMembers(UpdateMember);
        }
      } else {
        UpdateMember.push(member);
        setNewlyAddedMembers(UpdateMember);
      }
    }
  };

  useEffect(() => {
    let riderAllowed: any = [];
    if (policies?.length > 0) {
      policies?.map((item: any) => {
        if (item?.policyData?.productType?.name === "Group Term") {
          if (item.policyData.planType.name === "GRADED") {
            item?.policyData?.benefits_detail_graded[0]?.riders?.map(
              (detail: any) => {
                riderAllowed.push(detail?.enable);
              }
            );
          } else {
            item?.policyData?.benefits_detail_flat?.map((detail: any) => {
              riderAllowed.push(detail?.enable);
            });
          }
        }
      });
    }
    setriderIndArr(riderAllowed);
  }, []);

  return (
    <>
      {isMobile ? (
        <Box className="choosePolicy_wrapper p-0">
          <Grid container paddingX={"0px"} paddingY={"0"}>
            <Grid xs={12} className="my-2 pb-16">
              <div className="MenrollmentBackBtn">
                <ArrowBackIosOutlinedIcon
                  fontSize="small"
                  onClick={() => {
                    value_update("setStartThirdScreen", false);
                  }}
                />
                <h5> Policy Details</h5>
              </div>

              <Grid xs={8} padding={0} className="mt-20 pl-3 pr-3 ">
                {policies &&
                  policies?.map((item: any, index: number) => {
                    let fullAmount: any = calculateAmount(item);
                    let amountData = fullAmount.amountData || 0;
                    let dataNew =
                      item.policyData?.productType?.name === "Group Term" &&
                      item.policyData.planType.name === "GRADED"
                        ? item.policyData.benefits_detail_graded[0].riders
                        : item.policyData?.benefits_detail_flat;
                    console.log("fullAmount", fullAmount);

                    let enrEnd = new Date(item?.enrollment_date);
                    let memPeriod = +extractNumbersFromString(
                      item?.policyData.policy.newMemberPeriod
                    );
                    let enrolLastDate;
                    if (
                      new Date() <
                      new Date(item.policyData.policyDetails?.startDate)
                    ) {
                      enrolLastDate = new Date(
                        item.policyData.policy?.enrolmentEndDate
                      );
                      let gracePeriod = +extractNumbersFromString(
                        item?.policyData.policy.gracePeriod
                      );
                      enrolLastDate.setDate(
                        enrolLastDate.getDate() + gracePeriod
                      );
                    } else {
                      enrolLastDate = new Date(enrEnd);
                      enrolLastDate.setDate(enrEnd.getDate() + memPeriod - 1);
                      console.log(enrolLastDate, "prem>>>>>>>>>");
                    }
                    return (
                      <Accordion
                        className="Accordion_section px-0 m-0"
                        style={{
                          borderRadius: "20px",
                          boxShadow: " 0px 8px 20px 0px #2160AA33",
                        }}
                        expanded={item?.policyData._id === expandedPanelId}
                        onChange={() => {
                          setselectedIndexPolicy(index);
                          if (expandedPanelId === item?.policyData._id) {
                            setPolicySelected(item);
                            setExpandedPanelId("");
                          } else {
                            setExpandedPanelId(item?.policyData._id);
                          }
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          paddingRight={0}
                          padding={0}
                        >
                          <Grid
                            xs={12}
                            className="px-0"
                            // width={"calc(100% - 30px)"}
                          >
                            <AccordionSummary
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{
                                display: "inline-block",
                                width: "100%",
                                padding: "0",
                              }}
                            >
                              <Typography
                                className="title"
                                style={{ width: "100%" }}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems={"center"}
                                >
                                  <Grid xs={12} sx={{ alignSelf: "center" }}>
                                    <h5
                                      className="w-full"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {item?.policyData?.productType?.name}
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontFamily: "Montserrat",
                                      }}
                                    >
                                      {
                                        item?.policyData?.policyCase
                                          ?.policyNumber
                                      }
                                    </p>
                                  </Grid>
                                  <Grid xs={12}>
                                    <hr />
                                  </Grid>

                                  {item?.policyData._id === expandedPanelId ? (
                                    <Grid
                                      xs
                                      display={"flex"}
                                      justifyContent={"start"}
                                    >
                                      <ul className="m_policy_calender">
                                        <li
                                          className="lastdate"
                                          style={{ width: "50%" }}
                                        >
                                          Last Date to Enroll
                                          <span>
                                            {updateTimeFormat(
                                              enrolLastDate?.toString(),
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </li>
                                        <li
                                          className="lastdate"
                                          style={{
                                            width: "45%",

                                            marginLeft: "5%",
                                          }}
                                        >
                                          Policy Start Date
                                          <span>
                                            {updateTimeFormat(
                                              item?.policyData.policyDetails
                                                .startDate,
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </li>
                                        <li
                                          className="startdate"
                                          style={{
                                            width: "50%",
                                            marginTop: "20px",
                                          }}
                                        >
                                          Policy End Date
                                          <span>
                                            {updateTimeFormat(
                                              item?.policyData.policyDetails
                                                .endDate,
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </li>
                                      </ul>
                                    </Grid>
                                  ) : (
                                    <Grid xs sx={{ alignSelf: "center" }}>
                                      {/* <img
                                        src={
                                          item?.insurerDetails.insurerBasicInfo
                                            .logo
                                        }
                                        alt="logo"
                                        height={51}
                                      /> */}
                                    </Grid>
                                  )}
                                </Grid>
                              </Typography>
                            </AccordionSummary>
                          </Grid>
                          <Grid
                            xs
                            style={{
                              alignItems: "start",
                              display: "flex",
                              justifyContent: "end",
                              padding: "0",
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{
                                paddingRight: "0",
                                top: "-8px",
                                right: "8px",
                                height: "30px",
                              }}
                            >
                              <Typography></Typography>
                            </AccordionSummary>
                          </Grid>
                        </Grid>
                        <AccordionDetails style={{ padding: "0" }}>
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <hr />
                            </Grid>
                            <Grid xs={12}>
                              <div className="coverage_section">
                                <Grid
                                  container
                                  spacing={3}
                                  sx={{ padding: "0", alignItems: "center" }}
                                >
                                  <Grid xs={4}>
                                    <img
                                      src={
                                        item?.insurerDetails.insurerBasicInfo
                                          .logo
                                      }
                                      alt="logo"
                                      height={51}
                                    />
                                  </Grid>
                                  <Grid xs={6} className="sum_insured">
                                    <h6 className="text-right">
                                      Sum Insured
                                      <span>
                                        {formatCurrencyAccToUser(
                                          item?.policyData.planType.name ===
                                            "FLAT"
                                            ? Number(
                                                item?.policyData
                                                  .benefitsDetailsflat
                                                  ?.sumInsured
                                              )
                                            : Number(
                                                item?.policyData
                                                  .benefitsDetailsGrade[0]
                                                  ?.sumInsured
                                              ),
                                          getCurrencyCodeRegex(
                                            userLocalInfo?.currency
                                          ),
                                          userLocalInfo?.countryCurrencyCode,
                                          0
                                        )}
                                        /-
                                      </span>
                                    </h6>
                                  </Grid>
                                  <Grid xs={12} className="sum_insured">
                                    <h6>
                                      Coverage for
                                      <span>
                                        {parseCoverageString(
                                          item?.policyData.planType
                                            ?.basicConfig,
                                          item?.policyData.planType
                                            ?.parentConfig
                                        )?.ParentStr
                                          ? parseCoverageString(
                                              item?.policyData.planType
                                                ?.basicConfig,
                                              item?.policyData.planType
                                                ?.parentConfig
                                            )?.EmployeeStr +
                                            ", " +
                                            parseCoverageString(
                                              item?.policyData.planType
                                                ?.basicConfig,
                                              item?.policyData.planType
                                                ?.parentConfig
                                            )?.ParentStr
                                          : parseCoverageString(
                                              item?.policyData.planType
                                                ?.basicConfig,
                                              item?.policyData.planType
                                                ?.parentConfig
                                            )?.EmployeeStr}
                                      </span>
                                    </h6>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                            <Grid xs={12}>
                              <Box width={"100%"}>
                                <Grid container className="dotted_border p-3">
                                  <Grid xs={12}>
                                    <div className="m_payment_config">
                                      <img
                                        src="./images/payment_config_img.svg"
                                        alt=""
                                      />

                                      <div>
                                        <h5
                                          className="mb-4"
                                          style={{ lineHeight: "15px" }}
                                        >
                                          Payment Configuration
                                        </h5>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid xs={5} className="sum_insured">
                                    <h6>
                                      Included
                                      {/* <span>
                                        {includedFunc(item)?.includes}
                                      </span> */}
                                      <span>
                                        {(() => {
                                          const text = includedFunc(item)
                                            ?.includes?.replace(/\d+/g, "") // Remove all digits
                                            .replace(/\s{2,}/g, " ") // Replace multiple spaces with a single space
                                            .trim(); // Trim leading/trailing spaces

                                          return text
                                            ? text
                                                .split(" ") // Split the string by spaces into words
                                                .map((word) => {
                                                  // Handle compound words ending in "inlaw"
                                                  if (
                                                    word
                                                      .toLowerCase()
                                                      .endsWith("inlaw")
                                                  ) {
                                                    const base = word.slice(
                                                      0,
                                                      word.length - 6
                                                    ); // Extract base word (e.g., "parent", "mother")
                                                    return (
                                                      base
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      base
                                                        .slice(1)
                                                        .toLowerCase() +
                                                      "-In-Law"
                                                    );
                                                  }
                                                  // Capitalize first letter of each word, make the rest lowercase
                                                  return (
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1).toLowerCase()
                                                  );
                                                })
                                                .join(" ") // Join the words back into a string
                                            : "";
                                        })()}
                                      </span>
                                    </h6>
                                  </Grid>
                                  <Grid xs={2}></Grid>
                                  <Grid
                                    xs={5}
                                    className="sum_insured text-right"
                                  >
                                    <h6>
                                      Voluntary
                                      {/* <span>
                                        {includedFunc(item)?.voluntary}
                                      </span> */}
                                      <span>
                                        {(() => {
                                          const voluntaryText =
                                            includedFunc(item)?.voluntary;

                                          if (voluntaryText?.length > 0) {
                                            return voluntaryText
                                              .split(",") // Split the string by commas
                                              .map((word) => {
                                                word = word
                                                  .trim()
                                                  .toLowerCase(); // Trim spaces and convert to lowercase

                                                // Handle special case for "parent-in-law"
                                                if (
                                                  word.toUpperCase() ===
                                                  "PARENTSINLAW"
                                                ) {
                                                  return "Parents-In-Law"; // Special case for parents-in-law
                                                }

                                                // Handle special case for other words ending in "inlaw"
                                                if (word.endsWith("inlaw")) {
                                                  const base = word.slice(
                                                    0,
                                                    -6
                                                  ); // Extract base word (remove "inlaw")
                                                  return (
                                                    base
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    base.slice(1) +
                                                    "-In-Law" // Capitalize and add "-In-Law"
                                                  );
                                                }

                                                // Capitalize the first letter of regular words
                                                return (
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                                );
                                              })
                                              .join(", "); // Join them back with commas and spaces
                                          }

                                          return ""; // Return an empty string if voluntaryText is not available
                                        })()}
                                      </span>
                                    </h6>

                                    {item?.policyData?.productType?.name ===
                                      "Group Term" && (
                                      <>
                                        {item.policyData.planType.name ===
                                        "GRADED" ? (
                                          <Box className="sum_insured text-right w-100 ">
                                            {/* <h6>Riders</h6> */}
                                            {item?.policyData?.rider?.map(
                                              (detail: any) => (
                                                <div
                                                  className="pt-1"
                                                  style={{
                                                    fontSize: "0.75rem",
                                                    fontWeight: "600",
                                                    fontFamily: "Montserrat",
                                                  }}
                                                >
                                                  {detail?.name}
                                                  <span className="rider-text-light">
                                                    /Rider
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </Box>
                                        ) : (
                                          <Box className="sum_insured text-right w-100 ">
                                            {/* <h6>Riders</h6> */}
                                            {item?.policyData?.rider?.map(
                                              (detail: any) => (
                                                <div
                                                  className="pt-1"
                                                  style={{
                                                    fontSize: "0.75rem",
                                                    fontWeight: "600",
                                                    fontFamily: "Montserrat",
                                                  }}
                                                >
                                                  {detail?.name}
                                                  <span className="rider-text-light">
                                                    /Rider
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </Box>
                                        )}
                                      </>
                                    )}
                                  </Grid>
                                  <Grid xs={6}></Grid>
                                  {/* <Grid xs={6} className="mt-1">
                                    {item?.policyData?.productType?.name ===
                                      "Group Term" && (
                                      <>
                                        {item.policyData.planType.name ===
                                        "GRADED" ? (
                                          <Box className="sum_insured text-right w-100">
                                            {/* <h6>Riders</h6> */}
                                  {/*item?.policyData?.benefits_detail_graded?.map(
                                              (detail: any) => (
                                                <div
                                                  className="pt-1"
                                                  style={{
                                                    fontSize: "0.75rem",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  {detail?.label}
                                                  <span className="rider-text-light">
                                                    /Rider
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </Box>
                                        ) : (
                                          <Box className="sum_insured text-right w-100 ">
                                            {/* <h6>Riders</h6> */}
                                  {/*item?.policyData?.benefits_detail_flat?.map(
                                              (detail: any) => (
                                                <div
                                                  className="pt-1"
                                                  style={{
                                                    fontSize: "0.75rem",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  {detail?.label}
                                                  <span className="rider-text-light">
                                                    /Rider
                                                  </span>
                                                </div>
                                              )
                                            )}
                                          </Box>
                                        )}
                                      </>
                                    )}
                                  </Grid> */}
                                </Grid>
                              </Box>
                            </Grid>

                            {item?.policyData?.productType?.name !==
                            "Group Term" ? (
                              <Grid xs={12} className="choose_dependents">
                                <h5 className="mt-1">Choose Dependents</h5>
                                <ul className="dependent_list">
                                  {item?.memberData?.length > 0 &&
                                    item?.memberData?.map(
                                      (member: any, memIndex: number) => {
                                        const classs =
                                          member.relationWithEmployee ||
                                          member.relationShip;
                                        if (!member) {
                                          return null;
                                        }
                                        return (
                                          <li key={member._id}>
                                            <div className="Checkbox">
                                              <input
                                                type="checkbox"
                                                name="check"
                                                checked={
                                                  member?.newlyAdded
                                                    ? member.checked
                                                    : true
                                                }
                                                onChange={(e) => {
                                                  handleCheckboxChange(
                                                    index,
                                                    memIndex,
                                                    e.target.checked,
                                                    member?.newlyAdded
                                                  );
                                                  handleCheckedMember(
                                                    member,
                                                    memIndex,
                                                    e.target.checked,
                                                    member?.newlyAdded,
                                                    member?.memId
                                                  );
                                                }}
                                              />
                                              <label
                                                // className={
                                                //   classs === "Brother" ||
                                                //   classs === "Husband" ||
                                                //   classs === "Father" ||
                                                //   classs === "Father-In-Law"
                                                //     ? "super"
                                                //     : classs === "Wife" ||
                                                //       classs === "Mother" || classs === "Mother-In-Law"
                                                //     ? "accident"
                                                //     : classs === "Son"
                                                //     ? "health"
                                                //     : classs === "Daughter" ||
                                                //       classs === "Sister"
                                                //     ? "critical"
                                                //     : "critical"
                                                // }
                                                className={
                                                  classs === "Father-In-Law"
                                                    ? "father-in-law"
                                                    : classs === "Mother-In-Law"
                                                    ? "mother-in-law"
                                                    : classs === "Husband"
                                                    ? "husband"
                                                    : classs === "Wife"
                                                    ? "wife"
                                                    : classs === "Brother"
                                                    ? "brother"
                                                    : classs == "Sister"
                                                    ? "sister"
                                                    : classs === "Son"
                                                    ? "son"
                                                    : classs === "Daughter"
                                                    ? "daughter"
                                                    : classs === "Father"
                                                    ? "father-in-law"
                                                    : classs === "Mother"
                                                    ? "mother-in-law"
                                                    : ""
                                                }
                                              >
                                                <p>
                                                  {member.fullName}
                                                  <span>
                                                    {member.relationWithEmployee ||
                                                      member.relationShip}
                                                  </span>
                                                  <span>
                                                    {format(
                                                      new Date(member.dob),
                                                      "P"
                                                    )}
                                                  </span>
                                                </p>
                                              </label>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                  <li>
                                    <div
                                      className="Checkbox"
                                      onClick={() => setAddMember(true)}
                                      // onClick={()=>alert("hh")}
                                    >
                                      <input type="checkbox" name="check" />
                                      <label className="add_member">
                                        <img
                                          src="./images/add_member_icon.svg"
                                          alt=""
                                          height={71}
                                          className="mt-4"
                                        />
                                        <p className="mt-2">Add Member</p>
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </Grid>
                            ) : (
                              <Box width={"100%"}>
                                {item?.policyData?.is_rider &&
                                item?.policyData?.productType?.name ===
                                  "Group Term" &&
                                item.policyData.planType.name === "GRADED"
                                  ? // grp term graded case
                                    item?.policyData?.benefits_detail_graded[0]?.riders?.map(
                                      (detail: any, indexRider: number) => {
                                        return (
                                          <Grid
                                            container
                                            key={indexRider}
                                            className="riders_cont"
                                          >
                                            <Grid xs={12}>
                                              <h6>{detail.label}</h6>
                                            </Grid>
                                            <Grid xs={12}>
                                              <div className="rider_sa">
                                                Rider SA
                                              </div>
                                              <div className="rider_amount">
                                                {formatCurrencyAccToUser(
                                                  detail.amount,
                                                  getCurrencyCodeRegex(
                                                    userLocalInfo?.currency
                                                  ),
                                                  userLocalInfo?.countryCurrencyCode,
                                                  0
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid xs={12} className=" mb-3">
                                              <div className="description-heading">
                                                Description
                                              </div>
                                              <div className="description-text">
                                                {detail.description}
                                              </div>
                                            </Grid>
                                            <Grid xs={1}>
                                              <input
                                                type="checkbox"
                                                className="switch ml-3"
                                                checked={detail.enable}
                                                onClick={(e) => {
                                                  updateToggle(
                                                    e,
                                                    index,
                                                    indexRider
                                                  );
                                                }}
                                                // disabled={
                                                //   !riderIndArr[indexRider]
                                                // }
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )
                                  : // grp term flat case
                                    item?.policyData?.is_rider &&
                                    item?.policyData?.benefits_detail_flat?.map(
                                      (detail: any, indexRider: number) => {
                                        return (
                                          <Grid
                                            container
                                            key={indexRider}
                                            className="riders_cont"
                                          >
                                            <Grid xs={12}>
                                              <h6>{detail.label}</h6>
                                            </Grid>
                                            <Grid xs={12}>
                                              <div className="rider_sa">
                                                Rider SA
                                              </div>
                                              <div className="rider_amount">
                                                {formatCurrencyAccToUser(
                                                  detail.amount,
                                                  getCurrencyCodeRegex(
                                                    userLocalInfo?.currency
                                                  ),
                                                  userLocalInfo?.countryCurrencyCode,
                                                  0
                                                )}
                                              </div>
                                            </Grid>
                                            <Grid xs={12} className=" mb-3">
                                              <div className="description-heading">
                                                Description
                                              </div>
                                              <div className="description-text">
                                                {detail.description}
                                              </div>
                                            </Grid>
                                            <Grid xs={1}>
                                              <input
                                                type="checkbox"
                                                className="switch ml-3"
                                                checked={detail.enable}
                                                onClick={(e) => {
                                                  updateToggle(
                                                    e,
                                                    index,
                                                    indexRider
                                                  );
                                                }}
                                                // disabled={
                                                //   !riderIndArr[indexRider]
                                                // }
                                              />
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )}
                              </Box>
                            )}
                            <Grid xs={12} marginY={"0px"} paddingY={0}>
                              <div
                                className="feature_exclusion_btn"
                                style={{ display: "flex" }}
                              >
                                <Button
                                  variant="contained"
                                  className="featureBtn mr-5"
                                  onClick={() => setMPolicyFeature(true)}
                                >
                                  <img
                                    src="./images/feature_btn_img.svg"
                                    alt=""
                                    height={25}
                                    width={30}
                                  />
                                  <span> View Features</span>
                                </Button>
                                <Button
                                  variant="contained"
                                  className="featureBtn"
                                  onClick={() => setMPolicyExclusion(true)}
                                >
                                  <img
                                    src="./images/exclusion_btn_img.svg"
                                    alt=""
                                    height={25}
                                    width={30}
                                  />
                                  <span>View Exclusions</span>
                                </Button>
                              </div>
                            </Grid>
                            <Grid xs={12} className="policy_pay_text">
                              What you'll pay
                            </Grid>
                            <Grid xs={12} className="calculate_policy_amount">
                              {/* <React.Fragment key={item.policyData._id}>
                                <Accordion
                                  
                                  
                                  className="Accordion_section p-0"
                                  style={{border:"1px solid red"}}
                                  expanded={
                                    item?.policyData._id === expandedSidePanelId
                                  }
                                  onChange={() => {
                                    if (
                                      expandedSidePanelId === item?.policyData._id
                                    ) {
                                      setExpandedSidePanelId("");
                                    } else {
                                      setExpandedSidePanelId(item?.policyData._id);
                                    }
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={0}
                                    paddingRight={0}
                                    padding={0}
                                    style={{ padding: "10px" }}
                                  >
                                    <Grid
                                      xs={10}
                                      className="px-0"
                                      width={"calc(100% - 30px)"}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          padding: "0",
                                        }}
                                      >
                                        <Typography
                                          className="title"
                                          style={{ width: "100%" }}
                                        >
                                          <Grid
                                            container
                                            spacing={0}
                                            padding={0}
                                            alignItems="center"
                                            justifyContent="space-between"
                                            // border={"1px solid red"}
                                          >
                                            <Grid>
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {
                                                  item.policyData.policyCase
                                                    .policyNumber
                                                }
                                              </p>
                                            </Grid>
                                            <Grid
                                              style={{
                                                fontSize: "15px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {formatCurrencyAccToUser(
                                                amountData,
                                                getCurrencyCodeRegex(
                                                  userLocalInfo?.currency
                                                ),
                                                userLocalInfo?.countryCurrencyCode,
                                                2
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Typography>
                                      </AccordionSummary>
                                    </Grid>
                                    <Grid
                                      xs
                                      style={{
                                        alignSelf: "Center",
                                        display: "flex",
                                        justifyContent: "end",
                                        padding: "0",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        style={{
                                          paddingRight: "0",
                                          alignItems: "start",

                                          top: "-3px",
                                        }}
                                      >
                                        <Typography></Typography>
                                      </AccordionSummary>
                                    </Grid>
                                  </Grid>

                                  <AccordionDetails style={{ padding: "6px 15px" }}>
                                    {Array.isArray(fullAmount.memberArrWithPrice) &&
                                      fullAmount.memberArrWithPrice?.map(
                                        (member: any, memIndex: any) => {
                                          return (
                                            <div
                                              className=" mb-2"
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: "11px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {member.name}
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {formatCurrencyAccToUser(
                                                  member.amount ?? 0,
                                                  getCurrencyCodeRegex(
                                                    userLocalInfo?.currency
                                                  ),
                                                  userLocalInfo?.countryCurrencyCode,
                                                  2
                                                )}{" "}
                                                /-
                                              </p>
                                            </div>
                                          );
                                        }
                                      )}
                                    {dataNew?.map(
                                      (benefit: any, riderIndex: any) => {
                                        if (benefit.enable) {
                                          let riderAmount: number =
                                            calculateRiderAmount(
                                              item,
                                              riderIndex
                                            ) ?? 0;
                                          return (
                                            <div
                                              key={benefit._id}
                                              className=" mb-2"
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: "11px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {benefit.label}
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {formatCurrencyAccToUser(
                                                  riderAmount ?? 0,
                                                  getCurrencyCodeRegex(
                                                    userLocalInfo?.currency
                                                  ),
                                                  userLocalInfo?.countryCurrencyCode,
                                                  2
                                                )}{" "}
                                                /-
                                              </p>
                                            </div>
                                          );
                                        }
                                        return null; // Added return null for disabled benefits
                                      }
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </React.Fragment> */}
                              <React.Fragment key={item.policyData._id}>
                                <Accordion
                                  className="Accordion_section p-0"
                                  expanded={
                                    item?.policyData._id ===
                                      expandedSidePanelId || true
                                  } // Keep it open by default
                                  onChange={() => {
                                    if (
                                      expandedSidePanelId ===
                                      item?.policyData._id
                                    ) {
                                      setExpandedSidePanelId("");
                                    } else {
                                      setExpandedSidePanelId(
                                        item?.policyData._id
                                      );
                                    }
                                  }}
                                >
                                  <Grid
                                    container
                                    spacing={0}
                                    paddingRight={0}
                                    padding={0}
                                    // style={{ padding: "10px" }}
                                  >
                                    <Grid
                                      xs={12}
                                      className="px-0"
                                      // width={"calc(100% - 30px)"}
                                      width={"100%"}
                                    >
                                      <AccordionSummary
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          padding: "0",

                                          minHeight: 0,
                                        }}
                                      >
                                        <Typography
                                          className="title"
                                          style={{ width: "100%" }}
                                        >
                                          <Grid
                                            container
                                            spacing={0}
                                            padding={0}
                                            alignItems="center"
                                            justifyContent="space-between"
                                          >
                                            <Grid>
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {
                                                  item.policyData.policyCase
                                                    .policyNumber
                                                }
                                              </p>
                                            </Grid>
                                            <Grid
                                              style={{
                                                fontSize: "15px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {formatCurrencyAccToUser(
                                                amountData,
                                                getCurrencyCodeRegex(
                                                  userLocalInfo?.currency
                                                ),
                                                userLocalInfo?.countryCurrencyCode,
                                                2
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Typography>
                                      </AccordionSummary>
                                    </Grid>
                                    {/* Removed the expandIcon */}
                                  </Grid>

                                  <AccordionDetails style={{ padding: 0 }}>
                                    {Array.isArray(
                                      fullAmount.memberArrWithPrice
                                    ) &&
                                      fullAmount.memberArrWithPrice?.map(
                                        (member: any, memIndex: any) => {
                                          return (
                                            <div
                                              className="mb-0"
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: "11px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {member.name}
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {formatCurrencyAccToUser(
                                                  member.amount ?? 0,
                                                  getCurrencyCodeRegex(
                                                    userLocalInfo?.currency
                                                  ),
                                                  userLocalInfo?.countryCurrencyCode,
                                                  2
                                                )}{" "}
                                                /-
                                              </p>
                                            </div>
                                          );
                                        }
                                      )}
                                    {dataNew?.map(
                                      (benefit: any, riderIndex: any) => {
                                        if (benefit.enable) {
                                          let riderAmount: number =
                                            calculateRiderAmount(
                                              item,
                                              riderIndex
                                            ) ?? 0;
                                          return (
                                            <div
                                              key={benefit._id}
                                              className="mb-0"
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: "11px",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {benefit.label}
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: "12px",
                                                  fontWeight: "500",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {formatCurrencyAccToUser(
                                                  riderAmount ?? 0,
                                                  getCurrencyCodeRegex(
                                                    userLocalInfo?.currency
                                                  ),
                                                  userLocalInfo?.countryCurrencyCode,
                                                  2
                                                )}{" "}
                                                /-
                                              </p>
                                            </div>
                                          );
                                        }
                                        return null; // Added return null for disabled benefits
                                      }
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </React.Fragment>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </Grid>
              <Grid xs={4} sx={{ position: "relative" }}>
                <div className="paysection-outer">
                  <div className="">
                    {/* <hr /> */}
                    {/* <ul className="pay_list">
                      {policiesAmount.map((item: any) => {
                        let fullAmount: any = calculateAmount(item);
                        console.log("fullAmount", fullAmount);
                        let amountData = fullAmount.amountData || 0;
                        let dataNew =
                          item.policyData?.productType?.name === "Group Term" &&
                          item.policyData.planType.name === "GRADED"
                            ? item.policyData.benefits_detail_graded[0].riders
                            : item.policyData?.benefits_detail_flat;

                        return (
                          <React.Fragment key={item.policyData._id}>
                            <Accordion
                              className="Accordion_section p-0"
                              expanded={
                                item?.policyData._id === expandedSidePanelId
                              }
                              onChange={() => {
                                if (
                                  expandedSidePanelId === item?.policyData._id
                                ) {
                                  setExpandedSidePanelId("");
                                } else {
                                  setExpandedSidePanelId(item?.policyData._id);
                                }
                              }}
                            >
                              <Grid
                                container
                                spacing={0}
                                paddingRight={0}
                                padding={0}
                                style={{ padding: "10px" }}
                              >
                                <Grid
                                  xs={10}
                                  className="px-0"
                                  width={"calc(100% - 30px)"}
                                >
                                  <AccordionSummary
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      padding: "0",
                                    }}
                                  >
                                    <Typography
                                      className="title"
                                      style={{ width: "100%" }}
                                    >
                                      <Grid
                                        container
                                        spacing={0}
                                        padding={0}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        // border={"1px solid red"}
                                      >
                                        <Grid>
                                          <p
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {
                                              item.policyData.policyCase
                                                .policyNumber
                                            }
                                          </p>
                                        </Grid>
                                        <Grid
                                          style={{
                                            fontSize: "15px",
                                            fontFamily: "Montserrat",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {formatCurrencyAccToUser(
                                            amountData,
                                            getCurrencyCodeRegex(
                                              userLocalInfo?.currency
                                            ),
                                            userLocalInfo?.countryCurrencyCode,
                                            2
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Typography>
                                  </AccordionSummary>
                                </Grid>
                                <Grid
                                  xs
                                  style={{
                                    alignSelf: "Center",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    style={{
                                      paddingRight: "0",
                                      alignItems: "start",

                                      top: "-3px",
                                    }}
                                  >
                                    <Typography></Typography>
                                  </AccordionSummary>
                                </Grid>
                              </Grid>

                              <AccordionDetails style={{ padding: "6px 15px" }}>
                                {Array.isArray(fullAmount.memberArrWithPrice) &&
                                  fullAmount.memberArrWithPrice?.map(
                                    (member: any, memIndex: any) => {
                                      return (
                                        <div
                                          className=" mb-2"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: "11px",
                                              fontWeight: "400",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {member.name}
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {formatCurrencyAccToUser(
                                              member.amount ?? 0,
                                              getCurrencyCodeRegex(
                                                userLocalInfo?.currency
                                              ),
                                              userLocalInfo?.countryCurrencyCode,
                                              2
                                            )}{" "}
                                            /-
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                                {dataNew?.map(
                                  (benefit: any, riderIndex: any) => {
                                    if (benefit.enable) {
                                      let riderAmount: number =
                                        calculateRiderAmount(
                                          item,
                                          riderIndex
                                        ) ?? 0;
                                      return (
                                        <div
                                          key={benefit._id}
                                          className=" mb-2"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: "11px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {benefit.label}
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {formatCurrencyAccToUser(
                                              riderAmount ?? 0,
                                              getCurrencyCodeRegex(
                                                userLocalInfo?.currency
                                              ),
                                              userLocalInfo?.countryCurrencyCode,
                                              2
                                            )}{" "}
                                            /-
                                          </p>
                                        </div>
                                      );
                                    }
                                    return null; // Added return null for disabled benefits
                                  }
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </React.Fragment>
                        );
                      })}
                      <li className="total_payment">
                        <span className="name">Total Payment</span>
                        <span className="price" style={{ textAlign: "right" }}>
                          {formatCurrencyAccToUser(
                            test,
                            getCurrencyCodeRegex(userLocalInfo?.currency),
                            userLocalInfo?.countryCurrencyCode,
                            2
                          )}{" "}
                          /-
                        </span>
                      </li>
                    </ul> */}
                    {/* <ul className="pay_list">
                      {policiesAmount.map((item: any) => {
                        let fullAmount: any = calculateAmount(item);
                        console.log("fullAmount", fullAmount);
                        console.log('policySelected',policySelected)
                        console.log('policy',item)
                        let amountData = fullAmount.amountData || 0;
                        let dataNew =
                          item.policyData?.productType?.name === "Group Term" &&
                          item.policyData.planType.name === "GRADED"
                            ? item.policyData.benefits_detail_graded[0].riders
                            : item.policyData?.benefits_detail_flat;

                        return (
                          <React.Fragment key={item.policyData._id}>
                            <Accordion
                              className="Accordion_section p-0"
                              expanded={
                                item?.policyData._id === expandedSidePanelId
                              }
                              onChange={() => {
                                if (
                                  expandedSidePanelId === item?.policyData._id
                                ) {
                                  setExpandedSidePanelId("");
                                } else {
                                  setExpandedSidePanelId(item?.policyData._id);
                                }
                              }}
                            >
                              <Grid
                                container
                                spacing={0}
                                paddingRight={0}
                                padding={0}
                                style={{ padding: "10px" }}
                              >
                                <Grid
                                  xs={10}
                                  className="px-0"
                                  width={"calc(100% - 30px)"}
                                >
                                  <AccordionSummary
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    style={{
                                      display: "inline-block",
                                      width: "100%",
                                      padding: "0",
                                    }}
                                  >
                                    <Typography
                                      className="title"
                                      style={{ width: "100%" }}
                                    >
                                      <Grid
                                        container
                                        spacing={0}
                                        padding={0}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        // border={"1px solid red"}
                                      >
                                        <Grid>
                                          <p
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {
                                              item.policyData.policyCase
                                                .policyNumber
                                            }
                                          </p>
                                        </Grid>
                                        <Grid
                                          style={{
                                            fontSize: "15px",
                                            fontFamily: "Montserrat",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {formatCurrencyAccToUser(
                                            amountData,
                                            getCurrencyCodeRegex(
                                              userLocalInfo?.currency
                                            ),
                                            userLocalInfo?.countryCurrencyCode,
                                            2
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Typography>
                                  </AccordionSummary>
                                </Grid>
                                <Grid
                                  xs
                                  style={{
                                    alignSelf: "Center",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0",
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    style={{
                                      paddingRight: "0",
                                      alignItems: "start",

                                      top: "-3px",
                                    }}
                                  >
                                    <Typography></Typography>
                                  </AccordionSummary>
                                </Grid>
                              </Grid>

                              <AccordionDetails style={{ padding: "6px 15px" }}>
                                {Array.isArray(fullAmount.memberArrWithPrice) &&
                                  fullAmount.memberArrWithPrice?.map(
                                    (member: any, memIndex: any) => {
                                      return (
                                        <div
                                          className=" mb-2"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: "11px",
                                              fontWeight: "400",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {member.name}
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {formatCurrencyAccToUser(
                                              member.amount ?? 0,
                                              getCurrencyCodeRegex(
                                                userLocalInfo?.currency
                                              ),
                                              userLocalInfo?.countryCurrencyCode,
                                              2
                                            )}{" "}
                                            /-
                                          </p>
                                        </div>
                                      );
                                    }
                                  )}
                                {dataNew?.map(
                                  (benefit: any, riderIndex: any) => {
                                    if (benefit.enable) {
                                      let riderAmount: number =
                                        calculateRiderAmount(
                                          item,
                                          riderIndex
                                        ) ?? 0;
                                      return (
                                        <div
                                          key={benefit._id}
                                          className=" mb-2"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: "11px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {benefit.label}
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {formatCurrencyAccToUser(
                                              riderAmount ?? 0,
                                              getCurrencyCodeRegex(
                                                userLocalInfo?.currency
                                              ),
                                              userLocalInfo?.countryCurrencyCode,
                                              2
                                            )}{" "}
                                            /-
                                          </p>
                                        </div>
                                      );
                                    }
                                    return null; // Added return null for disabled benefits
                                  }
                                )}
                              </AccordionDetails>
                            </Accordion>
                          </React.Fragment>
                        );
                      })}
                      <li className="total_payment">
                        <span className="name">Total Payment</span>
                        <span className="price" style={{ textAlign: "right" }}>
                          {formatCurrencyAccToUser(
                            test,
                            getCurrencyCodeRegex(userLocalInfo?.currency),
                            userLocalInfo?.countryCurrencyCode,
                            2
                          )}{" "}
                          /-
                        </span>
                      </li>
                    </ul> */}

                    {/* {amountRiders === 0 && (
                      <Alert severity="info" className="mb-5">
                        Your policy(s) have been paid for by your employer.
                      </Alert>
                    )} */}

                    {/* {!amountRiders && (
                <Alert severity="info" className="mb-5">
                  Your policy(s) have been paid for by your employer.
                </Alert>
              )} */}
                    {/* <div style={{ textAlign: "center" }}>
                      <img src="mobile/girl.svg" alt="" />
                    </div> */}
                    <div className="MenrollmentStartBtn ">
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={() => {
                          value_update("setStartFourthScreen", true);
                          submitRider();
                          window.scrollTo({
                            top: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* <Grid xs={12}>
              <div className="ctaBtn mb-5">
                <Button
                  variant="contained"
                  className="submitBtn"
                  onClick={() => {
                    value_update("setStartFourthScreen", true);
                    submitRider();
                  }}
                >
                  Continue
                </Button>
              </div>
            </Grid> */}
          </Grid>
        </Box>
      ) : (
        <Box className="choosePolicy_wrapper">
          <Grid container spacing={3} className="row">
            <Grid xs={12} className="mb-5">
              <div className="backBtn">
                <ArrowBackIcon
                  onClick={() => {
                    value_update("setStartThirdScreen", false);
                  }}
                />
                <span>Back to Choose Policy</span>
              </div>
            </Grid>
            <Grid xs={4} sx={{ position: "relative" }}>
              <div className="paysection-outer">
                <div className="pay_section">
                  <h5>What you'll pay</h5>
                  <hr />
                  <ul className="pay_list">
                    {policiesAmount.map((item: any) => {
                      let fullAmount: any = calculateAmount(item);
                      console.log("fullAmount", fullAmount);
                      let amountData = fullAmount.amountData || 0;
                      let dataNew =
                        item.policyData?.productType?.name === "Group Term" &&
                        item.policyData.planType.name === "GRADED"
                          ? item.policyData.benefits_detail_graded[0].riders
                          : item.policyData?.benefits_detail_flat;

                      return (
                        <React.Fragment key={item.policyData._id}>
                          <Accordion
                            className="Accordion_section"
                            expanded={
                              item?.policyData._id === expandedSidePanelId
                            }
                            onChange={() => {
                              if (
                                expandedSidePanelId === item?.policyData._id
                              ) {
                                setExpandedSidePanelId("");
                              } else {
                                setExpandedSidePanelId(item?.policyData._id);
                              }
                            }}
                          >
                            <Grid container spacing={0} paddingRight={0}>
                              <Grid
                                xs={10}
                                className="px-0"
                                width={"calc(100% - 30px)"}
                              >
                                <AccordionSummary
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                  style={{
                                    display: "inline-block",
                                    width: "100%",
                                    padding: "0",
                                  }}
                                >
                                  <Typography
                                    className="title"
                                    style={{ width: "100%" }}
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Grid>
                                        <p
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            fontFamily: "Montserrat",
                                          }}
                                        >
                                          {
                                            item.policyData.policyCase
                                              .policyNumber
                                          }
                                        </p>
                                      </Grid>
                                      <Grid>
                                        {formatCurrencyAccToUser(
                                          amountData,
                                          getCurrencyCodeRegex(
                                            userLocalInfo?.currency
                                          ),
                                          userLocalInfo?.countryCurrencyCode,
                                          2
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Typography>
                                </AccordionSummary>
                              </Grid>
                              <Grid
                                xs
                                style={{
                                  alignSelf: "Center",
                                  display: "flex",
                                  justifyContent: "end",
                                  padding: "0",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                  style={{ paddingRight: "0" }}
                                >
                                  <Typography></Typography>
                                </AccordionSummary>
                              </Grid>
                            </Grid>

                            <AccordionDetails style={{ padding: "0" }}>
                              {Array.isArray(fullAmount.memberArrWithPrice) &&
                                fullAmount.memberArrWithPrice?.map(
                                  (member: any, memIndex: any) => {
                                    return (
                                      <div
                                        className="ml-5 mb-2"
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <h5
                                          style={{
                                            fontSize: "11px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {member.name}
                                        </h5>
                                        <p
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {formatCurrencyAccToUser(
                                            member.amount ?? 0,
                                            getCurrencyCodeRegex(
                                              userLocalInfo?.currency
                                            ),
                                            userLocalInfo?.countryCurrencyCode,
                                            2
                                          )}{" "}
                                          /-
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              {dataNew?.map((benefit: any, riderIndex: any) => {
                                if (benefit.enable) {
                                  let riderAmount: number =
                                    calculateRiderAmount(item, riderIndex) ?? 0;
                                  return (
                                    <div
                                      key={benefit._id}
                                      className="ml-5 mb-2"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <h5
                                        style={{
                                          fontSize: "11px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {benefit.label}
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {formatCurrencyAccToUser(
                                          riderAmount ?? 0,
                                          getCurrencyCodeRegex(
                                            userLocalInfo?.currency
                                          ),
                                          userLocalInfo?.countryCurrencyCode,
                                          2
                                        )}{" "}
                                        /-
                                      </p>
                                    </div>
                                  );
                                }
                                return null; // Added return null for disabled benefits
                              })}
                            </AccordionDetails>
                          </Accordion>
                        </React.Fragment>
                      );
                    })}
                    <li className="total_payment">
                      <span className="name">Total Payment</span>
                      <span className="price" style={{ textAlign: "right" }}>
                        {formatCurrencyAccToUser(
                          test,
                          getCurrencyCodeRegex(userLocalInfo?.currency),
                          userLocalInfo?.countryCurrencyCode,
                          2
                        )}{" "}
                        /-
                      </span>
                    </li>
                  </ul>

                  <div className="ctaBtn mb-5">
                    <Button
                      variant="contained"
                      className="submitBtn"
                      onClick={() => {
                        value_update("setStartFourthScreen", true);
                        submitRider();
                      }}
                    >
                      Continue
                    </Button>
                  </div>

                  {amountRiders === 0 && (
                    <Alert severity="info" className="mb-5">
                      Your policy(s) have been paid for by your employer.
                    </Alert>
                  )}

                  {/* {!amountRiders && (
                <Alert severity="info" className="mb-5">
                  Your policy(s) have been paid for by your employer.
                </Alert>
              )} */}
                  <img src="./images/choose_policy_img.svg" alt="" />
                </div>
              </div>
            </Grid>
            <Grid xs={8}>
              {policies &&
                policies?.map((item: any, index: number) => {
                  let enrollment_date = item.enrollment_date;
                  let enrEnd = new Date(
                    item?.policyData?.policy?.enrolmentEndDate
                  );
                  let memPeriod = +extractNumbersFromString(
                    item?.policyData.policy.newMemberPeriod
                  );
                  let enrolLastDate;
                  if (
                    new Date(enrollment_date) <
                    new Date(item.policyData.policyDetails?.startDate)
                  ) {
                    enrolLastDate = new Date(
                      item.policyData.policy?.enrolmentEndDate
                    );
                    let gracePeriod = +extractNumbersFromString(
                      item?.policyData.policy.gracePeriod
                    );
                    enrolLastDate.setDate(
                      enrolLastDate.getDate() + gracePeriod
                    );
                  } else {
                    // Convert enrollment_date to Date object and set time to midnight
                    enrolLastDate = new Date(enrollment_date);
                    enrolLastDate.setDate(enrolLastDate.getDate() + memPeriod-1);
    
                  }
                  return (
                    <Accordion
                      className="Accordion_section"
                      expanded={item?.policyData._id === expandedPanelId}
                      onChange={() => {
                        setselectedIndexPolicy(index);
                        if (expandedPanelId === item?.policyData._id) {
                          setPolicySelected(item);
                          setExpandedPanelId("");
                        } else {
                          setExpandedPanelId(item?.policyData._id);
                        }
                      }}
                    >
                      <Grid container spacing={0} paddingRight={0}>
                        <Grid
                          xs={10}
                          className="px-0"
                          width={"calc(100% - 30px)"}
                        >
                          <AccordionSummary
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            style={{
                              display: "inline-block",
                              width: "100%",
                              padding: "0",
                            }}
                          >
                            <Typography
                              className="title"
                              style={{ width: "100%" }}
                            >
                              <Grid container spacing={3} alignItems={"center"}>
                                <Grid xs={4} sx={{ alignSelf: "center" }}>
                                  <h6>{item?.policyData?.productType?.name}</h6>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    {item?.policyData?.policyCase?.policyNumber}
                                  </p>
                                </Grid>

                                {item?.policyData._id === expandedPanelId ? (
                                  <Grid
                                    xs
                                    display={"flex"}
                                    justifyContent={"end"}
                                  >
                                    <ul className="policy_calender">
                                      <li className="lastdate">
                                        Last Date to Enroll
                                        <span>
                                          {updateTimeFormat(
                                            enrolLastDate?.toString(),
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </li>
                                      <li className="startdate">
                                        Policy Start Date
                                        <span>
                                          {updateTimeFormat(
                                            item?.policyData.policyDetails
                                              .startDate,
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </li>
                                      <li className="startdate">
                                        Policy End Date
                                        <span>
                                          {updateTimeFormat(
                                            item?.policyData.policyDetails
                                              .endDate,
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </li>
                                    </ul>
                                  </Grid>
                                ) : (
                                  <Grid xs sx={{ alignSelf: "center" }}>
                                    <img
                                      src={
                                        item?.insurerDetails.insurerBasicInfo
                                          .logo
                                      }
                                      alt="logo"
                                      height={51}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </Typography>
                          </AccordionSummary>
                        </Grid>
                        <Grid
                          xs
                          style={{
                            alignSelf: "Center",
                            display: "flex",
                            justifyContent: "end",
                            padding: "0",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            style={{ paddingRight: "0" }}
                          >
                            <Typography></Typography>
                          </AccordionSummary>
                        </Grid>
                      </Grid>
                      <AccordionDetails style={{ padding: "0" }}>
                        <Grid container spacing={3}>
                          <Grid xs={12}>
                            <hr />
                          </Grid>
                          <Grid xs={12}>
                            <div className="coverage_section">
                              <Grid
                                container
                                spacing={3}
                                sx={{ padding: "0", alignItems: "center" }}
                              >
                                <Grid xs={3}>
                                  <img
                                    src={
                                      item?.insurerDetails.insurerBasicInfo.logo
                                    }
                                    alt="logo"
                                    height={51}
                                  />
                                </Grid>
                                <Grid xs={2} className="sum_insured">
                                  <h6>
                                   {item.policyData?.productType?.name === "Group Term" ? "Sum Assured" : "Sum Insured"}
                                    <span>
                                      {formatCurrencyAccToUser(
                                        item?.policyData.planType.name ===
                                          "FLAT"
                                          ? Number(
                                              item?.policyData
                                                .benefitsDetailsflat?.sumInsured
                                            )
                                          : Number(
                                              item?.policyData
                                                .benefitsDetailsGrade[0]
                                                ?.sumInsured
                                            ),
                                        getCurrencyCodeRegex(
                                          userLocalInfo?.currency
                                        ),
                                        userLocalInfo?.countryCurrencyCode,
                                        0
                                      )}
                                      /-
                                    </span>
                                  </h6>
                                </Grid>
                                <Grid xs={7} className="sum_insured text-right">
                                  <h6>
                                    Coverage for
                                    <span>
                                      {parseCoverageString(
                                        item?.policyData.planType?.basicConfig,
                                        item?.policyData.planType?.parentConfig
                                      )?.ParentStr
                                        ? parseCoverageString(
                                            item?.policyData.planType
                                              ?.basicConfig,
                                            item?.policyData.planType
                                              ?.parentConfig
                                          )?.EmployeeStr +
                                          ", " +
                                          parseCoverageString(
                                            item?.policyData.planType
                                              ?.basicConfig,
                                            item?.policyData.planType
                                              ?.parentConfig
                                          )?.ParentStr
                                        : parseCoverageString(
                                            item?.policyData.planType
                                              ?.basicConfig,
                                            item?.policyData.planType
                                              ?.parentConfig
                                          )?.EmployeeStr}
                                    </span>
                                  </h6>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid xs={4}>
                            <div className="payment_config ">
                              <img
                                src="./images/payment_config_img.svg"
                                alt=""
                              />
                              <h5>Payment Configuration</h5>
                            </div>
                          </Grid>
                          <Grid xs={4} className="sum_insured">
                            <h6>
                              Included
                              {/* <span>{includedFunc(item)?.includes}</span> */}
                              <span>
                                {(() => {
                                  const text = includedFunc(item)
                                    ?.includes?.replace(/\d+/g, "") // Remove all digits
                                    .replace(/\s{2,}/g, " ") // Replace multiple spaces with a single space
                                    .trim(); // Trim leading/trailing spaces

                                  return text
                                    ? text
                                        .split(" ") // Split the string by spaces into words
                                        .map((word) => {
                                          // Handle compound words ending in "inlaw"
                                          if (
                                            word.toLowerCase().endsWith("inlaw")
                                          ) {
                                            const base = word.slice(
                                              0,
                                              word.length - 6
                                            ); // Extract base word (e.g., "parent", "mother")
                                            return (
                                              base.charAt(0).toUpperCase() +
                                              base.slice(1).toLowerCase() +
                                              "-In-Law"
                                            );
                                          }
                                          // Capitalize first letter of each word, make the rest lowercase
                                          return (
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1).toLowerCase()
                                          );
                                        })
                                        .join(" ") // Join the words back into a string
                                    : "";
                                })()}
                              </span>
                            </h6>
                          </Grid>
                          <Grid xs={4} className="sum_insured text-right mb-3">
                            <h6>
                              Voluntary
                              {/* <span>{includedFunc(item)?.voluntary}</span> */}
                              <span>
                                {(() => {
                                  const voluntaryText =
                                    includedFunc(item)?.voluntary;

                                  if (voluntaryText?.length > 0) {
                                    return voluntaryText
                                      .split(",") // Split the string by commas
                                      .map((word) => {
                                        word = word.trim().toLowerCase(); // Trim spaces and convert to lowercase

                                        // Handle special case for words ending in "inlaw"
                                        if (word.endsWith("inlaw")) {
                                          const base = word.slice(0, -6); // Extract base word (remove "inlaw")
                                          return (
                                            base.charAt(0).toUpperCase() +
                                            base.slice(1) +
                                            "-In-Law" // Capitalize and add "-In-Law"
                                          );
                                        }

                                        // Capitalize the first letter of regular words
                                        return (
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                        );
                                      })
                                      .join(", "); // Join them back with commas and spaces
                                  }

                                  return ""; // Return an empty string if voluntaryText is not available
                                })()}
                              </span>
                            </h6>
                            {item?.policyData?.productType?.name ===
                              "Group Term" && (
                              <>
                                {item.policyData.planType.name === "GRADED" ? (
                                  <Box className="sum_insured text-right w-100">
                                    {/* <h6>Riders</h6> */}
                                    {item?.policyData?.rider?.map(
                                      (detail: any) => (
                                        <div
                                          className="pt-1"
                                          style={{
                                            fontSize: "0.75rem",
                                            fontWeight: "600",
                                            fontFamily: "Montserrat",
                                          }}
                                        >
                                          <span>{detail?.name}</span>{" "}
                                          <span className="rider-text-light">
                                            /Rider
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </Box>
                                ) : (
                                  <Box className="sum_insured text-right w-100 ">
                                    {/* <h6>Riders</h6> */}
                                    {item?.policyData?.rider?.map(
                                      (detail: any) => (
                                        <div
                                          className="pt-1"
                                          style={{
                                            fontSize: "0.75rem",
                                            fontWeight: "600",
                                            fontFamily: "Montserrat",
                                          }}
                                        >
                                          {/* {detail?.label}/Rider */}
                                          <span>{detail?.name}</span>{" "}
                                          <span className="rider-text-light">
                                            /Rider
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </Box>
                                )}
                              </>
                            )}
                          </Grid>

                          {item?.policyData?.productType?.name !==
                          "Group Term" ? (
                            <Grid xs={12} className="choose_dependents">
                              <h5>Choose Dependents</h5>
                              <ul className="dependent_list">
                                {item?.memberData?.length > 0 &&
                                  item?.memberData?.map(
                                    (member: any, memIndex: number) => {
                                      const classs =
                                        member.relationWithEmployee ||
                                        member.relationShip;
                                      if (!member) {
                                        return null;
                                      }
                                      return (
                                        <li key={member._id}>
                                          <div className="Checkbox">
                                            <input
                                              type="checkbox"
                                              name="check"
                                              checked={
                                                member?.newlyAdded
                                                  ? member.checked
                                                  : true
                                              }
                                              onChange={(e) => {
                                                handleCheckboxChange(
                                                  index,
                                                  memIndex,
                                                  e.target.checked,
                                                  member?.newlyAdded
                                                );
                                                handleCheckedMember(
                                                  member,
                                                  memIndex,
                                                  e.target.checked,
                                                  member?.newlyAdded,
                                                  member?.memId
                                                );
                                              }}
                                            />
                                            <label
                                              className={
                                                classs === "Father-In-Law"
                                                  ? "father-in-law"
                                                  : classs === "Mother-In-Law"
                                                  ? "mother-in-law"
                                                  : classs === "Husband"
                                                  ? "husband"
                                                  : classs === "Wife"
                                                  ? "wife"
                                                  : classs === "Brother"
                                                  ? "brother"
                                                  : classs == "Sister"
                                                  ? "sister"
                                                  : classs === "Son"
                                                  ? "son"
                                                  : classs === "Daughter"
                                                  ? "daughter"
                                                  : classs === "Father"
                                                  ? "father-in-law"
                                                  : classs === "Mother"
                                                  ? "mother-in-law"
                                                  : ""
                                              }
                                            >
                                              <p>
                                                {member.fullName}
                                                <span>
                                                  {member.relationWithEmployee ||
                                                    member.relationShip}
                                                </span>
                                                <span>
                                                  {format(
                                                    new Date(member.dob),
                                                    "P"
                                                  )}
                                                </span>
                                              </p>
                                            </label>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                                <li>
                                  <div
                                    className="Checkbox"
                                    onClick={() => setAddMember(true)}
                                  >
                                    <input type="checkbox" name="check" />
                                    <label className="add_member">
                                      <img
                                        src="./images/add_member_icon.svg"
                                        alt=""
                                        height={71}
                                      />
                                      <p>Add Member</p>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </Grid>
                          ) : (
                            <Box width={"100%"}>
                              {item?.policyData?.is_rider &&
                              item?.policyData?.productType?.name ===
                                "Group Term" &&
                              item.policyData.planType.name === "GRADED"
                                ? // grp term graded case
                                  item?.policyData?.benefits_detail_graded[0]?.riders?.map(
                                    (detail: any, indexRider: number) => {
                                      return (
                                        <Grid
                                          container
                                          key={indexRider}
                                          className="riders_cont"
                                        >
                                          <Grid xs={4}>
                                            <h6 className="rider-label">
                                              {detail.label}
                                            </h6>
                                          </Grid>
                                          <Grid xs={2}>
                                            <div className="rider-label rider_sa">
                                              Rider SA
                                            </div>
                                            <div>
                                              {formatCurrencyAccToUser(
                                                detail.amount,
                                                getCurrencyCodeRegex(
                                                  userLocalInfo?.currency
                                                ),
                                                userLocalInfo?.countryCurrencyCode,
                                                0
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid xs={5}>
                                            <div className="rider-label description-heading">
                                              Description
                                            </div>
                                            <div className="rider-value-text description-text">
                                              {detail.description}
                                            </div>
                                          </Grid>
                                          <Grid xs={1}>
                                            <input
                                              type="checkbox"
                                              className="switch ml-3"
                                              checked={detail.enable}
                                              onClick={(e) => {
                                                updateToggle(
                                                  e,
                                                  index,
                                                  indexRider
                                                );
                                              }}
                                              // disabled={
                                              //   !riderIndArr[indexRider]
                                              // }
                                            />
                                          </Grid>
                                        </Grid>
                                      );
                                    }
                                  )
                                : // grp term flat case
                                  item?.policyData?.is_rider &&
                                  item?.policyData?.benefits_detail_flat?.map(
                                    (detail: any, indexRider: number) => {
                                      return (
                                        <Grid
                                          container
                                          key={indexRider}
                                          className="riders_cont"
                                        >
                                          <Grid xs={4}>
                                            <h6 className="rider-label">
                                              {detail.label}
                                            </h6>
                                          </Grid>
                                          <Grid xs={2}>
                                            <div className="rider-label rider_sa">
                                              Rider SA
                                            </div>
                                            <div className="rider-value-text rider_amount">
                                              {formatCurrencyAccToUser(
                                                detail.amount,
                                                getCurrencyCodeRegex(
                                                  userLocalInfo?.currency
                                                ),
                                                userLocalInfo?.countryCurrencyCode,
                                                0
                                              )}
                                            </div>
                                          </Grid>
                                          <Grid xs={5}>
                                            <div className="rider-label description-heading">
                                              Description
                                            </div>
                                            <div className="rider-value-text description-text">
                                              {detail.description}
                                            </div>
                                          </Grid>
                                          <Grid xs={1}>
                                            <input
                                              type="checkbox"
                                              className="switch ml-3"
                                              checked={detail.enable}
                                              onClick={(e) => {
                                                // alert("hello")
                                                updateToggle(
                                                  e,
                                                  index,
                                                  indexRider
                                                );
                                              }}
                                              // disabled={
                                              //   !riderIndArr[indexRider]
                                              // }
                                            />
                                          </Grid>
                                        </Grid>
                                      );
                                    }
                                  )}
                            </Box>
                          )}
                          <Grid xs={12} marginBottom={"8px"}>
                            <div className="feature_exclusion_btn">
                              <Button
                                variant="contained"
                                className="featureBtn mr-5"
                                onClick={() => setPolicyFeature(true)}
                              >
                                <img
                                  src="./images/feature_btn_img.svg"
                                  alt=""
                                  height={25}
                                />
                                View Features
                              </Button>
                              <Button
                                variant="contained"
                                className="featureBtn"
                                onClick={() => setPolicyExclusion(true)}
                              >
                                <img
                                  src="./images/exclusion_btn_img.svg"
                                  alt=""
                                  height={25}
                                />
                                View Exclusions
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Grid>
          </Grid>
        </Box>
      )}

      <AddFamily
        open_status={addMember}
        attrName={setAddMember}
        value_update={updateMasterState}
        callApiFromParent={add_family_member}
        apiUrl=""
        addedMemberData={newlyAddedMembers}
        policySelected={policiesAmount[selectedIndexPolicy]}
        policies={policies}
      />

      <PolicyFeatures
        open_status={policyFeature}
        attrName={setPolicyFeature}
        value_update={updateMasterState}
        policyId={expandedPanelId}
        maxWidth={"1000px !important"}
      />

      <PolicyExclusion
        open_status={policyExclusion}
        attrName={setPolicyExclusion}
        value_update={updateMasterState}
        policyId={expandedPanelId}
        maxWidth={"1000px !important"}
      />
      <MPolicyFeatures
        open_status={MFeatures}
        attrName={setMPolicyFeature}
        value_update={updateMasterState}
        policyId={expandedPanelId}
        maxWidth={"1000px !important"}
      />

      <MPolicyExclusion
        open_status={MExclusions}
        attrName={setMPolicyExclusion}
        value_update={updateMasterState}
        policyId={expandedPanelId}
        maxWidth={"1000px !important"}
      />
    </>
  );
};

export default ChoosePolicy;
