import { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "./constants";

import Grid from "@mui/material/Unstable_Grid2";
import { Link, Tooltip } from "@mui/material";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { ToggelModalAction } from "../../../../../Store/actions/modalAction";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useDispatch, useSelector } from "react-redux";
import { openRemoveModal, RemoveItem } from "../../../../../Store/actions/layoutAction";
import { IconButton } from "@material-ui/core";
import { detectBrowser } from "./helpers";

const style = {};
const Component = ({ data, components, path }) => {
  console.log("...........Prem",data)
  const is_disable = useState(false);
  const ref = useRef(null);
  const isLockRef = useRef(null);
  const [{ isDragging, isOver }, drag] = useDrag({
    item: {
      type: COMPONENT,
      id: data?.id,
      path,
      component: components[data?.id],
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      isOver: monitor.didDrop(),
    }),
  });
  const dispatch = useDispatch();
  const modal = useSelector(({ modalReducer }) => {
    return modalReducer;
  });
  const layoutData = useSelector(({ layoutReducer }) => {
    return layoutReducer;
  });
  const [isLock, setLock] = useState(false);
  const opacity = isDragging ? 0 : 1;
  drag(ref);

  //console.log(components, data.id)
  const component = components[data.id];
  const browser = detectBrowser();
  const crossBrowser = browser=='firefox'? 2 : 1
  //console.log(component.required) 

  useEffect(()=>{
    //console.log("component.field_type",component.field_type)
    if (component.field_type=="contactId" || component.field_type=="clientId" || component.field_type=="hideid"){
      ref.current.attributes[crossBrowser].value = false;
      isLockRef.current.attributes[0].nodeValue = ""
    }else if(component.isLocked && component.lockType==1){
      ref.current.attributes[crossBrowser].value = false;
      isLockRef.current.attributes[0].nodeValue = ""
    }else if(component.isLocked && component.lockType!=1){
      ref.current.attributes[crossBrowser].value = false;
      isLockRef.current.attributes[0].nodeValue = ""
    }else{
      isLockRef.current.attributes[0].nodeValue = "settings"
    }
    setLock(component.isLocked);
  }, []);

  const removeComponent = () => {
    dispatch(openRemoveModal({data, components, path, layoutData, component, modalType:"FR"}));
    //dispatch(RemoveItem(data, components, path, layoutData, component));
  };

  const lock = (index, itemIndexToLock) => {
    // console.log("ref.current.attributes[1].value",ref.current.attributes[2].value)
    // console.log(browser)

    isLockRef.current.attributes[1].value = true;
    ref.current.attributes[crossBrowser].value = false;
    isLockRef.current.attributes[0].nodeValue = "";
    setLock(true);
    layoutData.layout[index[0]].children[index[1]].children[index[2]].children[
      index[3]
    ].children[itemIndexToLock].isLocked = true;
  };

  const unlock = (index, itemIndexToLock) => {
    isLockRef.current.attributes[1].value = false;
    isLockRef.current.attributes[0].nodeValue = "settings";
    ref.current.attributes[crossBrowser].value = true;
    layoutData.layout[index[0]].children[index[1]].children[index[2]].children[
      index[3]
    ].children[itemIndexToLock].isLocked = false;
    setLock(false);
  };

  const toggleLockFunctionality = () => {
    const isLocked = JSON.parse(isLockRef.current.attributes[1].value);
    const lockType = JSON.parse(isLockRef.current.attributes[2].value);
    const index = path.split("-");
    const itemIndexToLock = index[4];
    //console.log(JSON.parse(isLockRef.current.attributes[2].value));

    // if(lockType === 2 || lockType === 1){
    if(lockType === 1){
      return false
    }
    if (!isLocked) {
      lock(index, itemIndexToLock);
    } else {
      unlock(index, itemIndexToLock);
    }
  };

  const isAddress = ()=>{
    return data.field_lable==="Country" || data.field_lable==="State" || data.field_lable==="City" || data.field_lable==="Pincode" || data.field_lable==="Area Name" || data.field_lable==="Bulding/Street Name"
  }

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className={`component draggable ${isAddress()? "disabled": ""}`}
    >
      <Grid minWidth={100} ref={ref} className={`component draggable ${isAddress()? "disabled": ""}`}>
        <div className="fieldBox">
          <div className="textField">
            <label>{component ? component.field_lable : "not found"} {component.required ? (<span>*</span>):""}</label>
            <p>{component ? component.content : "not found"}</p>
          </div>

          {component.isLocked ? (
            <Link
              ref={isLockRef}
              isLock="true"
              lockType={component.lockType ? component.lockType : 3}
              onClick={toggleLockFunctionality}
            >
              {isLock ? (
                <Tooltip title={component.lockType==1?"This is system generated field so you can't unlock/remove this":"You have locked this field so you can't modify this."}>
                  <IconButton>
                    <LockOutlinedIcon className="closedLock" />
                  </IconButton>
                </Tooltip>
              ) : (
                <LockOpenOutlinedIcon />
              )}
            </Link>
          ) : (
            <Link
              ref={isLockRef}
              isLock="false"
              lockType={component.lockType ? component.lockType : 3}
              onClick={toggleLockFunctionality}
            >
              {isLock ? (
                <Tooltip title={component.lockType==1?"This is system generated field so you can't unlock/remove this":"You have locked this field so you can't modify this."}>
                  <IconButton>
                    <LockOutlinedIcon className="closedLock" />
                  </IconButton>
                </Tooltip>
              ) : (
                <LockOpenOutlinedIcon />
              )}
            </Link>
          )}
          <span>
            {isLock ? (
              ""
            ) : (
              <>
                <Link
                  className="settings"
                  onClick={() => {
                    dispatch(
                      ToggelModalAction(
                        modal.isOpen,
                        { ...component, from: true },
                        path
                      )
                    );
                  }}
                >
                  <SettingsOutlinedIcon />
                </Link>
                {component.required ? (
                  <Tooltip title="This field has been marked as Mandatory. These cannot be deleted. To delete this field, please make it Non-Mandatory">
                      <Link className="delete_disable delete">
                        <DeleteForeverOutlinedIcon />
                      </Link>
                  </Tooltip>
                ) : (
                  <Link className="delete" onClick={removeComponent}>
                    <DeleteForeverOutlinedIcon />
                  </Link>
                )}
              </>
            )}
          </span>
        </div>

        {/* <div>{data.id}</div>
          <div>{component.content}</div> */}
      </Grid>
    </div>
  );
};
export default Component;
