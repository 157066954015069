// used
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import BulkUploadForm from "../../../../../Components/Common/BulkUploadFormSP/BulkUploadForm";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { contactsFormDTO } from "../../../../../Services/DTO/ContactsDTO";
import { EMPLOYEE_SERVICES } from "../../../../../Services/Employee/EmployeeService";
import { TUserData } from "../../../../../Services/Types/TUserData";
import { RootState } from "../../../../../Store/Store";
import { useAppSelector } from "../../../../../Store/hooks";
import { Get, Post } from "../../../../../Supporting files/CallAPI";
import CheckBox from "../../../../../Supporting files/CheckBox/CheckBox";
import CurrencyTextField from "../../../../../Supporting files/Currency/CurrencyTextField";
import DatePicker from "../../../../../Supporting files/DatePicker/DatePicker";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateEmail,
  validateMobileNumber,
  validateUrl,
} from "../../../../../Supporting files/HelpingFunction";
import MultipleSearchDropdown from "../../../../../Supporting files/MultipleSearchDropdown/MultipleSearchDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../../formLayout.scss";

function AddNewContact() {
  const navigate = useNavigate();
  const [showloader, setshowloader] = useState<boolean>(true);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState({});
  const [formData, setFormData] = useState<contactsFormDTO[]>([]);
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientId, setClientId] = useState<{
    id: string;
    label: string;
  }>({
    id: "",
    label: "",
  });
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [clientName, setClientName] = useState("");
  const [clientWarn, setClientWarn] = useState(false);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [clientIdData, setClientIdData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [postQueryForm, setPostQueryForm] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [sectionStatus, SetSectionStatus] = useState<any>("Personal Details");
  const [subsectionStatus, SetsubSectionStatus] = useState<any>("Basic Info");
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [uploadFile, setUploadFile] = useState(false);
  const [addClass, setAddClass] = useState<boolean>(false);
  const OrganisationDetail = useRef(null);
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  let timeout: any;

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }

    const delayedApiCall = () => {
      get_country_list((cb: any) => {
        setCountryData(cb);
      });

      Get(URL_CONSTANTS.get_contact_form_layout).then((res: any) => {
        let response = res.data.data[0].layout; //response[0].children[0].children[0].children.shift(0);

        let newData = response.map((dataMainsection: contactsFormDTO) => {
          let newMainSection = { ...dataMainsection };

          newMainSection.children = dataMainsection.children.map(
            (dataSubsection) => {
              let newSubsection = { ...dataSubsection };

              newSubsection.children = dataSubsection.children.map((dataRow) => {
                let newRow = { ...dataRow };

                newRow.children = dataRow.children.map((dataColumn) => {
                  let newColumn = { ...dataColumn };

                  newColumn.children = dataColumn.children;

                  newColumn.children = dataColumn.children.map((dataInput) => {
                    if (dataInput.field_type === "contactId") {
                      return {
                        ...dataInput,

                        field_lable: "hide",

                        field_type: "hide",
                      };
                    } else {
                      return { ...dataInput };
                    }
                  });

                  return newColumn;
                });

                return newRow;
              });

              return newSubsection;
            }
          );

          return newMainSection;
        });

        setFormData(newData); //setFormData(response);
      });

      get_client_list();
    }

    timeout = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);


  let checkCountry: any
  useEffect(() => {
    if (selectedCountry && checkCountry !== selectedCountry) {
      checkCountry = selectedCountry
      get_state_list(selectedCountry, (cb: any) => {
        setStateData(cb);
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      get_city_list_via_name(selectedCountry, selectedState, (cb: any) => {
        setCityData(cb);
      });
    }
  }, [selectedState]);


  // get client list
  const get_client_list = () => {
    const onSuccess = (res: any) => {
      let response = res.data.data;
      response.unshift({
        label: "None of these",
        id: "None of these",
      });
      setClientIdData(response);
      setshowloader(false)
    };
    const onError = (err: any) => {
      console.log("err", err);
      setshowloader(false)
    };
    setshowloader(true)
    EMPLOYEE_SERVICES.get_client_list(
      userState?.userType?.toLowerCase(),
      onSuccess,
      onError
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    let data: contactsFormDTO[] = formData;
    //For required fields
    if (attrName === "notes_new_data") {
      setNotesData((notesData) => [
        ...notesData,
        { name: "test", date: new Date(), description: value },
      ]);
    } else if (attrName === setPostQueryForm) {
      attrName(value);
    } else if (attrName === setClientId) {
      if (!value || value.label === null) {
        return;
      }
      setClientId({ id: value.id, label: value.label });
    } else if (attrName === setClientName) {
      setClientWarn(false);
      return clientId.label === "None of these"
        ? setClientName(value)
        : setClientName("");
    } else {
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].required === true &&
        value.length === 0
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = true;
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].error = false;
      }
      //update value
      if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "country"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value ? value.label : "";
        setSelectedCountry(value);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 1
        ].children[attrName[4]].value = "";
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3] + 2
        ].children[attrName[4]].value = "";
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "state"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value ? value.label : "";
        setSelectedState(value);
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value === "N.A."
          ? (data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3] + 1].children[attrName[4]].value = "N.A.")
          : (data[attrName[0]].children[attrName[1]].children[
            attrName[2]
          ].children[attrName[3] + 1].children[attrName[4]].value = "");
      } else if (
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].field_type === "city"
      ) {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value ? value.label : "";
      } else {
        data[attrName[0]].children[attrName[1]].children[attrName[2]].children[
          attrName[3]
        ].children[attrName[4]].value = value;
      }
      setFormData([...data]);
    }
  };

  const detail_form_submit = async (e: any) => {
    e.preventDefault();
    if (disableSave) return;
    // set form error for required true field
    let hasError = false;
    let newData = formData?.map((dataMainsection: contactsFormDTO) => {
      let newMainSection = { ...dataMainsection };
      newMainSection.children = dataMainsection.children.map(
        (dataSubsection) => {
          let newSubsection = { ...dataSubsection };
          newSubsection.children = dataSubsection.children.map((dataRow) => {
            let newRow = { ...dataRow };
            newRow.children = dataRow.children.map((dataColumn) => {
              let newColumn = { ...dataColumn };
              newColumn.children = dataColumn.children.map((dataInput) => {
                if (
                  dataInput.required === true &&
                  dataInput.value.length === 0
                ) {
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.characterLimit &&
                  dataInput.value.length > dataInput.characterLimit
                ) {
                  toast.error("error in character limit ");
                  hasError = true;
                  return { ...dataInput, error: true };
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "phone"
                ) {
                  console.log("dataInput",dataInput)
                  if (
                    validateMobileNumber(dataInput.value.toString()) === true
                  ) {
                    return { ...dataInput, error: false };
                  } else {
                    
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                }
                //  else if (
                //   dataInput.field_lable === "Office Phone Number" &&
                //   dataInput.value
                // ) {
                //   if (
                //     validateMobileNumber(dataInput.value.toString()) === true
                //   ) {
                //     return { ...dataInput, error: false };
                //   } 
                else if (dataInput.field_lable === "Office Phone Number" && dataInput.value) {
                  if (dataInput.value?.length < 7 || dataInput.value?.length > 15) {
                    toast.error("Invalid Office Phone Number");
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.field_type === "email"
                ) {
                  if (
                    validateEmail(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    toast.error("error in valid email");
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name") {
                  return { ...dataInput, value: dataInput.value.trim() }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "url"
                ) {
                  if (validateUrl(dataInput.value.toString()) === true) {
                    return { ...dataInput, error: false };
                  } else {
                    toast.error("error in valid url 1");
                    hasError = true;
                    return { ...dataInput, error: true };
                  }
                } else if (dataInput.field_type === "url") {
                  if (
                    validateUrl(dataInput.value.toString()) === false &&
                    dataInput.value.length >= 1
                  ) {
                    toast.error("error in valid url");
                    hasError = true;
                    return { ...dataInput, error: true };
                  } else {
                    return { ...dataInput, error: false };
                  }
                } else if (
                  dataInput.required === true &&
                  dataInput.field_type === "address"
                ) {
                  toast.error("error in address");
                  hasError = true;
                  return { ...dataInput, error: true };
                } else {
                  return { ...dataInput, error: false };
                }
              });
              return newColumn;
            });
            return newRow;
          });
          return newSubsection;
        }
      );
      return newMainSection;
    });

    setFormData(newData);

    let data: boolean = clientWarn;
    data = clientName.length === 0 ? true : false;
    setClientWarn(data);
    if (
      hasError === false &&
      clientId.label === "None of these" &&
      data === true
    ) {
      return;
    } else if (hasError === false) {
      let params = {
        layout: formData,
        notes: notesData,
        organisationId: clientId,
        organisationName: clientName,
      };
      setDisableSave(true);
      Post(URL_CONSTANTS.add_new_contact, params)
        .then((data: any) => {
          let status = data[0];
          let response = data[1];
          if (status === "success") {
            toast.success("Data added successfully");
            navigate(ALL_ROUTES.CONTACTS);
          } else if (status?.response?.data?.status === "failed") {
            setDisableSave(false);
            let param = status.response.data.errors[0].param;
            let error = status.response.data.errors[0].message;
            toast.error(param + " " + " " + error);
          } else if (status?.status === "failed") {
            setDisableSave(false);
            let param = status.errors[0].param;
            let error = status.errors[0].message;
            toast.error(param + " " + " " + error);
          }
        })
        .catch(() => setDisableSave(false));
    }
  };

  const handleClicks = (id: any) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {showloader && <NewLoader />}
      <Box className="layoutWrapper">
        <BulkUploadForm
          open_status={uploadFile}
          attrName={setUploadFile}
          value_update={updateMasterState}
        />
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link className="backStep" onClick={() => { navigate(-1) }} >
              <ChevronLeftIcon />
            </Link>

            <div>
              <h3>Add New Contact</h3>
            </div>
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
        <form onSubmit={detail_form_submit}>
          <Grid container spacing={3} className="mt-0">
            <Grid xs={12}>
              <Grid container spacing={3}>
                <Grid xs className="leftSection">
                  {formData.map(
                    (dataMainSection: contactsFormDTO, index: number) => (
                      <>
                        <Link
                          className={
                            sectionStatus === dataMainSection.name
                              ? `active sectionLink`
                              : "sectionLink"
                          }
                          onClick={() => {
                            handleClicks(dataMainSection.name);
                            setAddClass(true);
                            SetSectionStatus(
                              dataMainSection.name === undefined
                                ? ""
                                : dataMainSection.name
                            );
                          }}
                        >
                          <span>{dataMainSection.name}</span>
                        </Link>
                        {dataMainSection.children.map((dataSubsection) => (
                          <ul className="accordion_list">
                            <li>
                              <Link
                                className={
                                  subsectionStatus === dataSubsection.name
                                    ? `inner_link active`
                                    : `inner_link`
                                }
                                onClick={() => {
                                  handleClicks(dataSubsection.name);
                                  SetsubSectionStatus(dataSubsection.name);
                                  SetSectionStatus(
                                    dataMainSection.name === undefined
                                      ? ""
                                      : dataMainSection.name
                                  );
                                }}
                              >
                                {dataSubsection.name}
                              </Link>
                            </li>
                          </ul>
                        ))}
                      </>
                    )
                  )}

                  <Link
                    className={
                      sectionStatus === "OrganisationDetail"
                        ? "active sectionLink OrganisationDetail"
                        : "sectionLink OrganisationDetail"
                    }
                    onClick={() => {
                      scrollToSection(OrganisationDetail);
                      SetSectionStatus("OrganisationDetail");
                    }}
                  >
                    <span>Organisation Detail</span>
                  </Link>
                </Grid>
                <Grid xs className="rightSection scroll">
                  {formData.map(
                    (
                      dataMainSection: contactsFormDTO,
                      indexMainSection: number
                    ) => (
                      // Main section
                      <Box
                        onMouseEnter={() => {
                          SetSectionStatus(dataMainSection.name);
                        }}
                        className="detailSection"
                      >
                        <div id={dataMainSection.name}>
                          <div className="sectionTitle">
                            <h4>{dataMainSection.name}</h4>
                          </div>
                          {dataMainSection.children.map(
                            (dataSubsection, indexSubsection) => (
                              // Sub Section
                              <Grid
                                onMouseEnter={() => {
                                  SetsubSectionStatus(dataSubsection.name);
                                }}
                                container
                              >
                                <Grid xs={12}>
                                  <h5
                                    id={dataSubsection.name}
                                    key={dataSubsection.type}
                                    className="subHeading"
                                  >
                                    {dataSubsection.name}
                                  </h5>
                                  {dataSubsection.children.map(
                                    (dataRow, indexRow) => (
                                      // Row
                                      <Grid
                                        container
                                        className="subRow"
                                        spacing={3}
                                      >
                                        {dataRow.children.map(
                                          (dataColumn, indexColumn) => (
                                            // Columns created
                                            <Grid xs className="subColumn">
                                              {dataColumn.children.map(
                                                (dataInput, indexInput) => (
                                                  // input Component will be here
                                                  <form>
                                                    {dataInput.field_type ===
                                                      "singleselect" ? (
                                                      <SelectDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        dropdown_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_messg={`Select ${dataInput.field_lable}`}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "phone" ? (
                                                      <RKTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                            error_messg={
                                                              validateMobileNumber(dataInput.value) ? "" : dataInput.value.length === 0 ? `Enter ${dataInput.field_lable}`:`Enter Valid ${dataInput.field_lable}`
                                                 
                                                        }
                                                        required={
                                                          dataInput.field_lable ===
                                                            "Office Phone Number" &&
                                                            dataInput.value
                                                            ? true
                                                            : dataInput.required
                                                        }
                                                        charcterlimit={
                                                          dataInput.field_lable ===
                                                            "Office Phone Number" ? 15 :
                                                            !dataInput.characterLimit
                                                              ? 11
                                                              : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "email" ? (
                                                      <RKTextField
                                                        validation_type="email"
                                                        type="email"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : validateEmail(
                                                              dataInput.value
                                                            ) === false
                                                              ? `Enter valid Email`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 50
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "datepicker" ? (
                                                      <DatePicker
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={
                                                          dataInput.value || null
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        error_message={`Select ${dataInput.field_lable}`}
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        max_date={new Date()}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "searchselect" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message={
                                                          "Select the value"
                                                        }
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "checkbox" ? (
                                                      <CheckBox
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        name={
                                                          dataInput.field_lable
                                                        }
                                                        id={dataInput._id}
                                                        value={Boolean(
                                                          dataInput.value
                                                        )}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        default_check={true}
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "number" ? (
                                                      <RKTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} limit`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.maxDigits
                                                            ? 12
                                                            : dataInput.maxDigits
                                                        }
                                                      />
                                                    ) : dataInput.field_type ==
                                                      "currency" ? (
                                                      <CurrencyTextField
                                                        validation_type="numeric"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} Limit`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.maxDigits
                                                            ? 12
                                                            : dataInput.maxDigits
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "multiselect" ? (
                                                      <MultipleSearchDropdown
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={typeof dataInput.value === "string" ? [] : dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        multiple_data={
                                                          dataInput.pickliststep
                                                            ? dataInput.pickliststep
                                                            : []}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        error_messg={
                                                          "Select the value"
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "multiline" ? (
                                                      <TextAreaField
                                                        specialcharacter={
                                                          !dataInput.speacialChar
                                                            ? false
                                                            : dataInput.speacialChar
                                                        }
                                                        alphanumeric={
                                                          !dataInput.alphaNumeric
                                                            ? false
                                                            : dataInput.alphaNumeric
                                                        }
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        warn_status={
                                                          dataInput.error ===
                                                            undefined
                                                            ? false
                                                            : dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : dataInput.characterLimit &&
                                                              dataInput.value
                                                                .length >
                                                              dataInput.characterLimit
                                                              ? `Maximum ${dataInput.characterLimit} charcter`
                                                              : ""
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 30
                                                            : dataInput.characterLimit
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "url" ? (
                                                      <RKTextField
                                                        validation_type="url"
                                                        class_name="inputField"
                                                        title={
                                                          dataInput.field_lable
                                                        }
                                                        value={dataInput.value}
                                                        info_status={
                                                          dataInput.toolTip
                                                        }
                                                        info_message={
                                                          dataInput.tipText
                                                        }
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        error_messg={
                                                          dataInput.value
                                                            .length === 0
                                                            ? `Enter ${dataInput.field_lable}`
                                                            : validateUrl(
                                                              dataInput.value
                                                            ) === false
                                                              ? `Enter a valid Url`
                                                              : ""
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        charcterlimit={
                                                          !dataInput.characterLimit
                                                            ? 50
                                                            : dataInput.characterLimit
                                                        }
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "country" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title="Country"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={countryData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message="Please select country"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "state" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title="State"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={stateData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message="Please select state"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "city" ? (
                                                      <SearchDropdown
                                                        class_name="inputField"
                                                        title="City"
                                                        value={dataInput.value}
                                                        attrName={[
                                                          indexMainSection,
                                                          indexSubsection,
                                                          indexRow,
                                                          indexColumn,
                                                          indexInput,
                                                          "value",
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                        data={cityData}
                                                        warn_status={
                                                          dataInput.error
                                                        }
                                                        required={
                                                          dataInput.required
                                                        }
                                                        error_message="Please select city"
                                                      />
                                                    ) : dataInput.field_type ===
                                                      "hide" ? null : (
                                                      // <RKTextField
                                                      //   specialcharacter={
                                                      //     (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name") ? false :
                                                      //       !dataInput.speacialChar
                                                      //         ? false
                                                      //         : dataInput.speacialChar
                                                      //                                 }
                                                        
                                                      //   alphanumeric={
                                                      //     (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name") ? false :
                                                      //       !dataInput.alphaNumeric
                                                      //         ? false
                                                      //         : dataInput.alphaNumeric
                                                      //   }
                                                      //   validation_type={"name"}
                                                      //   class_name="inputField"
                                                        
                                                      //   title={
                                                      //     dataInput.field_lable
                                                          
                                                          
                                                      //                                 }
                                                                                      
                                                      
                                                        
                                                      //   value={dataInput.value}
                                                      //   info_status={
                                                      //     dataInput.toolTip
                                                      //   }
                                                      //   info_message={
                                                      //     dataInput.tipText
                                                      //   }
                                                        
                                                      //   attrName={[
                                                      //     indexMainSection,
                                                      //     indexSubsection,
                                                      //     indexRow,
                                                      //     indexColumn,
                                                      //     indexInput,
                                                      //     "value",
                                                      //   ]}
                                                        
                                                      //   value_update={
                                                      //     updateMasterState
                                                      //   }
                                                      //   warn_status={
                                                      //     dataInput.error
                                                      //   }
                                                      //   error_messg={
                                                      //     dataInput.value
                                                      //       .length === 0
                                                      //       ? `Enter ${dataInput.field_lable}`
                                                      //       : dataInput.characterLimit &&
                                                      //         dataInput.value
                                                      //           .length >
                                                      //         dataInput.characterLimit
                                                      //         ? `Maximum ${dataInput.characterLimit} charcter`
                                                      //         : ""
                                                      //   }
                                                      //   required={
                                                      //     dataInput.required
                                                      //   }
                                                      //   charcterlimit={
                                                      //     !dataInput.characterLimit
                                                      //       ? 30
                                                      //       : dataInput.characterLimit
                                                      //   }
                                                                                    // />
                                                                                    <RKTextField
                                                                                    specialcharacter={
                                                                                      (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name")
                                                                                        ? false
                                                                                        : (dataInput.field_lable === "Department" || dataInput.field_lable === "Designation")
                                                                                          ? true
                                                                                          : dataInput.speacialChar || false
                                                                                    }
                                                                                    
                                                                                    alphanumeric={
                                                                                      (dataInput.field_lable === "First Name" || dataInput.field_lable === "Last Name")
                                                                                        ? false
                                                                                        : (dataInput.field_lable === "Department" || dataInput.field_lable === "Designation")
                                                                                          ? true
                                                                                          : dataInput.alphaNumeric || false
                                                                                    }
                                                                                    
                                                                                    validation_type={"name"}
                                                                                    class_name="inputField"
                                                                                    title={dataInput.field_lable}
                                                                                    value={dataInput.value}
                                                                                    
                                                                                    info_status={dataInput.toolTip}
                                                                                    info_message={dataInput.tipText}
                                                                                    
                                                                                    attrName={[
                                                                                      indexMainSection,
                                                                                      indexSubsection,
                                                                                      indexRow,
                                                                                      indexColumn,
                                                                                      indexInput,
                                                                                      "value",
                                                                                    ]}
                                                                                    
                                                                                    value_update={updateMasterState}
                                                                                    warn_status={dataInput.error}
                                                                                    
                                                                                    error_messg={
                                                                                      dataInput.value.length === 0
                                                                                        ? `Enter ${dataInput.field_lable}`
                                                                                        : dataInput.characterLimit && dataInput.value.length > dataInput.characterLimit
                                                                                          ? `Maximum ${dataInput.characterLimit} character`
                                                                                          : ""
                                                                                    }
                                                                                    
                                                                                    required={dataInput.required}
                                                                                    
                                                                                    charcterlimit={
                                                                                      !dataInput.characterLimit
                                                                                        ? 30
                                                                                        : dataInput.characterLimit
                                                                                    }
                                                                                  />
                                                                                  
                                                    )}
                                                  </form>
                                                )
                                              )}
                                            </Grid>
                                          )
                                        )}
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            )
                          )}
                        </div>
                      </Box>
                    )
                  )}
                  <Box
                    onMouseEnter={() => {
                      SetSectionStatus("OrganisationDetail");
                    }}
                    className="detailSection"
                    ref={OrganisationDetail}
                  >
                    <div>
                      <div className="sectionTitle">
                        <h4>Organisation Detail</h4>
                      </div>
                      <Grid container spacing={3} className={"mx-0"}>
                        <Grid xs={6}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Select Organisation"
                            value={clientId.label}
                            attrName={setClientId}
                            value_update={updateMasterState}
                            data={clientIdData ? clientIdData : []}
                            warn_status={false}
                            error_message="Select state"
                          // required={true}
                          />
                        </Grid>
                        {clientId.label === "None of these" ? (
                          <Grid xs={6}>
                            <RKTextField
                              validation_type="name"
                              class_name="inputField"
                              title="Name of Organsiation"
                              value={clientName}
                              attrName={setClientName}
                              value_update={updateMasterState}
                              warn_status={clientWarn}
                              error_messg="Select Field"
                              specialcharacter={true}
                            // required={true}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Footer Section */}
          <Grid container spacing={3} className="footerSection">
            <Grid xs={12} className="ctaBtn">
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                variant="outlined"
                className="borderBtn"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="submitBtn"
                disabled={disableSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default AddNewContact;
