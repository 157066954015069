import { Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import warningImage from "../../../Assets/images/Attention.svg";
const PoliciesWarningpopup = ({
  popupStatus,
  setPopupStatus,
  title,
}: {
  popupStatus: boolean;
  setPopupStatus: Function;
  title: string;
}) => {
  return (
    <Modal open={popupStatus} className="modalWrapper">
      <Box className="modalInner deletepopup">
        <Grid container spacing={1}>
          <Grid xs={12} sx={{ width: "100%" }}>
            {/* <Link
              className="close-button"
              onClick={() => {
                setPopupStatus(false);
              }}
            /> */}
            <div className="img-prt">
              <img src={warningImage} alt="" className="img" />
            </div>
          </Grid>
          <Grid xs={12} sx={{ width: "100%" }}>
            <Link className="md-link mb-10" underline="none">
              {title}
            </Link>
          </Grid>
          <Grid
            xs={12}
            className="ctaBtn"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              className="submitBtn"
              onClick={() => {
                setPopupStatus(false);
              }}
            >
              I Understand
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default PoliciesWarningpopup;
