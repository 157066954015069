import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../../../../../../Supporting files/DatePicker/DatePicker";
import RKTextField from "../../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../../../../formLayout.scss";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  uploadImage,
  validateMobileNumber,
  validatePincode,
} from "../../../../../../../Supporting files/HelpingFunction";
import { toast } from "react-toastify";
import { EMPLOYER_SETTING_SERVICES } from "../../../../../../../Services/EmployerServices/Settings/SetingServices";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../../APIVar/EmployerUrlConstant";
import { useAppSelector } from "../../../../../../../Store/hooks";
import { ALL_ROUTES } from "../../../../../../../Routes/all_routes";
import SelectDropdown from "../../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../../Services/Settings/AccessLevelServices";

function EditPersonalDetails() {
  const userData: any = useAppSelector(({ userDetailsSlice }) => {
    return userDetailsSlice;
  });
  const navigate = useNavigate();
  const [selectedCountryStateCity, setselectedCountryStateCity] = useState<{
    country: any;
    state: any;
    city: any;
  }>({
    country: "",
    state: "",
    city: "",
  });
  const [branch_data, setBranch_data] = useState<
    Array<{
      id: string;
      label: string;
    }>
  >([]);
  const [countryData, setCountryData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [departmentData, setdepartmentData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [personalSettingdata, setpersonalSettingdata] = useState({
    department: "",
    logo: "",
    gender: "",
    dob: "",
    building: "",
    area: "",
    country: "",
    state: "",
    city: "",
    mobileNumber: "",
    phoneNumber: "",
    name: "",
    pinCode: "",
    designations: "",
    email: "",
    branch: "",
  });

  const [displayLogo, setDisplayLogo] = useState<{
    logo: string;
    warning: boolean;
  }>({ logo: "", warning: false });

  const [personalSettingdataWarn, setpersonalSettingdataWarn] = useState({
    department: false,
    logo: false,
    gender: false,
    dob: false,
    building: false,
    area: false,
    country: false,
    state: false,
    city: false,
    mobileNumber: false,
    phoneNumber: false,
    name: false,
    pinCode: false,
    designations: false,
  });

  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const updateMasterState = (attrName: any, value: any) => {
    let keys = attrName[0];
    let mainstate = attrName[1];
    if (keys === "inputfields") {
      setpersonalSettingdata({ ...personalSettingdata, [mainstate]: value });
      setpersonalSettingdataWarn({
        ...personalSettingdataWarn,
        [mainstate]: false,
      });
      let label = attrName[2];
      if (label === "label_field") {
        setpersonalSettingdata({
          ...personalSettingdata,
          [mainstate]: value?.label,
        });
      } else {
        setselectedCountryStateCity({
          ...selectedCountryStateCity,
          [mainstate]: value?.label,
        });
      }
      if (mainstate === "country") {
        let country = { id: "", label: value?.label };
        get_state_list(country, (cb: any) => {
          setStateData(cb);
        });
        setpersonalSettingdata({
          ...personalSettingdata,
          [mainstate]: value?.label,
          state: "",
          city: "",
        });
      } else if (mainstate === "state") {
        let state = { id: "", label: value?.label };
        let country = { id: "", label: selectedCountryStateCity.country };
        console.log("dfgfdgdfgdfg",selectedCountryStateCity)

        
        get_city_list_via_name(country, state, (cb: any) => {
          setCityData(cb);
        });
        setpersonalSettingdata({
          ...personalSettingdata,
          [mainstate]: value?.label,
          city: "",
        });
      } else if (mainstate === "city") {
        setpersonalSettingdata({
          ...personalSettingdata,
          [mainstate]: value?.label,
        });
      }
    }
  };
  console.log("displayLogo.logo", displayLogo.logo)
  //get personal setting data
  const get_personal_settings = () => {
    const onSuccess = (res: any) => {
      const data = res.data.data;
      const obj = {
        department: data.Departments,
        logo: data.logo,
        gender: data.gender,
        dob: data.dob,
        building: data["Building/Street Name"],
        area: data["Area Name"],
        country: data.Country,
        state: data.State,
        city: data.City,
        mobileNumber: data.addressDetails.mobileNumber,
        phoneNumber: data.addressDetails.phoneNumber,
        name: data.name,
        pinCode: data.Pincode,
        designations: data.Designations,
        email: data.email,
        branch: data.employer_branch,
      };
      setpersonalSettingdata(obj);
      setDisplayLogo({ logo: data.logo, warning: true });
      setselectedCountryStateCity({
        country: data.Country ?? data.gender,
        state: data.State ?? "",
        city: data.City ?? "",
      });
    };
    const onError = (err: any) => {
      console.log("Branches", err);
    };
    userData.parentsId
      ? EMPLOYER_SETTING_SERVICES.get_personal_settings(
        EMPLOYER_URL_CONSTANTS.get_emp_personal_settings,
        onSuccess,
        onError
      )
      : EMPLOYER_SETTING_SERVICES.get_personal_settings(
        EMPLOYER_URL_CONSTANTS.get_emp_personal_settings,
        onSuccess,
        onError
      );
  };

  //get personal setting data
  const update_personal_settings = () => {
    let data: any = personalSettingdataWarn;
    // data.department = personalSettingdata.department ? false : true;
    data.logo = personalSettingdata.logo ? false : true;
    data.gender = personalSettingdata.gender ? false : true;
    data.dob = personalSettingdata.dob ? false : true;
    data.building = personalSettingdata.building ? false : true;
    // data.area = personalSettingdata.area ? false : true;
    data.country = personalSettingdata.country ? false : true;
    data.state = personalSettingdata.state ? false : true;
    data.city = personalSettingdata.city ? false : true;
    data.mobileNumber = !validateMobileNumber(personalSettingdata.mobileNumber);
    // data.name = personalSettingdata.name ? false : true;
    data.pinCode = !validatePincode(personalSettingdata.pinCode);
    setpersonalSettingdataWarn({ ...data });
    if (
      data.logo === false &&
      // data.department === false &&
      data.gender === false &&
      data.dob === false &&
      data.building === false &&
      // data.area === false &&
      data.country === false &&
      data.state === false &&
      data.city === false &&
      data.mobileNumber === false &&
      // data.name === false &&
      data.pinCode === false
    ) {
      let params = {
        // department: personalSettingdata.department,
        logo: personalSettingdata.logo ? personalSettingdata.logo : "",
        gender: personalSettingdata.gender,
        dob: personalSettingdata.dob,
        // building: personalSettingdata.building,
        // area: personalSettingdata.area,
        // country: personalSettingdata.country,
        // state: personalSettingdata.state,
        // city: personalSettingdata.city,
        // mobileNumber: Number(personalSettingdata.mobileNumber),
        // phoneNumber: Number(personalSettingdata.phoneNumber),
        // name: personalSettingdata.name,
        // pinCode: personalSettingdata.pinCode,
        // designations: personalSettingdata.designations,
        // email: personalSettingdata.email,
        addressDetails: {
          address1: personalSettingdata.building,
          address2: personalSettingdata.area,
          city: personalSettingdata.city,
          country: personalSettingdata.country,
          mobileNumber: Number(personalSettingdata.mobileNumber),
          pinCode: personalSettingdata.pinCode,
          state: personalSettingdata.state,
        },
      };

      const onSuccess = (res: any) => {
        // navigate(-1);
        toast.success(" Updated successfully");
      };
      const onError = (err: any) => {
        toast.error(err);
        console.log("Branches", err);
      };
      EMPLOYER_SETTING_SERVICES.update_personal_settings(
        params,
        onSuccess,
        onError
      );
    }
  };

  //get department data
  // const get_department_list = () => {
  //   const onSuccess = (res: any) => {
  //     setdepartmentData(res.data.data);
  //   };
  //   const onError = (err: any) => {
  //     console.log("department", err);
  //   };
  //   PERSONAL_SETTINGS_SERVICES.department_list(onSuccess, onError);
  // };

  useEffect(() => {
    get_personal_settings();
    get_country_list((cb: any) => {
      setCountryData(cb);
    });
    get_Dropdown_branch_list()
    // get_department_list();
  }, []);
  const get_Dropdown_branch_list = () => {
    const onSuccess = (res: any) => {

      let data: any = res.data.data.map(
        ({ _id, name }: { _id: string; name: string }) => ({
          label: _id,
          value: name,
        })
      );
      setBranch_data(data);

    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_branch_department_dropdown_list(
      EMPLOYER_URL_CONSTANTS.get_employer_location_list,
      onSuccess,
      onError
    );
  };

  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(
                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`
              );
            }}
          >
            <ChevronLeftIcon />
          </Link>
          <div>
            <h3>Edit</h3>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="rightSection">
              <Box className="detailSection">
                <div className="sectionTitle">
                  <h4>Personal Details</h4>
                </div>
                <Grid container spacing={3}>
                  <Grid xs={4} className="align-item-center text-center">
                    <div className="profile_section">
                      <h6 className="mb-2">Profile Picture</h6>
                      <div className="profile_inner_section">
                        <img
                          src={displayLogo.logo.includes("undefined")? "images/nobody_original.svg" : displayLogo.logo}
                          alt=""
                          className="edit_profile_logo"
                          onError={(e) => {
                            e.currentTarget.src = "images/nobody_original.svg";
                          }}
                        />
                        <Link className="upload_img" component="label">
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={(e: any) => {
                              let displaydata: any = displayLogo;
                              displaydata.warning =
                                displayLogo.logo !== "" ? true : false;
                              setDisplayLogo({ ...displaydata });
                              const file = e.target.files[0];
                              uploadImage(
                                file,
                                (base64Image: any, base64String: any) => {
                                  setpersonalSettingdata({
                                    ...personalSettingdata,
                                    logo: base64Image,
                                  });
                                  setDisplayLogo({
                                    logo: base64String,
                                    warning: false,
                                  });
                                  setpersonalSettingdataWarn({
                                    ...personalSettingdataWarn,
                                    logo: false,
                                  });
                                }
                              );
                            }}
                          />
                          <img src="images/edit_image_icon.svg" alt="" />
                        </Link>
                        {personalSettingdataWarn.logo === true ? (
                          <span
                            className="inputField"
                            style={{
                              color: "#ec3f3f",
                              fontSize: "11px",
                              position: "relative",
                              right: "0",
                              bottom: "-56px",
                            }}
                          >
                            Update logo
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={8}>
                    <Box>
                      <Grid container spacing={6}>
                        {/* <Grid sm={4} md={4} lg={4} xl={4}>
                        <RKTextField
                          class_name="inputField"
                          title={"Name"}
                          value={personalSettingdata.name}
                          attrName={["inputfields", "name"]}
                          value_update={updateMasterState}
                          warn_status={personalSettingdataWarn.name}
                          disabled={true}
                        />
                      </Grid> */}
                        <Grid xs={4}>
                          <RKTextField
                            class_name="inputField"
                            title={"Designation"}
                            value={personalSettingdata.designations}
                            attrName={["inputfields", "designations"]}
                            value_update={updateMasterState}
                            // warn_status={personalSettingdataWarn.designations}
                            warn_status={false}
                            disabled
                          />
                        </Grid>

                        <Grid xs={4}>
                          <RKTextField
                            class_name="inputField"
                            title={"Department"}
                            value={personalSettingdata.department}
                            attrName={["inputfields", "department"]}
                            value_update={updateMasterState}
                            // warn_status={personalSettingdataWarn.department}
                            warn_status={false}
                            disabled
                          />
                        </Grid>
                        <Grid xs={4}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Branch"
                            value={personalSettingdata.branch}
                            attrName={["editUser", "branch"]}
                            value_update={updateMasterState}
                            dropdown_data={branch_data}
                            disabled
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <SearchDropdown
                            class_name="inputField"
                            title="Gender"
                            value={personalSettingdata.gender}
                            attrName={["inputfields", "gender", "label_field"]}
                            value_update={updateMasterState}
                            data={gender_data}
                            warn_status={personalSettingdataWarn.gender}
                            required
                          />
                        </Grid>
                        <Grid xs={4} md={4} lg={4} xl={4}>
                          <DatePicker
                            class_name="inputField"
                            title="Date of Birth"
                            value={personalSettingdata.dob}
                            attrName={["inputfields", "dob"]}
                            value_update={updateMasterState}
                            error_message="Enter Date of Birth"
                            warn_status={personalSettingdataWarn.dob}
                            max_date={new Date()}
                            required
                          />
                        </Grid>
                        {/* <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                            class_name="inputField"
                            title={"Phone Number"}
                            value={personalSettingdata.phoneNumber}
                            attrName={["inputfields", "phoneNumber"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.phoneNumber}
                            error_messg="Phone Number should be valid"
                            validation_type="numeric"
                            charcterlimit={10}
                          />
                        </Grid> */}
                        <Grid sm={4} md={4} lg={4} xl={4}>
                          <RKTextField
                            class_name="inputField"
                            title={"Mobile Number"}
                            value={personalSettingdata.mobileNumber}
                            attrName={["inputfields", "mobileNumber"]}
                            value_update={updateMasterState}
                            warn_status={personalSettingdataWarn.mobileNumber}
                            error_messg="Mobile Number should be valid"
                            validation_type="numeric"
                            charcterlimit={10}
                            required
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                {/* Address Details */}
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h5 className="subHeading">Address Details</h5>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Street Name"}
                      value={personalSettingdata.building}
                      attrName={["inputfields", "building"]}
                      value_update={updateMasterState}
                      warn_status={personalSettingdataWarn.building}
                      required
                    />
                  </Grid>
                  <Grid sm={6} md={6} lg={6} xl={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Area Name"}
                      value={personalSettingdata.area}
                      attrName={["inputfields", "area"]}
                      value_update={updateMasterState}
                      // warn_status={personalSettingdataWarn.area}
                      warn_status={false}
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      title="Country"
                      value={personalSettingdata.country}
                      attrName={["inputfields", "country"]}
                      value_update={updateMasterState}
                      data={countryData}
                      warn_status={personalSettingdataWarn.country}
                      error_message="Select country"
                      required
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      title="State"
                      value={personalSettingdata.state}
                      attrName={["inputfields", "state"]}
                      value_update={updateMasterState}
                      data={stateData}
                      warn_status={personalSettingdataWarn.state}
                      error_message="Select state"
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <SearchDropdown
                      class_name="inputField"
                      title="City"
                      value={personalSettingdata.city}
                      attrName={["inputfields", "city"]}
                      value_update={updateMasterState}
                      data={cityData}
                      warn_status={personalSettingdataWarn.city}
                      error_message="Select city"
                      required
                    />
                  </Grid>
                  <Grid sm={3} md={3} lg={3} xl={3}>
                    <RKTextField
                      class_name="inputField"
                      title={"Pincode"}
                      value={personalSettingdata.pinCode}
                      attrName={["inputfields", "pinCode"]}
                      value_update={updateMasterState}
                      warn_status={personalSettingdataWarn.pinCode}
                      error_messg="Pincode should be valid"
                      validation_type="numeric"
                      charcterlimit={6}
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            onClick={update_personal_settings}
            variant="contained"
            className="submitBtn"
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditPersonalDetails;
