import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import "./UGTable.scss";
import { Box, Button, Checkbox, Link, Tooltip } from "@mui/material";
import { SaveColumnSelectionDTO } from "../../Services/DTO/UGTableDTO";
import { TUserData } from "../../Services/Types/TUserData";
import { TABLE_SERVICES } from "../../Services/UGTableServices/TableServices";
import { RootState } from "../../Store/Store";
import { useAppSelector } from "../../Store/hooks";
import SortableList from "./SortableList";
import { useNavigate } from "react-router-dom";

interface Props {
  header_data: Array<any>;
  data: Array<any>;
  value_update: Function;
  attrName: string;
  // check_box?: boolean;
  BE_filter_code?: number;
  refetch_data?: any;
  totalRecords?: number;
  setTableLimit?: any;
  setTableSkip?: any;
  paginationMode?: any;
  class_Name?: string;
  setPageNumberData?: any;
  checkboxSelection?: boolean;
  hideThreeDot?: boolean;
  disableCheckboxKey?: string;
  disableCheckboxValue?: string;
  enableCheckboxDisable?: boolean;
  hideRowsPerPageOption?: boolean;
}

const UGTable: React.FC<Props> = ({
  header_data,
  data,
  value_update,
  attrName,
  BE_filter_code,
  refetch_data,
  totalRecords,
  setTableLimit,
  setTableSkip,
  paginationMode,
  class_Name,
  setPageNumberData,
  checkboxSelection,
  hideThreeDot,
  disableCheckboxKey,
  disableCheckboxValue,
  enableCheckboxDisable,
  hideRowsPerPageOption,
}) => {
  console.log("data", data);
  const navigate = useNavigate();
  const userState: TUserData = useAppSelector(
    (state: RootState) => state.userDetailsSlice
  );
  const [columnSortingPopUp, setColumnSortingPopUp] = useState(false);
  const [filterCheckboxData, setFilterCheckboxData] = useState(header_data);
  const currentRoute = BE_filter_code?.toString() || "";

  const [pageSize, setPageSize] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [columns, setColumns] = useState(() => {
    let data = [];
    for (var i = 0; i < header_data.length; i++) {
      if (header_data[i].check_status === true) {
        data.push(header_data[i]);
      }
    }
    return data;
  });

  useEffect(() => {
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
    const existingEntryIndex = parsedData.find(
      (entry: any) => entry.route === currentRoute
    );
    setPageSize(existingEntryIndex?.pageNumber ?? 25);
  }, []);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "asc",
    },
  ]);

  // For Hide Filter Pop-Up
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setColumnSortingPopUp(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  let timeout: any;

  // set header data
  useEffect(() => {
    if (BE_filter_code?.toString()) {
      if (timeout) {
        clearTimeout(timeout);
      }

      const delayedApiCall = () => {
        getSavedColumnConfig();
      };

      timeout = setTimeout(delayedApiCall, 900);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    } else {
      setColumns(header_data);
      setFilterCheckboxData(header_data);
    }
  }, [header_data]);

  const getSavedColumnConfig = (): void => {
    const onSuccess = (res: any) => {
      if (!res) {
        setColumns(header_data);
        setFilterCheckboxData(header_data);
      }

      if (!res.data.data || res.data.data.length === 0) {
        setColumns(header_data);
        setFilterCheckboxData(header_data);
      } else {
        const newHeaderData = [];

        for (let i = 0; i < res.data.data.length; i++) {
          for (let j = 0; j < header_data.length; j++) {
            if (res.data.data[i].name === header_data[j].field) {
              newHeaderData.push({
                ...header_data[j],
                check_status: res.data.data[i].isChecked,
              });
              break;
            }
          }
        }

        for (let i = 0; i < header_data.length; i++) {
          if (!newHeaderData.some((el) => el.field === header_data[i].field)) {
            newHeaderData.push(header_data[i]);
          }
        }

        const newColumns = newHeaderData.filter((item) => item.check_status);

        setColumns(newColumns);
        setFilterCheckboxData(newHeaderData);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    TABLE_SERVICES.get_selected_columns(
      onSuccess,
      onError,
      BE_filter_code,
      userState.userType.toLowerCase()
    );
  };

  //On Click action perform of Checkbox
  const onClickFilterCheckbox = (item: any, value: Boolean) => {
    let data: any = [];

    for (var i = 0; i < filterCheckboxData.length; i++) {
      if (filterCheckboxData[i].field === item.field) {
        filterCheckboxData[i] = {
          ...filterCheckboxData[i],
          check_status: value,
        };
      }
    }

    data = filterCheckboxData.filter((e: any) => e.check_status);
    setColumns(data);
    setFilterCheckboxData(filterCheckboxData);
    saveColumnSelection(filterCheckboxData);
  };

  //Hide and show all columns
  const hide_and_show_all_columns = (index: number, status: boolean) => {
    let filterData = filterCheckboxData.map((el) =>
      el.headerName === filterCheckboxData[index].headerName
        ? { ...el, check_status: status }
        : { ...el, check_status: status }
    );
    let data = [];
    for (var i = 0; i < filterData.length; i++) {
      if (filterData[i].check_status === true) {
        data.push(filterData[i]);
      }
    }

    setColumns(data);
    setFilterCheckboxData(filterData);
    saveColumnSelection(filterData);
  };
  //Filter on Checkbox
  const filter_data = (search: string) => {
    let filteredData = [];

    for (var i = 0; i < header_data.length; i++) {
      if (
        header_data[i].headerName.toLowerCase().includes(search.toLowerCase())
      ) {
        filteredData.push(header_data[i]);
      }
    }

    setFilterCheckboxData(filteredData);
  };

  const handleSortModelChange = (newModel: any) => {
    setSortModel(newModel);
  };

  const saveColumnSelection = (filteredColumns: any) => {
    const data = [];

    for (let i = 0; i < filteredColumns.length; i++) {
      data.push({
        name: filteredColumns[i].field,
        isChecked: filteredColumns[i].check_status,
      });
    }

    const onSuccess = (res: any) => { };

    const onError = (err: any) => {
      console.log(err);
    };

    const objToSave: SaveColumnSelectionDTO = {
      filterData: data,
    };

    TABLE_SERVICES.save_selected_columns(
      onSuccess,
      onError,
      BE_filter_code,
      objToSave,
      userState.userType.toLowerCase()
    );
  };
  const reorder = ({ oldIndex, newIndex }: any) => {
    const result = Array.from(filterCheckboxData);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    setFilterCheckboxData(result);
    setColumns(result.filter((item: any) => item.check_status));
    saveColumnSelection(result);
  };

  function updateRowPerPage(route: string, pageNumber: number) {
    // Step 1: Retrieve the current rowPerPage data from sessionStorage
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : []; // Default to an empty array if null

    // Step 2: Check if the route already exists
    const existingEntryIndex = parsedData.findIndex(
      (entry: any) => entry.route === route
    );

    if (existingEntryIndex !== -1) {
      // Step 3a: If it exists, update the pageNumber
      parsedData[existingEntryIndex].pageNumber = pageNumber;
    } else {
      // Step 3b: If it doesn't exist, add a new entry
      parsedData.push({ route, pageNumber });
    }

    // Step 4: Save the updated array back to sessionStorage
    sessionStorage.setItem("rowPerPage", JSON.stringify(parsedData));
  }

  // Usage Example

  const handlePageSizeChange = (pageSize: number) => {
    updateRowPerPage(currentRoute, pageSize);
    if (!refetch_data) {
      return;
    }
    setPageSize(pageSize);
    setTableLimit(pageSize);
    refetch_data(pageSize, pageNumber * pageSize);
  };

  const handlePageChange = (page: number) => {
    if (!refetch_data) {
      return;
    }
    setTableSkip(page * pageSize);
    console.log("insidetable", pageSize, page * pageSize);
    refetch_data(pageSize, page * pageSize);
    setPageNumber(page);
    if (setPageNumberData) {
      setPageNumberData(page);
    }
  };

  const SortableListProps = {
    items: filterCheckboxData,
    onSortEnd: reorder,
    onClickFilterCheckbox: onClickFilterCheckbox,
    BE_filter_code: BE_filter_code,
  };
  // Create a mapping for quick lookup
  const rowDataMap = React.useMemo(
    () => Object.fromEntries(data.map((row) => [row.id, row])),
    []
  );

  return (
    <Box
      className={`tableHeight ${class_Name}`}
      sx={{ display: "flex", width: "100%" }}
    >
      <div className="filterBar">
        {!hideThreeDot && (
          <Button onClick={() => setColumnSortingPopUp(!columnSortingPopUp)}>
            <span className="bars" />
          </Button>
        )}
        {columnSortingPopUp === true ? (
          <div className="showColumns" ref={wrapperRef}>
            <div className="findInput">
              <input
                type="text"
                placeholder="Find column"
                onChange={(e) => {
                  filter_data(e.target.value);
                }}
              />
            </div>

            <div className="rahul">
              <SortableList {...SortableListProps} />
            </div>

            <div className="hideShowComulns">
              <Link
                onClick={() => {
                  hide_and_show_all_columns(0, false);
                }}
              >
                Hide All
              </Link>
              <Link
                onClick={() => {
                  hide_and_show_all_columns(0, true);
                }}
              >
                Show All
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <DataGrid
        className="dataList"
        rows={data}
        getRowHeight={() => "auto"}
        isRowSelectable={(params) => {
          // Check if row selection should be disabled based on provided conditions
          if (
            enableCheckboxDisable && disableCheckboxKey && (params.row[disableCheckboxKey] === disableCheckboxValue || params.row["hr_status"] === 77)
          ) {
            return false; // Disable row selection
          }
          return true; // Enable row selection
        }}
        sortModel={sortModel}
        rowCount={totalRecords}
        onSortModelChange={handleSortModelChange}
        columns={columns ?? header_data}
        pageSize={pageSize}
        page={pageNumber}
        paginationMode={paginationMode ?? "server"}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={
          hideRowsPerPageOption ? [] : [1, 2, 3, 4, 5, 6, 25, 50, 100]
        }
        checkboxSelection={checkboxSelection ?? true}
        onSelectionModelChange={(ids: any) => {
          value_update("selected_data", ids);
        }}
        {...data}
        disableColumnSelector
        disableSelectionOnClick={true}
        getCellClassName={() => "truncate_text"}
      />
    </Box>
  );
};
export default UGTable;
