import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import tickicon from "../../../../../src/images/deletePopup_tickicon.svg";
import deleteimage from "../../../../../src/images/deletepopup_deleteicon.svg";
import {
  physical_product_data,
  physical_product_data_general,
  physical_product_data_life,
  physical_subtype_data,
  physical_type_data,
} from "../../../../Pages/Desktop/Broker/ProductConfig/Insurers/dropDown_data";
import { physicalFormDTO } from "../../../../Services/DTO/ProductConfigurator/AddInsurerDTO";
import { ADD_NEW_INSURER } from "../../../../Services/ProductConfigurator/AddNewInsurer";
import { allowFileExtention, downloadAttachement, uploadImage } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../Supporting files/TextAreaField/TextAreaField";
import "../../../../formLayout.scss";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";

interface Props {
  id?: string;
  data?: any;
  type: string;
}
const PhysicalDetailView = ({ type }: { type?: string }) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const usertypeinfo = useAppSelector((state: RootState) => state.userDetailsSlice.userType);
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id: any = params.get("id");
  const types: any = params.get("type");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [openPhysicalForm, setOpenPhysicalForm] = useState(false);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [emailId, setemailId] = useState("");
  let [physicalFormData, setPhysicalFormData] = useState<Array<any>>([]);
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: physicalFormDTO;
  }>();
  const [editDetailIndex, setEditDetailIndex] = useState<number | null>(null);
  const [physicalForm, setPhysicalForm] = useState<physicalFormDTO>({
    title: "",
    product: "",
    description: "",
    fileName: "",
    type: "",
    subType: "",
    attachment: "",
    warnTitle: false,
    warnProduct: false,
    warnDescription: false,
    warnType: false,
    warnSubType: false,
    warnAttachment: false,
  });
  let [physicalData, setPhysicalData] = useState<any>([]);
  const [openPhysicalFormEdit, setopenPhysicalFormEdit] = useState<boolean>(false);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [EditPermission, setEditPermission] = useState(true);
  // get detail of insurer
  useEffect(() => {
    insurerDetail();
    setEditPermission(BROKER?.ProductConfigurator.edit === undefined ? false : BROKER?.ProductConfigurator.edit);
  }, [openPhysicalForm, openPhysicalFormEdit, deleteStatus]);

  const physicalPopupEdit = (index: number, data: any) => {
    setIndividualEditKey({
      key: "physicalForm." + index,
      value: data,
    });
    setopenPhysicalFormEdit(true);
    setEditDetailIndex(data.id || data._id);
    setPhysicalForm(data);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let data = { ...physicalForm };
    let mainstate = attrName[0];
    let key = attrName[1];
    if (mainstate === "physicalForm") {
      data = {
        ...data,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      };
      // setPhysicalForm({
      //   ...physicalForm,
      //   [key]: value,
      //   [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      // });

      let keydata = "physicalForm.";
      if (openPhysicalFormEdit) {
        let len = physicalData.length - 1
        setIndividualEditKey({
          key: keydata + len,
          value: data,
        });
      } else {
        setIndividualEditKey({
          key: keydata + physicalData.length,
          value: data,
        });
      }
      setPhysicalForm(data);
    } else if (attrName === setEditDetailIndex) {
      attrName(value);
    } else {
      //   attrName(value);
    }
  };

  const physicalFormSave = () => {
    if (disableSave) return;
    let data: any = { ...physicalForm };
    data.warnTitle = physicalForm.title.length === 0 ? true : false;
    data.warnProduct = physicalForm.product.length === 0 ? true : false;
    data.warnDescription = physicalForm.description.length === 0 ? true : false;
    data.warnType = physicalForm.type.length === 0 ? true : false;
    data.warnSubType = physicalForm.subType.length === 0 ? true : false;
    data.warnAttachment = physicalForm.attachment.length === 0 ? true : false;
    setPhysicalForm({ ...data });

    let physicalDataForm: any[] = [...physicalFormData];

    if (
      data.warnTitle === false &&
      data.warnProduct === false &&
      data.warnDescription === false &&
      data.warnType === false &&
      data.warnSubType === false &&
      data.warnAttachment === false
    ) {
      const onSuccess = (res: any) => {
        toast.success(" Update successfully");
        setOpenPhysicalForm(false);
        insurerDetail();
        setPhysicalFormData(physicalDataForm);
        setPhysicalForm({
          title: "",
          product: "",
          description: "",
          type: "",
          subType: "",
          attachment: "",
          warnTitle: false,
          warnProduct: false,
          warnDescription: false,
          warnType: false,
          warnSubType: false,
          warnAttachment: false,
        });
        setDisableSave(false);
      };
      const onError = (err: any) => {
        setDisableSave(false);
        console.log("err", err);
      };
      setDisableSave(true);
      type === "tpa"
        ? ADD_NEW_INSURER.updateTpaIndividualEdit(
          { key: individualEditKey?.key, value: physicalForm },
          onSuccess,
          {},
          onError,
          id
        )
        : ADD_NEW_INSURER.updateInsurerIndividualEdit(
          { key: individualEditKey?.key, value: physicalForm },
          onSuccess,
          {},
          onError,
          id
        )
    }
  };

  const physicalEditpopup = () => {
    let data: any = { ...physicalForm };
    data.warnTitle = physicalForm.title === "" ? true : false;
    data.warnProduct = physicalForm.product === "" ? true : false;
    data.warnDescription = physicalForm.description === "" ? true : false;
    data.warnType = physicalForm.type === "" ? true : false;
    data.warnSubType = physicalForm.subType === "" ? true : false;
    data.warnAttachment = physicalForm.attachment === "" ? true : false;
    setPhysicalForm({ ...data });

    if (
      data.warnTitle === false &&
      data.warnProduct === false &&
      data.warnDescription === false &&
      data.warnType === false &&
      data.warnSubType === false &&
      data.warnAttachment === false
    ) {
      if (physicalData === undefined) {
        physicalData = [];
      } else {
        if (editDetailIndex === null) {
          // dataArray.push(-1);
        } else {
          let foundIndex = physicalData.findIndex((e: any) => {
            return e.id === editDetailIndex;
          });
          const onSuccess = (res: any) => {
            toast.success("Update successfully");
            setopenPhysicalFormEdit(false);
          };
          const onError = (err: any) => {
            console.log("err", err);
          };
          type === "tpa"
            ? ADD_NEW_INSURER.updateTpaIndividualEdit(
              {
                key: `physicalForm.${foundIndex}`,
                value: physicalForm,
              },
              onSuccess,
              {},
              onError,
              id
            )
            : ADD_NEW_INSURER.updateInsurerIndividualEdit(
              { key: individualEditKey?.key, value: physicalForm },
              onSuccess,
              {},
              onError,
              id
            );
        }
      }
    }
  };

  const delete_item = () => {
    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    type === "tpa"
      ? ADD_NEW_INSURER.delete_tpa_item(
        id ? id : "",
        onSuccess,
        {},
        onError,
        `&item=physicalForm&itemId=${emailId}`
      )
      : ADD_NEW_INSURER.delete_insurer_item(
        id ? id : "",
        onSuccess,
        {},
        onError,
        `&item=physicalForm&itemId=${emailId}`
      );
  };
  // get insurer detail
  const insurerDetail = () => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data[0].physicalForm.forEach((e: any) => {
        data.push({ ...e, id: e._id });
      });
      setPhysicalData(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    type === "tpa"
      ? ADD_NEW_INSURER.getTpaDetail(onSuccess, onError, id, usertypeinfo.toLocaleLowerCase())
      : ADD_NEW_INSURER.getInsuranceDetail(onSuccess, onError, id);
  };


  return (
    <Box className="detailSection mb-10">
      <div className="sectionTitle">
        <h4>Physical Forms</h4>
        <div className="actionBtns">
          {/* <Link
            className="blueBtn view"
            sx={{ textDecoration: "none", cursor: "pointer" }}
          >
            View
          </Link> */}
          <Link
            className="blueBtn addfile"
            onClick={() => {
              if (!EditPermission) return toast.error("Edit permissions not allowed");
              setOpenPhysicalForm(true);
            }}
            sx={{ textDecoration: "none", cursor: "pointer" }}
          >
            Add Form
          </Link>
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="table">
            <div className="attatchment_table" style={{ overflow: "hidden" }}>
              <ul className="heading">
                <li>Tittle</li>
                <li>Description</li>
                <li>Product</li>
                <li>Type</li>
                <li>Sub-Type</li>
                <li>Actions</li>
              </ul>

              {Array.isArray(physicalData) &&
                physicalData.map((data: any, index: any) => {
                  let subType = data.subType || data.sub_type
                  return(
                    <ul key={data.id} className="subheading">
                    <li>{data.title}</li>
                    <li>{data.description}</li>
                    <li>{data.product}</li>
                    <li>{data.type}</li>
                    <li>{subType.toLowerCase() === "claim intimation" ? "Claim Reimbursement" :subType  }</li>
                    <li style={{
                      display: "flex",
                      justifyContent: "flex-start",
                     
                      
                    }}
                    >
                      <Link
                        className="blueBtn edit"
                        onClick={() => {
                          if (!EditPermission) return toast.error("Edit permissions not allowed");
                          physicalPopupEdit(index, data);
                        }}
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                          marginLeft:"0 !important"
                        }}
                      ></Link>

                      <Link
                        className="redBtn delete ml-4"
                        onClick={() => {
                          if (!EditPermission) return toast.error("Edit permissions not allowed");
                          setemailId(data._id);
                          setDeleteStatus(true);
                        }}
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      ></Link>
                      <Link
                        className="blueBtn download ml-4"
                        // href={data.downloadLink}
                        onClick={()=>downloadAttachement(data.downloadLink,usertypeinfo)}
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      ></Link>
                    </li>
                  </ul>
                  )
               
})}
            </div>
          </div>
        </Grid>
      </Grid>

      {/* <Grid container>
        <Grid xs={12} className="mt-5">
          <UGTable
            header_data={physicalFormColumn}
            data={physicalData}
            value_update={updateMasterState}
            attrName={"UGTable"}
          />
        </Grid>
      </Grid>  */}

      <SlidingPanel
        type={"right"}
        isOpen={openPhysicalForm}
        size={75}
      // backdropClicked={() => {
      //   setOpenPhysicalForm(false);
      // }}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-4 ml-0">Add New Form</h4>
            </Grid>
            <Grid
              xs={2}
              className="pt-7"
              textAlign={"right"}
              onClick={() => {
                setOpenPhysicalForm(false);
                setPhysicalForm({
                  title: "",
                  product: "",
                  description: "",
                  type: "",
                  subType: "",
                  attachment: "",
                  warnTitle: false,
                  warnProduct: false,
                  warnDescription: false,
                  warnType: false,
                  warnSubType: false,
                  warnAttachment: false,
                });
              }}
            >
              <GridCloseIcon />
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {/* <div className="locations_section"> */}
            <Grid container spacing={3}>
              <Grid xs={7}>
                <RKTextField
                  class_name="inputField"
                  title="Title"
                  value={physicalForm.title}
                  attrName={["physicalForm", "title"]}
                  value_update={updateMasterState}
                  warn_status={physicalForm.warnTitle}
                />
              </Grid>
              <Grid xs={5}>
                <SelectDropdown
                  class_name="inputField"
                  title="Product"
                  value={physicalForm.product}
                  attrName={["physicalForm", "product"]}
                  value_update={updateMasterState}
                  // dropdown_data={filteredProduct}
                  dropdown_data={types === "LIFE" ? physical_product_data_life : types === "GENERAL" ? physical_product_data_general : physical_product_data}
                  warn_status={physicalForm.warnProduct}
                />
              </Grid>
              <Grid xs={12} className="mb-0">
                <RKTextField
                  class_name="inputField"
                  title="Description"
                  value={physicalForm.description}
                  attrName={["physicalForm", "description"]}
                  value_update={updateMasterState}
                  warn_status={physicalForm.warnDescription}
                  error_messg="Enter Description"
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Type"
                  value={physicalForm.type}
                  attrName={["physicalForm", "type"]}
                  value_update={updateMasterState}
                  dropdown_data={physical_type_data}
                  warn_status={physicalForm.warnType}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Sub Type"
                  value={physicalForm.subType || physicalForm.sub_type}
                  attrName={["physicalForm", "subType"]}
                  value_update={updateMasterState}
                  dropdown_data={physical_subtype_data}
                  warn_status={physicalForm.warnSubType}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              justifyContent="center"
              className="mt-4 mb-4"
            >
              <Grid xs={10} className="inputField uploadFile">
                <TextAreaField
                  class_name="inputField"
                  title="Add Attatchment"
                  value={physicalForm.fileName}
                  attrName={["physicalForm", "attachment"]}
                  value_update={updateMasterState}
                  warn_status={physicalForm.warnAttachment}
                  error_messg="Add Attatchment"
                  disable
                />
              </Grid>
              <Grid xs={2}>
                <Button
                  variant="contained"
                  className="browsebtn"
                  component="label"
                >
                  Browse
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={(e: any) => {
                      const file = e.target.files[0];
                      const allowExt = allowFileExtention(file)
                      if(!allowExt){
                        toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                        return (e.target.value = null);
                      }
                      uploadImage(file, (base64Image: any) => {
                        setPhysicalForm((physicalForm: any) => ({
                          ...physicalForm,
                          attachment: base64Image,
                          fileName: file.name,
                        }));
                      });
                    }}
                  />
                </Button>
              </Grid>
              <Grid xs={12}>
                <p className="fileUploadTypeDetails">
                  Files may be upto 50 MB, and .xls, .xlsx, .csv or .vcf formats
                </p>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={physicalFormSave}
                disabled={disableSave}
              >
                Add Form
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>

      <SlidingPanel
        type={"right"}
        isOpen={openPhysicalFormEdit}
        size={75}
      // backdropClicked={() => {
      //   setOpenPhysicalForm(false);
      // }}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-4 ml-0">Edit Form</h4>
            </Grid>
            <Grid
              xs={2}
              className="pt-7"
              textAlign={"right"}
              onClick={() => {
                setopenPhysicalFormEdit(false);
                setPhysicalForm({
                  title: "",
                  product: "",
                  description: "",
                  type: "",
                  subType: "",
                  attachment: "",
                  warnTitle: false,
                  warnProduct: false,
                  warnDescription: false,
                  warnType: false,
                  warnSubType: false,
                  warnAttachment: false,
                });
              }}
            >
              <GridCloseIcon />
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            {/* <div className="locations_section"> */}
            <Grid container spacing={3}>
              <Grid xs={7}>
                <RKTextField
                  class_name="inputField"
                  title="Title"
                  value={physicalForm.title}
                  attrName={["physicalForm", "title"]}
                  value_update={updateMasterState}
                  warn_status={physicalForm.warnTitle}
                />
              </Grid>
              <Grid xs={5}>
                <SelectDropdown
                  class_name="inputField"
                  title="Product"
                  value={physicalForm.product}
                  attrName={["physicalForm", "product"]}
                  value_update={updateMasterState}
                  dropdown_data={types === "LIFE" ? physical_product_data_life : types === "GENERAL" ? physical_product_data_general : physical_product_data}
                  warn_status={physicalForm.warnProduct}
                />
              </Grid>
              <Grid xs={12} className="mb-0">
                <RKTextField
                  class_name="inputField"
                  title="Description"
                  value={physicalForm.description}
                  attrName={["physicalForm", "description"]}
                  value_update={updateMasterState}
                  warn_status={physicalForm.warnDescription}
                  error_messg="Enter Description"
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Type"
                  value={physicalForm.type}
                  attrName={["physicalForm", "type"]}
                  value_update={updateMasterState}
                  dropdown_data={physical_type_data}
                  warn_status={physicalForm.warnType}
                />
              </Grid>
              <Grid xs={6}>
                <SelectDropdown
                  class_name="inputField"
                  title="Sub Type"
                  value={physicalForm.sub_type || physicalForm.subType}
                  attrName={["physicalForm", "subType"]}
                  value_update={updateMasterState}
                  dropdown_data={physical_subtype_data}
                  warn_status={physicalForm.warnSubType}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              justifyContent="center"
              className="mt-4 mb-4"
            >
              <Grid xs={10} className="inputField uploadFile">
                <TextAreaField
                  class_name="inputField textArea"
                  title="Add Attatchmentt"
                  value={physicalForm.fileName}
                  attrName={["physicalForm", "attachment"]}
                  value_update={updateMasterState}
                  warn_status={physicalForm.warnAttachment}
                  error_messg="Add Attatchment"
                  disable
                />
              </Grid>
              <Grid xs={2}>
                <Button
                  variant="contained"
                  className="browsebtn"
                  component="label"
                >
                  Browse
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={(e: any) => {
                      const file = e.target.files[0];
                      const allowExt = allowFileExtention(file)
                      if(!allowExt){
                        toast.warn("Invalid file type. Please upload a PDF, CSV, Excel, DOC, or Image file.");
                        return (e.target.value = null);
                      }
                      uploadImage(file, (base64Image: any) => {
                        setPhysicalForm((physicalForm) => ({
                          ...physicalForm,
                          attachment: base64Image,
                          fileName: file.name,
                        }));
                      });
                    }}
                  />
                </Button>
              </Grid>
              <Grid xs={12}>
                <p className="fileUploadTypeDetails">
                  Files may be upto 50 MB, and .xls, .xlsx, .csv or .vcf formats
                </p>
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn mt-4">
              <Button
                variant="contained"
                className="submitBtn fixedbtn"
                onClick={physicalEditpopup}
              >
                Update Form
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
      <Modal open={deleteStatus} className="modalWrapper">
        <>
          {deleteStatusStep === 1 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setDeleteStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={deleteimage} alt="" className="img" />
                  </div>

                  <h5 className="popup-heading">
                    Are you sure you want
                    <br /> to delete this Physical form?
                  </h5>
                </Grid>

                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={delete_item}
                  >
                    Yes, Delete
                  </Button>
                  <Button
                    onClick={() => {
                      setDeleteStatus(false);
                    }}
                    variant="contained"
                    className="cancelBtn"
                  >
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : deleteStatusStep === 2 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setDeleteStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={tickicon} alt="" className="img" />
                  </div>
                  <h5 className="popup-heading">Deleted Successfully!</h5>
                </Grid>
                <Grid xs={12}></Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    onClick={() => {
                      setDeleteStatus(false);
                      setDeleteStatusStep(1);
                    }}
                    variant="contained"
                    className="submitBtn"
                  >
                    Okay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      </Modal>
    </Box>
  );
};

export default PhysicalDetailView;
