import React, { useState, useRef } from "react";
import { Alert, Box, Link, Tooltip } from "@mui/material";
import CurrencyTextField from "../Currency/CurrencyTextField";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Patch } from "../CallAPI";
import { useFormatCurrency } from "../CustomHooks";
import { extractNumbersFromString } from "../HelpingFunction";
interface Props {
  title: string;
  value: any;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name: string;
  error_messg?: string;
  validation_type?: string;
  required?: boolean;
  charcterlimit?: number;
  alphanumeric?: boolean;
  specialcharacter?: boolean;
  params?: any;
  id?: string;
  disabled?: boolean;
}

const CurrencyTextFieldDisable: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  required,
  charcterlimit,
  alphanumeric,
  specialcharacter,
  id,
  params,
  disabled,
}) => {
  const [disbaleToggle, setDisableToggle] = useState<boolean>(false);
  const updateMasterState = (attrName: any, value: any) => {
    value_update(attrName, value);
  };
  const { formatCurrency } = useFormatCurrency();
  const [focus, setFocus] = useState<boolean>(false);
  const refinput = useRef(null);
  // individual edit
  const edit = () => {
    setDisableToggle(!disbaleToggle);
    setFocus(true);
    if (disbaleToggle === false) {
      // const input = document.getElementById(value);
      // console.log(input);
      // input?.focus();
    }
    if (disbaleToggle === true) {
      // individual_edit();
      params(attrName)
    }
  };
  const individual_edit = () => {
    let edit_layout = { layout: params };
    if (value.length > 0) {
      Patch(`${URL_CONSTANTS.edit_contact}${id}`, edit_layout).then(
        (data: any) => {
          let status = data[0];
          let response = data[1];
          if (status === "success") {
            alert("Data update successfully");
          }
          if (status.response.data.status === "failed") {
            let param = status.response.data.errors[0].param;
            let error = status.response.data.errors[0].message;
            alert(param + " " + " " + error);
          }
        }
      );
    } else {
      alert("Please enter the value");
    }
  };
  return (
    <Box className="individualEdit">
      {disbaleToggle === false ? (
        <div
          className={
            value?.length === 0 ? "staticField nullValue" : "staticField"
          }>
          <label>{title}</label>
          <p>{formatCurrency(Number(extractNumbersFromString(value || "0")))}</p>
        </div>
      ) : (
        <CurrencyTextField
          class_name={class_name}
          title={title}
          value_update={updateMasterState}
          value={value}
          attrName={attrName}
          warn_status={warn_status}
          required={required}
          error_messg={error_messg}
          info_status={info_status}
          info_message={info_message}
          charcterlimit={charcterlimit}
          disable={disabled}
        />
      )}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}

      {
        disabled === false && (
          <Link
            className={disbaleToggle === true ? "editUpdate visible" : "editUpdate"}
            onClick={edit}>
            {disbaleToggle === false ? <EditIcon /> : <CheckIcon />}
          </Link>
        )
      }


    </Box>
  );
};

export default CurrencyTextFieldDisable;
