import {
  Box,
  Button,
  Link,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SlidingPanel from "react-sliding-side-panel";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import { ADD_NEW_INSURER } from "../../../../../Services/ProductConfigurator/AddNewInsurer";
import { RootState } from "../../../../../Store/Store";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import "../ProductConfig.scss";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";

function Tpa({
  shortcutDisable,
  setShortcutDisable,
}: {
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}) {
  const dispatch = useAppDispatch();
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [tpaType, setTpaType] = useState("Integrated");
  const [hideDataStatus, setHideDataStatus] = useState(false);
  const [openTpa, setopenTpa] = useState(false);
  //Features Variables
  const [addNewFeatures, setAddNewFeatures] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [isGeneralInsuranceAssigned, setIsGeneralInsuranceAssigned] =
    useState(false);
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [insurerList, setInsurerList] = useState([]);
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [AddPermission, setAddPermission] = useState(false);
  const [EditPermission, setEditPermission] = useState(false);
  const [RemovePermission, setRemovePermission] = useState(false);
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [integratedTpaValue, setintegratedTpaValue] = useState({
    id: "",
    value: "",
    warning: false,
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setAddPermission(
      BROKER?.ProductConfigurator.add === undefined
        ? false
        : BROKER?.ProductConfigurator.add
    );
    setEditPermission(
      BROKER?.ProductConfigurator.edit === undefined
        ? false
        : BROKER?.ProductConfigurator.edit
    );
    setRemovePermission(
      BROKER?.ProductConfigurator.remove === undefined
        ? false
        : BROKER?.ProductConfigurator.remove
    );
  }, []);
  //Category Data

  const [generalColumns, setGeneralColumns] = useState([
    {
      field: "logo",
      headerName: "Logo",
      minWidth: 250,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <img
            src={row.logo}
            style={{
              maxHeight: "60px",
              background: "#FFFFFF",
              maxWidth: "250px",
              borderRadius: "5px",
              paddingLeft: "5px",
              paddingRight: "5px",
              objectFit: "cover",
            }}
            alt="logo"
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 600,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <button
            className="truncate_text"
            style={{
              cursor: "pointer",
              fontWeight: "400",
              color: "#2160aa",
              background: "transparent",
              textDecoration: "none",
            }}
            onClick={() => {
              navigate(`${ALL_ROUTES.TPA_DETAILS}?id=${row.id}`);
            }}
          >
            {row.name}
          </button>
        );
      },
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      width: 200,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return <>{row.contactNumber}</>;
      },
    },
    {
      field: "website",
      headerName: "Website Url",
      flex: 3,
      minWidth: 300,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link href={row.website_url} target="_blank" underline="none">
            {row.website}
          </Link>
        );
      },
    },
    {
      field: "createdAt",
      minWidth: 200,
      check_status: true,
      headerName: "Created On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.createdAt, user_type_dateFormat, tz)
      },
    },
    {
      field: "updatedAt",
      minWidth: 200,
      check_status: true,
      headerName: "Updated On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.updatedAt, user_type_dateFormat, tz)
      },
    }
  ]);
  let [selected_data, setData] = useState([]);
  let [selected_value, setValue] = useState("");

  // get list data of insurer
  useEffect(() => {
    // get_tpa();
    setShowLoader(true);
    if (openTpa) get_dropdown_data();
  }, [deleteStatus, openTpa]);

  // useEffect(() => {
  //   setShowLoader(true)
  // },[])
  const get_dropdown_data = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const response = res.data.data;
      setData(response);
    };
    const onError = (err: any) => {
      console.log(err);
    };
    ADD_NEW_INSURER.getIntegratedDropdownTpa(onSuccess, onError);
  };

  // get insure detail
  const get_tpa = () => {
    setShowLoader(true);
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const response: any = res.data.data;
      const dataArr: any = [];
      response.forEach((e: any) => {
        dataArr.push({
          id: e._id,
          logo: e.logo,
          name: e.name,
          email: e.email,
          contactNumber: e.contactNumber,
          website: e.website,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
        });
      });
      setInsurerList(dataArr);
      setShowLoader(false);
    };
    const onError = (err: any) => {
      console.log(err);
      setShowLoader(false);
    };
    ADD_NEW_INSURER.getTpa(onSuccess, onError, searchBarFilter);
  };

  const add_to_list = (integratedTpaValue: string) => {
    const onSuccess = (res: any) => { };
    const onError = (err: any) => {
      console.log(err);
    };
    ADD_NEW_INSURER.addToList(onSuccess, onError, integratedTpaValue);
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      selected_data = value;
      setValue(value);
      setintegratedTpaValue({ ...value, warning: false });
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else {
      attrName(value);
    }
    setArray_Delete(value);

    if (attrName === setAddNewFeatures) {
      setHideDataStatus(value);
    }
  };

  const delete_contact_multiple = () => {
    const onSuccess = (res: any) => {
      setDeleteStatus(false);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ADD_NEW_INSURER.deleteTpa({ ids: array_delete }, onSuccess, {}, onError);
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_tpa();
    };

    timeoutId = setTimeout(delayedApiCall, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [searchBarFilter, deleteStatus, openTpa]);

  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h3 className="mb-0">TPA</h3>
          </Grid>
          <>
            <Grid xs={7} style={{ display: "flex", alignItems: "center" }}>
              <SearchBox value_update={updateMasterState} />
            </Grid>
            <Grid xs={5} className="text-right">
              <>
                {RemovePermission && (
                  <Link
                    className={
                      isGeneralInsuranceAssigned
                        ? `redBtnlight delete`
                        : `redBtn delete`
                    }
                    underline="none"
                    onClick={() => {
                      isGeneralInsuranceAssigned
                        ? setwarningpopup(true)
                        : setDeleteStatus(true);
                    }}
                  >
                    Remove
                  </Link>
                )}
                {AddPermission && (
                  <Link
                    className="greenBtn addfile"
                    underline="none"
                    onClick={() => {
                      setopenTpa(true);
                      if (!shortcutDisable && setShortcutDisable) {
                        setShortcutDisable(true);
                      }
                    }}
                  >
                    Add New
                  </Link>
                )}
                {array_delete.length > 0 && EditPermission && (
                  <Link
                    className="blueBtn edit"
                    underline="none"
                    onClick={() => {
                      navigate(`${ALL_ROUTES.EDIT_TPA}?id=${array_delete[0]}`);
                    }}
                  >
                    Edit
                  </Link>
                )}
              </>
            </Grid>
            <Grid xs={12}>
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={generalColumns}
                  data={insurerList}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                  BE_filter_code={E_BE_FILTER_CODE.LIFE_INSURANCE}
                  refetch_data={get_tpa}
                  setTableLimit={setTableLimit}
                  setTableSkip={setTableSkip}
                  paginationMode="client"
                />
              </div>
            </Grid>
          </>

          <Modal open={deleteStatus} className="modalWrapper">
            {array_delete.length === 0 ? (
              <Box className="modalInner">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <h5 className="popup-heading">No Insurer Selected</h5>
                    <p className="text-center">
                      Please select Insurer for delete
                    </p>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box className="modalInner">
                <Grid container spacing={1}>
                  <Grid xs={12}>
                    <Link
                      className="close-button"
                      onClick={() => setDeleteStatus(false)}
                    />
                    <h5 className="popup-heading">Are you sure ?</h5>
                  </Grid>
                  <Grid xs={12}>
                    <Link className="md-link" underline="none">
                      You want to delete this {array_delete.length} Tpa.
                    </Link>
                  </Grid>
                  <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                    <Button
                      onClick={delete_contact_multiple}
                      variant="contained"
                      className="submitBtn"
                    >
                      Yes, Delete
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Modal>

          <SlidingPanel type={"right"} isOpen={openTpa} size={80}>
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={10}>
                  <h4 className="mb-0 mt-4 ml-0">TPA Details</h4>
                </Grid>
                <Grid xs={2} textAlign="end" className="mt-3">
                  <GridCloseIcon
                    onClick={() => {
                      setintegratedTpaValue({
                        ...integratedTpaValue,
                        warning: false,
                      });
                      setopenTpa(false);
                      if (setShortcutDisable) {
                        setShortcutDisable(false);
                      }
                    }}
                  />
                </Grid>
                <Grid xs={12} className="pt-0">
                  <hr />
                </Grid>
              </Grid>
              <div className="scrollable_area">
                <Grid container spacing={3} className="">
                  <Grid xs={12} textAlign={"center"}>
                    <h4 className="mb-0">Choose Your TPA</h4>
                  </Grid>
                  <Grid xs={12} className="text-center">
                    <ToggleButtonGroup
                      className="loginTypeToggle"
                      value={tpaType}
                      exclusive
                      onChange={(e, value) => {
                        setTpaType(value);
                      }}
                    >
                      <ToggleButton
                        className="toggle_bottons"
                        value="Integrated"
                      >
                        Integrated TPA
                      </ToggleButton>
                      <ToggleButton className="toggle_bottons" value="Custom">
                        Custom TPA
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  {tpaType === "Integrated" ? (
                    <Grid xs={12}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Choose your TPA"
                        value={selected_value}
                        attrName={"selected_data"}
                        value_update={updateMasterState}
                        data={selected_data}
                        warn_status={integratedTpaValue.warning}
                        error_message="Select TPA"
                      />
                    </Grid>
                  ) : (
                    <Grid xs={12} textAlign={"center"}>
                      <p>
                        Proceed to add new TPA form to fill out the details.
                      </p>
                    </Grid>
                  )}
                </Grid>
              </div>
              <Grid container spacing={3}>
                <Grid xs={12} className="ctaBtn mt-4">
                  <Button
                    variant="contained"
                    className="submitBtn fixedbtn"
                    onClick={() => {
                      if (tpaType === "Custom") {
                        navigate(ALL_ROUTES.ADD_NEW_TPA);
                        if (setShortcutDisable) {
                          setShortcutDisable(false);
                        }
                      } else {
                        add_to_list(integratedTpaValue?.id);
                        setValue("");
                        setintegratedTpaValue({
                          ...integratedTpaValue,
                          value: "",
                          warning: false,
                        });
                        setopenTpa(false);
                      }
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </SlidingPanel>
        </Grid>
      )}
    </>
  );
}

export default Tpa;
