import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import "./LogsTable.scss";

function LogsTable() {
  const [sectionStatus, SetSectionStatus] = React.useState<string>("logsTab");

  // for scrolling
  const logsTab = useRef(null);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [logsData, setLogsData] = React.useState([
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
    {
      log_creation_time: "11:00PM",
      log_creation_date: "23 Nov, 2022",
      log_activty: "Change SPOC Detail",
      field: "Email Id",
      log_desc: "SP01-Rahul email id changes to rahul@evervent.in",
      type: "Modification",
    },
  ]);
  return (
    <Grid container spacing={3}>
      <Grid
        xs
        style={{
          maxWidth: "260px",
          position: "relative",
          top: "-75px",
          paddingLeft: "0",
        }}
      >
        <div
          className="leftSection h-100"
          style={{ position: "fixed", paddingLeft: "8px" }}
        >
          <Link
            className={
              sectionStatus === "logsTab"
                ? "active sectionLink logsTab"
                : "sectionLink logsTab"
            }
            onClick={() => {
              scrollToSection(logsTab);
              SetSectionStatus("logsTab");
            }}
          >
            <span>Logs</span>
          </Link>
        </div>
      </Grid>
      <Grid xs paddingLeft={"0"}>
        <Box ref={logsTab}>
          <Grid xs={12} className="px-0 py-0">
            <div className="tableview">
              <div className="tableinner">
                <ul className="tableHeader">
                  <li>
                    Creation Time
                    <button className="sorting-btn"></button>
                  </li>
                  <li>
                    Created on
                    <button className="sorting-btn"></button>
                  </li>
                  <li>
                    Activity
                    <button className="sorting-btn"></button>
                  </li>
                  <li>
                    Field
                    <button className="sorting-btn mr-0"></button>
                  </li>
                  <li>
                    Description
                    <button className="sorting-btn mr-0"></button>
                  </li>
                  <li>
                    Type
                    <button className="sorting-btn mr-0"></button>
                  </li>
                </ul>
                {logsData.map((data, index) => (
                  <ul className="tableData">
                    <li>{data.log_creation_time}</li>
                    <li>{data.log_creation_date}</li>
                    <li>{data.log_activty}</li>
                    <li>{data.field}</li>
                    <li>{data.log_desc}</li>
                    <li>{data.type}</li>
                  </ul>
                ))}
              </div>
            </div>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
export default LogsTable;
