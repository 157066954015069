import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Link,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { capitalize } from "../HelpingFunction";
import "./Filter.scss";
import { toast } from "react-toastify";
import { useFormatCurrency } from "../CustomHooks";
import { useAppSelector } from "../../Store/hooks";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// Define the type for available filters
type FilterType = string; // Allow any string type for dynamic filters

// Helper function to load from sessionStorage
const loadFromSessionStorage = () => {
  const savedFilters = sessionStorage.getItem("filters");
  if (savedFilters) {
    return JSON.parse(savedFilters);
  }
  return null;
};

// Helper function to save filters to sessionStorage
const saveToSessionStorage = (
  filters: any,
  selectedOptions: any,
  dropdownData: any
) => {
  sessionStorage.setItem(
    "filters",
    JSON.stringify({ filters, selectedOptions, dropdownData })
  );
};

// Helper function to clear filters from sessionStorage
const clearSessionStorage = () => {
  sessionStorage.removeItem("filters");
};

// Helper function to check if a filter is a date-based filter
const isDateFilter = (filterName: string) => {
  const dateFields = [
    "date of birth",
    "Created On",
    "last day of working",
    "date of joining",
  ];
  return dateFields.includes(filterName.toLowerCase());
};

const FilterComponent = ({
  allFilterNameApiService,
  apiFiltervaluesByName,
  clientId,
  sendData,
}: {
  allFilterNameApiService: any;
  apiFiltervaluesByName: any;
  clientId: string;
  sendData: Function;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const { userType } = useAppSelector((state) => state.userDetailsSlice);
  const [open, setOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [availableFilters, setAvailableFilters] = useState<
    { id: number; name: FilterType }[]
  >([]);
  const [filters, setFilters] = useState(availableFilters);
  const [selectedFilters, setSelectedFilters] = useState<
    { id: number; name: FilterType }[]
  >([]);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key in FilterType]?: string[] | { startDate: string; endDate: string };
  }>({});
  const [dropdownData, setDropdownData] = useState<{
    [key in FilterType]?: string[];
  }>({
    Gender: ["Male", "Female", "Others"],
    Status: ["Employed", "Probation"],
  });

  // API call to get all filter names
  const allFilterName = () => {
    const onSuccess = (res: any) => {
      let data = res.data.data.map((item: string, index: number) => ({
        id: index + 1,
        name: capitalize(item),
      }));
      setAvailableFilters(data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    allFilterNameApiService &&
      allFilterNameApiService(
        onSuccess,
        onError,
        userType?.toLowerCase(),
        clientId
      );
  };

  // Load filters from sessionStorage when the component mounts
  useEffect(() => {
    allFilterName();
    const savedData = loadFromSessionStorage();

    const selectedOptionsLength =
      savedData && savedData.selectedOptions
        ? Object.keys(savedData.selectedOptions).length
        : 0;
    if (selectedOptionsLength > 0) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    setSelectedFilters(savedData?.filters || []);
    setSelectedOptions(savedData?.selectedOptions || {});
    setDropdownData(
      savedData?.dropdownData || {
        Gender: ["Male", "Female", "Others"],
        Status: ["Employed", "Probation"],
      }
    );

    sendData(null, null, savedData?.selectedOptions);
  }, []);

  // Update available filters when selected filters change
  useEffect(() => {
    const selectedFilterIds = selectedFilters.map(
      (filter: { id: number }) => filter.id
    );
    setFilters(
      availableFilters.filter(
        (filter) => !selectedFilterIds.includes(filter.id)
      )
    );
  }, [availableFilters, selectedFilters]);

  const applyFilter = () => {
    const hasValues = Object.keys(selectedOptions);
    if (!(selectedFilters.length == hasValues.length)) {
      return toast.error("Please provide filter values");
    }
    sendData(null, null, selectedOptions);
    setOpen(false);
  };
  // API call to get values for a selected filter and store in the dropdownData state
  const fetchFilterValues = (filterName: string) => {
    if (dropdownData[filterName]) return; // Skip API call if data already exists
    const onSuccess = (res: any) => {
      let fetchedValues = res.data.data; // Assume response format is an array of options
      if (filterName === "Salary bracket") {
        // Format fetched values
        const formattedValues = fetchedValues.map((element: any) => {
          const amounts = element.split("-");

          // Safely handle the splitting
          const startAmount = amounts[0] ? parseFloat(amounts[0]) : 0;
          const endAmount = amounts[1] ? parseFloat(amounts[1]) : 0;

          const formattedStart = formatCurrency(startAmount.toString());
          const formattedEnd = formatCurrency(endAmount.toString());

          return `${formattedStart} - ${formattedEnd}`;
        });

        // Handle the last range
        const lastRange = fetchedValues[fetchedValues.length - 1];
        const lastMaxValue = parseFloat(lastRange.split("-")[1]) || 0;

        // Create a new range starting from lastMaxValue + 1 and append "Above"

        const newRange = `${formatCurrency(lastMaxValue.toString())} - Above`;
        formattedValues.push(newRange);
        fetchedValues = formattedValues;
      }

      setDropdownData((prevData) => ({
        ...prevData,
        [filterName]: fetchedValues,
      }));
    };

    const onError = (err: any) => {
      console.log("Error fetching filter values:", err);
    };

    apiFiltervaluesByName &&
      apiFiltervaluesByName(
        onSuccess,
        onError,
        userType?.toLowerCase(),
        filterName,
        clientId
      );
  };

  // Handle adding a filter from dropdown
  const handleAddFilter = (filterId: number) => {
    const selectedFilter = filters.find((filter) => filter.id === filterId);
    if (selectedFilter) {
      setSelectedFilters([...selectedFilters, selectedFilter]);
      fetchFilterValues(selectedFilter.name); // Fetch values for the added filter
    }
  };

  // Handle selecting options for each filter
  const handleSelectOption = (
    filterName: FilterType,
    values: string[] | { startDate: string; endDate: string }
  ) => {
    setSelectedOptions({
      ...selectedOptions,
      [filterName]: values,
    });
  };

  // Handle deleting a filter
  const handleDeleteFilter = (filterId: number, filterName: FilterType) => {
    setSelectedFilters(
      selectedFilters.filter((filter) => filter.id !== filterId)
    );
    const updatedSelectedOptions = { ...selectedOptions };
    delete updatedSelectedOptions[filterName];
    setSelectedOptions(updatedSelectedOptions);
  };

  // Handle saving filters to sessionStorage
  const handleSaveFilters = () => {
    const hasValues = Object.keys(selectedOptions);
    if (hasValues.length != selectedFilters.length) {
      return toast.error("Please provide filter values");
    }
    setIsFilter(true);
    sendData(null, null, selectedOptions);
    setOpen(false);
    saveToSessionStorage(selectedFilters, selectedOptions, dropdownData);
  };

  // Handle clearing all filters and removing from sessionStorage
  const handleClearFilters = () => {
    setSelectedFilters([]);
    setSelectedOptions({});
    clearSessionStorage();
    sendData(null, null, "", "clear");
    setOpen(false);
    setIsFilter(false);
  };

  const handleRemoveOption = (
    filterName: FilterType,
    valueToRemove: string
  ) => {
    const updatedOptions = (selectedOptions[filterName] as string[]).filter(
      (value) => value !== valueToRemove
    );

    setSelectedOptions({
      ...selectedOptions,
      [filterName]: updatedOptions,
    });

    if (updatedOptions.length === 0) {
      handleDeleteFilter(
        selectedFilters.find((filter) => filter.name === filterName)?.id || 0,
        filterName
      );
    }
  };

  return (
    <>
      <Box className="filter_button pr-3" onClick={() => setOpen(true)}>
        <img
          src={`images/filter_${isFilter ? "fill" : "border"}.svg`}
          alt="fil"
        />
        <Link> Filters </Link>
      </Box>

      <Modal open={open} className="modalWrapper modal_filter">
        <Box className="modalInner xl-width filterModal">
          <Grid container padding={0}>
            <Grid xs={12} className="mb-3">
              <Link className="close-button" onClick={() => setOpen(false)} />
              <h5 className="popup-heading">Filters</h5>
            </Grid>
            <Grid xs={12} className="mb-3">
              <div className="inputField">
                <FormControl>
                  <Select
                    onChange={(e) => handleAddFilter(Number(e.target.value))}
                    value=""
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Add Filter
                    </MenuItem>
                    {filters.map((filter) => (
                      <MenuItem key={filter.id} value={filter.id}>
                        {filter.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid xs={12}>
              {selectedFilters?.map((filter) => (
                <Box className="mt-3">
                  <p className="label mb-1">{filter.name}</p>
                  <div className="inputField form_fields">
                    {isDateFilter(filter.name) ? (
                      <div className="w-100">
                        <Grid container padding={0} columnSpacing={3}>
                          <Grid xs={6}>
                            <TextField
                              label="Start Date"
                              type="date"
                              value={
                                (selectedOptions[filter.name] as any)?.startDate
                                  ? new Date(
                                      (
                                        selectedOptions[filter.name] as any
                                      ).startDate
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                              onChange={(e: any) =>
                                handleSelectOption(filter.name, {
                                  ...((selectedOptions[filter.name] as {
                                    startDate: string;
                                    endDate: string;
                                  }) || {
                                    startDate: "",
                                    endDate: "",
                                  }),
                                  startDate: new Date(
                                    e.target.value
                                  ).toISOString(),
                                })
                              }
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid xs={6}>
                            <TextField
                              label="End Date"
                              type="date"
                              value={
                                (selectedOptions[filter.name] as any)?.endDate
                                  ? new Date(
                                      (
                                        selectedOptions[filter.name] as any
                                      ).endDate
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  : ""
                              }
                              onChange={(e: any) =>
                                handleSelectOption(filter.name, {
                                  ...((selectedOptions[filter.name] as {
                                    startDate: string;
                                    endDate: string;
                                  }) || {
                                    startDate: "",
                                    endDate: "",
                                  }), // Ensure both startDate and endDate are present
                                  endDate: new Date(
                                    e.target.value
                                  ).toISOString(),
                                })
                              }
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: (selectedOptions[filter.name] as any)
                                  ?.startDate
                                  ? new Date(
                                      (
                                        selectedOptions[filter.name] as any
                                      ).startDate
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  : null,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <div className="field_row">
                        <FormControl fullWidth>
                          <Select
                            multiple
                            value={
                              Array.isArray(selectedOptions[filter.name])
                                ? selectedOptions[filter.name]
                                : []
                            }
                            onChange={(e: any) =>
                              handleSelectOption(
                                filter.name,
                                e.target.value as string[]
                              )
                            }
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                              Array.isArray(selected) ? (
                                <div className="chipp">
                                  {selected.map((value) => (
                                    <div className="neww">
                                      <Chip
                                        key={value}
                                        label={value}
                                        className="filter_selected"
                                        deleteIcon={
                                          <span
                                            className="cross icon"
                                            onMouseDown={(e) =>
                                              e.stopPropagation()
                                            } // Prevents the dropdown from opening
                                            onClick={() =>
                                              handleRemoveOption(
                                                filter.name,
                                                value
                                              )
                                            }
                                          >
                                            X
                                          </span>
                                        }
                                        onDelete={() =>
                                          handleRemoveOption(filter.name, value)
                                        }
                                      />
                                      {/* <span className="cross"
                                        onClick={(e) => {
                                          e.stopPropagation(); // Prevent the dropdown from opening
                                          handleRemoveOption(filter.name, value);
                                        }}>x</span> */}
                                    </div>
                                  ))}
                                </div>
                              ) : null
                            }
                          >
                            {dropdownData[filter?.name]?.map((option) => (
                              <MenuItem key={option} value={option}>
                                {/* <Checkbox checked={Array.isArray(selectedOptions[filter.name]) &&
                                  (selectedOptions[filter.name] as string[]).indexOf(option) > -1}
                                /> */}
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    )}
                    <div className="delete_icon">
                      <DeleteIcon
                        className="dlt"
                        onClick={() =>
                          handleDeleteFilter(
                            filter.id,
                            filter.name as FilterType
                          )
                        }
                      />
                    </div>
                  </div>
                </Box>
              ))}
            </Grid>
            <Grid xs={12} className="footer ctaBtn mt-10">
              <Button onClick={handleClearFilters} className="submitBtn">
                Clear All
              </Button>
              <Button className="submitBtn" onClick={applyFilter}>
                Apply
              </Button>
              <Button
                // disabled={selectedFilters?.length === 0}
                onClick={handleSaveFilters}
                className="submitBtn"
              >
                Save & Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default FilterComponent;
