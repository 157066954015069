import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMPLOYER_URL_CONSTANTS } from "../../../../../../APIVar/EmployerUrlConstant";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  get_client_list,
  validateEmail,
} from "../../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../../../formLayout.scss";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";

function CreateUsers() {
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState<boolean>(false);
  const [hidePassword, setHidePassword] = useState<any>(false);
  const [usersRole, setUsersRole] = useState<any>([]);
  const [sectionStatus, SetSectionStatus] = useState<string>("userInformation");
  const [subSection, setSubSection] = useState<string>("basics");
  const [id, setId] = useState<string>("");
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [officialMailId, setOfficialMailId] = useState<any>("");
  const [editUser, setEditUser] = useState<any>({
    name: "",
    email: "",
    dob: null,
    roleName: "",
    branch: { label: "", id: "" },
    department: { label: "", id: "" },
    designation: { label: "", id: "" },
    clientName: "",
    client: { label: "", id: "" },
    password: "",
    warnPassword: false,
    warnName: false,
    warnEmail: false,
    warnRolename: false,
    warnBranch: false,
    warnDepartment: false,
    warnDesignation: false,
  });
  const [department_data, setDepartment_data] = useState<
    Array<{
      id: string;
      label: string;
    }>
  >([]);
  const [designation_data, setDesignation_data] = useState<
    Array<{
      id: string;
      label: string;
    }>
  >([]);
  const [trueConditions, setTrueConditions] = useState(0);

  const [passwordValidityObj, setPasswordValidityObj] = useState<{
    isValid: boolean;
    containsNameOrEmail: boolean;
    hasMinLength: boolean;
    hasSymbolOrNumber: boolean;
    sequence: boolean;
  }>({
    containsNameOrEmail: false,
    hasMinLength: false,
    hasSymbolOrNumber: false,
    isValid: false,
    sequence: false,
  });
  const [branch_data, setBranch_data] = useState<
    Array<{
      id: string;
      label: string;
    }>
  >([]);
  const userDetails = useAppSelector((state: any) => state.userDetailsSlice);
  const [warn_password, setWarn_Password] = useState(false);
  const [clientListData, setClientListData] = useState<
    Array<{
      checkbox_status: boolean;
      label: string;
      id: string;
    }>
  >([]);
  // for scrolling
  const userInformation = useRef(null);
  const basics = useRef(null);

  const isValidPassword = () => {
    const containsNameOrEmail = editUser?.email
      ? !editUser.password.includes(editUser?.email)
      : true;
    const hasMinLength = editUser.password.length >= 8;
    const symbolOrNumberRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[a-z])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}/;
    const forbiddenSequence = /12345678/;
    const hasSymbolOrNumber = symbolOrNumberRegex.test(editUser.password);
    const sequence = editUser.password
      ? !forbiddenSequence.test(editUser.password)
      : false;
    const isValid = !(
      containsNameOrEmail &&
      hasMinLength &&
      hasSymbolOrNumber &&
      sequence
    );
    const returnObj: any = {
      isValid,
      containsNameOrEmail,
      hasMinLength,
      hasSymbolOrNumber,
      sequence,
    };
    let numTrues: number = 0;
    for (let i = 0; i < Object.keys(returnObj).length; i++) {
      if (returnObj[Object.keys(returnObj)[i]]) {
        numTrues += 1;
      }
    }

    setTrueConditions(numTrues);
    setPasswordValidityObj(returnObj);
  };
  useEffect(() => {
    client_list();
    user_role_data();
  }, []);

  useEffect(() => {
    // ACCESS_LEVEL_SERVICES.user_data_by_id
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const id = params.get("id");
    setId(id ?? "");
    //user_data(id ?? "");
    get_Dropdown_branch_list(EMPLOYER_URL_CONSTANTS.get_employer_location_list);
    get_Dropdown_branch_list(
      EMPLOYER_URL_CONSTANTS.get_employer_department_list
    );
    get_Dropdown_branch_list(
      EMPLOYER_URL_CONSTANTS.get_employer_designation_list
    );
  }, []);

  const get_Dropdown_branch_list = (url: string) => {
    const onSuccess = (res: any) => {
      if (url === EMPLOYER_URL_CONSTANTS.get_employer_location_list) {
        let data: any = res.data.data.map(
          ({ _id, name }: { _id: string; name: string }) => ({
            id: _id,
            label: name,
          })
        );
        setBranch_data(data);
      } else if (url === EMPLOYER_URL_CONSTANTS.get_employer_department_list) {
        let newVar = res.data.data[0].value.map((item: any) => ({
          id: item._id,
          label: item.label,
        }));
        setDepartment_data(newVar);
      } else if (url === EMPLOYER_URL_CONSTANTS.get_employer_designation_list) {
        let newVar = res.data.data[0].value.map((item: any) => ({
          id: item._id,
          label: item.label,
        }));
        setDesignation_data(newVar);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.get_branch_department_dropdown_list(
      url,
      onSuccess,
      onSuccess
    );
  };

  const user_data = (id: string) => {
    const onSuccess = (res: any) => {
      setEditUser(res.data.data[0]);
      setClientListData(res.data.data[0].clientName);
    };
    const onError = (err: any) => {
      console.log(".......,,", err);
    };
    ACCESS_LEVEL_SERVICES.user_data_by_id(id, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    if (mainState === "editUser") {
      if (key === "email") {
        setOfficialMailId(value);
      }
      setEditUser({
        ...editUser,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else {
      attrName(value);
      setWarn_Password(false);
    }
  };
  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const client_list = () => {
    const onSuccess = (res: any) => {
      let data: any = [];
      res.data.data?.forEach((e: any) => {
        data.push({
          ...e,
          checkbox_status: false,
        });
      });
      setClientListData(data);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    get_client_list(userDetails?.userType?.toLowerCase(), onSuccess, onError);
  };

  const user_role_data = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.id, value: data.label };
      });
      setUsersRole(statusData);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    ACCESS_LEVEL_SERVICES.get_user_role_data_employer("", onSuccess, onError);
  };

  const detail_form_submit = () => {
    if (disableSave) return;
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const dynamicPart = Math.random().toString().slice(2, 6);
    let companyId = `USER${month}${year}${dynamicPart}`;
    let data: any = editUser;
    // data.warnName = editUser?.name && editUser?.name === "" ? true : false;
    data.warnName = editUser?.name === "" ? true : false;
    data.warnEmail = !validateEmail(editUser.email);
    data.warnRolename = editUser?.roleName === "" ? true : false;
    data.warnBranch = editUser.branch.label === "" ? true : false;
    data.warnDepartment = editUser.department.label === "" ? true : false;
    data.warnDesignation = editUser.designation.label === "" ? true : false;
    data.warnPassword = editUser?.password === "" ? true : false;
    setWarn_Password(editUser?.password === "" ? true : false);

    setEditUser({ ...data });
    let passFlag = false;

    if (hidePassword) {
      passFlag = true;
    } else {
      passFlag = !passwordValidityObj.isValid;
    }
    if (
      data.warnName === false &&
      data.warnEmail === false &&
      data.warnRolename === false &&
      data.warnBranch === false &&
      data.warnDepartment === false &&
      data.warnDesignation === false &&
      passFlag
    ) {
      let params = {
        companyCode: companyId,
        employer_parent_id: userDetails.companyCode,
        name: editUser.name,
        email: editUser.email,
        username: editUser.email,
        userType: userDetails.userType,
        role: editUser.roleName.id ?? editUser.roleName,
        branch: editUser.branch.id ?? editUser.branch,
        Department: editUser.department.id ?? editUser.department,
        employer_designation: editUser.designation.id ?? editUser.designation,
        client: "All",
        clientName: [],
        password: hidePassword ? "123456" : editUser?.password,
      };
      const onSuccess = (res: any) => {
        navigate(-1);
        toast.success("User Created Successfully");
      };
      const onError = (err: any) => {
        toast.error(err.response.data.errors[0].message);
      };
      ACCESS_LEVEL_SERVICES.create_new_employer_user(
        params,
        onSuccess,
        onError
      );
    }
  };

  const checkMailIsExist = (currentValue: string) => {
    const onSuccess = (res: any) => {
      setDisableSave(false);
      setHidePassword(res.data.data.isExist);
      if (res.data.data.isExist) {
        return toast.error(res.data.message);
      }
    };
    const onError = (err: any) => {
      setDisableSave(true);
      toast.error(err?.response?.data?.errors[0]?.message);
    };

    ACCESS_LEVEL_SERVICES.checkIsMailUsedEmployer(
      onSuccess,
      onError,
      currentValue
    );
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      if (officialMailId) {
        checkMailIsExist(officialMailId);
      }
    };
    timeoutId = setTimeout(delayedApiCall, 1000);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [officialMailId]);
  useEffect(() => {
    isValidPassword();
  }, [editUser.password]);
  return (
    <Box className="layoutWrapper">
      {/* Page header */}
      <Grid container spacing={3} className="pageHeader">
        <Grid xs={6} className="pageTitle">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeftIcon />
          </Link>

          <div>
            <h3>Create New User</h3>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <Grid container spacing={3}>
            <Grid xs className="leftSection">
              <Link
                className={
                  sectionStatus === "userInformation"
                    ? "active sectionLink userInformation"
                    : "sectionLink userInformation"
                }
                onClick={() => {
                  handleClicks(sectionStatus);
                  scrollToSection(userInformation);
                  SetSectionStatus("userInformation");
                }}
              >
                <span> User Information</span>
              </Link>
              <ul className="accordion_list">
                <li>
                  <Link
                    className={
                      subSection === "basics"
                        ? "inner_link active basics"
                        : "inner_link  basics"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(basics);
                      setSubSection("basics");
                    }}
                  >
                    Basic Info
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={
                      subSection === "personalDetails"
                        ? "inner_link active personalDetails"
                        : "inner_link  personalDetails"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(personalDetails);
                      setSubSection("personalDetails");
                    }}
                  >
                    Personal Details
                  </Link>
                </li>
                <li>
                  <Link
                    className={
                      subSection === "addressDetails"
                        ? "inner_link active addressDetails"
                        : "inner_link  addressDetails"
                    }
                    onClick={() => {
                      handleClicks(subSection);
                      scrollToSection(addressDetails);
                      setSubSection("addressDetails");
                    }}
                  >
                    Address Details
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className={
                      subSection === "clientInfo"
                        ? "inner_link active clientInfo"
                        : "inner_link clientInfo"
                    }
                    onClick={() => {
                      setSubSection("clientInfo");
                      handleClicks(subSection);
                      scrollToSection(clientInfo);
                    }}>
                    Client Info
                  </Link>
                </li> */}
              </ul>
            </Grid>
            <Grid xs className="rightSection">
              <Box className="detailSection" ref={userInformation}>
                <div ref={basics}>
                  <div className="sectionTitle">
                    <h4>User Information</h4>
                  </div>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h5 className="subHeading">Basic Info</h5>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="name"
                        class_name="inputField"
                        title={"Name"}
                        value={editUser?.name}
                        attrName={["editUser", "name"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnName}
                        required
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={"Email"}
                        value={editUser?.email}
                        attrName={["editUser", "email"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnEmail}
                        required
                      />
                    </Grid>
                    {/* <Grid sm={6} md={4} lg={3} xl={3}>
                      <RKTextField
                        validation_type="email"
                        class_name="inputField"
                        title={"Support Email"}
                        value={editUser?.supportEmail}
                        attrName={["editUser", "supportEmail"]}
                        value_update={updateMasterState}
                        warn_status={editUser?.warnSupportEmail}
                      />
                    </Grid> */}

                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Role"
                        value={
                          editUser?.roleName === undefined
                            ? ""
                            : editUser?.roleName
                        }
                        attrName={["editUser", "roleName"]}
                        value_update={updateMasterState}
                        dropdown_data={usersRole}
                        warn_status={editUser?.warnRolename}
                        error_messg="Select Role"
                        required
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Branch"
                        value={
                          editUser?.branch === undefined ? "" : editUser?.branch
                        }
                        attrName={["editUser", "branch"]}
                        value_update={updateMasterState}
                        data={branch_data}
                        warn_status={editUser.warnBranch}
                        error_message="Select Branch"
                        info_status={true}
                        required
                        info_message="This is the branch list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Department"
                        value={
                          editUser?.department === undefined
                            ? ""
                            : editUser?.department
                        }
                        attrName={["editUser", "department"]}
                        value_update={updateMasterState}
                        data={department_data}
                        warn_status={editUser.warnDepartment}
                        error_message="Select department"
                        info_status={true}
                        required
                        info_message="This is the department list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    <Grid sm={6} md={4} lg={3} xl={3}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Designation"
                        value={
                          editUser?.designation === undefined
                            ? ""
                            : editUser?.designation
                        }
                        attrName={["editUser", "designation"]}
                        value_update={updateMasterState}
                        data={designation_data}
                        warn_status={editUser.warnDepartment}
                        error_message="Select designation"
                        info_status={true}
                        info_message="This is the designation list that you have created in the Organisation Settings"
                      />
                    </Grid>
                    {!hidePassword && (
                      <Grid sm={6} md={4} lg={3} xl={3}>
                        <RKTextField
                          class_name="inputField"
                          title="Password"
                          value={editUser?.password}
                          attrName={["editUser", "password"]}
                          value_update={updateMasterState}
                          warn_status={editUser?.warnPassword}
                          error_messg="Enter Password"
                          type="password"
                          autoComplete="new-password"
                          password_status
                          required
                        />
                      </Grid>
                    )}
                    {!hidePassword && (
                      <Grid xs={12}>
                        <div className="confirmation_content">
                          <p>
                            Password Strength:{" "}
                            {trueConditions >= 3 ? (
                              <span>Strong</span>
                            ) : trueConditions <= 1 ? (
                              <span style={{ color: "red" }}>Weak</span>
                            ) : (
                              <span style={{ color: "#FC6509" }}>Medium</span>
                            )}
                          </p>
                          <ul>
                            {passwordValidityObj.hasMinLength ? (
                              <li className="success">At least 8 characters</li>
                            ) : (
                              <li className="error">At least 8 characters</li>
                            )}
                            {passwordValidityObj.hasSymbolOrNumber ? (
                              <li className="success">
                                {" "}
                                Password must contain at least one uppercase
                                letter, number, and special characterr
                              </li>
                            ) : (
                              <li className="error">
                                {" "}
                                Password must contain at least one uppercase
                                letter, one lowercase, number, and special
                                character
                              </li>
                            )}
                            {passwordValidityObj.sequence ? (
                              <li className="success">
                                {" "}
                                Password should not contain the sequence
                                "12345678"
                              </li>
                            ) : (
                              <li className="error">
                                {" "}
                                Password should not contain the sequence
                                "12345678"
                              </li>
                            )}
                            {passwordValidityObj.containsNameOrEmail ? (
                              <li className="success">
                                {" "}
                                Must not contain your name or email
                              </li>
                            ) : (
                              <li className="error">
                                {" "}
                                Must not contain your email
                              </li>
                            )}
                          </ul>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <Grid container spacing={3} className="footerSection">
        <Grid xs={12} className="ctaBtn">
          <Button
            variant="outlined"
            className="borderBtn"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={disableSave}
            variant="contained"
            className="submitBtn"
            onClick={detail_form_submit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CreateUsers;
