import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { GetTicketDTO } from "../../../../Services/DTO/SupportDTO";
import { EMPLOYER_SETTING_SERVICES } from "../../../../Services/EmployerServices/Settings/SetingServices";
import { SUPPORT_SERVICES } from "../../../../Services/Support/SupportServices";
import { RootState } from "../../../../Store/Store";
import { useAppSelector } from "../../../../Store/hooks";
import { uploadImage } from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import "./Support.scss";
import TextAreaField from "../../../../Supporting files/TextAreaField/TextAreaField";

function Support() {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const userData = useAppSelector((state: RootState) => state.userDetailsSlice);
  const [supportTicket, setSupportTicket] = useState<any>({
    description: "",
    reasons: "",
    subject: "",
    upload: "",
    emailId: "",
    username: userData?.username,
    organisationName: userData?.organizationDetails?.orgnizationName,
  });
  const [warnReasons, setwarnReasons] = useState(false);
  const [warnSubject, setwarnSubject] = useState(false);
  const [warnDescription, setWarnDescription] = useState(false);
  const [warnUpload, setwarnUpload] = useState(false);
  const [filename, setFilename] = useState<string>("");
  const [getTicket, setGetTicket] = useState<GetTicketDTO>([]);
  const [uploadFile, setUploadFile] = React.useState("");
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [addPermission, setaddPermission] = useState(false);

  const reason_data = [
    { key: "Facing Issue", value: "Facing Issue" },
    { key: "Complaint", value: "Complaint" },
    { key: "Feedback", value: "Feedback" },
  ];

  const faqs = [
    {
      expanded: "panel1",
      title: "How to raise a request for claim intimation?",
      description:
        "Reach out to your insurance company as soon as possible to inform them about the incident or event that may lead to a claim. You can find their contact information on your insurance policy documents or their website.",
    },
    {
      expanded: "panel2",
      title: "How to update password?",
      description: "kdsgjsdkjgkdsjgksd dskgsdkgs gksdjgk",
    },
    {
      expanded: "panel3",
      title: "What is riskmap?",
      description:
        "A risk score map is a visual representation or a graphical representation that indicates the level of risk associated with different areas or regions. It is used to identify and communicate the varying degrees of risk across a specific geographical area or within a particular domain.",
    },
    {
      expanded: "panel4",
      title: "How does group health insurance policy work?",
      description:
        "An employer purchases a group healthcare coverage plan for their respective employees based on the number of workers. The employer entirely bears the cost of group healthcare. In return, the employees can avail medical insurance as per the sum insured.",
    },
  ];

  const updateMasterState = (attrName: any, value: string) => {
    if (attrName === "setSupportTicket") {
      setSupportTicket({ ...supportTicket, reasons: value });
      setwarnReasons(false);
    } else if (attrName === "subject") {
      setSupportTicket({ ...supportTicket, subject: value });
      setwarnSubject(false);
    } else if (attrName == "description") {
      setSupportTicket({ ...supportTicket, description: value });
      setWarnDescription(false);
    } else {
      attrName(value);
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const fileSize = file?.size;
    const maxSizeBytes = 8 * 1024 * 1024;
    if (fileSize && fileSize > maxSizeBytes) {
      e.target.files = null;
      e.target.value = null;
      return toast.error("File size exceeds 8 MB limit");
    }
    if (file) {
      setwarnUpload(false);
      uploadImage(file, (base64Image: any, base64String: any) => {
        setFilename(file.name);
        setSupportTicket({
          ...supportTicket,
          upload: base64Image,
        });
      });
    }
    e.target.files = null;
    e.target.value = null;
  };

  const getSupportTicket = () => {
    const onSuccess = (res: any) => {
      setGetTicket(res.data.data);
    };
    const onError = (err: any) => {
      console.log(err);
      toast.error("Error submiting support ticket");
    };
    const url =
      userData.userType === "BROKER" ? URL_CONSTANTS.get_support_ticket : "";
    EMPLOYER_SETTING_SERVICES.fetch_data(url, onSuccess, onError);
  };

  useEffect(() => {
    setaddPermission(
      BROKER?.Support?.add === undefined ? false : BROKER?.Support?.add
    );
  }, []);

  const submitSupportTicket = () => {
    if (disableSave) return;
    if (!addPermission) return toast.error("Update permission not allowed");
    let hasError = false;
    if (supportTicket.reasons?.trim() === "") {
      setwarnReasons(true);
      hasError = true;
    }
    if (supportTicket.subject?.trim() === "" || null) {
      setwarnSubject(true);
      hasError = true;
    }
    if (supportTicket.description?.trim() === "" || null) {
      setWarnDescription(true);
      hasError = true;
    }
    if (supportTicket.upload === "") {
      setwarnUpload(true);
      hasError = true;
    }
    const onSuccess = (res: any) => {
      toast.success("Submitted support ticket successfully!");
      setFilename("");
      setwarnReasons(false);
      setSupportTicket({
        description: "",
        reasons: "",
        subject: "",
        upload: "",
        emailId: "",
        username: userData.username,
        organisationName: userData?.organizationDetails?.orgnizationName,
      });
      setwarnUpload(false);
      setTimeout(() => {
        setDisableSave(false);
      }, 1500);
    };

    const onError = (err: any) => {
      setDisableSave(false);
      toast.error("Error submiting support ticket");
    };
    if (!hasError) {
      setDisableSave(true);
      SUPPORT_SERVICES.create_support_ticket(supportTicket, onSuccess, onError);
    }
  };

  return (
    <Box className="supportWrapper">
      <Grid container spacing={3}>
        <Grid xs={12} className="pageTitle">
          <h2>
            <span>Get</span> Support
          </h2>
          {/* <p>
            What incredible experiencesj are unfolding within you? Kindly grace
            us with the gift of sharing.
          </p> */}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <>
            <Grid container spacing={3}>
              <Grid xs={4}>
                <img src="./images/confused.svg" />
              </Grid>
              <Grid xs={8}>
                <>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Reason of writing to us"
                        value={supportTicket.reasons}
                        attrName={"setSupportTicket"}
                        value_update={updateMasterState}
                        dropdown_data={reason_data}
                        warn_status={warnReasons}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <RKTextField
                        attrName="subject"
                        class_name="inputField"
                        title="Subject"
                        value={supportTicket.subject}
                        value_update={updateMasterState}
                        warn_status={warnSubject}
                        error_messg={"Subject is required"}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <p className="describe-text mb-2">Describe here</p>
                      <TextAreaField
                        class_name="inputField"
                        title="Description"
                        value={supportTicket.description}
                        attrName="description"
                        value_update={updateMasterState}
                        warn_status={warnDescription}
                        error_messg={"Description is required"}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Box>
                        <Grid
                          container
                          spacing={0}
                          className="uploadFile"
                          padding={0}
                        >
                          <Grid xs={10} className="inputField">
                            <RKTextField
                              class_name="inputField"
                              title={"Upload .jpg .png .pdf file"}
                              value={filename}
                              attrName={setUploadFile}
                              value_update={updateMasterState}
                              warn_status={false}
                              disabled
                            />
                          </Grid>
                          <Grid xs={2}>
                            <Button
                              variant="contained"
                              className="browsebtn"
                              component="label"
                            >
                              Browse
                              <input
                                hidden
                                accept="application/pdf, image/*"
                                multiple
                                type="file"
                                style={{ zIndex: "99" }}
                                onChange={handleFileUpload}
                              />
                            </Button>
                          </Grid>
                          <Grid xs={12}>
                            <div className="uploadAttachmentInfo">
                              <span>Maximum size of attachment is 8 MB*</span>
                            </div>
                            <div className="inputField">
                              <span className="error">
                                {warnUpload && "Upload file"}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid xs={12} className="ctaBtn">
                      <Button
                        variant="contained"
                        className="submitBtn"
                        onClick={submitSupportTicket}
                        disabled={disableSave}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
              <Grid xs={12}>
                <h3>
                  <span>General</span> Questions
                </h3>
                <p>
                  You will discover the solutions you seek. Our mission is to
                  offer a seamless and user-friendly experience, ensuring that
                  you can access valuable information promptly to address your
                  frequently asked questions.
                </p>
              </Grid>
              <Grid xs={12}>
                {faqs.map((data, index) => {
                  return (
                    <FAQ
                      data={{
                        description: data.description,
                        expanded: data.expanded,
                        title: data.title,
                      }}
                    />
                  );
                })}
              </Grid>
            </Grid> */}
          </>
        </Grid>
        {/* <Grid xs={4} className="requestSection">
          <Box textAlign="center">
            <img src="./images/no-requests.svg" />
            <p>No Request Pending</p>
          </Box>
          <ul className="requestList">
            {getTicket.map((ticket: GetTicketsDTO) => {
              return (
                <li>
                  <Collapse
                    in={status}
                    collapsedSize={80}
                    className="cancelled"
                  >
                    <h6>{ticket.ticketId}</h6>
                    <span className="status">{ticket.status}</span>
                    <h5>{ticket.reasons}</h5>
                    <p>{ticket.description}</p>
                    <Link
                      className="viewMore"
                      onClick={() => setStatus(!status)}
                    >
                      <ExpandMoreIcon />
                    </Link>
                  </Collapse>
                </li>
              );
            })}
          </ul>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default Support;
