import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../Routes/all_routes";
import { EMPLOYEE_SERVICES } from "../../../Services/Employee/EmployeeService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../Services/EmployerServices/Employee/EmployeeServices";
import { useAppSelector } from "../../../Store/hooks";
import UGTable from "../../../Supporting files/UGTable/UGTable";
import NewLoader from "../../NewLoader/NewLoader";
import { ENDORSEMENT_SERVICES } from "../../../Services/Endorsement/EndorsementService";
import { RootState } from "../../../Store/Store";
import {
  add_date_format,
  date_time_format,
  isDateString,
} from "../../../Supporting files/HelpingFunction";
import { useFormatCurrency } from "../../../Supporting files/CustomHooks";
import Rejected from "@mui/icons-material/Cancel";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import { E_BE_FILTER_CODE } from "../../../Services/Enum/E_UGTable";
import { CLIENT_SERVICES } from "../../../Services/Clients/ClientService";

const EndorsementList = ({ id }: { id: any }) => {
  const navigate = useNavigate();
  const { formatCurrency } = useFormatCurrency();
  const user = useAppSelector((state) => state.userDetailsSlice.userType);
  const { dateFormat, timeZone } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const [sectionStatus, SetSectionStatus] = useState<string>("endorsementTab");
  const endorsementTab = useRef(null);
  const [columns, setColumns] = useState<any>([]);
  const [endorsement, setEndorsement] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const updateMasterState = (attrName: any, value: any) => { };

  const get_endorsement_data = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      if (res) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      const data: any = res.data.data.employee.map((data: any) => ({
        ...data,
        id: data._id,
      }));
      if (!csv) {
        setEndorsement(data);
        setTotalRecords(res.data.data.countData);
      }
      if (csv) {
        downloadCsv(res.data.data.employee);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    const currentRoute = E_BE_FILTER_CODE.EMPOYEEDETAILSENDORSEMENT.toString();
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
    const existingEntryIndex = parsedData.find(
      (entry: any) => entry.route === currentRoute
    );
    {
      user === "BROKER"
        ? EMPLOYEE_SERVICES.get_endorsement_list(
          onSuccess,
          onError,
          id,
          limit ? limit : existingEntryIndex?.pageNumber ?? 25,
          skip ?? 0
        )
        : EMPLOYER_EMPLOYEE_SERVICES.get_endorsement_list(
          onSuccess,
          onError,
          id,
          limit ? limit : existingEntryIndex?.pageNumber ?? 25,
          skip ?? 0
        );
    }
  };
  function renderEndorsementValue(row: any, data: string) {
    switch (row?.endorsementFor) {
      case "Name":
        return (
          <span>{row?.[data]?.firstName + " " + row?.[data]?.lastName}</span>
        );
      case "Personal Address":
        return <span>{row?.[data]?.street}</span>;
      case "Contact Number":
        return <span>{row?.[data]?.contactNumber}</span>;
      case "Gender":
        return <span>{row?.[data]?.gender}</span>;
      case "Department":
        return <span>{row?.[data]?.department}</span>;
      case "Designation":
        return <span>{row?.[data]?.designation}</span>;
      case "salary":
        return (
          <span>{formatCurrency(Number(row[data]?.salary.split("/")[0]))}</span>
        );
      case "Salary":
        return (
          <span>{formatCurrency(Number(row[data]?.salary.split("/")[0]))}</span>
        );
      case "Salary Grade":
        return <span>{row?.[data]?.salary_grade}</span>;
      case "Email":
        return <span>{row?.[data]?.email}</span>; // Assuming you meant "Email" here
      default:
        return null; // or some default value or message
    }
  }
  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      get_Hr_status(statusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_status(onSuccess, onError);
  };

  const get_Hr_status = (statusData: any) => {
    const onSuccess = (res: any) => {
      let HrstatusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setColumns([
        {
          field: "endorsementId",
          headerName: "Endorsement ID",
          flex: 2,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                className="primary-text"
                underline="none"
                style={{
                  cursor: "pointer",
                  fontWeight: "400",
                  textDecoration: "none",
                }}
                onClick={() => {
                  if (
                    row.endorsementFor === "Nominee Deletion" ||
                    row.endorsementFor === "Nominee Addition" ||
                    row.endorsementFor === "Member Deletion" ||
                    row.endorsementFor === "Member Addition"
                  ) {
                    navigate(
                      `${ALL_ROUTES.ENDORSEMENT_DETAILS_MEMBER_ADDITION}?id=${row._id}&policyId=${row.policyId}&memberId=${row.member_id}&tab=OVERVIEW`
                    );
                  } else {
                    navigate(
                      `${ALL_ROUTES.ENDORSEMENT_DETAILS}?id=${row._id}&tab=OVERVIEW`
                    );
                  }
                }}
              >
                {row.endorsementId}
              </Link>
            );
          },
        },
        {
          field: "clientId",
          headerName: "Client ID",
          flex: 4,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "clientName",
          headerName: "Client Name",
          flex: 8,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "memberId",
          headerName: "Member ID",
          flex: 3,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "insurer",
          headerName: "Insurer",
          flex: 2,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "tpaName",
          headerName: "TPA Name",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "memberName",
          headerName: "Member Name",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "policy",
          headerName: "Policy Number",
          flex: 1,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "type",
          headerName: "Endorsement Type",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          flex: 1,
          minWidth: 200,
          check_status: true,
        },
        {
          field: "endorsementFor",
          headerName: "Endorsement Value",
          flex: 1,
          minWidth: 150,
          check_status: true,
        },
        {
          field: "old",
          headerName: "Old Value",
          flex: 1,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            if (row.endorsementFor === "Date of Birth") {
              return <span>{add_date_format(row.old)}</span>;
            } else if (row.endorsementFor === "Salary") {
              return <span>{formatCurrency(Number(row?.old))}</span>;
            } else {
              return <span>{row?.old}</span>;
            }
            // return <span>{row?.new?.dob}</span>;
          },
        },
        {
          field: "newvalue",
          headerName: "New Value",
          flex: 1,
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            if (isDateString(row?.new?.dob)) {
              return <span>{add_date_format(row?.new?.dob)}</span>;
            } else {
              return renderEndorsementValue(row, "new");
            }
          },
        },
        {
          field: "status",
          headerName: "Endorsement Status",
          minWidth: 250,
          check_status: true,
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return statusData.filter((fd:any)=>fd.label === row.status)[0].value
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 54 ? (
                    <Requested id={`_${row.status.toString()}`} />
                  ) : row.status === 55 ? (
                    <SentToTPA id={`_${row.status.toString()}`} />
                  ) : row.status === 56 ? (
                    <Required id={`_${row.status.toString()}`} />
                  ) : row.status === 57 ? (
                    <Approved id={`_${row.status.toString()}`} />
                  ) : row.status === 58 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 59 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 71 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 72 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "hr_status",
          headerName: "HR Status",
          minWidth: 250,
          check_status: true,
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return HrstatusData.filter((fd:any)=>fd.label === row.hr_status)[0].value
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.hr_status}`}>
                <div className="status_dropdown" id={`_${row.hr_status}`}>
                  {row.hr_status === 77 ? (
                    <Requested id={`_${row.hr_status.toString()}`} />
                  ) : row.hr_status === 75 ? (
                    <Approved id={`_${row.hr_status.toString()}`} />
                  ) : row.hr_status === 76 ? (
                    <Rejected id={`_${row.hr_status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.hr_status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={HrstatusData}
                    warn_status={false}
                    disabled={true}
                  />
                </div>
              </div>
            );
          },
        },
        // {
        //   field: "supportingdocuments",
        //   headerName: "Supporting Documents",
        //   flex: 2,
        //   minWidth: 150,
        //   check_status: true,
        //   renderCell: (ValueFormatterParams: any) => {
        //     const { row } = ValueFormatterParams;
        //     return (
        //       <Link sx={{ textDecoration: "none" }}>
        //         <span
        //           style={{
        //             color: "#000000",
        //             textDecoration: "none",
        //             marginRight: "10px",
        //           }}
        //         >
        //           {row.docCount}
        //         </span>
        //         <img
        //           src="images/visibility-icon.svg"
        //           alt=""
        //           style={{
        //             zIndex: 99,
        //             position: "relative",
        //             cursor: "pointer",
        //           }}
        //           onClick={() => {
        //             setViewDocuments(true);
        //             setDoc_data(row?.attachments);
        //           }}
        //         />
        //       </Link>
        //     );
        //   },
        // },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.createdAt, dateFormat, timeZone)
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.updatedAt, dateFormat, timeZone)
          },
        }
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_Hr_status(onSuccess, onError);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    setShowLoader(true);
    get_endorsement_data();
    get_status();
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDownloadAll = () => {
    // downloadCsv(rows);
    get_endorsement_data(totalRecords, 0, "csv");
    handleClose();
  };

  const handleDownloadSelected = () => {
    let csvData;
    if (tableLimit >= endorsement.length) {
      csvData = endorsement;
    } else {
      csvData = endorsement.slice(
        pageNumber ? pageNumber * tableLimit : 0,
        (pageNumber + 1) * tableLimit
      );
    }
    downloadCsv(csvData);
    handleClose();
  };


  const downloadCsv = (csvData: any) => {
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "endorsement.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("sucess", err);
    };
    const updatedArr = csvData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          "Endorsement ID": item?.endorsementId,
          "Client ID": item?.clientId,
          "Client Name": item?.clientName,
          "Member ID": item?.memberId,
          Insurer: item?.insurer,
          "TPA Name": item?.tpaName,
          "Member Name": item?.memberName,
          "Policy Number": item?.policy,
          "Endorsement Type": item?.type,
          "Policy Type": item?.policyType,
          "Endorsement Value": item?.endorsementFor,
          "Old Value": item?.old,
          "New Value": item.new
            ? Object.values(item.new)
              .filter((value) => value !== "" && value !== 0)
              .join(" ")
            : "",
          "Endorsement Status": statusObj?.value,
          "Created On": date_time_format(
            item?.createdAt,
            dateFormat,
            timeZone
          ),
          "Updated On": date_time_format(
            item?.updatedAt,
            dateFormat,
            timeZone
          ),
        };
      }
      return item;
    });

    columns.forEach((item: any) => {
      item.field = item.headerName;
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      user.toLowerCase(),
      "endorsement"
    );
  };
  return (
    <>
      {showLoader ? (
        <NewLoader />
      ) : (
        <Grid container spacing={3}>
          <Grid
            xs
            style={{
              maxWidth: "260px",
              position: "relative",
              top: "-75px",
              paddingLeft: "0",
            }}
          >
            <div
              className="leftSection h-100"
              style={{ position: "fixed", paddingLeft: "8px" }}
            >
              <Link
                className={
                  sectionStatus === "endorsementTab"
                    ? "active sectionLink endorsementTab"
                    : "sectionLink endorsementTab"
                }
              >
                <span>Endorsement</span>
              </Link>
            </div>
          </Grid>
          <Grid xs={12} className="text-right">

            {true && (
              <>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  className="blueBtn download"
                  style={{ paddingLeft: 30, textTransform: "unset" }}
                >
                  Download CSV
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                  <MenuItem onClick={handleDownloadSelected}>
                    Selected Items
                  </MenuItem>
                </Menu>
              </>
            )}

          </Grid>
          <Grid xs paddingLeft={"0"}>
            <Box ref={endorsementTab}>
              <Grid xs={12} className="px-0 py-0">
                <Grid xs={12} className="detailBox mb-5">
                  <Grid container padding="0">
                    <Grid xs={12} width={"100%"}>
                      <UGTable
                        header_data={columns}
                        data={endorsement}
                        value_update={updateMasterState}
                        attrName={"UGTable"}
                        BE_filter_code={
                          E_BE_FILTER_CODE.EMPOYEEDETAILSENDORSEMENT
                        }
                        refetch_data={get_endorsement_data}
                        setTableLimit={setTableLimit}
                        setTableSkip={setTableSkip}
                        setPageNumberData={setPageNumber}
                        totalRecords={totalRecords}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EndorsementList;
