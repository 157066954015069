import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../../Routes/all_routes";
import { useAppSelector } from "../../../../Store/hooks";
import "./NewSettings.scss";
// import OrganisationDetails from "./OrganisationSettings/OrganisationDetails/OrganisationDetails";

function Settings() {
  const navigate = useNavigate();
  const userDetails = useAppSelector((state) => {
    return state.userDetailsSlice;
  });
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);

  const [personalSettingsViewPermission, setPersonalSettingsViewPermission] =
    useState(true);
  console.log("personalSettingsViewPermission", personalSettingsViewPermission);
  const [accessControlsViewPermission, setAccessControlsViewPermission] =
    useState(false);
  const [userControlsViewPermission, setUserControlsViewPermission] =
    useState(false);
  const [companyDetailViewPermission, setCompanyDetailViewPermission] =
    useState(false);
  const [branchViewPermission, setBranchViewPermission] = useState(false);
  const [departmentViewPermission, setDepartmentViewPermission] =
    useState(false);  
    const [designationViewPermission, setDesignationViewPermission] =
    useState(false);
  const [modulesViewPermission, setModulesViewPermission] = useState(false);
  const [taxViewPermission, setTaxViewPermission] = useState(false);
  const [currencyViewPermission, setCurrencyViewPermission] = useState(false);
  const [usersViewPermission, setUsersViewPermission] = useState(false);
  const [emailSettingsViewPermission, setEmailSettingsViewPermission] =
    useState(false);
  const [appearanceViewPermission, setApperanceViewPermission] =
    useState(false);
  useEffect(() => {
    setPersonalSettingsViewPermission(
      EMPLOYER?.personalSetting?.view === undefined
        ? false
        : EMPLOYER?.personalSetting?.view
    );
    setAccessControlsViewPermission(
      EMPLOYER?.AccessControl?.view === undefined
        ? false
        : EMPLOYER?.AccessControl?.view
    );
    setUserControlsViewPermission(
      EMPLOYER?.Users?.view === undefined ? false : EMPLOYER?.Users?.view
    );
    setCompanyDetailViewPermission(
      EMPLOYER?.CompanyDetails?.view === undefined
        ? false
        : EMPLOYER?.CompanyDetails?.view
    );

    setBranchViewPermission(
      EMPLOYER?.Branches?.view === undefined ? false : EMPLOYER?.Branches?.view
    );
    setDepartmentViewPermission(
      EMPLOYER?.Department?.view === undefined
        ? false
        : EMPLOYER?.Department?.view
    );
    setDesignationViewPermission(
      EMPLOYER?.Designation?.view === undefined
        ? false
        : EMPLOYER?.Designation?.view
    );
    setModulesViewPermission(
      EMPLOYER?.Module?.view === undefined ? false : EMPLOYER?.Module?.view
    );
    setTaxViewPermission(
      EMPLOYER?.Taxation?.view === undefined ? false : EMPLOYER?.Taxation?.view
    );
    setCurrencyViewPermission(
      EMPLOYER?.Currencies?.view === undefined
        ? false
        : EMPLOYER?.Currencies?.view
    );
    setUsersViewPermission(
      EMPLOYER?.Users?.view === undefined ? false : EMPLOYER?.Users?.view
    );
    setEmailSettingsViewPermission(
      EMPLOYER?.EmailSetting?.view === undefined
        ? false
        : EMPLOYER?.EmailSetting?.view
    );
    setApperanceViewPermission(
      EMPLOYER?.Appearance?.view === undefined
        ? false
        : EMPLOYER?.Appearance?.view
    );
  }, [EMPLOYER]);

  return (
    <Box className="newSettingWrapper">
      <Grid container className="p-0">
        <Grid xs={12} spacing={3}>
          <h3 className="pageTitle mb-0 ">
            <Link
              onClick={() => {
                navigate(ALL_ROUTES.DASHBOARD);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            Settings
          </h3>
        </Grid>
        <Grid
          xs={12}
          sx={{ maxWidth: "1600px", margin: "auto" }}
          className="innerSection"
        >
          <>
            <Grid container spacing={3} justifyContent={"center"}>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>General</h4>
                  <div className="menulistBox">
                    <span className="general"></span>
                    <ul className="menulist">
                      {personalSettingsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=personalSettings&section=general`
                              );
                            }}
                          >
                            Personal Settings
                          </Link>
                        </li>
                      )}

                      {emailSettingsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=emailSettings&section=general`
                              );
                            }}
                          >
                            Email Settings
                          </Link>
                        </li>
                      )}
                      {appearanceViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=appearance&section=general`
                              );
                            }}
                          >
                            Appearance
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>Organisation</h4>
                  <div className="menulistBox">
                    <span className="organisation"></span>
                    <ul className="menulist">
                      {companyDetailViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=companyDetails&section=org`
                              );
                            }}
                          >
                            Company Details
                          </Link>
                        </li>
                      )}

                      {branchViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=branches&section=org`
                              );
                            }}
                          >
                            Branches/Locations
                          </Link>
                        </li>
                      )}

                      {departmentViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem "
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=departments&section=org`
                              );
                            }}
                          >
                            Departments
                          </Link>
                        </li>
                      )}
                      {designationViewPermission &&
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=designations&section=org`
                            );
                          }}
                        >
                          Designations
                        </Link>
                      </li>
}
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => {
                            navigate(
                              `${ALL_ROUTES.SETTING_MODULE_FORM}?page=salary&section=org`
                            );
                          }}
                        >
                          Salary Brackets
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>User And Controls</h4>
                  <div className="menulistBox">
                    <span className="userControl"></span>
                    <ul className="menulist">
                      {userControlsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem users"
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=users&section=userControl`
                              );
                            }}
                          >
                            Users
                          </Link>
                        </li>
                      )}
                      {accessControlsViewPermission && (
                        <li>
                          <Link
                            underline="none"
                            className="listItem access_control"
                            onClick={() => {
                              navigate(
                                `${ALL_ROUTES.SETTING_MODULE_FORM}?page=accessControl&section=userControl`
                              );
                            }}
                          >
                            Access Control
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Grid>
              {/* <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <h4>Data Admin</h4>
                  <div className="menulistBox">
                    <span className="dataAdmin"></span>
                    <ul className="menulist">

                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => { }}>
                          Activity Log
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem "
                          onClick={() => { }}>
                          Audit Log
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid lg={3} xs={6}>
                <div className="MenuBox">
                  <div className="menuBoxHeading">
                    <h4>Developer Space</h4>
                  </div>
                  <div className="menulistBox">
                    <span className="developerSpace"></span>
                    <ul className="menulist">
                      <li>
                        <Link
                          underline="none"
                          className="listItem integartions"
                          onClick={() => { }}>
                          Integrations
                        </Link>
                      </li>
                      <li>
                        <Link
                          underline="none"
                          className="listItem webhook"
                          onClick={() => { }}>
                          API & Webhooks
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Settings;
