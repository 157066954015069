import { Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import AddCategory from "../../../../../Components/Broker/ProductConfigurator/Common/Categories/AddCategory";
import EditCategory from "../../../../../Components/Broker/ProductConfigurator/Common/Categories/EditCategory";
import RemoveCategoryPopup from "../../../../../Components/Broker/ProductConfigurator/Common/Categories/RemoveCategoryPopup";
import AddExclusions from "../../../../../Components/Broker/ProductConfigurator/Common/Exclusions/AddExclusions";
import EditExculsions from "../../../../../Components/Broker/ProductConfigurator/Common/Exclusions/EditExclusions";
import RemoveExclusionPopup from "../../../../../Components/Broker/ProductConfigurator/Common/Exclusions/RemoveExclusionPopup";
import AddFeatures from "../../../../../Components/Broker/ProductConfigurator/Common/Features/AddFeatures";
import EditFeature from "../../../../../Components/Broker/ProductConfigurator/Common/Features/EditFeature";
import RemoveFeaturePopup from "../../../../../Components/Broker/ProductConfigurator/Common/Features/RemoveFeaturePopup";
import PoliciesWarningpopup from "../../../../../Components/Broker/WarningForPlicies/PoliciesWarningpopup";
import { E_BE_FILTER_CODE } from "../../../../../Services/Enum/E_UGTable";
import { GROUP_HEALTH_SERVICES } from "../../../../../Services/ProductConfigurator/GroupHealthServices";
import { RootState } from "../../../../../Store/Store";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { date_time_format } from "../../../../../Supporting files/HelpingFunction";
import SearchBox from "../../../../../Supporting files/SearchBarAnimation/SearchBox";
import UGTable from "../../../../../Supporting files/UGTable/UGTable";
import "../ProductConfig.scss";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { toast } from "react-toastify";
import { ALL_ROUTES } from "../../../../../Routes/all_routes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { bulkUploadSlice } from "../../../../../Store/Slice_Reducer/BulkUpload/BulkUploadSlice";

function GroupHealth({
  shortcutDisable,
  setShortcutDisable,
}: {
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [tableLimit, setTableLimit] = useState();
  const [tableSkip, setTableSkip] = useState();
  const [productTabsStatus, setProductTabsStatus] = useState("Categories");
  const [hideDataStatus, setHideDataStatus] = useState(false);
  const [removeCategory, setRemoveCategory] = useState(false);
  const [removeExclusion, setRemoveExclusion] = useState(false);
  const [removeFeature, setRemoveFeature] = useState(false);
  //Features Variables
  const [addNewFeatures, setAddNewFeatures] = useState(false);
  const [addExclusions, setAddExclusions] = useState(false);
  const [editExclusions, setEditExclusions] = useState(false);
  const [editFeature, setEditFeature] = useState(false);
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [searchBarFilter, setsearchBarFilter] = useState("");
  const [isFeatureAssigned, setIsFeatureAssigned] = useState(false);
  const [isExclusionAssigned, setIsExclusionAssigned] = useState(false);
  const [warningpopup, setwarningpopup] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState(true);
  // permission hendlling
  const { BROKER } = useAppSelector((state) => state.permissionSlice);
  const [AddPermission, setAddPermission] = useState(true);
  const [RemovePermission, setRemovePermission] = useState(true);
  const [bulkPermission, setBulkPermission] = useState(true);
  const [sendEditData, setSendEditData] = useState<{
    category: {
      name: string;
      description: string;
    };
    _id: string;
  }>();

  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setAddPermission(
        BROKER?.ProductConfigurator.add === undefined
          ? false
          : BROKER.ProductConfigurator.add
      );
      setRemovePermission(
        BROKER?.ProductConfigurator.remove === undefined
          ? false
          : BROKER.ProductConfigurator.remove
      );
      setBulkPermission(BROKER?.ProductConfigurator.upload === undefined
        ? false
        : BROKER.ProductConfigurator.upload)
    }, 2000);

  }, []);
  //send exculsion edit
  const [sendEditExclusionsData, setSendEditExclusionsData] = useState<{
    exclusion: {
      title: string;
      description: string;
    };
    _id: string;
  }>();
  // send individual feature
  const [sendFeatureData, setSendFetureData] = useState<any>({});
  const [rowsCategory, setRowsCategory] = useState<
    Array<{
      id: Number;
      sr_no: Number;
      description: string;
      name: string;
      action_btns: string;
      createdAt: string;
      deleteable: boolean;
    }>
  >([]);
  console.log("totalRecords", rowsCategory);
  const [rowFeature, setRowFeature] = useState<any[]>([]);
  const [columns, setColumns] = useState([
    {
      field: "name",
      headerName: "Category Name",
      width: 300,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "createdAt",
      minWidth: 200,
      check_status: true,
      headerName: "Created On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.createdAt, user_type_dateFormat, tz)
      },
      sort: "asc", // Explicitly set default sort order to ascending

    },
    {
      field: "updatedAt",
      minWidth: 200,
      check_status: true,
      headerName: "Updated On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.updatedAt, user_type_dateFormat, tz)
      },
      sort: "asc", // Explicitly set default sort order to ascending

    },
    {
      field: "_id",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              if (!BROKER?.ProductConfigurator?.edit) {
                toast.error("Edit permissions not allowed");
                return;
              }
              setSendEditData(row);
              setEditCategory(true);
              if (!shortcutDisable && setShortcutDisable) {
                setShortcutDisable(true);
              }
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  const [rowExclusion, setRowExclusion] = useState<
    Array<{
      id: Number;
      name: String;
      description: string;
      action_btns: string;
      deleteable: boolean;
    }>
  >([]);
  const [array_delete, setArray_Delete] = useState<Array<{ ids: number }>>([]);
  let selected_data: Array<{ id: number }> = [];
  //Features Data
  const [featureColumns, setFeatureColumns] = useState([
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 300,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span className="">
            {row.categoryName ? row.categoryName : "Category not avialable"}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Feature Name",
      width: 300,
      check_status: true,
    },
    {
      field: "fieldType",
      headerName: "Field Type",
      width: 200,
      check_status: true,
    },
    {
      field: "valueType",
      headerName: "Value Type",
      width: 200,
      check_status: true,
    },
    {
      field: "unit",
      headerName: "Unit",
      width: 150,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 400,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <span className="truncate_text">
            {row.description.length > 100
              ? row.description.substring(0, 100) + "..."
              : row.description}
          </span>
        );
      },
    },
    {
      field: "createdAt",
      minWidth: 200,
      check_status: true,
      headerName: "Created On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.createdAt, user_type_dateFormat, tz)
      },
    },
    {
      field: "updatedAt",
      minWidth: 200,
      check_status: true,
      headerName: "Updated On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.updatedAt, user_type_dateFormat, tz)
      },
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              if (!BROKER?.ProductConfigurator?.edit) {
                toast.error("Edit permissions not allowed");
                return;
              }
              setSendFetureData(row);
              setEditFeature(true);
              if (!shortcutDisable && setShortcutDisable) {
                setShortcutDisable(true);
              }
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);

  //Exclusions Data
  const [exclusionColumns, setExclusionColumns] = useState([
    {
      field: "name",
      headerName: "Exclusion Name/List",
      width: 300,
      check_status: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
      minWidth: 150,
      check_status: true,
    },
    {
      field: "createdAt",
      minWidth: 200,
      check_status: true,
      headerName: "Created On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.createdAt, user_type_dateFormat, tz)
      },
    },
    {
      field: "updatedAt",
      minWidth: 200,
      check_status: true,
      headerName: "Updated On",
      valueGetter: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return date_time_format(row.updatedAt, user_type_dateFormat, tz)
      },
    },
    {
      field: "_id",
      headerName: "Action",
      width: 150,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => {
              if (!BROKER?.ProductConfigurator?.edit) {
                toast.error("Edit permissions not allowed");
                return;
              }
              setSendEditExclusionsData(row);
              setEditExclusions(true);
              if (!shortcutDisable && setShortcutDisable) {
                setShortcutDisable(true);
              }
            }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  //Insurer Data
  const [insurerColumns, setInsurerColumns] = useState([
    {
      field: "insurer_name",
      headerName: "Insurer Name",
      flex: 3,
      minWidth: 340,
      check_status: true,
    },
    {
      field: "website",
      headerName: "Website",
      minWidth: 320,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link href={row.website} target="_blank" underline="none">
            {row.website}
          </Link>
        );
      },
    },
    {
      field: "network_hospitals",
      headerName: "Network Hospitals",
      minWidth: 160,
      check_status: true,
    },
    {
      field: "action_btns",
      headerName: "Action",
      width: 120,
      check_status: true,
      renderCell: (ValueFormatterParams: any) => {
        const { row } = ValueFormatterParams;
        return (
          <Link
            className="blueBtn edit ml-0"
            underline="none"
            onClick={() => { }}
          >
            Edit
          </Link>
        );
      },
    },
  ]);
  const insurerrows = [
    {
      id: 0,
      sr_no: "1",
      insurer_name: "Aviva Life Insurance Co. India Ltd.",
      website: "https://www.avivaindia.com/",
      network_hospitals: "5,000",
      action_btns: "Edit",
    },
    {
      id: 1,
      sr_no: "2",
      insurer_name:
        "Canara HSBC Oriental Bank of Commerce Life Insurance Company Limited",
      website: "https://www.canarahsbclife.com/index.html",
      network_hospitals: "10,000",
      action_btns: "Edit",
    },
    {
      id: 2,
      sr_no: "3",
      insurer_name: "Kotak Mahindra General Insurance Co Ltd",
      website: "https://www.kotakgeneralinsurance.com/",
      network_hospitals: "15,000",
      action_btns: "Edit",
    },
    {
      id: 3,
      sr_no: "4",
      insurer_name: "Royal Sundaram General Insurance Co Ltd",
      website: "https://www.royalsundaram.in/",
      network_hospitals: "8,000",
      action_btns: "Edit",
    },
  ];

  //update state
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === setAddNewFeatures) {
      setAddNewFeatures(value);
      setHideDataStatus(value);
    } else if (attrName === "table_data") {
      setRowsCategory(value);
    } else if (attrName === "searchBarfilter") {
      setsearchBarFilter(value);
    } else if (attrName === "data_feature") {
      setRowFeature(value);
    } else if (attrName === "selected_data") {
      selected_data = value;
      setArray_Delete(value);
    } else {
      attrName(value);
    }
  };
  // get category
  const getCatageoryetails = () => {
    setShowLoader(true);
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1000);
      }
      let arrdata: any = [];
      res.data.data.forEach((e: any) => {
        arrdata.push({
          id: e._id,
          name: e.category.name,
          description: e.category.description,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
        });
      });
      setRowsCategory(arrdata);
    };
    const onError = (err: any) => {
      setShowLoader(false);
      console.log("false", err);
    };
    GROUP_HEALTH_SERVICES.getCatageory(searchBarFilter, onSuccess, onError);
  };
  // get feature
  const getFeatureDetails = () => {
    setShowLoader(true);
    const onSuccess = (res: any) => {
      let arrdata: any = res.data.data.map((e: any) => {
        let obj: any = {};
        obj.id = e._id;
        obj.categoryId = e?.categoryId?._id;
        obj.categoryName = e?.categoryId?.category?.name;
        obj.name = e?.featureDetails?.name;
        obj.fieldType = e?.featureDetails?.fieldType;
        obj.valueType = e?.featureDetails?.valueType;
        obj.unit = e?.featureDetails?.unit;
        obj.customUnitType = e?.featureDetails?.customUnitType;
        obj.description = e?.featureDetails?.description;
        obj.createdAt = e?.createdAt;
        obj.updatedAt = e?.updatedAt;
        if (e?.featureDetails?.dropDownOptions) {
          obj.dropDownOptions = e?.featureDetails?.dropDownOptions;
        }
        return obj;

        // return {
        //   id: e._id,
        //   categoryId: e?.categoryId?._id,
        //   categoryName: e?.categoryId?.category?.name,
        //   dropDownOptions: e?.featureDetails?.dropDownOptions,
        //   name: e?.featureDetails?.name,
        //   fieldType: e?.featureDetails?.fieldType,
        //   valueType: e?.featureDetails?.valueType,
        //   unit: e?.featureDetails?.unit,
        //   customUnitType: e?.featureDetails?.customUnitType,
        //   description: e?.featureDetails?.description,
        //   createdAt: e?.createdAt,
        // };
      });
      setRowFeature(arrdata);
      setShowLoader(false);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    GROUP_HEALTH_SERVICES.getFeature(searchBarFilter, onSuccess, onError);
  };
  // get exclusions
  const getExclusionDetails = () => {
    setShowLoader(true);
    const onSuccess = (res: any) => {
      let arrdata: any = res.data.data.map((e: any) => {
        return {
          id: e._id,
          name: e.exclusion.title,
          description: e.exclusion.description,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
        };
      });

      setRowExclusion(arrdata);
      setShowLoader(false);
    };
    const onError = (err: any) => {
      setShowLoader(false);
      console.log("false", err);
    };
    GROUP_HEALTH_SERVICES.getExculsion(searchBarFilter, onSuccess, onError);
  };

  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const delayedApiCall = () => {
      if (
        productTabsStatus === "Categories" &&
        !removeCategory &&
        !addNewCategory &&
        !editCategory
      ) {
        getCatageoryetails();
      }
      if (
        productTabsStatus === "Features" &&
        !addNewFeatures &&
        !removeFeature &&
        !editFeature
      ) {
        getFeatureDetails();
      }
      if (
        productTabsStatus === "Exclusions" &&
        !removeExclusion &&
        !addExclusions &&
        !editExclusions
      ) {
        getExclusionDetails();
      }
    };

    timeoutId = setTimeout(delayedApiCall, 0);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    searchBarFilter,
    productTabsStatus,
    removeCategory,
    addNewCategory,
    editCategory,
    addNewFeatures,
    removeFeature,
    editFeature,
    removeExclusion,
    addExclusions,
    editExclusions,
  ]);

  useEffect(() => {
    let exclusionAssigned = false;

    if (productTabsStatus === "Exclusions") {
      array_delete?.forEach((item: any, index: number) => {
        const onSuccess = (res: any) => {
          if (!exclusionAssigned) {
            exclusionAssigned = res.data.data.isAttached;
            setIsExclusionAssigned(exclusionAssigned);
          }
        };

        const onError = (err: any) => {
          console.log("false", err);
        };

        if (!exclusionAssigned) {
          GROUP_HEALTH_SERVICES.isexclusionAssigned(item, onSuccess, onError);
        }
      });
    }

    let featureAssigned = false;
    if (productTabsStatus === "Features") {
      array_delete?.forEach((item: any, index: number) => {
        const onSuccess = (res: any) => {
          if (!featureAssigned) {
            featureAssigned = res.data.data.isAttached;
            setIsFeatureAssigned(featureAssigned);
          }
        };

        const onError = (err: any) => {
          console.log("false", err);
        };

        if (!featureAssigned) {
          GROUP_HEALTH_SERVICES.isfeatureAssigned(item, onSuccess, onError);
        }
      });
    }
  }, [array_delete]);

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h3 className="mb-0">Group Health Insurance</h3>
        </Grid>
        <>
          <Grid xs={8} style={{ display: "flex", alignItems: "center" }}>
            <div className="productTabs mr-3">
              <Button
                className={
                  productTabsStatus === "Categories"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Categories");
                }}
              >
                Categories
              </Button>
              <Button
                className={
                  productTabsStatus === "Features"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Features");
                }}
              >
                Features
              </Button>
              <Button
                className={
                  productTabsStatus === "Exclusions"
                    ? "defaultTab active"
                    : "defaultTab"
                }
                onClick={() => {
                  updateMasterState(setProductTabsStatus, "Exclusions");
                }}
              >
                Exclusions
              </Button>
            </div>
            <SearchBox value_update={updateMasterState} />
          </Grid>
          <Grid xs={4} className="text-right">
            {productTabsStatus === "Categories" ? (
              <>
                {bulkPermission &&
                  <Link
                    sx={{ height: "15px" }}
                    title="Bulk Upload"
                    underline="none"
                    className="blueBtn upload"
                    onClick={() => {
                      dispatch(
                        bulkUploadSlice.actions.setBulkUploadData(
                          bulkUploadSlice.getInitialState()
                        )
                      );
                      navigate({
                        pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
                        search: createSearchParams({
                          calledFrom: ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH,
                          subpage: "category",
                        }).toString(),
                      });
                    }}
                  >
                    Bulk Upload
                  </Link>
                }

                {RemovePermission && (
                  <Link
                    className="redBtn delete"
                    underline="none"
                    onClick={() => {
                      setRemoveCategory(true);
                    }}
                  >
                    Remove
                  </Link>
                )}
                {AddPermission && (
                  <Link
                    className="greenBtn addfile"
                    underline="none"
                    onClick={(e) => {
                      setAddNewCategory(true);
                      if (!shortcutDisable && setShortcutDisable) {
                        setShortcutDisable(true);
                      }
                    }}
                  >
                    Add New
                  </Link>
                )}
              </>
            ) : null}
            {productTabsStatus === "Features" ? (
              <>
                {bulkPermission &&
                  <Link
                    sx={{ height: "15px" }}
                    title="Bulk Upload"
                    underline="none"
                    className="blueBtn upload"
                    onClick={() => {
                      dispatch(
                        bulkUploadSlice.actions.setBulkUploadData(
                          bulkUploadSlice.getInitialState()
                        )
                      );
                      navigate({
                        pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
                        search: createSearchParams({
                          calledFrom: ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH,
                          subpage: "feature",
                        }).toString(),
                      });
                    }}
                  >
                    Bulk Upload
                  </Link>}
                {RemovePermission && (
                  <Link
                    className={
                      isFeatureAssigned ? `redBtnlight delete` : `redBtn delete`
                    }
                    underline="none"
                    onClick={() => {
                      isFeatureAssigned
                        ? setwarningpopup(true)
                        : setRemoveFeature(true);
                    }}
                  >
                    Remove
                  </Link>
                )}
                {AddPermission && (
                  <Link
                    className="greenBtn addfile"
                    underline="none"
                    onClick={() => {
                      updateMasterState(setAddNewFeatures, true);
                      if (!shortcutDisable && setShortcutDisable) {
                        setShortcutDisable(true);
                      }
                    }}
                  >
                    Add New
                  </Link>
                )}
              </>
            ) : null}
            {productTabsStatus === "Exclusions" ? (
              <>
                {bulkPermission &&
                  <Link
                    sx={{ height: "15px" }}
                    title="Bulk Upload"
                    underline="none"
                    className="blueBtn upload"
                    onClick={() => {
                      dispatch(
                        bulkUploadSlice.actions.setBulkUploadData(
                          bulkUploadSlice.getInitialState()
                        )
                      );
                      navigate({
                        pathname: ALL_ROUTES.BULK_UPLOAD_PAGE,
                        search: createSearchParams({
                          calledFrom: ALL_ROUTES.PRODUCT_CONFIG_GROUP_HEALTH,
                          subpage: "exclusion",
                        }).toString(),
                      });
                    }}
                  >
                    Bulk Upload
                  </Link>}
                {RemovePermission && (
                  <Link
                    className={
                      isExclusionAssigned
                        ? `redBtnlight delete`
                        : `redBtn delete`
                    }
                    underline="none"
                    onClick={() => {
                      isExclusionAssigned
                        ? setwarningpopup(true)
                        : setRemoveExclusion(true);
                    }}
                  >
                    Remove
                  </Link>
                )}

                {AddPermission && (
                  <Link
                    className="greenBtn addfile"
                    underline="none"
                    onClick={() => {
                      updateMasterState(setAddExclusions, true);
                      if (!shortcutDisable && setShortcutDisable) {
                        setShortcutDisable(true);
                      }
                    }}
                  >
                    Add New
                  </Link>
                )}
              </>
            ) : null}
          </Grid>
          <Grid xs={12}>
            {productTabsStatus === "Categories" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={columns}
                  data={rowsCategory}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                  totalRecords={rowsCategory.length}
                  BE_filter_code={E_BE_FILTER_CODE.GROUP_HEALTH_CATEGORIES}
                  refetch_data={getCatageoryetails}
                  setTableLimit={setTableLimit}
                  setTableSkip={setTableSkip}
                  paginationMode="client"
                />
              </div>
            ) : null}
            {productTabsStatus === "Features" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={featureColumns}
                  data={rowFeature}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                  BE_filter_code={E_BE_FILTER_CODE.GROUP_HEALTH_FEATURES}
                  totalRecords={rowFeature.length}
                  refetch_data={getFeatureDetails}
                  setTableLimit={setTableLimit}
                  setTableSkip={setTableSkip}
                  paginationMode="client"
                />
              </div>
            ) : null}
            {productTabsStatus === "Exclusions" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={exclusionColumns}
                  data={rowExclusion}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                  BE_filter_code={E_BE_FILTER_CODE.GROUP_HEALTH_EXCLUSIONS}
                  refetch_data={getExclusionDetails}
                  setTableLimit={setTableLimit}
                  setTableSkip={setTableSkip}
                  paginationMode="client"
                />
              </div>
            ) : null}
            {productTabsStatus === "Insurers" ? (
              <div style={{ height: 400, width: "100%" }}>
                <UGTable
                  header_data={insurerColumns}
                  data={insurerrows}
                  value_update={updateMasterState}
                  attrName={"UGTable"}
                />
              </div>
            ) : null}
          </Grid>
        </>
        <AddCategory
          attrName={setAddNewCategory}
          value_update={updateMasterState}
          open_status={addNewCategory}
          api_url={URL_CONSTANTS.create_group_health_catageory}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
        />
        <EditCategory
          attrName={setEditCategory}
          value_update={updateMasterState}
          open_status={editCategory}
          table_data={
            sendEditData === undefined
              ? {
                category: {
                  name: "",
                  description: "",
                },
              }
              : sendEditData
          }
          api_url={URL_CONSTANTS.update_group_health_catageory}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
        />
        <RemoveCategoryPopup
          open_status={removeCategory}
          attrName={setRemoveCategory}
          value_update={updateMasterState}
          ids={array_delete}
          get_api_url={URL_CONSTANTS.delete_group_health_catageory}
        />

        <AddExclusions
          open_status={addExclusions}
          attrName={setAddExclusions}
          value_update={updateMasterState}
          api_url={URL_CONSTANTS.create_group_health_exclusion}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
        />
        <PoliciesWarningpopup
          popupStatus={warningpopup}
          setPopupStatus={setwarningpopup}
          title={`One of the ${productTabsStatus} is assigned to the policy, so you will not be able to delete that ${productTabsStatus}`}
        />
        <EditExculsions
          open_status={editExclusions}
          attrName={setEditExclusions}
          value_update={updateMasterState}
          table_data={
            sendEditExclusionsData === undefined
              ? {
                exclusion: {
                  title: "",
                  description: "",
                },
              }
              : sendEditExclusionsData
          }
          api_url={URL_CONSTANTS.update_group_health_exclusions}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
        />
        <RemoveExclusionPopup
          open_status={removeExclusion}
          attrName={setRemoveExclusion}
          value_update={updateMasterState}
          ids={array_delete}
          get_api_url={URL_CONSTANTS.delete_group_health_exclusions}
        />
        <AddFeatures
          open_status={addNewFeatures}
          attrName={setAddNewFeatures}
          value_update={updateMasterState}
          api_url={URL_CONSTANTS.create_group_health_feature}
          get_category_api_url={URL_CONSTANTS.get_group_health_catageory}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
        />
        <EditFeature
          open_status={editFeature}
          attrName={setEditFeature}
          value_update={updateMasterState}
          table_data={
            sendFeatureData === undefined
              ? {
                categoryId: "",
                featureDetails: {
                  name: "",
                  fieldType: "",
                  valueType: "",
                  unit: "",
                  description: "",
                },
              }
              : sendFeatureData
          }
          api_url={URL_CONSTANTS.update_group_health_feature}
          get_category_api_url={URL_CONSTANTS.get_group_health_catageory}
          shortcutDisable={shortcutDisable}
          setShortcutDisable={setShortcutDisable}
        />
        <RemoveFeaturePopup
          open_status={removeFeature}
          attrName={setRemoveFeature}
          value_update={updateMasterState}
          ids={array_delete}
          get_api_url={URL_CONSTANTS.delete_group_health_feature}
        />
      </Grid>
    </>
  );
}

export default GroupHealth;
