import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, TextareaAutosize } from "@mui/material";
import SlidingPanel from "react-sliding-side-panel";
import { GridCloseIcon } from "@mui/x-data-grid";
import SearchDropdown from "../../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import { AddQuoteInsurerDTO } from "../../../../../Services/DTO/QuotesDTO";
import { POLICIES_SERVICES } from "../../../../../Services/Policies/PoliciesServices";
import { QUOTES_SERVICES } from "../../../../../Services/Quotes/QuotesServices";
import { validateEmail } from "../../../../../Supporting files/HelpingFunction";
import { toast } from "react-toastify";

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  overviewData?: any;
  quoteId: string;
  refetchData: any;
  insurers:any;
}

const AddQuoteInsurerForm: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  overviewData,
  quoteId,
  refetchData,
  insurers,
}) => {
  const [warn, setWarn] = useState<any>({
    insurer: false,
    location: false,
    email: false,
  });
  const [data, setData] = useState<{
    email: string;
    selectedInsurer: { id: string; label: string };
    name: string;
    office: string;
    quoteId: string;
  }>({
    email: "",
    selectedInsurer: {
      label: "",
      id: "",
    },
    name: "",
    office: "",
    quoteId: "",
  });
  const [insurerList, setInsurerList] = useState<any>([]);
  const [insurerAddressList, setInsurerAddressList] = useState<any>([]);

  useEffect(() => {
    insurance_name_dropdownlist(
      overviewData?.productType?.name === "Group Term" ? "LIFE" : "GENERAL"
    );
  }, [overviewData?.productType?.name]);

  const insurance_name_dropdownlist = (type: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ id: data.id, label: data.label });
      });
      setInsurerList(newArr);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.insurance_name_dropdownlist(onSuccess, onError, type);
  };

  // Get insurance_address_dropdownlist data
  const insurance_address_dropdownlist = (id: any) => {
    const onSuccess = (res: any) => {
      let arr: any = res.data.data;
      let newArr: any = [];
      arr.map((data: any) => {
        newArr.push({ key: data.label, value: data.label });
      });
      setInsurerAddressList(newArr);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.insurance_address_dropdownlist(onSuccess, onError, id);
  };

  const onAdd = () => {
    let hasError = false;
    let warnData = { ...warn };
    
    // Update `warnData` properties based on conditions
    warnData.insurer = !data.selectedInsurer?.id;
    warnData.location = !data.office;
    warnData.email = !validateEmail(data.email);
    
    // Update `warn` state
    setWarn(warnData);
    
    // Check for any errors in `warnData`
    Object.keys(warnData).forEach((item) => {
      if (warnData[item]) {
        hasError = true;
      }
    });
    if (data.email) {
      const isDuplicateEmail = insurers.some((insurer: any) => insurer.email.toLowerCase() === data.email.toLowerCase());
      if(isDuplicateEmail){
        toast.error("Duplicate Insurer Email found")
        hasError = true
      }
    }
    const dto: AddQuoteInsurerDTO = {
      email: data.email,
      insurerId: data.selectedInsurer.id,
      name: data.selectedInsurer.label,
      office: data.office,
      quoteId,
    };

    const onSuccess = (res: any) => {
      refetchData(quoteId);
      attrName(false);
      setData({
        email: "",
        name: "",
        office: "",
        quoteId: "",
        selectedInsurer: { id: "", label: "" },
      });
      setWarn({
        insurer: false,
        location: false,
        email: false,
      });
    };

    const onError = (err: any) => { };
    if (!hasError) {
      QUOTES_SERVICES.add_insurer(onSuccess, onError, dto);
    }

  };

  return (
    <SlidingPanel type={"right"} isOpen={open_status} size={75}>
      <div>
        <Grid container spacing={3} alignItems="center">
          <Grid xs={10}>
            <h4 className="mb-0 mt-3">Add Insurer</h4>
          </Grid>
          <Grid xs={2} textAlign="right" className="mt-4">
            <GridCloseIcon
              onClick={() => {
                value_update(attrName, false);
                setData({
                  email: "",
                  name: "",
                  office: "",
                  quoteId: "",
                  selectedInsurer: { id: "", label: "" },
                });
                setWarn({
                  insurer: false,
                  location: false,
                  email: false,
                });
              }}
            />
          </Grid>
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <div className="scrollable_area mt-6">
          <Grid container spacing={3} justifyContent="center" className="mb-3">
            <Grid xs={12}>
              <SearchDropdown
                class_name="inputField"
                title="Select Insurer"
                value={data.selectedInsurer.label}
                attrName={[]}
                value_update={(attrName: any, value: any) => {
                  setWarn({ ...warn, insurer: value ? false : true });
                  setData({ ...data, selectedInsurer: value });
                  insurance_address_dropdownlist(value.id);
                }}
                data={insurerList}
                warn_status={warn.insurer}
                error_message="Select insurer"
              />
            </Grid>
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Location"
                value={data.office}
                attrName={[]}
                value_update={(attrName: any, value: any) => {
                  setWarn({ ...warn, location: value ? false : true });
                  setData({ ...data, office: value });
                }}
                dropdown_data={insurerAddressList}
                warn_status={warn.location}
                error_messg="Select Location"
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="E-mail"
                validation_type="email"
                value={data.email}
                attrName={[]}
                value_update={(attrName: any, value: any) => {
                  setWarn({ ...warn, email: value ? false : true });
                  setData({ ...data, email: value });
                }}
                warn_status={warn.email}
                error_messg={data.email ? "Enter valid E-mail" : "Enter E-mail"}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid xs={12} className="ctaBtn mt-4">
            <Button
              variant="contained"
              className="submitBtn fixedbtn"
              onClick={() => {
                onAdd();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </SlidingPanel>
  );
};

export default AddQuoteInsurerForm;
