import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import tickicon from "../../../../../src/images/deletePopup_tickicon.svg";
import deleteimage from "../../../../../src/images/deletepopup_deleteicon.svg";
import { locationDTO } from "../../../../Services/DTO/ProductConfigurator/EditInsurerDTO";
import { ADD_NEW_INSURER } from "../../../../Services/ProductConfigurator/AddNewInsurer";
import {
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validatePincode,
} from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../Supporting files/SearchDropdown/SearchDropdown";
import "../../../../formLayout.scss";

interface Props {
  id?: any;
  length?: any;
  parentType?: any;
  value_update?: Function;
  data?: any;
  deleteurl?: any;
  parentComponentname?: any;
  refetchData?: any;
  disabled?: boolean;
}

const LocationTableView: React.FC<Props> = ({
  id,
  length,
  parentType,
  value_update,
  data,
  deleteurl,
  parentComponentname,
  refetchData,
  disabled,
}) => {
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const clientId = params.get("id");
  const [openLocation, setopenLocation] = useState(false);
  const [getContactListData, setGetContactListData] = useState<Array<any>>([]);
  const [locationPopupDetail, setLocationPopupDetail] = useState<locationDTO>({
    id: Math.random() + 1,
    name: "",
    type: "",
    street: "",
    area: "",
    city: "",
    state: "",
    zip: null,
    country: "",
    spocId: "",
    spocName: "",
    warnName: false,
    warnType: false,
    warnAddress: false,
    warnArea: false,
    warnCity: false,
    warnState: false,
    warnZip: false,
    warnCountry: false,
    warnContactId: false,
    warnStreet: false,
  });
  const [countryDataPopup, setCountryDataPopup] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateDataPopup, setStateDataPopup] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityDataPopup, setCityDataPopup] = useState<
    Array<{ label: string; id: string }>
  >([]);
  const [individualEditKey, setIndividualEditKey] = useState<{
    key: string;
    value: locationDTO;
  }>();
  const [editDetailIndex, setEditDetailIndex] = useState<number | null>(null);
  const [locationPopupEdit, setLocationPopupEdit] = useState<any>({
    id: Math.random() + 1,
    name: "",
    type: "",
    street: "",
    area: "",
    city: "",
    state: "",
    zip: null,
    country: "",
    spocId: "",
    spocName: "",
    warnLocationName: false,
    warnType: false,
    warnStreet: false,
    warnArea: false,
    warnCity: false,
    warnState: false,
    warnZip: false,
    warnCountry: false,
    warnContactId: false,
  });
  let [openLocationEdit, setopenLocationEdit] = useState<boolean>(false);
  let [locationData, setLocationData] = useState<Array<any>>([]);
  const [deleteStatusStep, setDeleteStatusStep] = useState(1);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [countryId, setCountryId] = useState("");
  const [refresh, setrefresh] = useState(false);
  const [locationId, setlocationId] = useState("");
  let timeoutIdData: any;
console.log("locationPopupEdit",locationPopupEdit)
  // get detail of insurer
  useEffect(() => {
    getContactList();

    // insurerDetail();
    if (timeoutIdData) {
      clearTimeout(timeoutIdData);
    }

    const delayedApiCall = () => {
      if (openLocationEdit || openLocation) {

        get_country_list((cb: any) => {
          setCountryDataPopup(cb);
        });
      }

    }
    timeoutIdData = setTimeout(delayedApiCall, 1000);

    return () => {
      if (timeoutIdData) {
        clearTimeout(timeoutIdData);
      }
    };

  }, [refresh, openLocation, openLocationEdit, length]);

  let timeoutIdData1: any;

  let checkCountry: any
  useEffect(() => {
    if (timeoutIdData1) {
      clearTimeout(timeoutIdData1);
    }

    const delayedApiCall = () => {
      if (data[0]?.country && checkCountry !== data[0]?.country) {
        checkCountry = data[0]?.country
        get_state_list(data[0]?.country, (cb: any) => {
          setStateDataPopup(cb);
        });
      }
      if (data[0]?.country && data[0]?.state) {
        get_city_list_via_name(data[0]?.country, data[0]?.state, (cb: any) => {
          setCityDataPopup(cb);
        });
      }
    }
    timeoutIdData1 = setTimeout(delayedApiCall, 1000);

    return () => {
      if (timeoutIdData1) {
        clearTimeout(timeoutIdData1);
      }
    };

  }, [openLocationEdit]);

  // contact get id
  const getContactList = () => {
    const onSuccess = (res: any) => {
      setGetContactListData(res.data.data);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ADD_NEW_INSURER.getSpocList(onSuccess, onError);
  };

  useEffect(() => {
    let dataArr: any = [];
    data?.forEach((e: any) => {
      dataArr.push({
        ...e,
        id: e?._id,
      });
    });

    setLocationData(dataArr);
  }, [data]);

  // used in client detail view
  const locationpopup = () => {
    if (disableSave) return;
    let data: any = locationPopupDetail;
    // data.warnArea = locationPopupDetail.area.length === 0 ? true : false;
    data.warnZip = !validatePincode(locationPopupDetail.zip?.toString() ?? "");
    data.warnName = locationPopupDetail.name.length < 3 ? true : false;
    data.warnType = locationPopupDetail.type.length === 0 ? true : false;
    data.warnAddress = locationPopupDetail.street.length === 0 ? true : false;
    data.warnCity = locationPopupDetail.city.length === 0 ? true : false;
    data.warnState = locationPopupDetail.state.length === 0 ? true : false;
    data.warnCountry = locationPopupDetail.country.length === 0 ? true : false;
    data.warnContactId = locationPopupDetail.spocId.length === 0 ? true : false;
    data.warnStreet = locationPopupDetail.street.length === 0 ? true : false;

    setLocationPopupDetail({ ...data });
    let locationDataPopup: any[] = [...locationData];
    if (
      locationPopupDetail.warnName === false &&
      locationPopupDetail.warnZip === false &&
      // locationPopupDetail.warnArea === false &&
      locationPopupDetail.warnType === false &&
      locationPopupDetail.warnAddress === false &&
      locationPopupDetail.warnCity === false &&
      locationPopupDetail.warnState === false &&
      locationPopupDetail.warnCountry === false &&
      locationPopupDetail.warnContactId === false
    ) {
      locationDataPopup.push({
        id: Math.random() + "id",
        name: locationPopupDetail.name?.trim(),
        type: locationPopupDetail.type?.label?.trim(),
        street: locationPopupDetail?.street?.trim(),
        area: locationPopupDetail.area,
        city: locationPopupDetail.city.label,
        state: locationPopupDetail.state.label,
        zip: locationPopupDetail.zip,
        country: locationPopupDetail.country.label,
        spocId: locationPopupDetail.spocId.label || locationPopupDetail.spocId,
        warnLocationName: false,
        warnType: false,
        warnStreet: false,
        warnArea: false,
        warnCity: false,
        warnState: false,
        warnZip: false,
        warnCountry: false,
        warnContactId: false,
      });
      const onSuccess = (res: any) => {
        setopenLocation(false);
        setLocationPopupDetail({
          name: "",
          type: "",
          street: "",
          area: "",
          city: "",
          state: "",
          zip: null,
          country: "",
          spocId: "",
          warnName: false,
          warnType: false,
          warnAddress: false,
          warnArea: false,
          warnCity: false,
          warnState: false,
          warnZip: false,
          warnCountry: false,
          warnContactId: false,
          warnStreet: false,
        });
        if (refetchData) {
          refetchData();
        }
        if (value_update) {
          value_update("location_updated", true);
        }
        toast.success(" Update successfully");
        setDisableSave(false);
      };
      const onError = (err: any) => {
        setDisableSave(false);
        toast.error(err?.response?.data?.errors[0]?.message);
      };
      let newdata: any;
      if (individualEditKey && individualEditKey.value) {
        for (const key in individualEditKey && individualEditKey.value) {
          newdata = individualEditKey;
          if (key.startsWith("warn")) {
            delete newdata.value[key];
          } else {
            newdata.value[key] =
              typeof newdata.value[key] === "string"
                ? newdata.value[key].trim()
                : newdata.value[key];
          }
        }
      }
      delete newdata?.value?.id;
      setDisableSave(true);
      // dynamic url from the props the function name should be same
      parentType(newdata, onSuccess, {}, onError, id);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainstate = attrName[0];
    let key = attrName[1];
    if (mainstate === "locationPopupDetail") {
      const updatedDetail: any = { ...locationPopupDetail };
      if (key === "country") {
        get_state_list(value, (cb: any) => {
          setStateDataPopup(cb);
        });

        setCountryId(value);
        updatedDetail[key] = value.label;
        updatedDetail.state = "";
        updatedDetail.city = "";
        updatedDetail[`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
          false;
      } else if (key === "state") {
        get_city_list_via_name(countryId, value, (cb: any) => {
          setCityDataPopup(cb);
        });
        updatedDetail[key] = value.label;
        updatedDetail.city = "";
        updatedDetail[`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
          false;
      } else if (key === "city") {
        updatedDetail[key] = value.label;
        updatedDetail[`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
          false;
      } else if (key === "spocId") {
        updatedDetail[key] = value.id;
        updatedDetail["spocName"] = value.label;
        updatedDetail[`warnContactId`] = false;
      } else {
        updatedDetail[key] = value;
        updatedDetail[`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
          false;
      } // locationPopupDetail.warnContactId

      setLocationPopupDetail(updatedDetail);
      setIndividualEditKey({
        key: "location." + length,
        value: {
          ...updatedDetail,
          spocId: key === "spocId" ? value?.id : updatedDetail?.spocId,
        },
      });
    } else if (mainstate === "locationPopupEdit") {
      if (key === "country") {
        get_state_list(value, (cb: any) => {
          setStateDataPopup(cb);
        });
        setCountryId(value);
        setLocationPopupEdit({
          ...locationPopupEdit,
          [key]: value.label,
          city: "",
          state: "",
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      } else if (key === "state") {
        get_city_list_via_name(
          countryId ? countryId : data[0].country,
          value ? value : data[0].state,
          (cb: any) => {
            setCityDataPopup(cb);
          }
        );
        setLocationPopupEdit({
          ...locationPopupEdit,
          [key]: value.label,
          city: "",
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      } else if (key === "city") {
        setLocationPopupEdit({
          ...locationPopupEdit,
          [key]: value.label,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      } else if (key === "type") {
        setLocationPopupEdit({
          ...locationPopupEdit,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      } else if (key === "spocId") {
        let data = { ...locationPopupEdit };
        data = {
          ...data,
          [key]: value.id,
        };
        data = {
          ...data,
          ["spocName"]: value.label,
        };
        data = {
          ...data,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        };

        setLocationPopupEdit(data);
      } else {
        setLocationPopupEdit({
          ...locationPopupEdit,
          [key]: value,
          [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
        });
      }
      setIndividualEditKey({
        key: "location." + editDetailIndex,
        value: locationPopupEdit,
      });
    } else if (attrName === setopenLocationEdit) {
      attrName(value);
    }
  };
  console.log("locationData", locationData)
  const locationPopupEditDetail = (index: number, data: any) => {
    setopenLocationEdit(true);
    setEditDetailIndex(data.id);
    const spocname = getContactListData?.filter(
      (item) => item.id === data.spocId
    )[0]?.label;
    setLocationPopupEdit({ ...data, spocName: spocname });
  };

  const locationpopupedit = () => {
    let data: any = { ...locationPopupEdit };
    data.warnLocationName = locationPopupEdit.name ? false : true;
    data.warnType = locationPopupEdit.type ? false : true;
    data.warnStreet = locationPopupEdit.street ? false : true;
    data.warnCity = locationPopupEdit.city ? false : true;
    data.warnState = locationPopupEdit.state ? false : true;
    data.warnCountry = locationPopupEdit.country ? false : true;
    data.warnContactId =
      locationPopupEdit.spocId || locationPopupEdit.spocName ? false : true;
    setLocationPopupEdit(data);
    if (
      data.warnLocationName === false &&
      data.warnType === false &&
      data.warnStreet === false &&
      data.warnCity === false &&
      data.warnState === false &&
      data.warnCountry === false &&
      data.warnContactId === false
    ) {
      if (locationData === undefined) {
        locationData = [];
      } else {
        if (editDetailIndex === null) {
          // dataArray.push(-1);
        } else {
          let foundIndex = locationData.findIndex((e) => {
            return e.id === editDetailIndex;
          });

          const onSuccess = (res: any) => {
            setopenLocationEdit(false);
            toast.success(" Update successfully");
            if (refetchData) {
              refetchData();
            }
            if (value_update) {
              value_update("location_updated", true);
            }
          };

          const onError = (err: any) => {
            console.log("err", err);
          };

          for (const key in locationPopupEdit) {
            if (key.startsWith("warn")) {
              delete locationPopupEdit[key];
            } else {
              locationPopupEdit[key]?.trim();
            }
          }
          let matchName = locationData.filter((i) => i._id !== locationPopupEdit.id).some((data) => data.name.toLowerCase().trim() === locationPopupEdit.name.toLowerCase().trim())
          if (matchName) return toast.error("Location name already Exist")
          delete locationPopupEdit.id;
          let newdata: any;
          for (let key in locationPopupEdit) {
            locationPopupEdit[key] = locationPopupEdit[key]?.trim();
            //newdata[key] = locationPopupEdit[key];
          }


          // dynamic url from the props the function name should be same
          parentType(
            {
              key: "location." + foundIndex,
              value: locationPopupEdit,
              type: "edit"
            },
            onSuccess,
            {},
            onError,
            id
          );
        }
      }
    }
    setLocationPopupDetail(locationPopupDetail);
  };

  //delete location
  const delete_location_api = () => {
    setDeleteStatusStep(2);

    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };
    const onError = (err: any) => {
      console.log("false", err);
    };
    deleteurl(onSuccess, onError, clientId, locationId);
  };

  useEffect(() => {
    if (deleteStatusStep === 2) {
      if (value_update) {
        value_update("location_updated", true);
      }
      if (refetchData) {
        refetchData();
      }
    }
  }, [deleteStatusStep]);

  return (
    <Box className="detailSection mb-5">
      <div className="sectionTitle mb-3">
        <h4>Office Locations</h4>

        <div className="actionBtns">
          <Link
            className="blueBtn addfile"
            onClick={() => {
              if (disabled) {
                return toast.error("Edit Permissions Not Allowed");
              }
              setopenLocation(true);
            }}
            sx={{ textDecoration: "none", cursor: "pointer" }}
          >
            Add Office Location
          </Link>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="table">
            <div className="attatchment_table" style={{ overflow: "hidden" }}>
              <ul className="heading">
                <li>Name</li>
                <li>Type</li>
                <li>Address</li>
                <li>Spoc Name</li>
                <li>Actions</li>
              </ul>

              {locationData?.map((data, index) => (
                <ul className="subheading">
                  <li>{data.name}</li>
                  <li>{data.type}</li>
                  <li>
                    {data?.area},{data?.street},{data?.city},{data?.state},
                    {data?.country}-{data?.zip}
                  </li>
                  <li>{data?.spocName}</li>

                  <li
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Link
                      className="blueBtn edit ml-0"
                      onClick={() => {
                        if (disabled) {
                          return toast.error("Edit Permissions Not Allowed");
                        }
                        locationPopupEditDetail(index, data);
                      }}
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    ></Link>

                    <Link
                      className="redBtn delete"
                      onClick={() => {
                        if (disabled) {
                          return toast.error("Edit Permissions Not Allowed");
                        }
                        setlocationId(data._id);
                        setDeleteStatus(true);
                      }}
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    ></Link>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>

      <SlidingPanel
        type={"right"}
        isOpen={openLocation}
        size={75}
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-4 ml-0">Add Office Location</h4>
            </Grid>
            <Grid
              xs={2}
              className="pt-7"
              textAlign={"right"}
              onClick={() => {
                setopenLocation(false);
                setLocationPopupDetail({
                  name: "",
                  type: "",
                  street: "",
                  area: "",
                  city: "",
                  state: "",
                  zip: null,
                  country: "",
                  spocId: "",
                  warnName: false,
                  warnType: false,
                  warnAddress: false,
                  warnArea: false,
                  warnCity: false,
                  warnState: false,
                  warnZip: false,
                  warnCountry: false,
                  warnContactId: false,
                });
                setDisableSave(false);
              }}
            >
              <GridCloseIcon />
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div className="locations_section">
              <Grid container spacing={3}>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Location Name"
                    value={locationPopupDetail.name}
                    attrName={["locationPopupDetail", "name"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupDetail.warnName}
                    error_messg={
                      locationPopupDetail.name.length === 0
                        ? "Select Location Name"
                        : "Location name must have 3 charactors"
                    }
                    required
                  />
                </Grid>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Type"
                    value={locationPopupDetail.type}
                    attrName={["locationPopupDetail", "type"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupDetail.warnType}
                    error_messg="Select type"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Building/Street Name"
                    value={locationPopupDetail.street}
                    attrName={["locationPopupDetail", "street"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupDetail.warnStreet}
                    error_messg="Enter Building/Street Name"
                    required
                  />
                </Grid>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Area Name"
                    value={locationPopupDetail.area}
                    attrName={["locationPopupDetail", "area"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupDetail.warnArea}
                    error_messg="Enter Area Name"

                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} md={4} lg={6} xl={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Country"
                    value={locationPopupDetail.country}
                    attrName={["locationPopupDetail", "country"]}
                    value_update={updateMasterState}
                    data={countryDataPopup}
                    warn_status={locationPopupDetail.warnCountry}
                    error_message="Select Country"
                    required
                  />
                </Grid>
                <Grid sm={6} md={4} lg={6} xl={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="State"
                    value={locationPopupDetail.state}
                    attrName={["locationPopupDetail", "state"]}
                    value_update={updateMasterState}
                    data={stateDataPopup}
                    warn_status={locationPopupDetail.warnState}
                    error_message="Select State"
                    required
                  />
                </Grid>
                <Grid sm={6} md={4} lg={6} xl={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="City"
                    value={locationPopupDetail.city}
                    attrName={["locationPopupDetail", "city"]}
                    value_update={updateMasterState}
                    data={cityDataPopup}
                    warn_status={locationPopupDetail.warnCity}
                    error_message="Select City"
                    required
                  />
                </Grid>
                <Grid sm={6} md={4} lg={6} xl={6}>
                  <RKTextField
                    validation_type="numeric"
                    class_name="inputField"
                    charcterlimit={6}
                    title="Pincode"
                    value={locationPopupDetail.zip}
                    attrName={["locationPopupDetail", "zip"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupDetail.warnZip}
                    error_messg="Enter pincode"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={12} className="mb-0 pb-0">
                  <h6 className="popup-subheading">Spoc Details</h6>
                </Grid>

                <Grid xs={12}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Select Contact"
                    value={locationPopupDetail.spocName}
                    attrName={["locationPopupDetail", "spocId"]}
                    value_update={updateMasterState}
                    data={getContactListData}
                    warn_status={locationPopupDetail.warnContactId}
                    error_message="Select contact"
                    required
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="footer_sec">
              <Button
                variant="contained"
                className="save_btn"
                onClick={locationpopup}
                disabled={disableSave}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>
      <SlidingPanel
        type={"right"}
        isOpen={openLocationEdit}
        size={75} >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-4 ml-0">Edit Location Details</h4>
            </Grid>
            <Grid
              xs={2}
              className="pt-7"
              textAlign={"right"}
              onClick={() => {
                setLocationPopupEdit({
                  name: "",
                  type: "",
                  street: "",
                  area: "",
                  city: "",
                  state: "",
                  zip: null,
                  country: "",
                  spocId: "",
                  warnName: false,
                  warnType: false,
                  warnAddress: false,
                  warnArea: false,
                  warnCity: false,
                  warnState: false,
                  warnZip: false,
                  warnCountry: false,
                  warnContactId: false,
                });
                setopenLocationEdit(false);
              }}
            >
              <GridCloseIcon />
            </Grid>
            <Grid xs={12} className="mb-4">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div className="locations_section">
              <Grid container spacing={3}>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Location Name"
                    value={locationPopupEdit.name}
                    attrName={["locationPopupEdit", "name"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupEdit.warnLocationName}
                    error_messg="Select Location Name"
                    required
                  />
                </Grid>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Type"
                    value={locationPopupEdit.type}
                    attrName={["locationPopupEdit", "type"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupEdit.warnType}
                    error_messg="Select type"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Building/Street Name"
                    value={locationPopupEdit.street}
                    attrName={["locationPopupEdit", "street"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupEdit.warnStreet}
                    error_messg="Enter Building/Street Name"
                    required
                  />
                </Grid>
                <Grid xs={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Area Name"
                    value={locationPopupEdit.area}
                    attrName={["locationPopupEdit", "area"]}
                    value_update={updateMasterState}
                    warn_status={locationPopupEdit.warnArea}
                    error_messg="enter Area Name"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid sm={6} md={6} lg={6} xl={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Country"
                    value={locationPopupEdit.country}
                    attrName={["locationPopupEdit", "country"]}
                    value_update={updateMasterState}
                    data={countryDataPopup}
                    warn_status={locationPopupEdit.warnCountry}
                    error_message="Select Country"
                    required
                  />
                </Grid>
                <Grid sm={6} md={6} lg={6} xl={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="State"
                    value={locationPopupEdit.state}
                    attrName={["locationPopupEdit", "state"]}
                    value_update={updateMasterState}
                    data={stateDataPopup}
                    warn_status={locationPopupEdit.warnState}
                    error_message="Select State"
                    required
                  />
                </Grid>
                <Grid sm={6} md={6} lg={6} xl={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="City"
                    value={locationPopupEdit.city}
                    attrName={["locationPopupEdit", "city"]}
                    value_update={updateMasterState}
                    data={cityDataPopup}
                    warn_status={locationPopupEdit.warnCity}
                    error_message="Select City"
                    required
                  />
                </Grid>
                <Grid sm={6} md={6} lg={6} xl={6}>
                  <RKTextField
                    validation_type="numeric"
                    class_name="inputField"
                    title="Pincode"
                    value={locationPopupEdit.zip}
                    attrName={["locationPopupEdit", "zip"]}
                    value_update={updateMasterState}
                    warn_status={false}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={12} className="mb-0 pb-0">
                  <h6 className="popup-subheading">Spoc Details</h6>
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Select Contact"
                    value={
                      locationPopupEdit.spocName || locationPopupEdit.spocId
                    }
                    attrName={["locationPopupEdit", "spocId"]}
                    value_update={updateMasterState}
                    data={getContactListData}
                    warn_status={locationPopupEdit.warnContactId}
                    error_message="Please Select"
                    required
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid xs={12} className="footer_sec">
              <Button className="save_btn" onClick={locationpopupedit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </SlidingPanel>

      {/* pop up */}
      <Modal open={deleteStatus} className="modalWrapper">
        <>
          {deleteStatusStep === 1 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setDeleteStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={deleteimage} alt="" className="img" />
                  </div>

                  <h5 className="popup-heading">
                    Are you sure you want
                    <br /> to delete this ?
                  </h5>
                </Grid>

                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={delete_location_api}
                  >
                    Yes, Delete
                  </Button>
                  <Button
                    onClick={() => {
                      setDeleteStatus(false);
                    }}
                    variant="contained"
                    className="cancelBtn"
                  >
                    cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : deleteStatusStep === 2 ? (
            <Box className="modalInner deletepopup">
              <Grid container spacing={1}>
                <Grid xs={12}>
                  <Link
                    className="close-button"
                    onClick={() => setDeleteStatus(false)}
                  />
                  <div className="img-prt">
                    <img src={tickicon} alt="" className="img" />
                  </div>
                  <h5 className="popup-heading">Deleted Successfully!</h5>
                </Grid>
                <Grid xs={12}></Grid>
                <Grid
                  xs={12}
                  className="ctaBtn"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    onClick={() => {
                      setDeleteStatus(false);
                      setDeleteStatusStep(1);
                      setrefresh(true);
                    }}
                    variant="contained"
                    className="submitBtn"
                  >
                    Okay
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      </Modal>
    </Box>
  );
};

export default LocationTableView;
