import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { EMPLOYEE_POLICY_SERVICES } from "../../../../Services/Employee/EmployeePolicyService";
import { useAppSelector } from "../../../../Store/hooks";
import { RootState } from "../../../../Store/Store";
import { AddKycDTO } from "../../../../Services/DTO/ProfileDTO";
import { PROFILE_SERVICE } from "../../../../Services/Employee/ProfileService";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import DatePicker from "../../../../Supporting files/DatePicker/DatePicker";
import { calculateAgeFromDob } from "../../../../Supporting files/HelpingFunction";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import { Post } from "../../../../Supporting files/CallAPI";
import useIsMobile from "../../../../Supporting files/MobileProvider";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import './AddFamily.scss';

// this is used in Enrollment Journey only

interface Props {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  callApiFromParent?: Function;
  apiUrl: string;
  policyIdEmployee?: string;
  filterDropDown?: boolean;
  addedMemberData?: any[];
  policySelected?: any;
  policies:any
}

const AddFamily: React.FC<Props> = ({
  open_status,
  attrName,
  value_update,
  callApiFromParent,
  apiUrl,
  policyIdEmployee,
  filterDropDown,
  addedMemberData,
  policySelected,
  policies
}) => {
  const isMobile = useIsMobile();
  const usertypeinfo: any = useAppSelector(
    (state: RootState) => state.userDetailsSlice.userType
  );
  const [selectFamily_data, setSelectFamily_data] = useState([]);
  const [family_detail, setFamily_detail] = useState<AddKycDTO>({
    fullName: "",
    relationShip: "",
    memId: "",
    dob: null,
    warnFullName: false,
    warnRelationShip: false,
    warnDob: false,
  });
  const [relationEmployees_data, SetrelationEmployees_data] = useState<any[]>(
    []
  );
  const [toggle, setToggle] = useState(true);
  const [selectedMemberId, setselectedMemberId] = useState("");
  const [formValue, setFormValue] = useState({
    fullName: { value: "", warning: false },
    relationEmployee: { value: "", warning: false },
    date: { value: null, warning: false },
    familyMember: { value: "", warning: false },
  });
  const [allFmlyMembers, setAllFmlyMembers] = useState<any[]>([]);
  console.log("allFmlyMembers",allFmlyMembers)
  const [allRelations, setAllRelations] = useState<any[]>([
    { key: "Husband", value: "Husband" },
    { key: "Wife", value: "Wife" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Father-In-Law", value: "Father-In-Law" },
    { key: "Mother-In-Law", value: "Mother-In-Law" },
  ]);

  // console.log("relationEmployees_data",relationEmployees_data)
  // console.log("policySelected", policySelected);
  // console.log("policies", policies);
console.log("selectFamily_data",selectFamily_data)

  useEffect(() => {
    get_family_list();
   
    // const filteredData = allRelations?.filter((listData) => {
    //   if (listData.key === "Son" || listData.key === "Daughter") {
    //     return true;
    //   } else {
    //     return !allFmlyMembers?.some(
    //       (memberData) =>
    //         memberData.relationWithEmployee === listData.key ||
    //         memberData.relationShip === listData.key ||
    //         memberData.relation === listData.key
    //     );
    //   }
    // });
    // SetrelationEmployees_data(filteredData);
    const filteredData = allRelations?.filter((listData) => {
      // Check if "Husband" or "Wife" is present in any policy
      const hasHusband = allFmlyMembers?.some(
        (memberData) =>
          memberData.relationWithEmployee === "Husband" ||
          memberData.relationShip === "Husband" ||
          memberData.relation === "Husband"
      );
    
      const hasWife = allFmlyMembers?.some(
        (memberData) =>
          memberData.relationWithEmployee === "Wife" ||
          memberData.relationShip === "Wife" ||
          memberData.relation === "Wife"
      );
    
      // Filtering logic
      if (listData.key === "Son" || listData.key === "Daughter") {
        return true; // Always include Son and Daughter
      } else if (listData.key === "Husband") {
        return !hasHusband && !hasWife; // Exclude if either Husband or Wife is already present
      } else if (listData.key === "Wife") {
        return !hasHusband && !hasWife; // Exclude if either Husband or Wife is already present
      } else {
        return !allFmlyMembers?.some(
          (memberData) =>
            memberData.relationWithEmployee === listData.key ||
            memberData.relationShip === listData.key ||
            memberData.relation === listData.key
        );
      }
    });
    
    // Update the state with the filtered data
    SetrelationEmployees_data(filteredData);
    

   
    
    
  }, [open_status]);

  // Create a set of names from the already added members array
  const alreadyAddedNames = new Set(
    policySelected?.memberData?.map((member: any) => member?.fullName)
  );

  const get_family_list = () => {
    const onSucces = (res: any) => {
      setAllFmlyMembers(res.data.data[0].family);
      let data = res.data.data[0].family
        ?.filter((element: any) => !alreadyAddedNames?.has(element.fullName)) // Filter out names already in the added members
        ?.map((element: any) => ({
          key: element._id,
          value: element.fullName,
          relationWithEmployee: element.relationWithEmployee,
          dob: element.dob,
        }));
      setSelectFamily_data(data);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    EMPLOYEE_POLICY_SERVICES.get_member_list(onSucces, onError, "employee");
  };

  const updateMasterState = (attrName: any, value: any) => {
    let key = attrName[1];
    let mainState = attrName[0];
    if (mainState === "family_detail") {
      setFamily_detail({
        ...family_detail,
        [key]: value,
        [`warn${key.charAt(0).toUpperCase()}${key.slice(1)}`]: false,
      });
    } else if (attrName[1] === "familyMember") {
      selectFamily_data.forEach((el: any) => {
        if (el.key === value) {
          setselectedMemberId(value);
          setFamily_detail((prev) => ({
            ...prev,
            relationShip: el.relationWithEmployee,
            fullName: el.value,
            memId: el.key || el.id,
            dob: el.dob,
            warnFullName: false,
            warnRelationShip: false,
            warnDob: false,
          }));
        }
      });
    } else {
      attrName(value);
    }
  };

  const handleClicktoogle = () => {
    setToggle(true);
    setselectedMemberId("");
    setFormValue({
      fullName: { value: "", warning: false },
      relationEmployee: { value: "", warning: false },
      date: { value: null, warning: false },
      familyMember: { value: "", warning: false },
    });
    setFamily_detail({
      fullName: "",
      relationShip: "",
      dob: null,
      warnFullName: false,
      warnRelationShip: false,
      warnDob: false,
    });
  };
  const handleClicked = () => {
    setToggle(false);
    setselectedMemberId("");
    setFormValue({
      fullName: { value: "", warning: false },
      relationEmployee: { value: "", warning: false },
      date: { value: null, warning: false },
      familyMember: { value: "", warning: false },
    });
    setFamily_detail({
      fullName: "",
      relationShip: "",
      dob: null,
      warnFullName: false,
      warnRelationShip: false,
      warnDob: false,
    });
  };

  useEffect(() => {
    if (open_status) {
      document.body.style.overflowY="hidden"
    }
    else {
      document.body.style.overflowY = ""
    }
    
  },[open_status])


  return (
    <>
      {isMobile ? (
          
          <Drawer
            className="Mdrawer"
             anchor="right" // Open drawer from the right
            open={open_status}
            PaperProps={{
              sx: {
                backgroundColor: "#fff", // Set the background color to white
                width: "100%", // Full width
                height: "100%", // Full height
                top: 0, // Align to the top
                right: 0, // Align to the right
                overflow:"hidden"
              },
            }}
            
           >
             <Grid container spacing={3} className="mt-6" >
                <Grid xs={12} alignItems="center"  className="MenrollmentBackBtn" >
                 
                  <ArrowBackIosOutlinedIcon
                  fontSize="small"
                    onClick={() => {
                      value_update(attrName, false);
                      setToggle(true);
                      setFamily_detail({
                        fullName: "",
                        relationShip: "",
                        dob: null,
                        warnFullName: false,
                        warnRelationShip: false,
                        warnDob: false,
                      });
                      setFormValue({
                        fullName: { value: "", warning: false },
                        relationEmployee: { value: "", warning: false },
                        date: { value: null, warning: false },
                        familyMember: { value: "", warning: false },
                      });
                      setselectedMemberId("");
                    }}
                  />
                <h5 className="mb-0 ml-1 text-left">Add Family Member</h5>
                    
              </Grid>
              <Grid
                container
                spacing={0}
                alignItems="center"
              sx={{ paddingTop: "0",paddingX:"7px" }}
                padding={0}
                className="pt-4 my-6"
              >
                <Grid xs={12}>
                  <div className="scrollable_area">
                    <Grid container spacing={0} padding={1}>
                      <Grid xs={12} className="radio_button no-border">
                        <RadioGroup row defaultValue="existing">
                          <FormControlLabel
                            value="existing"
                            control={<Radio checked={toggle} sx={{
                              "&.Mui-checked": {
                                color: "#2160aa",
                              },
                            }}/>}
                            label="Choose from existing family member"
                            onClick={handleClicktoogle}
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid xs={12} className="radio_button no-border">
                        <RadioGroup row defaultValue="existing">
                          <FormControlLabel
                            value="new"
                            control={<Radio checked={!toggle} sx={{
                              "&.Mui-checked": {
                                color: "#2160aa",
                              },
                            }}/>}
                            label="Add new member"
                            onClick={handleClicked}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {toggle === true ? (
                      <Grid container spacing={0}>
                        <Grid xs={12}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Select Family Member"
                            value={selectedMemberId}
                            attrName={["formValue", "familyMember"]}
                            value_update={updateMasterState}
                            dropdown_data={selectFamily_data}
                            warn_status={family_detail.warnRelationShip}
                            error_messg="Select Relationship"
                          />
                        </Grid>
                        <Grid xs={12}>
                          <p className="health_ecard mt-2">
                            Relationship with Employee
                            <span style={{color:"#000",display:"block"}}>{family_detail.relationShip}</span>
                          </p>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={0}>
                        <Grid xs={12}>
                          <RKTextField
                            class_name="inputField mb-4"
                            title={"Full Name"}
                            value={family_detail.fullName}
                            attrName={["family_detail", "fullName"]}
                            value_update={updateMasterState}
                            warn_status={family_detail.warnFullName}
                            error_messg="Enter Full Name"
                            validation_type="name"
                          />
                        </Grid>
                        <Grid xs={12}>
                          <SelectDropdown
                            class_name="inputField mb-5  mt-2"
                            title="Relationship with employee"
                            value={family_detail.relationShip}
                            attrName={["family_detail", "relationShip"]}
                            value_update={updateMasterState}
                            dropdown_data={relationEmployees_data}
                            warn_status={family_detail.warnRelationShip}
                            error_messg="Select Relationship with employee"
                          />
                        </Grid>
                        <Grid xs={12} className="mt-2">
                          <DatePicker
                            class_name="inputField"
                            title="DOB"
                            value={family_detail.dob}
                            attrName={["family_detail", "dob"]}
                            max_date={new Date()}
                            value_update={updateMasterState}
                            error_message="Please enter Date of Birth"
                            warn_status={
                              family_detail.warnDob === undefined
                                ? false
                                : family_detail.warnDob
                            }
                          />
                        </Grid>
                        <Grid xs={12} className="mt-2">
                          <p className="health_ecard">
                            Age
                            <span style={{display:"block",color:"#000"}}>
                              {calculateAgeFromDob(family_detail.dob)}
                            </span>
                          </p>
                        </Grid>
                      </Grid>
                    )}
                    {/* <div  className="MenrollmentStartBtn "  >
                      <Button
                        variant="contained"
                        className=" submitBtn "
                        style={{ background: "#2160aa", borderRadius: "10px" }}
                        onClick={() => {
                          if (callApiFromParent) {
                            let data: any = family_detail;
                            data.warnFullName =
                              family_detail.fullName.length === 0
                                ? true
                                : false;
                            data.warnRelationShip =
                              family_detail.relationShip.length === 0
                                ? true
                                : false;
                            data.warnDob =
                              family_detail.dob === null ? true : false;
                            setFamily_detail({ ...data });
                            if (
                              data.warnFullName === false &&
                              data.warnRelationShip === false &&
                              data.warnDob === false
                            ) {
                              setToggle(true);
                              callApiFromParent(data, toggle);
                              setToggle(true);
                              setFamily_detail({
                                fullName: "",
                                relationShip: "",
                                dob: null,
                                warnFullName: false,
                                warnRelationShip: false,
                                warnDob: false,
                              });
                              setselectedMemberId("");
                              get_family_list();
                            }
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </div> */}
                  </div>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} className="footer_sec" style={{background:"none",boxShadow:"none !important"}}>
                    <Button
                      variant="contained"
                      className="save_btn menrollment"
                    
                      onClick={() => {
                        if (callApiFromParent) {
                          let data: any = family_detail;
                          data.warnFullName =
                            family_detail.fullName.length === 0 ? true : false;
                          data.warnRelationShip =
                            family_detail.relationShip.length === 0
                              ? true
                              : false;
                          data.warnDob =
                            family_detail.dob === null ? true : false;
                          setFamily_detail({ ...data });
                          if (
                            data.warnFullName === false &&
                            data.warnRelationShip === false &&
                            data.warnDob === false
                          ) {
                            setToggle(true);
                            callApiFromParent(data, toggle);
                            setToggle(true);
                            setFamily_detail({
                              fullName: "",
                              relationShip: "",
                              dob: null,
                              warnFullName: false,
                              warnRelationShip: false,
                              warnDob: false,
                            });
                            setselectedMemberId("");
                            get_family_list();
                          }
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
           </Drawer>
 
      ) : (
        <Box>
          <SlidingPanel
            type={"right"}
            isOpen={open_status}
            size={75}
            panelClassName="sliding-panel"
          >
            <div>
              <Grid container spacing={3} alignItems="center">
                <Grid xs={10}>
                  <h4 className="mb-0 mt-3 text-left">Add Family Member</h4>
                </Grid>
                <Grid xs={2} textAlign="end" className="mt-2">
                  <GridCloseIcon
                    onClick={() => {
                      value_update(attrName, false);
                      setToggle(true);
                      setFamily_detail({
                        fullName: "",
                        relationShip: "",
                        dob: null,
                        warnFullName: false,
                        warnRelationShip: false,
                        warnDob: false,
                      });
                      setFormValue({
                        fullName: { value: "", warning: false },
                        relationEmployee: { value: "", warning: false },
                        date: { value: null, warning: false },
                        familyMember: { value: "", warning: false },
                      });
                      setselectedMemberId("");
                    }}
                  />
                </Grid>
                <Grid xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                alignItems="center"
                sx={{ paddingTop: "0" }}
              >
                <Grid xs={12}>
                  <div className="scrollable_areas">
                    <Grid container spacing={3}>
                      <Grid xs={12} className="radio_button no-border">
                        <RadioGroup row defaultValue="existing">
                          <FormControlLabel
                            value="existing"
                            control={<Radio checked={toggle} />}
                            label="Choose from existing family member"
                            onClick={handleClicktoogle}
                          />
                          <FormControlLabel
                            value="new"
                            control={<Radio checked={!toggle} />}
                            label="Add new member"
                            onClick={handleClicked}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    {toggle === true ? (
                      <Grid container spacing={3}>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Select Family Member"
                            value={selectedMemberId}
                            attrName={["formValue", "familyMember"]}
                            value_update={updateMasterState}
                            dropdown_data={selectFamily_data}
                            warn_status={family_detail.warnRelationShip}
                            error_messg="Select RelationShip"
                          />
                        </Grid>
                        <Grid xs={6} className="mt-2">
                          <p className="health_ecard">
                            Relationship with Employee
                            <span>{family_detail.relationShip}</span>
                          </p>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid xs={6}>
                          <RKTextField
                            class_name="inputField"
                            title={"Full Name"}
                            value={family_detail.fullName}
                            attrName={["family_detail", "fullName"]}
                            value_update={updateMasterState}
                            warn_status={family_detail.warnFullName}
                            error_messg="Enter Full Name"
                            validation_type="name"
                          />
                        </Grid>
                        <Grid xs={6}>
                          <SelectDropdown
                            class_name="inputField"
                            title="Relationship with employee"
                            value={family_detail.relationShip}
                            attrName={["family_detail", "relationShip"]}
                            value_update={updateMasterState}
                            dropdown_data={relationEmployees_data}
                            warn_status={family_detail.warnRelationShip}
                            error_messg="Select Relationship with employee"
                          />
                        </Grid>
                        <Grid xs={6}>
                          <DatePicker
                            class_name="inputField"
                            title="DOB"
                            value={family_detail.dob}
                            attrName={["family_detail", "dob"]}
                            max_date={new Date()}
                            value_update={updateMasterState}
                            error_message="Please enter Date of Birth"
                            warn_status={
                              family_detail.warnDob === undefined
                                ? false
                                : family_detail.warnDob
                            }
                          />
                        </Grid>
                        <Grid xs={6}>
                          <p className="health_ecard">
                            Age
                            <span>
                              {calculateAgeFromDob(family_detail.dob)}
                            </span>
                          </p>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} className="footer_sec">
                    <Button
                      variant="contained"
                      className="save_btn"
                      onClick={() => {
                        if (callApiFromParent) {
                          let data: any = family_detail;
                          data.warnFullName =
                            family_detail.fullName.length === 0 ? true : false;
                          data.warnRelationShip =
                            family_detail.relationShip.length === 0
                              ? true
                              : false;
                          data.warnDob =
                            family_detail.dob === null ? true : false;
                          setFamily_detail({ ...data });
                          if (
                            data.warnFullName === false &&
                            data.warnRelationShip === false &&
                            data.warnDob === false
                          ) {
                            setToggle(true);
                            callApiFromParent(data, toggle);
                            setToggle(true);
                            setFamily_detail({
                              fullName: "",
                              relationShip: "",
                              dob: null,
                              warnFullName: false,
                              warnRelationShip: false,
                              warnDob: false,
                            });
                            setselectedMemberId("");
                            get_family_list();
                          }
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </SlidingPanel>
        </Box>
      )}
    </>
  );
};

export default AddFamily;
