import { TextField } from "@mui/material";
import "./SearchBox.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
interface Props {
  value_update: Function;
  custom?: boolean;
  fullWidth?: boolean;
  value?: string;
}
const SearchBox: React.FC<Props> = ({
  value_update,
  custom,
  fullWidth,
  value,
}) => {
  const [newValue , serNewValue] = useState("")

  const onchange = (e: any) => {
    serNewValue( e.target.value)
    value_update("searchBarfilter", e.target.value);
  };
  return (
    <div
      className="searchBox"
      style={{
        width: "100%",
        maxWidth: fullWidth ? "650px" : custom ? "400px" : "",
      }}
    >
      <SearchIcon />
      <TextField
        fullWidth
        variant="outlined"
        type="text"
        placeholder="Search..."
        onChange={onchange}
        value={newValue}
      />
    </div>
  );
};

export default SearchBox;
