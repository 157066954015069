//used
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArticleIcon from "@mui/icons-material/Article";
import { Button, Link, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridCloseIcon } from "@mui/x-data-grid/components/icons";
import { useEffect, useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import { toast } from "react-toastify";
import { EMPLOYEE_URL_CONSTANTS } from "../../../../APIVar/EmployeeUrlConstant";
import { EMPLOYER_URL_CONSTANTS } from "../../../../APIVar/EmployerUrlConstant";
import { URL_CONSTANTS } from "../../../../APIVar/URLConstants";
import { Claim_SERVICES } from "../../../../Services/Claim/ClaimServices";
import { claimDTO } from "../../../../Services/DTO/ClaimDTO";
import { TPA_TYPE } from "../../../../Services/Enum/tpaType";
import { POLICIES_SERVICES } from "../../../../Services/Policies/PoliciesServices";
import { TSearchDropdown } from "../../../../Services/Types/Dropdown";
import { useAppSelector } from "../../../../Store/hooks";
import { RootState } from "../../../../Store/Store";
import CheckBox from "../../../../Supporting files/CheckBox/CheckBox";
import { useFormatCurrency } from "../../../../Supporting files/CustomHooks";
import DatePicker from "../../../../Supporting files/DatePicker/DatePicker";
import {
  allowFileExtention,
  date_time_format,
  downloadAttachement,
  get_city_list_via_name,
  get_client_list,
  get_country_list,
  get_employer_client_list,
  get_state_list,
  getFileSignedUrl,
  uploadImage,
} from "../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../Supporting files/RKTextField/RKTextField";
import SearchDropdown from "../../../../Supporting files/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../Supporting files/SelectDropdown/SelectDropdown";
import TextAreaField from "../../../../Supporting files/TextAreaField/TextAreaField";
import DocumentViewer from "../../../Common/DocumentViewer/DocumentViewer";

const InitiateAClaim = ({
  open_status,
  attrName,
  value_update,
  client_id_status,
  shortcutDisable,
  setShortcutDisable,
  callApi,
}: {
  open_status: boolean;
  attrName: any;
  value_update: Function;
  client_id_status: boolean;
  shortcutDisable?: boolean;
  setShortcutDisable?: Function;
  callApi?: Function;
}) => {
  const { formatCurrency } = useFormatCurrency();
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const { id, clientId, Organisation, userType, Client } = useAppSelector(
    (state: any) => state.userDetailsSlice
  );
  const { timeZone, dateFormat } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const [selectedDocUrl, setselectedDocUrl] = useState("");
  const [docviewOpenStatus, setDocviewOpenStatus] = useState(false);
  const [attachFir, setAttachFir] = useState(false);
  const [checklist, setChecklist] = useState<any[]>([]);
  const [sumInsuredAmount, setSumInsuredAmount] = useState("");
  const [paidClaimAmount, setPaidClaimAmount] = useState(0);
  const [kycDocumentStatus, setKycDocumentStatus] = useState("Intimation");
  const [lastWorkingDaySelectedEmployee, setlastWorkingDaySelectedEmployee] =
    useState("");
  const [documentStatus, setDocumentStatus] = useState(0);

  const [addDocumentField, setAddDocumentField] = useState<
    Array<{
      doc: string;
      docName: string;
      warning: boolean;
      des: string;
      desWarn: boolean;
    }>
  >([{ doc: "", docName: "", warning: false, des: "", desWarn: false }]);
  const [claimFormData, setClaimFormData] = useState<claimDTO>({
    clientId: { label: "", id: "", warning: false },
    employeeId: { label: "", id: "", warning: false },
    selectPolicy: { label: "", id: "", warning: false },
    insurer: { label: "", id: "", warning: false },
    insurerPerson: { value: "", warning: false },
    hospital: { label: "", id: "", warning: false },
    country: { label: "", id: "", warning: false },
    state: { label: "", id: "", warning: false },
    city: { label: "", id: "", warning: false },
    pincode: { value: "", warning: false },
    claimAmount: { value: "", warning: false },
    hospitalName: { value: "", warning: false },
    admissionDate: { value: null, warning: false },
    dischargeDate: { value: null, warning: false },
    reasonForHospitalization: { value: "", warning: false },
  });
  console.log("claimFormata", claimFormData);
  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    doc_name: "",
  });
  console.log("displayData", displayData);
  const [hospitals, sethospitals] = useState<any[]>([]);
  const [hosList, setHosList] = useState<any[]>([]);
  const [client_id_data, setClient_id_data] = useState<TSearchDropdown[]>([]);
  const [employee_id_data, setEmployee_id_data] = useState<TSearchDropdown[]>(
    []
  );
  const [country_data, setCountry_data] = useState<TSearchDropdown[]>([]);
  const [state_data, setState_data] = useState<TSearchDropdown[]>([]);
  const [city_data, setCity_data] = useState<TSearchDropdown[]>([]);
  const [policy_data, setPolicy_data] = useState<any[]>([]);
  const [tpaId, settpaId] = useState("");
  const [tpaPolicy, setTpaPolicy] = useState<any>({ tpa: "" });
  const [tpaName, setTpaName] = useState<string>("");
  const [policyNumber, setpolicyNumber] = useState<string>("");
  const [primaryEmployeeId, setPrimaryEmployeeId] = useState<string>("");
  const [selectedPolicyForPhy, setselectedPolicyForPhy] = useState({
    insurerId: "",
    id: "",
  });
  console.log("employee_id_data", employee_id_data);
  const [physicalFormData, setPhysicalFormData] = useState<{
    formList: Array<any>;
    link: string;
    value: string;
  }>({
    formList: [],
    link: "",
    value: "",
  });
  const [document, setdocument] = useState<{
    label: string;
    id: string;
    attachment: string;
    downloadLink: string;
  }>({ label: "", id: "", attachment: "", downloadLink: "" });
  const [selectedPolicy, setSelectedPolicy] = useState<any>({});

  useEffect(() => {
    if (userType === "EMPLOYER" && open_status) {
      setClaimFormData({
        ...claimFormData,
        clientId: {
          label: clientId,
          id: id,
          warning: false,
        },
      });
    }
  }, [open_status, kycDocumentStatus]);

  useEffect(() => {
    setAddDocumentField([
      {
        doc: "",
        docName: "",
        warning: false,
        des: "",
        desWarn: false,
      },
    ]);
  }, [tpaName]);

  useEffect(() => {
    if (open_status === true) {
      get_client();
      get_country_list((cb: any) => {
        setCountry_data(cb);
      });
      if (userType != "BROKER") {
        get_employee_data(userType === "EMPLOYEE" ? clientId : id);
      }
    }
  }, [open_status, kycDocumentStatus]);

  useEffect(() => {
    if (claimFormData.country.label != "") {
      get_state_list(claimFormData.country.label, (cb: any) => {
        setState_data(cb);
      });
    }
    if (claimFormData.country.label != "" && claimFormData.state.label != "") {
      get_city_list_via_name(
        claimFormData.country.label,
        claimFormData.state.label,
        (cb: any) => {
          setCity_data(cb);
        }
      );
    }
  }, [claimFormData.country.label, claimFormData.state.label]);

  const get_client = () => {
    const onSuccess = (res: any) => {
      setClient_id_data(res.data.data);
    };
    const onError = (err: any) => {};

    if (userType === "BROKER") {
      get_client_list(userType?.toLowerCase(), onSuccess, onError);
    } else if (userType === "EMPLOYER") {
      get_employer_client_list(onSuccess, onError);
    }
  };

  const get_employee_data = (empid: string) => {
    const onSuccess = (res: any) => {
      setEmployee_id_data(res.data.data);
    };
    const onError = (err: any) => {};
    let api_url =
      userType === "BROKER"
        ? `${URL_CONSTANTS.get_employee_by_client}${empid}`
        : userType === "EMPLOYEE"
        ? `${EMPLOYEE_URL_CONSTANTS.get_employee_list_by_client}${empid}`
        : `${EMPLOYER_URL_CONSTANTS.get_employee_by_client}${empid}`;

    Claim_SERVICES.get_dropdown_data(api_url, onSuccess, onError);
  };

  const updateMasterState = (attrName: any, value: any) => {
    let mainState = attrName[0];
    let key = attrName[1];
    let ind = attrName[2];
    if (key !== "selectPolicy") {
      settpaId("");
    }
    if (mainState === "claimFormData") {
      if (value && value.label) {
        if (key === "clientId") {
          setClaimFormData({
            ...claimFormData,
            [key]: {
              id: value && value.id ? value.id : value._id,
              label: value.label,
              warning: false,
            },
            employeeId: { label: "", id: "", warning: false },
            selectPolicy: { label: "", id: "", warning: false },
            insurer: { label: "", id: "", warning: false },
            admissionDate: { value: null, warning: false },
            dischargeDate: { value: null, warning: false },
          });
          setSelectedPolicy({});
          setPolicy_data([]);
          setPrimaryEmployeeId("");
          setselectedPolicyForPhy({ insurerId: "", id: "" });
        } else if (key === "employeeId") {
          setClaimFormData({
            ...claimFormData,
            [key]: {
              id: value && value.id ? value.id : value._id,
              label: value.label,
              warning: false,
            },
            selectPolicy: { label: "", id: "", warning: false },
            insurer: { label: "", id: "", warning: false },
            admissionDate: { value: null, warning: false },
            dischargeDate: { value: null, warning: false },
          });
          setselectedPolicyForPhy({
            insurerId: "",
            id: "",
          });
          setSelectedPolicy({});
          setTpaName("");
          setSumInsuredAmount("");
          setPaidClaimAmount(0);
        } else if (key === "selectPolicy") {
          console.log("gdsgsgsg", value.lastWorkingDay);

          setlastWorkingDaySelectedEmployee(value.lastWorkingDay ?? "");
          setSelectedPolicy(value);
          setTpaName(value?.tpaName);
          setSumInsuredAmount(value?.sumInsured);
          setPaidClaimAmount(value?.totalClaimAmt);
          // setClaimFormData({
          //   ...claimFormData,
          //   [key]: { label: value.label, id: "", warning: false }
          // })
          console.log("policyData", policy_data);

          if (
            kycDocumentStatus === "Cashless" &&
            (value?.tpaName?.toLowerCase() === TPA_TYPE.PARAMOUNT ||
              value?.tpaName?.toLowerCase() === TPA_TYPE.MEDIASSIST ||
              value?.tpaName?.toLowerCase() === TPA_TYPE.MDINDIA)
          ) {
            toast.error(
              <>
                You Cannot Raise Cashless Claim/Pre-Authorization Request For
                This Policy.
                <br />
                <small>TPA For This Policy Is {value?.tpaName}.</small>
              </>,
              {
                autoClose: false,
                style: {
                  top: "50%",
                  right: "50%",
                  marginTop: "100%",
                },
              }
            );
          }
          setpolicyNumber(value.policyNumber);
          setClaimFormData({
            ...claimFormData,
            [key]: {
              id: value && value.id ? value.id : value._id,
              label: value.label,
              warning: false,
            },
            insurer: { label: "", id: "", warning: false },
            admissionDate: { value: null, warning: false },
            dischargeDate: { value: null, warning: false },
          });
          if (value?.tpa) {
            settpaId(value?.tpa);
            setTpaPolicy(value);
          } else {
            setTpaPolicy({ tpa: "" });
            setTpaName("");
            setSumInsuredAmount("");
            setPaidClaimAmount(0);
          }
        } else if (key === "hospital") {
          if (value.label === "Others") {
            setClaimFormData({
              ...claimFormData,
              [key]: {
                id: value.id,
                label: value.label,
                warning: false,
              },
              hospitalName: { value: "", warning: false },
            });
          } else {
            setClaimFormData({
              ...claimFormData,
              [key]: {
                id: value.id,
                label: value.label,
                warning: false,
              },
              hospitalName: { value: value.label, warning: false },
            });
          }
        }
      } else {
        if (key === "admissionDate") {
          setClaimFormData({
            ...claimFormData,
            [key]: {
              value: value,
              warning: false,
            },
            dischargeDate: {
              value: "",
              warning: false,
            },
          });
        } else {
          setClaimFormData({
            ...claimFormData,
            [key]: {
              value: value,
              warning: false,
            },
          });
        }
      }
      if (key === "clientId") {
        get_employee_data(value.id);
      } else if (key === "selectPolicy") {
        policy_data.forEach((data) => {
          if (data.label === value?.label) {
            setClaimFormData((prev) => ({
              ...prev,
              insurer: {
                label: data.value === undefined ? "" : data.value,
                id: data.insurerId,
                warning: false,
              },
            }));
          }
          setselectedPolicyForPhy(value);
          setPhysicalFormData({
            formList: [],
            link: "",
            value: "",
          });
        });
      } else if (key === "country") {
        setClaimFormData({
          ...claimFormData,
          country: { label: value.label, id: value.id, warning: false },
          state: {
            label: value.label === "N.A." ? "N.A." : "",
            id: value.label === "N.A." ? "N.A." : "",
            warning: false,
          },
          city: {
            label: value.label === "N.A." ? "N.A." : "",
            id: value.label === "N.A." ? "N.A." : "",
            warning: false,
          },
          pincode: { value: "", warning: false },
          hospital: { label: "", id: "", warning: false },
        });
        sethospitals(hosList);
      } else if (key === "state") {
        setClaimFormData({
          ...claimFormData,
          state: { label: value.label, id: value.id, warning: false },
          city: {
            label: value.label === "N.A." ? "N.A." : "",
            id: value.label === "N.A." ? "N.A." : "",
            warning: false,
          },
          pincode: { value: "", warning: false },
          hospital: { label: "", id: "", warning: false },
        });
        let list = hosList?.filter(
          (item: any) =>
            item.state_name?.toLowerCase() === value.label?.toLowerCase()
        );
        sethospitals(list);
      } else if (key === "city") {
        setClaimFormData({
          ...claimFormData,
          city: {
            label: value.label === "N.A." ? "N.A." : value.label,
            id: value.label === "N.A." ? "N.A." : value.id,
            warning: false,
          },
          pincode: { value: "", warning: false },
          hospital: { label: "", id: "", warning: false },
        });
        let list = hosList?.filter(
          (item: any) =>
            item.city_name?.toLowerCase() === value.label?.toLowerCase()
        );
        sethospitals(list);
      } else if (key === "pincode") {
        setClaimFormData({
          ...claimFormData,
          pincode: { value: value, warning: false },
          hospital: { label: "", id: "", warning: false },
        });
        if (value?.length === 6) {
          let list = hosList?.filter(
            (item: any) => item.pin_code?.toLowerCase() === value
          );
          sethospitals(list);
        } else {
          let list = hosList?.filter(
            (item: any) =>
              item.city_name?.toLowerCase() ===
              claimFormData.city.label?.toLowerCase()
          );
          sethospitals(list);
        }
      } else if (key === "employeeId") {
        const onSuccess = (res: any) => {
          console.log("res", res);
          if (value.label?.includes("DEP")) {
            setPrimaryEmployeeId(res.data.data[0].primaryEmployee);
          } else {
            setPrimaryEmployeeId("");
          }
          setPolicy_data(res.data.data);
        };
        const onError = (err: any) => {};
        let api_url =
          userType === "BROKER"
            ? `${URL_CONSTANTS.get_policy_by_employe}${value._id}`
            : userType === "EMPLOYEE"
            ? `${EMPLOYEE_URL_CONSTANTS.get_policy_list_by_employee}${value._id}`
            : `${EMPLOYER_URL_CONSTANTS.get_policy_by_employe}${value._id}`;

        Claim_SERVICES.get_dropdown_data(api_url, onSuccess, onError);
      }
    } else if (attrName === setPhysicalFormData) {
      setPhysicalFormData({
        ...physicalFormData,
        link: value.value,
        value: value,
      });
    } else if (attrName === setdocument) {
      setdocument(value);
    } else if (mainState === "addDocumentField") {
      setAddDocumentField((prevDisplayData: any) => {
        const updatedFields = [...prevDisplayData];
        updatedFields[ind].des = value;
        updatedFields[ind].desWarn = value === "";
        return updatedFields;
      });
    } else {
      attrName(value);
    }
  };

  const form_submit = () => {
    if (disableSave) return;
    let data: claimDTO = claimFormData;
    let docData: any = displayData;
    // Check if required fields are empty and set warning flag accordingly
    data.clientId.warning = claimFormData.clientId.label === "" ? true : false;
    data.claimAmount.warning = claimFormData.claimAmount.value ? false : true;
    data.employeeId.warning =
      claimFormData.employeeId.label === "" ? true : false;
    data.selectPolicy.warning =
      claimFormData.selectPolicy.label === "" ? true : false;
    data.insurer.warning = claimFormData.insurer.label === "" ? true : false;
    data.hospital.warning = claimFormData.hospital.label === "" ? true : false;
    data.country.warning = claimFormData.country.label === "" ? true : false;
    data.state.warning = claimFormData.state.label === "" ? true : false;
    data.city.warning = claimFormData.city.label === "" ? true : false;
    data.pincode.warning =
      claimFormData.pincode.value?.length === 6 ? false : true;
    if (claimFormData.hospital.label === "Others") {
      data.hospitalName.warning =
        claimFormData.hospitalName.value === "" ? true : false;
    } else {
      data.hospitalName.warning = false;
    }
    data.insurerPerson.warning =
      claimFormData.insurerPerson.value === "" ? true : false;
    data.admissionDate.warning =
      claimFormData.admissionDate.value === null ? true : false;
    if (documentStatus === 0) {
      data.dischargeDate.warning =
        claimFormData.dischargeDate.value === null ? true : false;
    } else {
      data.dischargeDate.warning = false;
    }
    data.reasonForHospitalization.warning =
      claimFormData.reasonForHospitalization.value === "" ? true : false;
    setClaimFormData({ ...data });
    let docArray = [...addDocumentField];
    let check_array_status = false;

    // Check if document fields are empty and set warning flag accordingly
    docArray = docArray.map((e: any) => ({
      docName: e.docName,
      doc: e.doc,
      warning: e.doc === "" ? ((check_array_status = true), true) : false,
      des: e.des,
      desWarn: e.des === "" ? ((check_array_status = true), true) : false,
    }));
    setAddDocumentField(docArray);
    if (attachFir === true) {
      docData.warning = displayData.doc === "" ? true : false;
    }
    setDisplayData({ ...displayData });
    const doc_data = addDocumentField.map((e: any) => {
      delete e.warning;
      return e;
    });
    // Check if all warnings are false, then proceed to create claim
    if (
      data.claimAmount.warning === false &&
      data.clientId.warning === false &&
      data.employeeId.warning === false &&
      data.selectPolicy.warning === false &&
      data.insurer.warning === false &&
      data.hospital.warning === false &&
      data.country.warning === false &&
      data.state.warning === false &&
      data.city.warning === false &&
      data.pincode.warning === false &&
      data.hospitalName.warning === false &&
      data.admissionDate.warning === false &&
      (documentStatus === 1 ||
        (documentStatus === 0 && data.dischargeDate.warning === false)) &&
      data.reasonForHospitalization.warning === false &&
      check_array_status === false
    ) {
      let param: any = {
        APITYPE: kycDocumentStatus,
        clientId: claimFormData.clientId.id,
        clientName:
          userType === "BROKER"
            ? claimFormData.clientId.label
            : userType === "EMPLOYER"
            ? claimFormData.clientId.label + "-" + Organisation
            : userType === "EMPLOYEE"
            ? claimFormData.clientId.label
            : "",
        memberId: claimFormData.employeeId.id,
        memberName: claimFormData.employeeId.label,
        policyName: claimFormData.selectPolicy.id,
        tpa: tpaPolicy?.tpa || "",
        claimAmt: claimFormData.claimAmount.value,
        insurer: claimFormData.insurer.id,
        hospital: claimFormData.hospital.id,
        insuredPerson: claimFormData.employeeId.label,
        country: claimFormData.country.label,
        state: claimFormData.state.label,
        city: claimFormData.city.label,
        pincode: claimFormData.pincode.value,
        hospitalName: claimFormData.hospitalName.value,
        admisionDate: claimFormData.admissionDate.value,
        dischargeDate: claimFormData.dischargeDate.value,
        reason: claimFormData.reasonForHospitalization.value,
        firDocument: {
          doc: displayData.doc,
          docName: displayData.doc_name,
        },
        firStatus: attachFir,
        upload: doc_data,
      };

      if (userType === "EMPLOYEE") {
        param.primaryEmployee = id;
      }
      if (primaryEmployeeId && userType !== "EMPLOYEE") {
        param.primaryEmployee = primaryEmployeeId;
      }
      // Check if FIR document is required and not attached, then return
      if (
        kycDocumentStatus === "Cashless" &&
        attachFir === true &&
        docData.warning === true
      ) {
        return;
      } else {
        const onSuccess = (res: any) => {
          value_update(attrName, false);
          setSelectedPolicy({});
          setPolicy_data([]);
          setPrimaryEmployeeId("");
          setEmployee_id_data([]);
          setClaimFormData({
            clientId: { label: "", id: "", warning: false },
            employeeId: { label: "", id: "", warning: false },
            selectPolicy: { label: "", id: "", warning: false },
            insurer: { label: "", id: "", warning: false },
            hospital: { label: "", id: "", warning: false },
            insurerPerson: { value: "", warning: false },
            country: { label: "", id: "", warning: false },
            state: { label: "", id: "", warning: false },
            city: { label: "", id: "", warning: false },
            claimAmount: { value: "", warning: false },
            hospitalName: { value: "", warning: false },
            admissionDate: { value: null, warning: false },
            dischargeDate: { value: null, warning: false },
            reasonForHospitalization: { value: "", warning: false },
            pincode: { value: "", warning: false },
          });
          setDisplayData({ doc: "", warning: false, doc_name: "" });
          setAttachFir(false);
          setAddDocumentField([
            { doc: "", docName: "", warning: false, des: "", desWarn: false },
          ]);
          setChecklist([]);
          setDocumentStatus(0);
          setTpaPolicy({ tpa: "" });
          setTpaName("");
          setSumInsuredAmount("");
          setPaidClaimAmount(0);
          setdocument({ label: "", id: "", attachment: "", downloadLink: "" });
          setPhysicalFormData({ formList: [], link: "", value: "" });
          toast.success(res.data.message);
          if (setShortcutDisable) {
            setShortcutDisable(false);
          }
          if (callApi) {
            callApi();
          }
          setTimeout(() => {
            setDisableSave(false);
          }, 1500);
        };
        const onError = (err: any) => {
          setDisableSave(false);
          toast.error(
            err?.response?.data?.errors[0]?.message ||
              err?.response?.data?.errors[0]?.param
          );
        };
        let api_url =
          userType === "BROKER"
            ? URL_CONSTANTS.create_claim
            : userType === "EMPLOYER"
            ? EMPLOYER_URL_CONSTANTS.create_claim
            : EMPLOYEE_URL_CONSTANTS.add_claim;

        setDisableSave(true);
        Claim_SERVICES.create_Claim(api_url, param, onSuccess, onError);
      }
    }
  };

  const get_tpa_check_list = () => {
    const onSuccess = (res: any) => {
      let data = res.data?.data?.documentChecklist;
      setChecklist(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    Claim_SERVICES.get_tpa_check_list(
      onSuccess,
      onError,
      tpaId,
      userType.toLowerCase()
    );
  };

  const get_physical_form_list = () => {
    const onSuccess = (res: any) => {
      let data = res.data.data.map((e: any) => {
        return {
          label: { value: e.downloadLink, label: e.title },
          value: e.title,
        };
      });
      setPhysicalFormData({ ...physicalFormData, formList: data });
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };

    if (
      policy_data[0]?.tpa &&
      selectedPolicyForPhy?.insurerId
      // && userType === "BROKER"
    ) {
      Claim_SERVICES.get_physical_form_data(
        onSuccess,
        onError,
        selectedPolicyForPhy?.insurerId || "",
        selectedPolicyForPhy?.id || "",
        userType.toLowerCase(),
        kycDocumentStatus === "Intimation"
          ? "Claim Intimation"
          : "Cashless Request"
      );
    }
  };

  useEffect(() => {
    if (tpaId) {
      get_tpa_check_list();
    }
  }, [tpaId]);

  useEffect(() => {
    get_physical_form_list();
  }, [selectedPolicyForPhy?.insurerId]);

  useEffect(() => {
    if (userType === "EMPLOYEE" && open_status) {
      setClaimFormData({
        ...claimFormData,
        clientId: {
          label: Client,
          id: clientId,
          warning: false,
        },
      });
    }
  }, [open_status, kycDocumentStatus]);

  const getNetworkHospitalsList = (tpaName: string, policyNumber: string) => {
    const onSuccess = (res: any) => {
      let response = res.data.data;
      let list = response?.map((item: any) => {
        return { label: item?.hospital_name, id: item?.hospital_name, ...item };
      });
      setHosList(list);
      sethospitals(list);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    POLICIES_SERVICES.networkHospitalList(
      onSuccess,
      onError,
      URL_CONSTANTS.getNetworkHospitalsList(tpaName, policyNumber)
    );
  };

  useEffect(() => {
    if (open_status) {
      getNetworkHospitalsList(tpaName, policyNumber);
    }
  }, [policyNumber, tpaName, open_status]);

  return (
    <>
      <DocumentViewer
        url={selectedDocUrl}
        open={docviewOpenStatus}
        onClose={() => {
          setDocviewOpenStatus(false);
        }}
      />
      <SlidingPanel
        type={"right"}
        isOpen={open_status}
        size={75}
        panelClassName="sliding-panel"
      >
        <div>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={10}>
              <h4 className="mb-0 mt-3">Initiate a Claim</h4>
            </Grid>
            <Grid xs={2} textAlign="end" className="mt-3">
              <GridCloseIcon
                onClick={() => {
                  value_update(attrName, false);
                  setSelectedPolicy({});
                  setPolicy_data([]);
                  setPrimaryEmployeeId("");
                  setEmployee_id_data([]);
                  setClaimFormData({
                    clientId: { label: "", id: "", warning: false },
                    employeeId: { label: "", id: "", warning: false },
                    selectPolicy: { label: "", id: "", warning: false },
                    insurer: { label: "", id: "", warning: false },
                    hospital: { label: "", id: "", warning: false },
                    insurerPerson: { value: "", warning: false },
                    country: { label: "", id: "", warning: false },
                    state: { label: "", id: "", warning: false },
                    city: { label: "", id: "", warning: false },
                    claimAmount: { value: "", warning: false },
                    hospitalName: { value: "", warning: false },
                    admissionDate: { value: null, warning: false },
                    dischargeDate: { value: null, warning: false },
                    reasonForHospitalization: { value: "", warning: false },
                    pincode: { value: "", warning: false },
                  });
                  setDisplayData({ doc: "", warning: false, doc_name: "" });
                  setAttachFir(false);
                  setAddDocumentField([
                    {
                      doc: "",
                      docName: "",
                      warning: false,
                      des: "",
                      desWarn: false,
                    },
                  ]);
                  setChecklist([]);
                  setDocumentStatus(0);
                  setTpaPolicy({ tpa: "" });
                  setTpaName("");
                  setSumInsuredAmount("");
                  setPaidClaimAmount(0);
                  setKycDocumentStatus("Intimation");
                  setdocument({
                    label: "",
                    id: "",
                    attachment: "",
                    downloadLink: "",
                  });
                  setPhysicalFormData({ formList: [], link: "", value: "" });
                  if (setShortcutDisable) {
                    setShortcutDisable(false);
                  }
                  setDisableSave(false);
                }}
              />
            </Grid>
            <Grid xs={12} className="pt-0">
              <hr />
            </Grid>
          </Grid>
          <div className="scrollable_area">
            <div>
              <Grid container spacing={2} className="mb-2 mt-4">
                <Grid xs={12}>
                  <div className="selector width-per-item">
                    <Button
                      className={
                        kycDocumentStatus === "Intimation" ? "selection" : ""
                      }
                      style={{ color: "#000" }}
                      onClick={() => {
                        setKycDocumentStatus("Intimation");
                        setDocumentStatus(0);
                        value_update(attrName, true);
                        setPolicy_data([]);
                        setSelectedPolicy({});
                        setPrimaryEmployeeId("");
                        setEmployee_id_data([]);
                        setClaimFormData({
                          clientId: { label: "", id: "", warning: false },
                          employeeId: { label: "", id: "", warning: false },
                          selectPolicy: { label: "", id: "", warning: false },
                          insurer: { label: "", id: "", warning: false },
                          hospital: { label: "", id: "", warning: false },
                          insurerPerson: { value: "", warning: false },
                          country: { label: "", id: "", warning: false },
                          state: { label: "", id: "", warning: false },
                          city: { label: "", id: "", warning: false },
                          claimAmount: { value: "", warning: false },
                          hospitalName: { value: "", warning: false },
                          admissionDate: { value: null, warning: false },
                          dischargeDate: { value: null, warning: false },
                          reasonForHospitalization: {
                            value: "",
                            warning: false,
                          },
                          pincode: { value: "", warning: false },
                        });
                        setDisplayData({
                          doc: "",
                          warning: false,
                          doc_name: "",
                        });
                        setAttachFir(false);
                        setAddDocumentField([
                          {
                            doc: "",
                            docName: "",
                            warning: false,
                            des: "",
                            desWarn: false,
                          },
                        ]);
                        setChecklist([]);
                        setTpaPolicy({ tpa: "" });
                        setTpaName("");
                        setSumInsuredAmount("");
                        setPaidClaimAmount(0);
                        setdocument({
                          label: "",
                          id: "",
                          attachment: "",
                          downloadLink: "",
                        });
                        setPhysicalFormData({
                          formList: [],
                          link: "",
                          value: "",
                        });
                        if (setShortcutDisable) {
                          setShortcutDisable(false);
                        }
                      }}
                    >
                      <ArticleIcon /> Claim Reimbursement
                    </Button>
                    <Button
                      className={
                        kycDocumentStatus === "Cashless" ? "selection" : ""
                      }
                      style={{ color: "#000" }}
                      onClick={() => {
                        setKycDocumentStatus("Cashless");
                        setDocumentStatus(1);
                        value_update(attrName, true);
                        setSelectedPolicy({});
                        setPolicy_data([]);
                        setPrimaryEmployeeId("");
                        setEmployee_id_data([]);
                        setClaimFormData({
                          clientId: { label: "", id: "", warning: false },
                          employeeId: { label: "", id: "", warning: false },
                          selectPolicy: { label: "", id: "", warning: false },
                          insurer: { label: "", id: "", warning: false },
                          hospital: { label: "", id: "", warning: false },
                          insurerPerson: { value: "", warning: false },
                          country: { label: "", id: "", warning: false },
                          state: { label: "", id: "", warning: false },
                          city: { label: "", id: "", warning: false },
                          claimAmount: { value: "", warning: false },
                          hospitalName: { value: "", warning: false },
                          admissionDate: { value: null, warning: false },
                          dischargeDate: { value: null, warning: false },
                          reasonForHospitalization: {
                            value: "",
                            warning: false,
                          },
                          pincode: { value: "", warning: false },
                        });
                        setDisplayData({
                          doc: "",
                          warning: false,
                          doc_name: "",
                        });
                        setAttachFir(false);
                        setAddDocumentField([
                          {
                            doc: "",
                            docName: "",
                            warning: false,
                            des: "",
                            desWarn: false,
                          },
                        ]);
                        setChecklist([]);
                        setTpaPolicy({ tpa: "" });
                        setTpaName("");
                        setSumInsuredAmount("");
                        setPaidClaimAmount(0);
                        setdocument({
                          label: "",
                          id: "",
                          attachment: "",
                          downloadLink: "",
                        });
                        setPhysicalFormData({
                          formList: [],
                          link: "",
                          value: "",
                        });
                        if (setShortcutDisable) {
                          setShortcutDisable(false);
                        }
                      }}
                    >
                      <AccountBalanceWalletIcon /> Cashless Request
                    </Button>
                  </div>
                  <p className="small-text">
                    {kycDocumentStatus === "Cashless"
                      ? "Use Cashless Request for planned hospitalisation where bills are settled directly by Insurer."
                      : "Use Claim Reimbursement (cash) to inform your Claim Administrator for upcoming Pre-Authorization/Cashless Procedures"}
                  </p>
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Client ID"
                    value={claimFormData.clientId.label}
                    attrName={["claimFormData", "clientId"]}
                    value_update={updateMasterState}
                    data={client_id_data ?? []}
                    warn_status={claimFormData.clientId.warning}
                    error_message={"Select client ID"}
                    disabled={userType === "BROKER" ? false : true}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Member ID"
                    value={claimFormData.employeeId.label}
                    attrName={["claimFormData", "employeeId"]}
                    value_update={updateMasterState}
                    data={employee_id_data.filter((item: any) => item?.label)}
                    warn_status={claimFormData.employeeId.warning}
                    error_message={"Select employee ID"}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Select Policy"
                    value={claimFormData.selectPolicy.label}
                    attrName={["claimFormData", "selectPolicy"]}
                    value_update={updateMasterState}
                    data={policy_data}
                    warn_status={claimFormData.selectPolicy.warning}
                    error_message={"Select policy"}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title="Insurer"
                    value={claimFormData.insurer.label}
                    attrName={["claimFormData", "insurer"]}
                    value_update={updateMasterState}
                    warn_status={claimFormData.insurer.warning}
                    error_messg={"Select insurer"}
                    disabled={true}
                    required
                  />
                </Grid>
                {selectedPolicy?.startDate && selectedPolicy?.endDate && (
                  <>
                    <Grid xs={6} padding={0} className="pl-2">
                      <h5 className="normal-text mb-1">
                        Policy Start Date :{" "}
                        {date_time_format(
                          selectedPolicy.startDate,
                          dateFormat,
                          timeZone
                        )}
                      </h5>
                    </Grid>
                    <Grid xs={6} padding={0} className="pl-2">
                      <h5 className="normal-text mb-1">
                        Policy End Date :{" "}
                        {date_time_format(
                          selectedPolicy.endDate,
                          dateFormat,
                          timeZone
                        )}
                      </h5>
                    </Grid>
                  </>
                )}
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title="tpa"
                    value={tpaName}
                    attrName={["claimFormData", "tpaName"]}
                    value_update={updateMasterState}
                    warn_status={false}
                    error_messg={"Select tpa"}
                    disabled={true}
                    required
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title="Insured Person Hospitalized"
                    value={claimFormData.employeeId.label}
                    attrName={["claimFormData", "insurerPerson"]}
                    value_update={updateMasterState}
                    warn_status={false}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Document Checklist"
                    value={document.label}
                    attrName={setdocument}
                    value_update={updateMasterState}
                    data={checklist?.map((item: any) => {
                      return {
                        label: item?.title,
                        id: item?._id,
                        attachment: item?.attachment,
                        downloadLink: item?.downloadLink,
                      };
                    })}
                    warn_status={false}
                  />
                </Grid>
                <Grid
                  xs={6}
                  className="ctaBtn"
                  display={"flex"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  {document.id && (
                    <>
                      <Link
                        className="blueBtn view ml-4"
                        target="_blank"
                        onClick={async () => {
                          debugger
                          let docUrl = document?.attachment?.includes("http")
                            ? document?.attachment
                            : (await getFileSignedUrl(
                                document.attachment,
                                userType
                              )) || "";
                          setselectedDocUrl(docUrl);
                          setDocviewOpenStatus(true);
                        }}
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        View
                      </Link>
                      <Link
                        className="blueBtn download ml-4"
                        target="_blank"
                        // href={document.downloadLink}
                        onClick={() =>
                          downloadAttachement(document.downloadLink, userType)
                        }
                        sx={{
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </Link>
                    </>
                  )}
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Country"
                    value={claimFormData.country.label}
                    attrName={["claimFormData", "country"]}
                    value_update={updateMasterState}
                    data={country_data}
                    warn_status={claimFormData.country.warning}
                    error_message={" Select Country"}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="State"
                    value={claimFormData.state.label}
                    attrName={["claimFormData", "state"]}
                    value_update={updateMasterState}
                    data={state_data}
                    warn_status={claimFormData.state.warning}
                    error_message={" Select State"}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <SearchDropdown
                    class_name="inputField"
                    title="City"
                    value={claimFormData.city.label}
                    attrName={["claimFormData", "city"]}
                    value_update={updateMasterState}
                    data={city_data}
                    warn_status={claimFormData.city.warning}
                    error_message={"Select city"}
                    required
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Pin Code"}
                    value={claimFormData?.pincode?.value}
                    attrName={["claimFormData", "pincode"]}
                    value_update={updateMasterState}
                    warn_status={claimFormData?.pincode?.warning}
                    error_messg={
                      !claimFormData.pincode.value
                        ? "Enter Pin Code"
                        : "Enter valid Pin Code"
                    }
                    charcterlimit={6}
                    validation_type="numeric"
                    required
                  />
                </Grid>
                <Grid xs={12}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Choose Hospital"
                    value={claimFormData.hospital.label}
                    attrName={["claimFormData", "hospital"]}
                    value_update={updateMasterState}
                    data={
                      hospitals?.length > 0
                        ? hospitals
                        : [{ label: "Others", id: "Others" }]
                    }
                    warn_status={claimFormData.hospital.warning}
                    error_message={"Choose Hospital"}
                    required
                  />
                </Grid>

                {claimFormData.hospital.label === "Others" && (
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Hospital Name"}
                      value={claimFormData.hospitalName.value}
                      attrName={["claimFormData", "hospitalName"]}
                      value_update={updateMasterState}
                      warn_status={claimFormData.hospitalName.warning}
                      required
                    />
                  </Grid>
                )}
                <Grid xs={physicalFormData?.link ? 5 : 6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Select Physical claim Form"
                    value={physicalFormData.value}
                    attrName={setPhysicalFormData}
                    value_update={updateMasterState}
                    dropdown_data={physicalFormData.formList}
                    warn_status={false}
                  />
                </Grid>
                {physicalFormData?.link && (
                  <Grid
                    xs={1}
                    className="mb-5"
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Link
                      className="blueBtn download ml-4"
                      target="_blank"
                      // href={physicalFormData.link}
                      onClick={() =>
                        downloadAttachement(physicalFormData.link, userType)
                      }
                      sx={{
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    ></Link>
                  </Grid>
                )}
                <Grid xs={6}>
                  <RKTextField
                    validation_type="numeric"
                    class_name="inputField"
                    title="Claim Amount"
                    value={formatCurrency(
                      Number(claimFormData.claimAmount.value)
                    )}
                    attrName={["claimFormData", "claimAmount"]}
                    value_update={updateMasterState}
                    warn_status={claimFormData.claimAmount.warning}
                    error_messg={"Enter Claim Amount"}
                    required
                  />
                  {sumInsuredAmount && (
                    <h5
                      className="normal-text mb-1"
                      style={{ color: "#eb5757" }}
                    >
                      Balance SI :{" "}
                      {formatCurrency(
                        Number(+sumInsuredAmount - paidClaimAmount)
                      )}
                    </h5>
                  )}
                </Grid>

                {documentStatus === 0 ? (
                  <>
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField"
                        title="Admission Date"
                        value={claimFormData.admissionDate.value}
                        attrName={["claimFormData", "admissionDate"]}
                        value_update={updateMasterState}
                        error_message="Enter Admission Date"
                        warn_status={claimFormData.admissionDate.warning}
                        required
                        min_date={selectedPolicy?.startDate ?? null}
                        max_date={
                          new Date(lastWorkingDaySelectedEmployee) <
                            new Date(selectedPolicy?.endDate) &&
                          new Date(lastWorkingDaySelectedEmployee) < new Date()
                            ? lastWorkingDaySelectedEmployee
                            : new Date(selectedPolicy?.endDate) <
                                new Date(lastWorkingDaySelectedEmployee) &&
                              new Date(selectedPolicy?.endDate) < new Date()
                            ? selectedPolicy?.endDate
                            : new Date()
                        }
                      />
                    </Grid>
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField"
                        title="Discharge Date"
                        value={claimFormData.dischargeDate.value}
                        attrName={["claimFormData", "dischargeDate"]}
                        value_update={updateMasterState}
                        error_message="Enter Discharge Date"
                        warn_status={claimFormData.dischargeDate.warning}
                        required
                        min_date={
                          claimFormData?.admissionDate?.value
                            ? claimFormData?.admissionDate?.value
                            : selectedPolicy?.startDate
                            ? selectedPolicy?.startDate
                            : null
                        }
                      />
                    </Grid>
                  </>
                ) : null}
                {documentStatus === 1 ? (
                  <>
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField"
                        title="Admission Date"
                        value={claimFormData.admissionDate.value}
                        attrName={["claimFormData", "admissionDate"]}
                        value_update={updateMasterState}
                        error_message="Enter Admission Date"
                        min_date={
                          selectedPolicy?.startDate
                            ? selectedPolicy?.startDate
                            : null
                        }
                        max_date={
                          new Date(lastWorkingDaySelectedEmployee) <
                          new Date(selectedPolicy?.endDate)
                            ? lastWorkingDaySelectedEmployee
                            : selectedPolicy?.endDate
                        }
                        warn_status={claimFormData.admissionDate.warning}
                        required
                      />
                    </Grid>
                  </>
                ) : null}

                <Grid xs={12}>
                  <TextAreaField
                    class_name="inputField multiline"
                    title={"Reason For Hospitalization"}
                    value={claimFormData.reasonForHospitalization.value}
                    attrName={["claimFormData", "reasonForHospitalization"]}
                    value_update={updateMasterState}
                    warn_status={claimFormData.reasonForHospitalization.warning}
                    error_messg="Enter Reason For Hospitalization"
                    required
                  />
                </Grid>

                <Grid xs={12}>
                  <CheckBox
                    title="If it is a legal case, you must attach the FIR as well."
                    name="regAddress"
                    id="regAddress"
                    value={attachFir}
                    attrName={setAttachFir}
                    value_update={updateMasterState}
                    default_check={false}
                  />
                </Grid>

                {attachFir === true ? (
                  <>
                    <Grid
                      xs={10}
                      className="inputField uploadFile"
                      paddingRight={0}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        value={displayData.doc_name}
                        variant="outlined"
                        className="textarea"
                        placeholder="Upload document"
                        disabled
                      />
                    </Grid>
                    <Grid xs={2} paddingLeft={0}>
                      <Button
                        variant="contained"
                        className="browsebtn"
                        component="label"
                      >
                        Browse
                        <input
                          hidden
                          // accept="image/*"
                          multiple
                          type="file"
                          style={{ zIndex: "99" }}
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            const fileSize = file?.size;
                            const maxSizeBytes = 8 * 1024 * 1024;
                            if (fileSize && fileSize > maxSizeBytes) {
                              toast.warn("File size exceeds 8 MB limit");
                              return (e.target.value = null);
                            }
                            const allowExt = allowFileExtention(file);
                            if (!allowExt) {
                              toast.warn(
                                "Invalid file type. Please upload a PDF, DOC, or Image file."
                              );
                              return (e.target.value = null);
                            }

                            uploadImage(
                              file,
                              (base64Image: any, base64String: any) => {
                                setDisplayData((prevDisplayData: any) => ({
                                  ...prevDisplayData,
                                  doc: base64Image,
                                  doc_name: e.target.files[0]?.name,
                                }));
                              }
                            );
                          }}
                        />
                      </Button>
                    </Grid>

                    <Grid xs={9}>
                      <div className="uploadAttachmentInfo">
                        <span>Maximum size of attachment is 8 MB*</span>
                      </div>
                    </Grid>
                    <Grid xs={3} textAlign={"right"}>
                      {displayData.warning && (
                        <span
                          className="textAreaerror  pr-3"
                          style={{ color: "#eb5757", fontSize: "11px" }}
                        >
                          Upload File
                        </span>
                      )}
                    </Grid>
                  </>
                ) : null}

                <Grid xs={10}>
                  <p className="note_text">
                    Note:- Attach all the original hospital bills, from the
                    admission date till the date of filing the claim.
                  </p>
                </Grid>
                <Grid xs={2}>
                  {tpaName?.toLowerCase() !== TPA_TYPE.HEALTHINDIA && (
                      <Link
                        className="blueBtn addfile ml-0"
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                        onClick={() => {
                          setAddDocumentField((addDocumentField) => [
                            ...addDocumentField,
                            {
                              doc: "",
                              docName: "",
                              warning: false,
                              des: "",
                              desWarn: false,
                            },
                          ]);
                        }}
                      >
                        Add
                      </Link>
                    )}
                </Grid>
                {addDocumentField?.map((data, index) => (
                  <>
                    <Grid
                      xs={index === 0 ? 9 : 7}
                      paddingBottom={0}
                      className="inputField uploadFile"
                      paddingRight={0}
                    >
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        value={addDocumentField[index].docName}
                        variant="outlined"
                        className="textarea"
                        placeholder="Upload document"
                        disabled
                      />
                    </Grid>
                    <Grid
                      xs={3}
                      paddingLeft={0}
                      paddingBottom={0}
                      textAlign={"end"}
                    >
                      <Button
                        variant="contained"
                        className="browsebtn"
                        component="label"
                      >
                        Browse
                        <input
                          hidden
                          // accept="image/*"
                          multiple
                          type="file"
                          style={{ zIndex: "99" }}
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            const fileSize = file?.size;
                            const maxSizeBytes = 8 * 1024 * 1024;
                            if (fileSize && fileSize > maxSizeBytes) {
                              toast.warn("File size exceeds 8 MB limit");
                              return (e.target.value = null);
                            }
                            const allowExt = allowFileExtention(file);
                            if (!allowExt) {
                              toast.warn(
                                "Invalid file type. Please upload a PDF, DOC, or Image file."
                              );
                              return (e.target.value = null);
                            }
                            uploadImage(
                              file,
                              (base64Image: any, base64String: any) => {
                                setAddDocumentField((prevDisplayData: any) => {
                                  const updatedFields = [...prevDisplayData];
                                  updatedFields[index].doc = base64Image;
                                  updatedFields[index].docName =
                                    e.target.files[0]?.name;
                                  updatedFields[index].warning = false;
                                  return updatedFields;
                                });
                              }
                            );
                          }}
                        />
                      </Button>
                    </Grid>
                    {index === 0 ? null : (
                      <Grid
                        xs={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"end"}
                      >
                        <Link
                          className="redBtn delete ml-0"
                          underline="none"
                          onClick={() => {
                            setAddDocumentField((addDocumentField: any) =>
                              addDocumentField?.filter(
                                (_: any, i: number) => i !== index
                              )
                            );
                          }}
                        >
                          Remove
                        </Link>
                      </Grid>
                    )}
                    <Grid xs={7} padding={0}>
                      <div className="uploadAttachmentInfo">
                        <span>Maximum size of attachment is 8 MB*</span>
                      </div>
                    </Grid>
                    {addDocumentField[index].warning && (
                      <Grid
                        xs={index === 0 ? 5 : 3}
                        padding={0}
                        textAlign={"end"}
                      >
                        <span
                          className="textAreaerror pr-3"
                          style={{
                            color: "#eb5757",
                            fontSize: "11px",
                            textAlign: "right",
                          }}
                        >
                          Upload File
                        </span>
                      </Grid>
                    )}
                    <Grid xs={12}>
                      <TextAreaField
                        class_name="inputField multiline"
                        title={"Description"}
                        value={addDocumentField[index].des}
                        attrName={["addDocumentField", "des", index]}
                        value_update={updateMasterState}
                        warn_status={addDocumentField[index].desWarn}
                        error_messg="Enter Description"
                        required
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </div>
          <Grid xs={12} className="footer_sec w-100">
            <Button
              variant="contained"
              className="save_btn"
              onClick={form_submit}
              disabled={
                (kycDocumentStatus === "Cashless" &&
                  (tpaName?.toLowerCase() === TPA_TYPE.PARAMOUNT ||
                    tpaName?.toLowerCase() === TPA_TYPE.MEDIASSIST ||
                    tpaName?.toLowerCase() === TPA_TYPE.MDINDIA)) ||
                disableSave
              }
            >
              Submit
            </Button>
          </Grid>
        </div>
      </SlidingPanel>
    </>
  );
};

export default InitiateAClaim;
