import { Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../OrganisationSettings/Currencies/Currencies.scss";

import GlobalDeletePopup from "../../../../../../Components/Broker/GlobalDeletePopup/GlobalDeletePopup";
import GlobelEditPopup from "../../../../../../Components/Broker/GlobalEditPopup/GlobalEditPopup";
import { ALL_ROUTES } from "../../../../../../Routes/all_routes";
import { E_BE_FILTER_CODE } from "../../../../../../Services/Enum/E_UGTable";
import { ACCESS_LEVEL_SERVICES } from "../../../../../../Services/Settings/AccessLevelServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  date_time_format,
  updateTimeFormat,
} from "../../../../../../Supporting files/HelpingFunction";
import UGTable from "../../../../../../Supporting files/UGTable/UGTable";
import { RootState } from "../../../../../../Store/Store";
import SelectDropdown from "../../../../../../Supporting files/SelectDropdown/SelectDropdown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import { ar } from "date-fns/locale";
const AccessControl = () => {
  const navigate = useNavigate();
  const user_type_dateFormat = useAppSelector(
    (state) => state.userDetailsSlice.localInformation.dateFormat
  );
  const tz = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation.timeZone
  );
  const [editStatus, setEditStatus] = React.useState(false);
  const [editPopupTitle, setEditPopupTitle] = useState("");
  const { companyCode } = useAppSelector((state) => state.userDetailsSlice);
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [AccessControlCreatePermission, setAccessControlCreatePermission] =
    useState(false);
  const [AccessControlEiditPermission, setAccessControlEiditPermission] =
    useState(false);
  const [AccessControlRemovePermission, setAccessControlRemovePermission] =
    useState(false);

  useEffect(() => {
    setAccessControlCreatePermission(
      !EMPLOYER
        ? true
        : EMPLOYER.AccessControl.add === undefined
          ? false
          : EMPLOYER.AccessControl.add
    );
    setAccessControlEiditPermission(
      !EMPLOYER
        ? true
        : EMPLOYER.AccessControl.edit === undefined
          ? false
          : EMPLOYER.AccessControl.edit
    );
    setAccessControlRemovePermission(
      !EMPLOYER
        ? true
        : EMPLOYER.AccessControl.remove === undefined
          ? false
          : EMPLOYER.AccessControl.remove
    );
    get_status(
      EMPLOYER?.AccessControl?.status === undefined
        ? false
        : EMPLOYER?.AccessControl?.status
    );
  }, []);

  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [deleteStatusStep, setDeleteStatusStep] = React.useState(1);
  const [array_delete, setArray_Delete] = useState<Array<{ id: number }>>([]);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  //Table Data
  const [columns, setColumns] = useState<any>([]);
  // redux state

  const [rows, setRows] = useState([]);

  useEffect(() => {
    get_data_access_control();
  }, [deleteStatus]);

  const get_status = (statusPermission1: any) => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "roleid",
          headerName: "Role ID",
          width: 200,
          check_status: true,
          valueGetter: (params: any) => {
            const { row } = params;
            // Construct the full address for filtering and sorting
            return row?.roleId;
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return (
              <Link
                underline="none"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`${ALL_ROUTES.EDIT_ACCESS_CONTROL}?id=${row.id}`);
                }}
              >
                {row.roleId}
              </Link>
            );
          },
        },
        {
          field: "role",
          headerName: "Role",
          width: 250,
          check_status: true,
          valueGetter: (params: any) => {
            const { row } = params;
            // Construct the full address for filtering and sorting
            return row?.roleName;
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row.roleName}</p>;
          },
        },
        {
          field: "status",
          headerName: "Status",
          minWidth: 200,
          check_status: true,
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return statusData.filter((fd:any)=>fd.label === row.status)[0].value
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 15 ? (
                    <CheckCircleIcon id={`_${row.status.toString()}`} />
                  ) : row.status === 16 ? (
                    <HighlightOffIcon id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    disabled={!statusPermission1}
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.createdAt, user_type_dateFormat, tz)
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.updatedAt, user_type_dateFormat, tz)
          },
        },
        {
          field: "assignedusers",
          headerName: "Assigned Users",
          width: 200,
          check_status: true,
          valueGetter: (params: any) => {
            const { row } = params;
            // Construct the full address for filtering and sorting
            return row?.totalUsers;
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return <p>{row?.totalUsers}</p>
          },
        },
      ]);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ACCESS_LEVEL_SERVICES.get_status_access_control(onSuccess, onError);
  };

  const get_data_access_control = () => {
    let params = {
      companyCode: companyCode,
    };
    const onSuccess = (res: any) => {
      const data = res.data.data.map((e: any) => ({
        ...e,
        id: e._id,
      }));
      setRows(data);
    };
    const onError = (err: any) => {
      console.log("true", err);
    };
    ACCESS_LEVEL_SERVICES.getEmployerAccessControlData(onSuccess, onError);
  };

  const delete_employee = (attrName?: any, step?: any) => {
    // success delete
    console.log("rows", rows);
    // const finalDeleteArray = rows?.filter((item: any, index) => {
    //   if (array_delete.includes(item.id) && item?.totalUsers === 0) {
    //     return item;
    //   }
    // });
    // console.log("finalDeleteArray", finalDeleteArray);

    let ids = {
      ids: array_delete,
    };

    const onSuccess = (res: any) => {
      setDeleteStatusStep(2);
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    ACCESS_LEVEL_SERVICES.delete_employer_user_accessControl(
      ids,
      onSuccess,
      {},
      onError
    );
    if (attrName === "data") {
      setDeleteStatusStep(step);
    }
  };

  const edit_navigate = () => {
    if (array_delete.length === 0) {
      setEditPopupTitle("Please select the role for edit");
      setEditStatus(true);
      return;
    } else if (array_delete.length > 1) {
      setEditPopupTitle("Only one role allow at a time");
      setEditStatus(true);
      return;
    } else {
      navigate(`${ALL_ROUTES.EDIT_ACCESS_CONTROL}?id=${array_delete[0]}`);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "selected_data") {
      setArray_Delete(value);
    } else if (attrName === "delete_step") {
      setDeleteStatusStep(value);
    } else if (attrName[0] === "updatestatus") {
      isBeingUsed(attrName[1]._id, value);
    } else {
      attrName(value);
    }
  };

  const isBeingUsed = (id: string, value: any) => {
    const onSuccess = (res: any) => {
      if (res.data.data.is_exist) {
        toast.warning("This Role is being used,Can't Inactive it. ");
      } else {
        update_status(value, id);
      }
    };
    const onError = (err: any) => {
      console.log("isBeingUsed", err);
    };
    ACCESS_LEVEL_SERVICES.isBeingUsed(onSuccess, onError, id);
  };
  const update_status = (value: number, id: string) => {
    const onSuccess = (res: any) => {
      get_data_access_control();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ACCESS_LEVEL_SERVICES.employer_update_status_access_control(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };
  return (
    <Grid container spacing={2} className="currunciesWrapper">
      <Grid xs={4}>
        <h3 className="mb-0">Access Control</h3>
      </Grid>

      <Grid xs={8} className="text-right">
        <div className="buttons">
          {AccessControlCreatePermission && (
            <Link
              underline="none"
              className="greenBtn addfile"
              onClick={() => {
                navigate(ALL_ROUTES.CREATE_ACCESS_CONTROL);
              }}
            >
              Create
            </Link>
          )}
          {AccessControlEiditPermission && (
            <Link
              underline="none"
              className="blueBtn edit"
              onClick={edit_navigate}
            >
              Edit
            </Link>
          )}
          {AccessControlRemovePermission && (
            <Link
              underline="none"
              className="redBtn delete"
              onClick={() => {
                const isRoleAssociateWithUser = rows?.filter(
                  (item: any, index) => {
                    if (
                      array_delete.includes(item.id) &&
                      item?.totalUsers > 0
                    ) {
                      return item;
                    }
                  }
                );
                if (isRoleAssociateWithUser.length === 0) {
                  setDeleteStatus(true);
                } else if (isRoleAssociateWithUser.length > 0) {
                  toast.error(
                    "You have select some roles which are associated with some users"
                  );
                }
              }}
            >
              Remove
            </Link>
          )}
        </div>
      </Grid>
      <Grid xs={12}>
        <div style={{ height: 400 }}>
          <UGTable
            header_data={columns}
            data={rows}
            value_update={updateMasterState}
            attrName={"UGTable"}
            BE_filter_code={E_BE_FILTER_CODE.ACCESS_CONTROL}
          />
        </div>
      </Grid>
      <GlobalDeletePopup
        open_status={deleteStatus}
        value_update={updateMasterState}
        attrName={setDeleteStatus}
        delete_step={deleteStatusStep}
        delete_item={() => {
          delete_employee("data", deleteStatusStep);
        }}
        ids={array_delete}
        title="User Data"
      />
      <GlobelEditPopup
        popupStatus={editStatus}
        setPopupStatus={setEditStatus}
        title={editPopupTitle}
      />
    </Grid>
  );
};

export default AccessControl;
