//used
import Rejected from "@mui/icons-material/Cancel";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import PartialApproval from "@mui/icons-material/Rule";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_ROUTES } from "../../../Routes/all_routes";
import { Claim_SERVICES } from "../../../Services/Claim/ClaimServices";
import { EMPLOYEE_SERVICES } from "../../../Services/Employee/EmployeeService";
import { EMPLOYER_EMPLOYEE_SERVICES } from "../../../Services/EmployerServices/Employee/EmployeeServices";
import { E_BE_FILTER_CODE } from "../../../Services/Enum/E_UGTable";
import { useAppSelector } from "../../../Store/hooks";
import SelectDropdown from "../../../Supporting files/SelectDropdown/SelectDropdown";
import UGTable from "../../../Supporting files/UGTable/UGTable";
import { date_time_format } from "../../../Supporting files/HelpingFunction";
import { RootState } from "../../../Store/Store";
import NewLoader from "../../NewLoader/NewLoader";
import { CLIENT_SERVICES } from "../../../Services/Clients/ClientService";

const ClaimList = ({ id }: { id: any }) => {
  const { dateFormat, timeZone } = useAppSelector(
    (state: RootState) => state.userDetailsSlice.localInformation
  );
  const [showLoader, setShowLoader] = useState(true);
  const user = useAppSelector((state) => state.userDetailsSlice.userType);
  const [sectionStatus, SetSectionStatus] = useState<string>("claimTab");
  const claimTab = useRef(null);
  const [prioritystatusData, setprioritystatusData] = useState([]);
  const [columns, setColumns] = useState<any>([]);
  const [endorsement, setEndorsement] = useState<any>([]);
  const updateMasterState = (attrName: any, value: any) => { };
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableLimit, setTableLimit] = useState(25);
  const [tableSkip, setTableSkip] = useState();
  const [anchorEl, setAnchorEl] = useState(null);


  const get_endorsement_data = (limit?: number, skip?: number, csv?: any) => {
    const onSuccess = (res: any) => {
      const data: any = res.data.data.claimData.map((data: any) => ({
        ...data,
        id: data._id,
      }));
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);
      if (!csv) {
        setEndorsement(data);
        setTotalRecords(res.data.data.countData);
      }
      if (csv) {
        downloadCsv(res.data.data.claimData);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      setShowLoader(false);
    };
    const currentRoute = E_BE_FILTER_CODE.EMPOYEEDETAILSCLAIMS.toString();
    const rowPerPage = sessionStorage.getItem("rowPerPage");
    const parsedData = rowPerPage ? JSON.parse(rowPerPage) : [];
    const existingEntryIndex = parsedData.find(
      (entry: any) => entry.route === currentRoute
    );
    {
      user === "BROKER"
        ? EMPLOYEE_SERVICES.get_claims_list(
          onSuccess,
          onError,
          id,
          limit ? limit : existingEntryIndex?.pageNumber ?? 25,
          skip ?? 0
        )
        : EMPLOYER_EMPLOYEE_SERVICES.get_claims_list(
          onSuccess,
          onError,
          id,
          limit ? limit : existingEntryIndex?.pageNumber ?? 25,
          skip ?? 0
        );
    }
  };


  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setprioritystatusData(statusData);
      setColumns([
        {
          field: "claimId",
          headerName: "Claim ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "clientId",
          headerName: "Client ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "clientName",
          headerName: "Client Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "memberId",
          headerName: "Member ID",
          minWidth: 150,
          check_status: true,
        },
        {
          field: "memberName",
          headerName: "Member Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "insurer",
          headerName: "Insurer Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "tpaName",
          headerName: "TPA Name",
          minWidth: 200,
          check_status: true,
        },
        {
          field: "designation",
          headerName: "Designation",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return row.designation || "--";
          },
        },
        {
          field: "department",
          headerName: "Department",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return row.department || "--";
          },
        },
        {
          field: "policyNumber",
          headerName: "Policy Number",
          minWidth: 250,
          check_status: true,
        },
        {
          field: "policyType",
          headerName: "Policy Type",
          minWidth: 250,
          check_status: true,
        },
        {
          field: "status",
          headerName: "Claim Status",
          minWidth: 250,
          check_status: true,
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return statusData.filter((fd:any)=>fd.label === row.status)[0].value
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return (
              <div id={`_${row.status}`}>
                <div className="status_dropdown" id={`_${row.status}`}>
                  {row.status === 47 ? (
                    <Requested id={`_${row.status.toString()}`} />
                  ) : row.status === 48 ? (
                    <SentToTPA id={`_${row.status.toString()}`} />
                  ) : row.status === 49 ? (
                    <Required id={`_${row.status.toString()}`} />
                  ) : row.status === 50 ? (
                    <Approved id={`_${row.status.toString()}`} />
                  ) : row.status === 51 ? (
                    <Rejected id={`_${row.status.toString()}`} />
                  ) : row.status === 52 ? (
                    <Expired id={`_${row.status.toString()}`} />
                  ) : row.status === 53 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 65 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 66 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 67 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 68 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 69 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : row.status === 70 ? (
                    <PartialApproval id={`_${row.status.toString()}`} />
                  ) : null}
                  <SelectDropdown
                    class_name="inputFieldd"
                    title=""
                    value={row.status}
                    attrName={["updatestatus", row, index]}
                    value_update={updateMasterState}
                    dropdown_data={statusData}
                    warn_status={false}
                    // disabled={row.tpaName === "Medi Assist"}
                    disabled
                  />
                </div>
              </div>
            );
          },
        },
        {
          field: "TpaClaimStatus",
          headerName: "TPA Claim Status",
          minWidth: 200,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row, index } = ValueFormatterParams;
            return row.tpa_status ? (
              <>
                <div id={`_${row.status}`}>
                  <div className="status_dropdown" id={`_${row.status}`}>
                    {row.status === 47 ? (
                      <Requested id={`_${row.status.toString()}`} />
                    ) : row.status === 48 ? (
                      <SentToTPA id={`_${row.status.toString()}`} />
                    ) : row.status === 49 ? (
                      <Required id={`_${row.status.toString()}`} />
                    ) : row.status === 50 ? (
                      <Approved id={`_${row.status.toString()}`} />
                    ) : row.status === 51 ? (
                      <Rejected id={`_${row.status.toString()}`} />
                    ) : row.status === 52 ? (
                      <Expired id={`_${row.status.toString()}`} />
                    ) : row.status === 53 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 65 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 66 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 67 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 68 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 69 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : row.status === 70 ? (
                      <PartialApproval id={`_${row.status.toString()}`} />
                    ) : null}
                    <SelectDropdown
                      class_name="inputFieldd"
                      title=""
                      value={row.tpa_status}
                      attrName={["updatestatus", row, index]}
                      value_update={updateMasterState}
                      dropdown_data={[
                        { label: row?.tpa_status, id: row?.tpa_status },
                      ]}
                      warn_status={false}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              "--"
            );
          },
        },
        {
          field: "requestType",
          headerName: "Request Type",
          minWidth: 150,
          check_status: true,
          valueGetter: (params: any) => {
            const { row } = params;
            // Return a plain value for filtering and sorting
            return row.requestType.toLowerCase() === "intimation"
              ? "Reimbursement"
              : row.requestType;
          },
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;

            return (
              <>
                <p>
                  {row.requestType.toLowerCase() === "intimation"
                    ? "Reimbursement"
                    : row.requestType}
                </p>
              </>
            );
          },
        },
        {
          field: "insured_person",
          headerName: "Insured Person",
          minWidth: 150,
          check_status: true,
          renderCell: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            let insuredPerson: any = [] || "";
            let insureData = "";
            if (Array.isArray(row.insuredPerson)) {
              insuredPerson =
                row.insuredPerson &&
                row.insuredPerson.length > 0 &&
                row.insuredPerson.map((e: any) => {
                  return delete e._id, e.value;
                });
              insureData = insuredPerson.toString();
            } else {
              insureData = row.insuredPerson;
            }
            return (
              <>
                <p>{insureData}</p>
              </>
            );
          },
        },
        {
          field: "createdAt",
          minWidth: 200,
          check_status: true,
          headerName: "Created On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.createdAt, dateFormat, timeZone)
          },
        },
        {
          field: "updatedAt",
          minWidth: 200,
          check_status: true,
          headerName: "Updated On",
          valueGetter: (ValueFormatterParams: any) => {
            const { row } = ValueFormatterParams;
            return date_time_format(row.updatedAt, dateFormat, timeZone)
          },
        }
      ]);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    Claim_SERVICES.get_status(onSuccess, onError);
  };



  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadAll = () => {
    get_endorsement_data(totalRecords, 0, "csv");
    handleClose();
  };


  const downloadCsv = (CsvData: any) => {
    console.log("csvData", CsvData);
    const onSuccess = async (res: any) => {
      const data = await res;
      const blob = new Blob([data.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "claims.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    const updatedArr = CsvData?.map((item: any) => {
      const statusObj: any =
        prioritystatusData &&
        prioritystatusData?.find((status: any) => status.label === item.status);
      if (statusObj) {
        return {
          "Claim ID": item?.claimId,
          "Client ID": item?.clientId,
          "Client Name": item?.clientName,
          "Member ID": item?.memberId,
          "Member Name": item?.memberName,
          "Insurer Name": item?.insurer,
          "TPA Name": item?.tpaName,
          Designation: item?.designation,
          Department: item?.department,
          "Policy Number": item?.policyNumber,
          "Policy Type": item?.policyType,
          "Claim Status": statusObj?.value,
          "TPA Claim Status": "--",
          "Request Type":
            item?.requestType.toLowerCase() === "intimation"
              ? "Reimbursement"
              : item?.requestType,
          "Insured Person": item?.insuredPerson,
        };
      }
      return item;
    });
    console.log("updatedArr", updatedArr);
    columns.forEach((item: any, index: number) => {
      if (item.headerName === "Document") columns.splice(index, 1);
      item.field = item.headerName;
    });

    let dto = {
      headers: columns,
      data: updatedArr,
    };

    CLIENT_SERVICES.csvDownload(
      onSuccess,
      onError,
      dto,
      user.toLowerCase(),
      "claims"
    );
  };


  const handleDownloadSelected = () => {
    let csvData;
    if (tableLimit > endorsement.length) {
      csvData = endorsement;
      downloadCsv(csvData);
      handleClose();
    } else {
      csvData = endorsement;
      downloadCsv(csvData);
      handleClose();
    };

  }
  useEffect(() => {
    get_endorsement_data();
    get_status();
  }, []);

  return (
    <>
      {showLoader && <NewLoader />}
      <Grid container spacing={3}>
        <Grid
          xs
          style={{
            maxWidth: "260px",
            position: "relative",
            top: "-75px",
            paddingLeft: "0",
          }}
        >
          <div
            className="leftSection h-100"
            style={{ position: "fixed", paddingLeft: "8px" }}
          >
            <Link
              className={
                sectionStatus === "claimTab"
                  ? "active sectionLink claimTab"
                  : "sectionLink claimTab"
              }
            >
              <span>Claim</span>
            </Link>
          </div>
        </Grid>
        <Grid xs={12} className="text-right">

          {true && (
            <>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="blueBtn download"
                style={{ paddingLeft: 30, textTransform: "unset" }}
              >
                Download CSV
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDownloadAll}>All Items</MenuItem>
                <MenuItem onClick={handleDownloadSelected}>
                  Selected Items
                </MenuItem>
              </Menu>
            </>
          )}

        </Grid>
        <Grid xs paddingLeft={"0"}>
          <Box ref={claimTab}>
            <Grid xs={12} className="px-0 py-0">
              <Grid xs={12} className="detailBox mb-5">
                <Grid container padding="0">
                  <Grid xs={12} width={"100%"}>
                    <UGTable
                      header_data={columns}
                      data={endorsement}
                      value_update={updateMasterState}
                      attrName={"UGTable"}
                      BE_filter_code={E_BE_FILTER_CODE.EMPOYEEDETAILSCLAIMS}
                      refetch_data={get_endorsement_data}
                      totalRecords={totalRecords}
                      setTableLimit={setTableLimit}
                      setTableSkip={setTableSkip}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimList;
