import Rejected from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Expired from "@mui/icons-material/GppBad";
import Requested from "@mui/icons-material/MarkChatRead";
import SentToTPA from "@mui/icons-material/MarkEmailRead";
import Required from "@mui/icons-material/TipsAndUpdates";
import Approved from "@mui/icons-material/Verified";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_CONSTANTS } from "../../../../../APIVar/URLConstants";
import NotesSection from "../../../../../Components/Broker/NotesSection/NotesSection";
import PostQueryForm from "../../../../../Components/Common/PostQuerySP/PostQueryForm";
import { EndorsementDTO } from "../../../../../Services/DTO/EndorsementDTO";
import { ENDORSEMENT_SERVICES } from "../../../../../Services/Endorsement/EndorsementService";
import DatePickerDisable from "../../../../../Supporting files/DatePickerDisable/DatePickerDisable";
import {
  date_time_format,
  get_city_list_via_name,
  get_country_list,
  get_state_list,
  validateMobileNumber,
} from "../../../../../Supporting files/HelpingFunction";
import RKTextField from "../../../../../Supporting files/RKTextField/RKTextField";
import RKTextFieldDisable from "../../../../../Supporting files/RKTextFieldDisable/RKTextFieldDisable";
import SearchDropdownDisable from "../../../../../Supporting files/SearchDropdownDisable/SearchDropdownDisable";
import SelectDropdown from "../../../../../Supporting files/SelectDropdown/SelectDropdown";
import "../../../../../formLayout.scss";
import Attachment from "../Attachment/Attachment";
import { useAppSelector } from "../../../../../Store/hooks";
import NewLoader from "../../../../../Components/NewLoader/NewLoader";
import { useFormatCurrency } from "../../../../../Supporting files/CustomHooks";
import ConfirmationPopup from "../../../../../Components/Broker/ConfirmationPopUp/ConfirmationPopup";

function EndorsementDetail() {
  const { formatCurrency } = useFormatCurrency();

  const { userType, employerUser } = useAppSelector(
    (state: any) => state.userDetailsSlice
  );
  const navigate = useNavigate();
  const windowURL = window.location.search;
  const params = new URLSearchParams(windowURL);
  const id = params.get("id");
  const [viewTabsStatus, setViewTabsStatus] = React.useState("overview");
  const [notesData, setNotesData] = useState<
    Array<{ name: string; date: Date; description: string }>
  >([]);
  const [attachment, setAttachment] = React.useState(false);
  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [removeStatus, setRemoveStatus] = React.useState(false);
  const [priorityStatus, setPriorityStatus] = useState<number>(54);
  const [prioritystatusData, setprioritystatusData] = React.useState([]);
  const [addPermission, setAddPermission] = useState(false);

  const [sectionStatus, SetSectionStatus] =
    React.useState<string>("personalDetails");
  const [subSection, setSubSection] = React.useState("memberdetails");
  const [hrStatusValue, sethrStatusValue] = React.useState(77);
  const [postQueryForm, setPostQueryForm] = React.useState(false);
  const [countryData, setCountryData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [stateData, setStateData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [cityData, setCityData] = React.useState<
    Array<{ label: string; id: string }>
  >([]);
  const [selectedEndorsementValue, setselectedEndorsementValue] = useState<any>(
    {}
  );
  const [statusWarningPopoup, setstatusWarningPopoup] =
    useState<boolean>(false);
  const [disableAddress, setDisableAddress] = useState<boolean>(false);
  const [endorsementDetailsData, setEndorsementDeatilsData] =
    useState<EndorsementDTO>({
      createdAt: "",
      updatedAt: "",
      memberId: "",
      clientName: "",
      memberName: "",
      policyNumber: "",
      insurer: "",
      member: "",
      policyId: "",
      policy: "",
      clientId: "",
      client: "",
      type: "",
      endorsementFor: "",
      old: "",
      new: {
        dob: "",
        firstName: "",
        lastName: "",
        contactNumber: "",
        gender: "",
        email: "",
        street: "",
        area: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
      },
      attachment: {
        doc: "",
        description: "",
        doc_name: "",
      },
      attachments: "",
    });
  const [showLoader, setShowLoader] = useState(true);
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const personalDetails = useRef(null);
  const endorsementInfo = useRef(null);
  const attatchmentsdetails = useRef(null);
  const memberdetails = useRef(null);
  const endorsementDetails = useRef(null);
  console.log("hrStatusValue", hrStatusValue);
  const [downnloadCsvPermission, setDownloadCsvPermission] = useState(false);
  const { dateFormat, timeZone } = useAppSelector(
    (state: any) => state.userDetailsSlice.localInformation
  );
  const { EMPLOYER } = useAppSelector((state) => state.permissionSlice);
  const [changeStatusPermission, setChangeStatusPermission] = useState(false);
  const [hrStatusData, setHrStatusData] = useState([]);

  const scrollToSection = (elementRef: any) => {
    elementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleClicks = (ref: any) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const updateMasterState = (attrName: any, value: any) => {
    let data = { ...endorsementDetailsData };
    if (attrName === "country") {
      data = {
        ...data,
        new: {
          ...data.new,
          ["country"]: value.label,
          ["state"]: "",
          ["city"]: "",
        },
      };

      get_state_list(value.label, (cb: any) => {
        setStateData(cb);
      });
    } else if (attrName === setPostQueryForm) {
      setPostQueryForm(false);
    } else if (attrName === "notes_new_data") {
      endorsement_notes(value);
    } else if (attrName === "state") {
      data = {
        ...data,
        new: { ...data.new, ["state"]: value.label, ["city"]: "" },
      };

      get_city_list_via_name(data.new.country, value.label, (cb: any) => {
        setCityData(cb);
      });
    } else if (attrName === "dob") {
      data = {
        ...data,
        new: {
          ...data.new,
          [attrName]: value,
        },
      };
    } else if (attrName === "close_attachment") {
      setAttachment(false);
    } else if (attrName === "attachment_updated") {
      get_endorsement_details();
    } else if (attrName === setPriorityStatus) {
      if (value === 76) {
        setstatusWarningPopoup(true);
        setselectedEndorsementValue(value);
        return;
      }
      if (endorsementDetailsData.hr_status === 76 && (value === 77 || value === 75)) {
        return toast.warning("This status can't be changed");
      }
      if (value === 77) {
        return toast.warning("This status is Automated");
      } else if (
        endorsementDetailsData?.hr_status === 75 &&
        value === 76 &&
        priorityStatus !== 54
      ) {
        return toast.warning("Endorsement status should be Requested");
      }
      update_status(value, id);
    } else {
      data = {
        ...data,
        new: {
          ...data.new,
          [attrName]: typeof value === "object" ? value.label : value,
        },
      };
    }
    setEndorsementDeatilsData(data);
  };

  useEffect(() => {
    setAddPermission(
      EMPLOYER?.Endorsement?.add === undefined
        ? false
        : EMPLOYER?.Endorsement?.add
    );
    setDownloadCsvPermission(
      EMPLOYER?.Endorsement?.csv === undefined
        ? false
        : EMPLOYER?.Endorsement?.csv
    );
    setChangeStatusPermission(
      EMPLOYER?.Endorsement?.status === undefined
        ? false
        : EMPLOYER?.Endorsement?.status
    );
  }, []);

  const update_status = (value: number, id: any) => {
    const onSuccess = (res: any) => {
      get_endorsement_details();
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ENDORSEMENT_SERVICES.hr_update_status(
      {
        value: value,
      },
      onSuccess,
      onError,
      id
    );
  };

  const get_status = () => {
    const onSuccess = (res: any) => {
      let statusData = res.data.data.map((data: any) => {
        return { label: data.value, value: data.label };
      });
      setprioritystatusData(statusData);
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    ENDORSEMENT_SERVICES.get_status(onSuccess, onError);
  };

  // get endrosment list with function contain callback onSucces and onError
  const get_endorsement_details = () => {
    const onSuccess = (res: any) => {
      if (res.status === 200) {
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      }
      sethrStatusValue(res.data.data[0].hr_status);
      setEndorsementDeatilsData(res.data.data[0]);
      setPriorityStatus(res.data.data[0].status);
      setNotesData(res.data.data[0].notes);
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };

    ENDORSEMENT_SERVICES.get_endorsement_details_view(
      `${URL_CONSTANTS.endorsement_details_view(
        userType.toLowerCase()
      )}${id}&tab=OVERVIEW`,
      onSuccess,
      onError
    );
  };

  // api for endrosment indivisual edit
  const endorsement_individual_edit = (dto: any) => {
    const onSuccess = (res: any) => {
      toast.success("Updated Successfully");
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };

    ENDORSEMENT_SERVICES.endorsement_individual_edit(
      `${URL_CONSTANTS.endorsement_indivisual_edit}${id}`,
      dto,
      onSuccess,
      onError
    );
  };

  const endorsement_notes = (dto: any) => {
    let params = {
      endorsementId: id,
      notes: {
        description: dto,
        name: employerUser.email,
        date: new Date(),
      },
    };
    const onSuccess = (res: any) => {
      get_endorsement_details();
      setPostQueryForm(false);
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };

    ENDORSEMENT_SERVICES.endorsement_notes(
      params,
      onSuccess,
      onError,
      userType.toLowerCase()
    );
  };
  // indivisual edit funtion for each fields

  const individual_edit = (attrName: any) => {
    const data: any = endorsementDetailsData;

    if (attrName === "country" || attrName === "state" || attrName === "city") {
      if (!data.new.country || !data.new.state || !data.new.city) {
        return toast.error("Please check value");
      } else {
        endorsement_individual_edit({
          key: `new.country`,
          value: data.new["country"],
        });
        endorsement_individual_edit({
          key: `new.state`,
          value: data.new["state"],
        });
        endorsement_individual_edit({
          key: `new.city`,
          value: data.new["city"],
        });
        setDisableAddress(false);
      }
    }
    if (attrName === "contactNumber") {
      if (!validateMobileNumber(data.new.contactNumber)) {
        return toast.error("Please check Mobile number");
      } else {
        endorsement_individual_edit({
          key: `new.${attrName}`,
          value: data.new[attrName],
        });
      }
    } else {
      if (!data.new[attrName]) {
        return toast.error("Please check value");
      } else {
        endorsement_individual_edit({
          key: `new.${attrName}`,
          value: data.new[attrName],
        });
      }
    }
  };
  let timeoutId: any;
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const delayedApiCall = () => {
      get_Hr_status();
      get_status();
      get_endorsement_details();
      get_country_list((cb: any) => {
        setCountryData(cb);
      });
      setShowLoader(true);
    };

    timeoutId = setTimeout(delayedApiCall, 900);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);
  const get_Hr_status = () => {
    const onSuccess = (res: any) => {
      let HrstatusData = res.data.data.map((data: any) => ({
        label: data.value,
        value: data.label,
      }));
      setHrStatusData(HrstatusData);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    ENDORSEMENT_SERVICES.get_Hr_status(onSuccess, onError);
  };

  return (
    <>
      {showLoader && <NewLoader />}
      <ConfirmationPopup
        statusWarningPopoup={statusWarningPopoup}
        setstatusWarningPopoup={setstatusWarningPopoup}
        update_status={() => {
          update_status(selectedEndorsementValue, endorsementDetailsData?._id);
        }}
        title={`Rejecting the endorsement ${endorsementDetailsData?.endorsementId} will halt the change of ${endorsementDetailsData?.endorsementFor} for ${endorsementDetailsData?.memberId}, ${endorsementDetailsData?.memberName} &  ${endorsementDetailsData?.policyNumber}`}
      />
      <Box className="layoutWrapper">
        {/* Page header */}
        <Grid container spacing={3} className="pageHeader">
          <Grid xs={6} className="pageTitle">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeftIcon />
            </Link>
            <span className="detailIcon">E</span>
            <div>
              <h3>Endorsements - {endorsementDetailsData.endorsementFor}</h3>
              <p>{`${endorsementDetailsData.endorsementId
                }${"  "}${"  "}Created On ${" "}${date_time_format(
                  endorsementDetailsData?.createdAt ?? "",
                  dateFormat,
                  timeZone
                )}`}</p>
            </div>
          </Grid>
          <Grid
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div id={`_${priorityStatus}`}>
              <div className="status_dropdown" id={`_${priorityStatus}`}>
                {priorityStatus === 54 ? (
                  <Requested id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 55 ? (
                  <SentToTPA id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 56 ? (
                  <Required id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 57 ? (
                  <Approved id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 58 ? (
                  <Rejected id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 59 ? (
                  <Expired id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 71 ? (
                  <Expired id={`_${priorityStatus.toString()}`} />
                ) : priorityStatus === 72 ? (
                  <Expired id={`_${priorityStatus.toString()}`} />
                ) : null}
                <SelectDropdown
                  class_name="inputFieldd"
                  title=""
                  value={priorityStatus}
                  attrName={setPriorityStatus}
                  value_update={updateMasterState}
                  dropdown_data={prioritystatusData}
                  warn_status={false}
                  disabled
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Grid container spacing={3}>
              <Grid xs className="leftSection h-100">
                <Link
                  className={
                    sectionStatus === "personalDetails"
                      ? "active sectionLink personalDetails"
                      : "sectionLink personalDetails"
                  }
                  onClick={() => {
                    scrollToSection(personalDetails);
                    SetSectionStatus("personalDetails");
                  }}
                >
                  <span> Member Info</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "memberdetails"
                          ? "inner_link active memberdetails"
                          : "inner_link memberdetails"
                      }
                      onClick={() => {
                        setSubSection("memberdetails");
                        handleClicks(subSection);
                        scrollToSection(memberdetails);
                      }}
                    >
                      Member & Policy Details
                    </Link>
                  </li>
                </ul>
                <Link
                  className={
                    sectionStatus === "endorsementInfo"
                      ? "active sectionLink endorsementInfo"
                      : "sectionLink endorsementInfo"
                  }
                  onClick={() => {
                    scrollToSection(endorsementInfo);
                    SetSectionStatus("endorsementInfo");
                  }}
                >
                  <span> Endorsement Info</span>
                </Link>
                <ul className="accordion_list">
                  <li>
                    <Link
                      className={
                        subSection === "endorsementDetails"
                          ? "inner_link active endorsementDetails"
                          : "inner_link endorsementDetails"
                      }
                      onClick={() => {
                        setSubSection("endorsementDetails");
                        handleClicks(subSection);
                        scrollToSection(endorsementDetails);
                      }}
                    >
                      Endorsement Details
                    </Link>
                  </li>
                </ul>

                <Link
                  className={
                    sectionStatus === "attatchmentsdetails"
                      ? "active sectionLink attatchmentsdetails"
                      : "sectionLink attatchmentsdetails"
                  }
                  onClick={() => {
                    handleClicks(sectionStatus);
                    scrollToSection(attatchmentsdetails);
                    SetSectionStatus("attatchmentsdetails");
                  }}
                >
                  <span>Supporting Documents</span>
                </Link>
              </Grid>
              <Grid xs className="rightSection h-100">
                <Box>
                  <Grid container spacing={3} alignItems="center">
                    <Grid lg={7} md={7} xs={12} className="pl-0">
                      <div className="productTabs ">
                        <Button
                          className={
                            viewTabsStatus === "overview"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            setViewTabsStatus("overview");
                          }}
                        >
                          Overview
                        </Button>

                        <Button
                          className={
                            viewTabsStatus === "notes"
                              ? "defaultTab active"
                              : "defaultTab"
                          }
                          onClick={() => {
                            setViewTabsStatus("notes");
                          }}
                        >
                          Notes
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      lg={5}
                      md={5}
                      xs={12}
                      style={{ textAlign: "right" }}
                      className="text-sm-center"
                    >
                      <p className="lastupdate">
                        Last Update:{" "}
                        {date_time_format(
                          endorsementDetailsData?.updatedAt ?? "",
                          dateFormat,
                          timeZone
                        )}
                      </p>
                      <Grid xs={6}>
                        <div
                          className="client_info_section"
                          style={{ justifyContent: "end" }}
                        >
                          <p
                            className="client_info_p user"
                            style={{ width: "unset" }}
                          >
                            HR Status:{" "}
                            <span>
                              <div
                                className="ml-2"
                                id={`_${endorsementDetailsData?.hr_status}`}
                                style={{ borderRadius: "20px" }}
                              >
                                <div
                                  className="status_dropdown"
                                  id={`_${endorsementDetailsData?.hr_status?.toString()}`}
                                >
                                  {endorsementDetailsData?.hr_status === 77 ? (
                                    <Requested
                                      id={`_${endorsementDetailsData?.hr_status.toString()}`}
                                    />
                                  ) : endorsementDetailsData?.hr_status ===
                                    75 ? (
                                    <Approved
                                      id={`_${endorsementDetailsData?.hr_status.toString()}`}
                                    />
                                  ) : endorsementDetailsData?.hr_status ===
                                    76 ? (
                                    <Rejected
                                      id={`_${endorsementDetailsData?.hr_status.toString()}`}
                                    />
                                  ) : null}

                                  <SelectDropdown
                                    class_name="inputFieldd"
                                    title=""
                                    value={hrStatusValue}
                                    attrName={setPriorityStatus}
                                    value_update={updateMasterState}
                                    dropdown_data={hrStatusData}
                                    warn_status={false}
                                    disabled={!changeStatusPermission}
                                  />
                                </div>
                              </div>
                            </span>
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {viewTabsStatus === "overview" ? (
                    <>
                      <Box
                        onMouseEnter={() => {
                          SetSectionStatus("personalDetails");
                        }}
                        className="detailSection mt-4"
                        ref={personalDetails}
                      >
                        <div className="sectionTitle">
                          <h4>Member Info</h4>
                        </div>
                        <div
                          onMouseEnter={() => {
                            setSubSection("memberdetails");
                          }}
                          ref={memberdetails}
                        >
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">
                                Member & Policy Details
                              </h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={4} md={6} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Member ID"
                                value={`${endorsementDetailsData?.memberId}`}
                                attrName={"setMemberId"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>

                            <Grid xs={6} md={6} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Client ID"
                                value={`${endorsementDetailsData?.clientId} ${endorsementDetailsData?.clientName &&
                                  endorsementDetailsData?.clientName
                                  }`}
                                attrName={"setclientId"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={6} md={4} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Policy"
                                value={endorsementDetailsData?.policy}
                                attrName={"setpolicyId"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                            <Grid xs={6} md={4} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="Insurer"
                                value={endorsementDetailsData?.insurer}
                                attrName={"setInsurer"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                            <Grid xs={6} md={4} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title="TPA"
                                value={endorsementDetailsData?.tpaName}
                                attrName={""}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box
                        className="detailSection"
                        onMouseEnter={() => {
                          SetSectionStatus("endorsementInfo");
                        }}
                        ref={endorsementInfo}
                      >
                        <div className="sectionTitle">
                          <h4>Endorsement Info</h4>
                        </div>
                        <div
                          onMouseEnter={() => {
                            setSubSection("endorsementDetails");
                          }}
                          ref={endorsementDetails}
                        >
                          <Grid container spacing={3}>
                            <Grid xs={12}>
                              <h5 className="subHeading">
                                Endorsement Details
                              </h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Endorsement Value"
                                value={endorsementDetailsData?.endorsementFor}
                                attrName={"setEndorsementValue"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                            <Grid xs={6} md={6} lg={3}>
                              <RKTextField
                                class_name="inputField"
                                title="Endorsement Type"
                                value={endorsementDetailsData?.type.toLocaleLowerCase()}
                                attrName={"setEndorsementType"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid xs={8} md={8} lg={4}>
                              <RKTextField
                                class_name="inputField"
                                title={`old ${endorsementDetailsData?.endorsementFor}`}
                                value={
                                  endorsementDetailsData?.endorsementFor ===
                                    "Date of Birth"
                                    ? date_time_format(
                                      endorsementDetailsData?.old,
                                      dateFormat,
                                      timeZone
                                    )
                                    : endorsementDetailsData?.old
                                }
                                attrName={"setOldValue"}
                                value_update={updateMasterState}
                                warn_status={false}
                              />
                            </Grid>
                            <Grid xs={12}>
                              <h5 className="mb-0 font-sm">
                                New {endorsementDetailsData?.endorsementFor}
                              </h5>
                            </Grid>
                            {endorsementDetailsData?.endorsementFor ===
                              "Personal Address" ? (
                              <>
                                <Grid xs={6} md={6} lg={3}>
                                  <RKTextFieldDisable
                                    disabled={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="Building/Street Name"
                                    params={individual_edit}
                                    value={endorsementDetailsData.new.street}
                                    value_update={updateMasterState}
                                    attrName={"street"}
                                    warn_status={false}
                                  />
                                </Grid>

                                <Grid xs={6} md={6} lg={3}>
                                  <RKTextFieldDisable
                                    disabled={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="Area Name"
                                    params={individual_edit}
                                    value={endorsementDetailsData.new.area}
                                    attrName={"area"}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                  />
                                </Grid>
                                <Grid xs={6} md={6} lg={3}>
                                  <SearchDropdownDisable
                                    fieldDisable={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="Country"
                                    params={individual_edit}
                                    value={endorsementDetailsData?.new.country}
                                    attrName={"country"}
                                    value_update={updateMasterState}
                                    data={countryData}
                                    warn_status={false}
                                    disable_status={disableAddress}
                                    set_disable_status={setDisableAddress}
                                    error_messg="Please select client"
                                  />
                                </Grid>
                                <Grid xs={6} md={6} lg={3}>
                                  <SearchDropdownDisable
                                    fieldDisable={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="State"
                                    params={individual_edit}
                                    value={endorsementDetailsData?.new.state}
                                    attrName={"state"}
                                    value_update={updateMasterState}
                                    data={stateData}
                                    disable_status={disableAddress}
                                    set_disable_status={setDisableAddress}
                                    warn_status={false}
                                    error_messg="Please select client"
                                  />
                                </Grid>
                                <Grid xs={6} md={6} lg={3}>
                                  <SearchDropdownDisable
                                    fieldDisable={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="City"
                                    params={individual_edit}
                                    value={endorsementDetailsData?.new.city}
                                    attrName={"city"}
                                    disable_status={disableAddress}
                                    set_disable_status={setDisableAddress}
                                    value_update={updateMasterState}
                                    data={cityData}
                                    warn_status={false}
                                    error_messg="Please select client"
                                  />
                                </Grid>
                                <Grid xs={6} md={6} lg={3}>
                                  <RKTextFieldDisable
                                    disabled={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title={"Zipcode"}
                                    params={individual_edit}
                                    value={
                                      endorsementDetailsData?.new.zipCode ||
                                      endorsementDetailsData?.new.zip
                                    }
                                    attrName={"zipCode"}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                  />
                                </Grid>
                              </>
                            ) : endorsementDetailsData?.endorsementFor ===
                              "Gender" ? (
                              <>
                                <Grid xs={6} md={6} lg={3}>
                                  <SearchDropdownDisable
                                    fieldDisable={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="Gender"
                                    params={individual_edit}
                                    value={endorsementDetailsData.new.gender}
                                    attrName={"gender"}
                                    value_update={updateMasterState}
                                    data={gender_data}
                                    warn_status={false}
                                    error_messg="Please select client"
                                  />
                                </Grid>
                              </>
                            ) : endorsementDetailsData?.endorsementFor ===
                              "Salary" ||
                              endorsementDetailsData?.endorsementFor ===
                              "Salary Grade" ? (
                              <>
                                <Grid xs={6} md={6} lg={3}>
                                  <RKTextFieldDisable
                                    disabled={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="Gender"
                                    params={individual_edit}
                                    value={
                                      endorsementDetailsData?.endorsementFor ===
                                        "Salary"
                                        ? formatCurrency(
                                          endorsementDetailsData.new.salary?.split(
                                            "/"
                                          )[0]
                                        )
                                        : endorsementDetailsData.new
                                          .salary_grade
                                    }
                                    attrName={"gender"}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                    error_messg="Please select client"
                                  />
                                </Grid>
                              </>
                            ) : endorsementDetailsData?.endorsementFor ===
                              "Name" ? (
                              <>
                                <Grid xs={6} md={6} lg={3}>
                                  <RKTextFieldDisable
                                    disabled={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="First Name"
                                    params={individual_edit}
                                    value={endorsementDetailsData.new.firstName}
                                    attrName={"firstName"}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                  />
                                </Grid>
                                <Grid xs={6} md={6} lg={3}>
                                  <RKTextFieldDisable
                                    disabled={
                                      endorsementDetailsData.status ===
                                        "Request Raised"
                                        ? false
                                        : true
                                    }
                                    class_name="inputField"
                                    title="Last Name"
                                    params={individual_edit}
                                    value={endorsementDetailsData.new.lastName}
                                    attrName={"lastName"}
                                    value_update={updateMasterState}
                                    warn_status={false}
                                  />
                                </Grid>
                              </>
                            ) : endorsementDetailsData?.endorsementFor ===
                              "Contact Number" ? (
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextFieldDisable
                                  disabled={
                                    endorsementDetailsData.status ===
                                      "Request Raised"
                                      ? false
                                      : true
                                  }
                                  class_name="inputField"
                                  title="Conatct Number"
                                  params={individual_edit}
                                  value={
                                    endorsementDetailsData.new.contactNumber
                                  }
                                  attrName={"contactNumber"}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                />
                              </Grid>
                            ) : endorsementDetailsData?.endorsementFor ===
                              "Email" ? (
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextFieldDisable
                                  disabled={
                                    endorsementDetailsData.status ===
                                      "Request Raised"
                                      ? false
                                      : true
                                  }
                                  class_name="inputField"
                                  title="Email"
                                  params={individual_edit}
                                  value={endorsementDetailsData.new.email}
                                  attrName={"email"}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                />
                              </Grid>
                            ) : endorsementDetailsData?.endorsementFor ===
                              "Date of Birth" ? (
                              <Grid xs={6} md={6} lg={3}>
                                <DatePickerDisable
                                  disabled={
                                    endorsementDetailsData.status ===
                                      "Request Raised"
                                      ? false
                                      : true
                                  }
                                  class_name="inputField"
                                  title="Date of Birth"
                                  params={individual_edit}
                                  value={endorsementDetailsData.new.dob}
                                  attrName={"dob"}
                                  value_update={updateMasterState}
                                  error_message="Enter a Valid Date"
                                  max_date={new Date()}
                                  warn_status={false}
                                />
                              </Grid>
                            ) : (
                              <Grid xs={6} md={6} lg={3}>
                                <RKTextFieldDisable
                                  disabled={
                                    endorsementDetailsData.status ===
                                      "Request Raised"
                                      ? false
                                      : true
                                  }
                                  class_name="inputField"
                                  title={endorsementDetailsData?.endorsementFor}
                                  params={individual_edit}
                                  value={
                                    endorsementDetailsData.new[
                                    endorsementDetailsData?.endorsementFor.toLowerCase()
                                    ]
                                  }
                                  attrName={"email"}
                                  value_update={updateMasterState}
                                  warn_status={false}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </Box>

                      <Box
                        className="detailSection"
                        onMouseEnter={() => {
                          SetSectionStatus("attatchmentsdetails");
                        }}
                        ref={attatchmentsdetails}
                      >
                        <div className="sectionTitle">
                          <h4>Supporting Documents </h4>

                          <div className="actionBtns">
                            {addPermission && (
                              <Link
                                className="blueBtn addfile"
                                sx={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => setAttachment(true)}
                              >
                                Add Document
                              </Link>
                            )}
                          </div>
                        </div>
                        {endorsementDetailsData.attachments && (
                          <Attachment
                            open_status={attachment}
                            attrName={setAttachment}
                            value_update={updateMasterState}
                            dataArray={endorsementDetailsData.attachments}
                            endoresemetId={id}
                            downnloadCsvPermission={downnloadCsvPermission}
                          />
                        )}
                      </Box>
                    </>
                  ) : null}

                  {viewTabsStatus === "notes" ? (
                    <>
                      <Box className="detailSection mt-4">
                        <div className="sectionTitle">
                          <h4>Notes</h4>

                          <div className="actionBtns">
                            {addPermission && (
                              <Link
                                className="blueBtn addfile"
                                sx={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setPostQueryForm(true);
                                }}
                              >
                                Add Notes
                              </Link>
                            )}
                          </div>
                        </div>

                        <NotesSection data={notesData} />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <PostQueryForm
          attrName={setPostQueryForm}
          open_status={postQueryForm}
          value_update={updateMasterState}
        />

        <Modal open={deleteStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are about to Delete this Attatchment.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={removeStatus} className="modalWrapper">
          <Box className="modalInner">
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setRemoveStatus(false);
                  }}
                />
                <h5 className="popup-heading">Are you sure ?</h5>
              </Grid>
              <Grid xs={12}>
                <Link className="md-link" underline="none">
                  You are want to remove this Endorsement.
                </Link>
              </Grid>
              <Grid xs={12} className="ctaBtn" textAlign={"center"}>
                <Button variant="contained" className="submitBtn">
                  Yes, Delete
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
export default EndorsementDetail;
